import React from "react";
import Typography from "@mui/material/Typography";
import ChangePasswordForm from "components/login/ChangePasswordForm";
import ForgotPasswordForm from "components/login/ForgotPasswordForm";
import { useSearchParams } from "react-router-dom";
import ChangePasswordTeachersForm from "../components/login/teachers/ChangePasswordFormTeacters";
import ForgotPasswordFormTeachers from "../components/login/teachers/ForgotPasswordFormTeachers";
import LinkStyled from "../components/ui/LinkStyled";

const ForgotPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const tenant = searchParams.get("tenant");

  return (
    <>
      <Typography variant="h2" sx={{ marginBottom: 2 }}>
        Resetează parola
      </Typography>
        {tenant === "teacher" && (token ? <ChangePasswordTeachersForm /> : <ForgotPasswordFormTeachers />)}
        {tenant === "school" && (token ? <ChangePasswordForm /> : <ForgotPasswordForm />)}
        {tenant === null && (token ? <ChangePasswordForm /> : <ForgotPasswordForm />)}

        {tenant === "teacher" &&  <LinkStyled to="/login/profesori">Autentificare</LinkStyled>}
        {tenant === "school" && <LinkStyled to="/">Autentificare</LinkStyled>}
        {tenant === null && <LinkStyled to="/">Autentificare</LinkStyled>}
    </>
  );
};

export default ForgotPassword;
