import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// @ts-ignore
import { faBars, faChevronRight } from '@awesome.me/kit-b8aac00bfe/icons/classic/solid';
import Drawer from "@mui/material/Drawer";
// import Search from "components/sidebar/Search";
import Menu from "components/sidebar/Menu";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CustomButton from "components/ui/CustomButton";
import BrioLogoFooter from "./BrioLogoFooter";

const MenuMobile = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <IconButton
        aria-label="menu"
        onClick={toggleDrawer}
        size="large"
        sx={{ backgroundColor: "#fff", borderRadius: 0, padding: "14px", color: "secondary.light" }}
      >
        <FontAwesomeIcon icon={faBars} />
      </IconButton>
      <Drawer
        PaperProps={{
          sx: { width: { xs: "100%", sm: "60%" }, borderRadius: "30px 0 0 30px" },
        }}
        anchor="right"
        open={open}
        onClose={toggleDrawer}
      >
          <Box display="flex" height="100%" flexDirection="column" justifyContent="space-between" mb={3}>
              <Box>
                  <Box my={2} ml={4}>
                      <CustomButton
                          buttonType="icon"
                          submit={true}
                          bgcolor="light"
                          onClick={handleDrawerClose}
                          icon={<FontAwesomeIcon style={{ fontSize: "1rem" }} icon={faChevronRight} />}
                      />
                  </Box>
                  <Box onClick={handleDrawerClose}>
                      {/* <Search /> */}
                      <Box width="100%" flexGrow={1} sx={{ overflow: "hidden", paddingRight: { xs: 0, lg: 1, xl: 3 } }}>
                          <Menu />
                      </Box>
                  </Box>
              </Box>
              <Box width="100%" pl={3} pt={3} pb={2}>
                  <BrioLogoFooter />
              </Box>
          </Box>
      </Drawer>
    </Box>
  );
};

export default MenuMobile;
