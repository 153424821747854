import {Text, View} from "@react-pdf/renderer";
import {styles } from "../../StylesPdfReport";

const AboutSection = () => {
    return (
        <>
            <View style={styles.sectionAbout}>
                <Text style={styles.h1}>Despre Raportul BRIO</Text>
                <View style={styles.subsection}>
                    <Text style={styles.h3}>Scopul raportului</Text>
                    <View style={styles.subsectionContent}>
                        <Text style={styles.p}>
                            Acest raport descrie nivelul de abilități de literație de bază (denumite și „alfabetizare
                            funcțională”) pentru elevul testat. El se bazează pe BRIO®, sistemul de testare standardizată pentru
                            accelerarea performanței în învățământ.
                            <br />
                            Raportul conține detalii privind indicele de literație și scorurile aferente abilităților de literație
                            de bază.
                        </Text>
                    </View>
                </View>
                <View style={styles.subsection}>
                    <Text style={styles.h3}>Ce sunt abilitățile de literație de bază?</Text>
                    <View style={styles.subsectionContent}>
                        <Text style={styles.p}>
                            Literația se referă la capacitatea unei persoane de a interacționa cu un text scris, în sensul
                            înțelegerii, evaluării, utilizării informațiilor și reflectării asupra acestora.
                        </Text>
                        <Text style={styles.p}>Literația presupune manifestarea a trei abilități, și anume:</Text>
                        <View style={{ flexDirection: "column", marginLeft: "12pt" }}>
                            <View style={{ flexDirection: "row", marginBottom: 5 }}>
                                <Text>
                                    <Text style={{ fontWeight: 600 }}>Localizarea de informație</Text>, anume capacitatea de a găsi în
                                    text o informație care este exprimată în mod direct, astfel încât elevul nu trebuie să facă
                                    presupuneri sau inferențe pentru a răspunde corect.
                                </Text>
                            </View>
                            <View style={{ flexDirection: "row", marginBottom: 5 }}>
                                <Text>
                                    <Text style={{ fontWeight: 600 }}>Înțelegerea</Text>, care presupune capacitatea de a da sens unor
                                    cuvinte, propoziții sau înșiruiri de evenimente dintr-un material. Astfel, dimensiunea acoperă, pe
                                    de o parte, cunoștințe specifice din sfera vocabularului. Pe de altă parte, această dimensiune
                                    vizează integrarea cu sens a unor informații disparate din material și capacitatea de a urmări
                                    succesiunea temporală a unor evenimente prezentate.
                                </Text>
                            </View>
                            <View style={{ flexDirection: "row", marginBottom: 5 }}>
                                <Text>
                                    <Text style={{ fontWeight: 600 }}>Reflecția</Text>, adică abilitatea de a reflecta la o anumită
                                    parte a unui material, pentru a desprinde fie ideea centrală, fie pentru a evalua critic
                                    informația accesată.
                                </Text>
                            </View>
                        </View>
                        <Text style={styles.p}>
                            Raportul de față conține scorurile aferente fiecărei abilități descrise mai sus.
                        </Text>
                    </View>
                </View>
                <View style={styles.subsection}>
                    <Text style={styles.h3}>Utilitatea acestui raport</Text>
                    <View style={styles.subsectionContent}>
                        <Text style={styles.p}>
                            Testul pe care se bazează acest raport nu este derivat din programa școlară, astfel nefiind specific
                            unei materii anume. Cu toate acestea, capacitatea de a opera la un nivel de bază cu un text este
                            esențială pentru elevi în situații variate, iar dezvoltarea insuficientă a literației are efecte
                            negative pentru performanța școlară a acestora.
                        </Text>
                    </View>
                </View>
                <View style={styles.subsection}>
                    <Text style={styles.h3}>Indicele de literație</Text>
                    <View style={styles.subsectionContent}>
                        <Text style={styles.p}>
                            Rezultatele sunt prezentate sub forma unui indice de literație, un scor care poate varia între 0
                            (foarte slab) și 100 (foarte competent). Indicele de literație este scorul total obținut de elevi la
                            evaluare și reflectă nivelul general al competenței de literație, capacitatea generală a elevului de a
                            procesa textul citit în cursul testării. Acest indice se calculează pe baza răspunsurilor corecte.
                        </Text>
                    </View>
                </View>
            </View>
        </>
    )
}

export default AboutSection;