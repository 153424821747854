import { useEffect, useRef, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Icon from "components/ui/Icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import { IDashboardBoxOptionsData } from "components/dashboard/_interfaces";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";
import IconButton from "@mui/material/IconButton";

const DropDown = ({ data, title }: IDashboardBoxOptionsData) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <IconButton
        ref={anchorRef}
        id={`menu-button-${title}`}
        aria-controls={open ? `menu-button-${title}` : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        sx={(theme) => ({
          color: "primary.contrastText",
          backgroundColor: "primary.light",
          "&:hover": {
            color: "primary.contrastText",
            backgroundColor: "secondary.light",
          },
          "&[aria-expanded='true']": {
            backgroundColor: "secondary.light",
          },
          borderRadius: theme.spacing(0.6),
          padding: theme.spacing(0.7),
          width: 30,
          height: 30,
          border: "none",
        })}
      >
        <FontAwesomeIcon style={{ fontSize: "1rem", width: "16px", height: "16px" }} icon={data.icon} />
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-end" transition disablePortal sx={{zIndex: 999}}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-end" ? "left top" : "left bottom",
            }}
          >
            <div>
              <ClickAwayListener onClickAway={handleClose} >
                <MenuList
                  autoFocusItem={open}
                  id={`menu-button-${title}`}
                  aria-labelledby={`menu-button-${title}`}
                  onKeyDown={handleListKeyDown}
                  sx={{
                    borderRadius: "6px",
                    minWidth: 180,
                    backgroundColor: "secondary.light",
                    color: "primary.contrastText",
                    boxShadow: "none",
                    height: "auto",
                    borderTop: "2px solid",
                    borderColor: "background.paper",
                  }}
                >
                  {data.menu.map((item, index) => (
                    <MenuItem
                      onClick={(e) => {
                        item.action();
                        handleClose(e);
                      }}
                      disableRipple
                      key={index}
                      sx={{
                        marginBottom: 0,
                        "&:active": {
                          backgroundColor: "primary.main",
                        },
                      }}
                    >
                      <Box component="span" display="inline-flex" alignItems="center">
                        <Box component="span" sx={{ width: 30 }} display="flex" justifyContent="flex-start">
                          <Icon icon={item.icon} key={index} fixedWidth={true} sx={{ marginRight: 1.5 }} />
                        </Box>
                        <Box component="span" flexGrow={1}>
                          {item.title}
                        </Box>
                      </Box>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default DropDown;
