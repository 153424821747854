import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import ErrorsList from "components/ui/ErrorsList";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
interface Option {
  value: string | number;
  label: string | JSX.Element;
  [key: string]: any;
}

interface Props {
  name: string;
  label?: string | JSX.Element;
  defaultValue?: string;
  checked?: boolean;
  error?: string | undefined;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  labelPlacement?: "top" | "bottom" | "end" | "start" | undefined;
  options?: Option[];
  row?: boolean;
  columns?: boolean;
}

const RadioField = ({
  name,
  label,
  defaultValue,
  checked = false,
  error = "",
  className = "",
  onChange,
  options = [],
  row = false,
  columns = false,
}: Props) => {
  const methods = useFormContext();
  return (
    <>
      <Controller
        name={name}
        control={methods.control}
        render={({ field }) => (
          <>
            <RadioGroup
              aria-labelledby="radio-button"
              value={defaultValue}
              onChange={onChange}
              sx={{ width: "100%" }}
              row={row}
              name={name}
            >
              <Box width="100%" sx={{ columnCount: { xs: columns ? 2 : 1, md: columns ? 2 : 1, lg: columns ? 3 : 1 } }}>
                {options.map((option) => (
                  <Box key={option.value} width="100%" mb={0.5}>
                    <FormControlLabel
                        control={<Radio sx={{
                      color: "text.primary",
                      '&.Mui-checked': {
                        color: "text.primary",
                      },}} />}
                        value={option.value} label={option.label} />
                    {option.description}
                  </Box>
                ))}
              </Box>
            </RadioGroup>
          </>
        )}
      />
      <ErrorsList show={error !== ""}>{error}</ErrorsList>
    </>
  );
};

export default RadioField;
