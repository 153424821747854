import { APIPostReportDigitalLiteracy } from "api/pages/report";
import Loading from "components/ui/Loading";
import { useAuth } from "hooks/useAuth";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Report from "components/reports/digitalLiteracy/pdf/Report";

const ReportDigitalLiteracy = () => {
  const { id = "" } = useParams();
  const { update } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [reportData, setReportData] = useState<any>();
  const [gradeLevels, setGradeLevel] = useState<any>();
  const [graphData, setGraphData] = useState<any>();
  const [skillsData, setSkillsData] = useState<any>();

  useEffect(() => {
    if (id !== "") {
      setLoading(true);
      APIPostReportDigitalLiteracy({ test_id: id })
        .then((response) => {
          if (response.data.success) {
            // console.log("getReport OK", response);
            setReportData(response.data.data);
            setGradeLevel(response.data.data.grade_levels);
            setGraphData(response.data.data.graph_data);
            setSkillsData(response.data.data.skills_data);
            setLoading(false);
          } else {
            update("error", { show: true, code: "A002" });
          }
        })
        .catch((err) => {
          // console.log("getReport ERR", err);
          update("error", { show: true, code: "A001" });
        });
    } else {
      navigate("/teste");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  // return <SchoolTestReport test={historyReport} />;
  return (
    <>
      {loading ? (
        <Loading show={true} />
      ) : (
        <Report
          reportData={reportData}
          gradeLevels={gradeLevels}
          graphData={graphData}
          skillsData={skillsData}
        />
      )}
    </>
  );
};

export default ReportDigitalLiteracy;
