import axiosClient from "api/apiClient";

interface IOptions {
  id?: string;
  sort?: string;
  search?: string;
}

export const APIGetClassroom = (options: IOptions) => {
  let sort = "";
  let search = "";
  if (options.hasOwnProperty("sort")) {
    sort = "&sort=" + options.sort;
  }
  if (options.hasOwnProperty("search")) {
    search = "&filter[search]=" + options.sort;
  }
  return axiosClient.get(`/classrooms/${options.id}?${search}${sort}`);
};

export const APIDeleteClassroom = (options: IOptions) => {
  return axiosClient.delete(`/classrooms/${options.id}`);
};

export const APIUpdateClassroom = (id: string, data: object) => {
  return axiosClient.put(`/classrooms/${id}`, data);
};

export const APIPostClassroom = (data: object) => {
  return axiosClient.post(`/classrooms`, data);
};
