import "components/reports/SchoolTestReport.css";
import CoverPage from "../components/CoverPage";
import Chart from "../components/Chart";
import AboutSection from "./AboutSection";
import ChartNoteWrapperBig from "../components/ChartNoteWrapperBig";
import TableRow from "../components/TableRow";
import MarkGroup from "../components/MarkGroup";
import Units from "../components/Units";
import ChartSubsection from "./ChartSubsection";
import ScrollBar from "../../ui/ScrollBar";

const Report = ({ reportData, gradeLevels, graphData}: any) => {

    return (
        <>
            <div className="cover-bg cover-test-peach">
                <div id="print_page" className="wrapper-cover">
                    <CoverPage coverImage="/images/reports/cover-literatie.png" type="literacy">
                        <div className="title">
                            <h1>Testul de literație </h1>
                            <h2>
                                Sesiune #{reportData.test.id}
                            </h2>
                            <h1>Indice de literație {reportData.score}</h1>
                        </div>
                    </CoverPage>

                    <div className="pagebreak"></div>

                    <AboutSection />

                    <div className="pagebreak"></div>

                    <div className="section-despre">
                        <h1>Indicele de literație: {reportData.score} din 100</h1>
                        <Chart
                            testScore={reportData.score}
                            type="literacy"
                        />
                    </div>

                    <div className="pagebreak"></div>
                    {graphData.length > 0 && (
                        <div className="section-despre">
                            <h1>Profilul competențelor evaluate</h1>
                            <ScrollBar forceVisible="x" autoHide={false}  >
                            <table className="tabel-note">
                                <thead>
                                <tr>
                                    <th className="col-capitol">&nbsp;</th>
                                    <th className="col-values">
                                        <Units type="literacy"/>
                                        <MarkGroup type="literacy" />
                                    </th>
                                </tr>
                                </thead>

                                {graphData.map((section: any, index: any) => (
                                    <tbody key={index}>
                                    <TableRow section={section} index={index} />
                                    </tbody>
                                ))}
                                <tfoot>
                                <tr className="legenda">
                                    <td>&nbsp;</td>
                                    <td className="col-legend">
                                        <img src="/images/reports/chart-tabel-literatie.png" className="chart-tabel" alt="" />
                                        <Units type="literacy"/>
                                        <ChartNoteWrapperBig big={false} type="literacy" />
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                            </ScrollBar>
                        </div>
                    )}

                    <div className="pagebreak"></div>
                    {gradeLevels.length > 0 && (
                        <div className="section-despre">
                            <h1>Interpretarea indicelui de literație</h1>
                            <p>
                                Mai jos este prezentată semnificația indicelui de literație rezultat în urma evaluării elevilor, în funcție de
                                încadrarea scorului la nivelul de alfabetizare subdezvoltată (scor între 0 și 25), nivelul funcțional al
                                alfabetizării (26-80) sau nivelul înalt al alfabetizării (81-100).
                            </p>
                            {gradeLevels.slice(0)
                                .reverse().map((gradeLevel: any, index: any) => (
                                <ChartSubsection gradeLevel={gradeLevel} score={reportData.score} index={index} type="literacy"/>))}
                            </div>

                    )}
                </div>
            </div>
        </>
    );
};

export default Report;
