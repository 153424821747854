import Typography from "@mui/material/Typography";
import RadioField from "components/form/RadioField";
import BoxContent from "components/ui/BoxContent";
import Loading from "components/ui/Loading";

const CreateTestGrade = ({ loading, errors, grade, grades, setGradeHandler }: any) => {
  return (
    <>
      <Typography variant="h3" mb={3} mt={3}>
        Alege clasa
      </Typography>
      <BoxContent>
        {loading.loading && loading.name === "grade" ? (
          <Loading show={true} />
        ) : (
          <RadioField
            row={true}
            name="grade"
            columns={true}
            defaultValue={grade}
            onChange={setGradeHandler}
            options={grades.map((item: { name: string; id: string }) => {
              return {
                label: item.name,
                value: item.id.toString(),
                ...item,
              };
            })}
            error={errors.grade?.message}
          />
        )}
      </BoxContent>
    </>
  );
};

export default CreateTestGrade;
