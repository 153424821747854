// import { styled } from "@mui/material/styles";
import { Theme } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { SystemStyleObject } from "@mui/system";
import Button from "@mui/material/Button";
import React from "react";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useAuth} from "../../hooks/useAuth";

interface Props {
  buttonType?: string;
  appearance?: string;
  bgcolor?: string;
  submit?: boolean;
  color?: string;
  size?: "small" | "medium" | "large" | undefined;
  borderColor?: string;
  icon?: JSX.Element;
  sxExtra?: SystemStyleObject<Theme> | undefined;
  // All other props
  [x: string]: any;
  children?: string | JSX.Element | JSX.Element[];
}

// const IconButtonStyled = styled(IconButton)(({ theme }) => ({
//   "& :after": {
//     content: `""`,
//     display: "block",
//     paddingBottom: "100%",
//   },
// }));

const CustomButton = (props: Props) => {
  const {
    buttonType,
    appearance,
    bgcolor = "",
    color = "",
    borderColor = "",
    icon,
    submit,
    width = 30,
    height = 30,
    size = "small",
    sxExtra = {},
    children = "",
    ...rest
  } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const{user} = useAuth();

  const getColor = () => {
    if (color !== "") {
      return color;
    } else {
      switch (bgcolor) {
        case "light":
          return "primary.contrastText";
        case "white":
          return "secondary.light";
        case "dark":
          return "secondary.contrastText";
        case "error":
          return "secondary.contrastText";
        case "table":
          return "text.primary";
        case "test":
          return user.theme === "light" ? "secondary.contrastText" : "secondary.dark";
        case "text":
          return "primary.main";
        default:
          return "secondary.contrastText";
      }
    }
  };
  const getColorHover = () => {
    switch (bgcolor) {
      case "light":
        return "primary.contrastText";
      case "paper":
        return "primary.contrastText";
      case "white":
        return "primary.contrastText";
      case "dark":
        return "secondary.contrastText";
      case "error":
        return "secondary.contrastText";
      case "table":
        return "primary.contrastText";
      case "modal":
        return user.theme === "light" ? "secondary.contrastText" : "primary.dark";
      case "test":
        return user.theme === "light" ? "secondary.contrastText" : "secondary.dark";
      case "text":
        return "primary.dark";
      default:
        return "secondary.contrastText";
    }
  };

  const getBgcolor = () => {
    switch (bgcolor) {
      case "light":
        return "primary.light";
      case "paper":
        return "background.paper";
      case "error":
        return "error.main";
      case "warning":
        return "warning.main";
      case "white":
        return "info.light";
      case "dark":
        return "primary.dark";
      case "table":
        return "primary.light";
      case "modal":
        return "primary.dark";
      case "test":
        return user.theme === "light" ? "primary.dark" : "secondary.light";
      default:
        return "primary.dark";
    }
  };
  const getBgcolorHover = () => {
    switch (bgcolor) {
      case "light":
        return "secondary.light";
      case "paper":
        return "secondary.light";
      case "white":
        return "secondary.light";
      case "dark":
        return "secondary.main";
      case "error":
        return "secondary.dark";
      case "table":
        return "secondary.light";
      case "modal":
        return user.theme === "light" ? "info.dark" : "primary.main";
      case "test":
        return user.theme === "light" ? "secondary.light" : "secondary.contrastText";
      default:
        return "info.dark";
    }
  };

  const getColorOutlineHover = () => {
    switch (bgcolor) {
      case "light":
        return "primary.contrastText";
      case "paper":
        return "primary.contrastText";
      case "white":
        return "primary.contrastText";
      case "dark":
        return "secondary.main";
      case "error":
        return "secondary.contrastText";
      case "table":
        return "primary.contrastText";
      case "modal":
        return user.theme === "light" ? "secondary.main" : "primary.dark";
      default:
        return "secondary.contrastText";
    }
  };

  const getBordercolor = () => {
    if (borderColor !== "") {
      return borderColor;
    } else return "transparent";
  };

  const getBordercolorHover = () => {
    switch (bgcolor) {
      case "light":
        return "secondary.light";
      case "paper":
        return "secondary.light";
      case "white":
        return "secondary.light";
      case "dark":
        return "secondary.main";
      case "error":
        return "secondary.dark";
      case "table":
        return "secondary.light";
      case "modal":
        return user.theme === "light" ? "secondary.main" : "primary.dark";
      default:
        return "info.dark";
    }
  };


  return (
      <>
          {buttonType === "icon" && (
              <IconButton
                  {...rest}
                  {...(submit ? {type: "submit"} : {})}
                  sx={(theme) => ({
                      color: getColor(),
                      backgroundColor: getBgcolor(),
                      "&:hover": {
                          color: getColorHover(),
                          backgroundColor: getBgcolorHover(),
                      },
                      "&[aria-expanded='true']": {
                          backgroundColor: getBgcolorHover(),
                      },
                      borderRadius: appearance === "circle" ? "50%" : theme.spacing(0.6),
                      padding: appearance === "circle" ? theme.spacing(0.5) : theme.spacing(0.7),
                      width: width,
                      height: height,
                      border: borderColor !== "" ? "1px solid" : "none",
                      borderColor: getBordercolor(),
                      ...sxExtra,
                  })}
              >
                  <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
                      {icon}
                  </Box>
              </IconButton>
          )}
          {buttonType === "textIcon" && (
              <Button
                  {...rest}
                  {...(submit ? {type: "submit"} : {})}
                  sx={(theme) => ({
                      color: getColor(),
                      backgroundColor: getBgcolor(),
                      "&:hover": {
                          color: getColorHover(),
                          backgroundColor: getBgcolorHover(),
                      },
                      "&[aria-expanded='true']": {
                          backgroundColor: getBgcolorHover(),
                      },
                      borderRadius: theme.spacing(0.6),
                      padding: appearance === "big" ? isSmallScreen ? "11.5px 16px" : "6px 10px" : isSmallScreen ? "6px 16px" : "6px 10px",
                      border: borderColor !== "" ? "1px solid" : "none",
                      borderColor: getBordercolor(),
                    whiteSpace: "nowrap",
                      ...sxExtra,
                  })}
                  variant="contained"
                  size={isSmallScreen ? "medium" : "small"}
                  startIcon={icon}
              >
                <>
                  {children}
                </>
              </Button>
          )}
        {buttonType === "textIconOutlined" && (
            <>
              <Button
                  {...rest}
                  {...(submit ? {type: "submit"} : {})}
                  sx={(theme) => ({
                    color: getColor(),
                    "&:hover": {
                      color: getColorOutlineHover(),
                      borderColor: getBordercolorHover(),
                    },
                    "&[aria-expanded='true']": {
                      backgroundColor: getBgcolorHover(),
                    },
                    borderRadius: theme.spacing(0.6),
                    padding: appearance === "big" ? isSmallScreen ? "11.5px 16px" : "6px 10px" : isSmallScreen ? "6px 16px" : "6px 10px",
                    border: "1px solid",
                    borderColor: getBordercolor(),
                    whiteSpace: "nowrap",
                    ...sxExtra,
                  })}
                  variant="outlined"
                  size={isSmallScreen ? "medium" : "small"}
                  startIcon={icon}
              >
                <>
                  {children}
                </>
              </Button>
            </>
        )}
        {buttonType === "textIconSmall" && (
            <Button
                {...rest}
                {...(submit ? {type: "submit"} : {})}
                sx={(theme) => ({
                  color: getColor(),
                  backgroundColor: getBgcolor(),
                  "&:hover": {
                    color: getColorHover(),
                    backgroundColor: getBgcolorHover(),
                  },
                  "&[aria-expanded='true']": {
                    backgroundColor: getBgcolorHover(),
                  },
                  // borderRadius: theme.spacing(0.6),
                  padding: "4px 17px",
                  border: borderColor !== "" ? "1px solid" : "none",
                  borderColor: getBordercolor(),
                  whiteSpace: "nowrap",
                  ...sxExtra,
                })}
                variant="contained"
                size="small"
                startIcon={icon}
            >
              <>
                {children}
              </>
            </Button>
        )}
        {buttonType === "text" && (
            <Button
                {...rest}
                {...(submit ? {type: "submit"} : {})}
                sx={(theme) => ({
                  color: getColor(),
                  "&:hover": {
                    color: getColorHover(),
                    backgroundColor: "transparent",
                  },
                  "&[aria-expanded='true']": {
                    backgroundColor: "transparent",
                  },
                  // borderRadius: theme.spacing(0.6),
                  padding: "4px 0",
                  whiteSpace: "nowrap",
                  ...sxExtra,
                })}
                variant="text"
                size={size}
            >
              <>
                {children}
              </>
            </Button>
        )}
      </>
          )
};

export default CustomButton;
