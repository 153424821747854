import {StyleSheet} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    body: {
        margin: "0",
        padding: "30px 0 30px 0",
        width: "100%",
        color: "#000",
        font: "11pt Rubik",
        fontWeight: 300,
        height: "100%",
        boxSizing: "border-box",
    },
    coverBg: {
        position: "relative",
    },
    wrapperCover: {
        color: "#000000",
        width: "100%",
        maxWidth: "990px",
        margin: "auto",
        padding: "40px",
    },
    title: {
        marginBottom: "10pt",
        fontFamily: "Rubik",
        fontWeight: 700,
        fontSize: "11.2pt",
        textTransform: "uppercase",
        width: "auto",
        padding: "1pt 2pt",
        flexDirection: "row",
        justifyContent: "flex-start",
    },
    coverTitle: {
        padding: "22mm 0 4mm 0",
        flexDirection: "column",
    },
    subsectionContent: {
        fontFamily: "Rubik",
        paddingLeft: "72pt",
        fontSize: "11pt",
        fontWeight: 400,
        textTransform: undefined,
    },
    chart: {
        position: "relative",
        width: "100%",
        margin: "10mm 0 20mm 0",
    },
    gradeWrapper: {
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        height: "45px",
    },
    chartGrade: {
        position: "absolute",
        left: 0,
        bottom: 0,
        marginLeft: "-6.35mm",
        zIdex: 10,
    },
    chartGradeLiteracy: {
        left: 0,
        bottom: 0,
        zIdex: 10,
        height: "5mm",
        marginLeft: "2px",
        top: "30px",
        position: "absolute",
    },
    gradeLabel: {
        fontFamily: "Rubik",
        fontSize: "9pt",
        fontWeight: 400,
        marginBottom: "2pt",
    },
    gradeValue: {
        width: "12mm",
        height: "12.5mm",
        margin: "0 auto",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center top",
        backgroundSize: "contain",
        backgroundColor: "#000",
        textAlign: "center",
        fontFamily: "Rubik",
        fontSize: "11pt",
        fontWeight: 700,
        color: "#fff",
        padding: "7pt",
        position: "relative",
        backgroundImage: "url(/images/reports/nota.png)",
    },
    chartBackground: {
        width: "100%",
        height: "auto",
        position: "relative",
        zIndex: "-1",
    },
    chartNoteWrapper: {
        position: "relative",
        height: "12mm",
        margin: "0 0pt",
    },
    chartNote: {
        textAlign: "center",
        position: "absolute",
        top: "2pt",
    },
    chartNoteBorder: {
        height: "4.5mm",
        width: "100%",
        border: "1.2pt solid #000",
        borderTop: "none",
        marginBottom: "4pt",
    },
    chartNoteName: {
        fontFamily: "Rubik",
        fontSize: "8pt",
        fontWeight: 400,
        marginBottom: "2pt",
        whiteSpace: "nowrap",
        textAlign: "center",
    },
    chartNoteValues: {
        fontFamily: "Rubik",
        fontSize: "6pt",
        fontWeight: 300,
        whiteSpace: "nowrap",
        textAlign: "center",
    },
    nextUpgrade: {
        width: "250pt",
        textAlign: "left",
        position: "absolute",
        left: "100%",
        top: "6mm",
        fontFamily: "Rubik",
        fontSize: "7.5pt",
        fontWeight: 300,
        // paddingLeft: "-5mm",
        whiteSpace: "nowrap",
    },
    arrowRight: {
        width: "4.3mm",
        left: 0,
        position: "absolute",
        top: "0.7mm",
    },
    nextUpgradeChartNoteName: {
        fontWeight: 400,
    },
    coverPage: {
        marginTop: "-30px",
        padding: "30px",
        position: "relative",
        backgroundColor: "#EBF3FF",
        width: "100%",
    },
    coverPagePeach: {
        backgroundColor: "#FFE1EB",
        marginTop: "-30px",
        padding: "30px",
        position: "relative",
        width: "100%",
    },
    titleCover: {
        padding: "22mm 0 4mm 0",
        flexDirection: "column",
    },
    h1: {
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: "24pt",
        color: "#000000",
        margin: "0 0 20pt 0",
        lineHeigth: "24pt",
    },
    h2: {
        fontFamily: "Rubik",
        fontWeight: 400,
        fontSize: "17pt",
        color: "#000000",
        margin: 0,
    },
    h3: {
        fontFamily: "Rubik",
        fontWeight: 700,
        fontSize: "11.2pt",
        margin: "0 0 6mm",
        textTransform: "uppercase",
    },
    h4: {
        fontFamily: "Rubik",
        fontWeight: 600,
        color: "#000000",
    },
    h1Peach: {
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: "24pt",
        color: "#000000",
        margin: "0 0 20pt 0",
        lineHeigth: "24pt",
    },
    h2Peach: {
        fontFamily: "Rubik",
        fontWeight: 400,
        fontSize: "17pt",
        color: "#000000",
        margin: 0,
    },
    h3Peach: {
        fontFamily: "Rubik",
        fontWeight: 600,
        fontSize: "10pt",
        color: "#000000",
        margin: 0,
    },
    coverImage: {
        float: "right",
        width: "50%",
        maxWidth: "360px",
        height: "auto",
    },
    coverLogo: {
        width: "80%",
        maxWidth: "288px",
        height: "auto",
    },
    coverFooter: {
        fontFamily: "Rubik",
        fontWeight: 400,
        fontSize: "7pt",
        color: "#000000",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: "20px",
    },
    coverFooterPeach: {
        fontFamily: "Rubik",
        fontWeight: 400,
        fontSize: "7pt",
        color: "#000000",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: "20px",
    },
    sectionAbout: {
        fontFamily: "Rubik",
        fontSize: "11pt",
        // lineHeight: "13pt",
        fontWeight: 400,
        width: "100%",
        position: "relative",
        margin: "20px 0",
    },
    p: {
        margin: "15pt 0 11pt 0",
        fontFamily: "Rubik",
        fontWeight: 400,
        fontSize: "11pt",
    },
    tableNote: {
        position: "relative",
    },
    gradesRow: {
        flexDirection: "row",
        width: "100%",
        // position: "relative",
        fontFamily: "Rubik",
        borderBottom: "0.2pt solid #9A9999",
        alignItems: "center",
    },
    colChapter: {
        width: "45.4mm",
        fontFamily: "Rubik",
        fontSize: "8pt",
        fontWeight: 700,
    },
    colSubchapter: {
        width: "45.4mm",
        fontFamily: "Rubik",
        fontSize: "7pt",
        fontWeight: 400,
    },
    colValues: {
        width: "100%",
        // position: "relative",
    },
    markGroup: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "36.5mm",
        right: 0,
        zIndex: 0,
        // height: "100%",
    },
    markLine: {
        width: "0.8pt",
        backgroundColor: "#9A9999",
        position: "absolute",
        top: "7mm",
        bottom: 0,
        zIndex: 0,
        height: "95%",
    },
    barGroup: {
        marginLeft: "1pt",
        width: "100%",
        position: "relative",
        alignContent: "center",
    },
    bar: {
        height: "2.5mm",
        backgroundColor: "#000",
    },
    chartTable: {
        width: "100%",
        height: "auto",
        position: "relative",
        left: "1pt",
    },
    number: {
        fontSize: "7pt",
        lineHeight: "7pt",
        fontWeight: 700,
        position: "absolute",
        right: "-10pt",
        top: 0,
    },
    numberSubchapter: {
        fontSize: "7pt",
        lineHeight: "7pt",
        fontWeight: 400,
        position: "absolute",
        right: "-10pt",
        top: 0,
    },
    unitWrapper: {
        position: "relative",
        height: "10pt",
        margin: "0 2pt",
    },
    unit: {
        position: "absolute",
        fontFamily: "Rubik",
        fontSize: "8.6pt",
        fontWeight: 200,
        top: 0,
        transform: "translate(-5%, 0)",
    },
    numberUnits: {
        textAlign: "center",
        fontWeight: 200,
    },
    subsection: {
        padding: "5mm",
        position: "relative",
    },
    linesActionPlan: {
        // paddingInlineStart: "20px",
        width: "95%",
        paddingBottom: "20px",
        paddingTop: "10px",
    },
    linesActionPlanLi: {
        width: "95%",
        borderBottom: "1px solid #888888",
        paddingTop: "10px",
    },
    pBold: {
        margin: "15pt 0 11pt 0",
        fontFamily: "Rubik",
        fontWeight: 600,
        fontSize: "9.2pt",
    },
    scoreHorizontalValue: {
        /* right: 100%; */
        fontFamily: "Rubik",
        fontSize: "9pt",
        fontWeight: 400,
        color: "#fff",
        position: "absolute",
        right: "0px",
        // top: "1mm",
        marginRight: "10px",
        textAlign: "right",
        zIndex: 2,
    },
    pagebreak: {
        marginBottom: "50px",
    },
    tableSubsection: {
        padding: '5mm 0',
    }
});