import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {markedLevel, scoreLevelCssClass} from "../../../utils/reportsFunctions";

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    ({ theme }) => ({
        border: "none",
        margin: 0,
        padding: 0,
        backgroundColor: "transparent",
    }),
);

const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
    backgroundColor: "transparent",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
        margin: 0,
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
    borderTop: "none",
}));

const SkillDataScale = ({ expanded, description, index, handleChange, score }: any) => {
    const marked = markedLevel(score, description.level, "digitalLiteracy");

    const scoreGroup = scoreLevelCssClass(description.level, "digitalLiteracy");

    return (
        <div key={index} className={`subsection ${marked} ${scoreGroup}`}>
            <Accordion
                expanded={marked ? true : expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
            >
                <AccordionSummary aria-controls={`panel${index}-content`} id={`panel${index}-content`} style={{ padding: 0 }}>
                    <div>
                        <h3 className="title-nivel">
              <span className={`legend ${scoreGroup}`} style={{ padding: 0 }}>
                <div className="square">
                  <svg
                      className="print svg-print"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 10 10"
                      preserveAspectRatio="none"
                  >
                    <rect width="10" height="10" />
                  </svg>
                </div>
              </span>
                            {description.level}
                        </h3>
                        {!marked && (
                            <p
                                className="subsection-content-more"
                                style={{ fontSize: "12px", color: "#002D96", textDecoration: "underline" }}
                            >
                                mai mult
                                <img
                                src="/images/reports/arrow-right.png"
                                className="arrow-right"
                                alt=""
                                style={{ width: "20px" }}/>
                            </p>
                        )}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div
                        style={{ fontSize: "12px" }}
                        dangerouslySetInnerHTML={{ __html: description.description }}
                        className={`subsection-content subsection-content-levels subsection-${index}`}
                    ></div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default SkillDataScale;
