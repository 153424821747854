import axiosClient from "api/apiClient";

export const APIPostNotifications = (page: string) => {
  return axiosClient.post(`/notifications${page}`);
};

export const APIPostNotificationsUnread = () => {
  return axiosClient.post(`/notifications/unread`);
};

export const APIPostNotificationsMarkAsRead = (data: object) => {
    return axiosClient.post(`/notifications/markAsRead`, data);
  };