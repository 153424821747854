import React, { useEffect, useState } from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAuth} from "hooks/useAuth";
import Box from "@mui/material/Box";
import { APIGetEmailVerify, APIVerificationEmail } from "api/pages/login";
import Grid from "@mui/material/Grid";
import Loading from "components/ui/Loading";


const EmailVerify = () => {
  const { update, tenant } = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);

  const tenantParams = searchParams.get("tenant");
  const url = searchParams.get("verify_url");
  const role = searchParams.get("role");
  const pass = searchParams.get("password");

  const sendVerificationEmailHandler = (tenant: string, user: number) => {
    update("error", {}, true);
    setLoading(true);
    APIVerificationEmail({ tenant, user })
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          // console.log("EmailSent OK", response);
          update("error", {
            show: true,
            title: "E-mailul a fost trimis cu success!",
            message: "Verifică e-mailul.",
            icon: "fat envelope",
          });
        } else {
          update("error", { show: true, code: "A002" });
        }
      })
      .catch((err) => {
        // console.log("EmailSent ERR", err);
        update("error", {
          show: true,
          code: "A001",
        });
      });
  };

  useEffect(() => {

    if (url !== null) {
      setLoading(true);
      APIGetEmailVerify(url)
        .then((response) => {
          if (response.data.success) {
            setLoading(false);
            if(tenantParams === "teacher") {
              if(role === "teacher") {
                update("error", {
                  show: true,
                  title: response.data.data.message,
                  message: "<p></p>",
                  button: "Autentificare",
                  icon: "fat envelope",
                  closable: true,
                  returnFunction: () => {
                    navigate("/login/profesori");
                  },
                });
              } else if(role === "student") {
                update("error", {
                  show: true,
                  title: response.data.data.message,
                  message: "<p></p>",
                  button: "Setează parola",
                  icon: "fat envelope",
                  closable: true,
                  returnFunction: () => {
                    window.location.replace(response.data.data.password_url);
                  },
                });
              }
            } else if (pass === "1") {
                update("error", {
                  show: true,
                  title: response.data.data.message,
                  message: "<p></p>",
                  button: "Autentificare",
                  icon: "fat envelope",
                  closable: true,
                  returnFunction: () => {
                    navigate("/");
                  },
                });
            } else {
              update("error", {
                show: true,
                title: response.data.data.message,
                message: "<p></p>",
                button: "Setează parola",
                icon: "fat envelope",
                closable: true,
                returnFunction: () => {
                  window.location.replace(response.data.data.password_url);
                },
              });
            }
            // setSearchParams({});
          } else {
            update("error", { show: true, code: "A002" });
          }
        })
        .catch((err) => {
          if (err.response.status === 410) {
            setLoading(false);
            update("error", {
              show: true,
              message: err.response.data.data.message,
              button: "Trimite e-mail",
              icon: "fat face-eyes-xmarks",
              closable: false,
              onClickFunction: () => {
                sendVerificationEmailHandler(err.response.data.data.tenant, err.response.data.data.user);
              },
            });
          } else {
            update("error", {
              show: true,
              code: "A001",
            });
          }
        });
    } else {
      update("error", {
        show: true,
        title: "Verifică adresa de e-mail",
        message:
            "<p>Pentru a continua trebuie sa îți verifici adresa de e-mail</p>",
        icon: "fat envelope",
        closable: true,
        button: "ÎNCHIDE",
        returnFunction: () => {
          if(tenant.type === "teacher") {
            navigate("/login/profesori");
          } else {
            navigate("/");
          }

        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component="main">
      <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="stretch" mt={0}>
        {loading && <Loading modal={true} show={true} />}
      </Grid>
    </Box>
  );
};

export default EmailVerify;
