const Units = ({type} : any) => {
    return (
        <>
            {type === "school" && (
                <div className="clearfix unit-wrapper">
                    <div className="unit">
                        <div className="number first">
                            <strong>0</strong>
                        </div>
                    </div>
                    <div className="unit">
                        <div className="number">5</div>
                    </div>
                    <div className="unit">
                        <div className="number">
                            <strong>10</strong>
                        </div>
                    </div>
                    <div className="unit">
                        <div className="number">15</div>
                    </div>
                    <div className="unit">
                        <div className="number">20</div>
                    </div>
                    <div className="unit">
                        <div className="number">25</div>
                    </div>
                    <div className="unit">
                        <div className="number">
                            <strong>30</strong>
                        </div>
                    </div>
                    <div className="unit">
                        <div className="number">35</div>
                    </div>
                    <div className="unit">
                        <div className="number">40</div>
                    </div>
                    <div className="unit">
                        <div className="number">45</div>
                    </div>
                    <div className="unit">
                        <div className="number">50</div>
                    </div>
                    <div className="unit">
                        <div className="number">55</div>
                    </div>
                    <div className="unit">
                        <div className="number">
                            <strong>60</strong>
                        </div>
                    </div>
                    <div className="unit">
                        <div className="number">65</div>
                    </div>
                    <div className="unit">
                        <div className="number">70</div>
                    </div>
                    <div className="unit">
                        <div className="number">75</div>
                    </div>
                    <div className="unit">
                        <div className="number">
                            <strong>80</strong>
                        </div>
                    </div>
                    <div className="unit">
                        <div className="number">85</div>
                    </div>
                    <div className="unit">
                        <div className="number">90</div>
                    </div>
                    <div className="unit">
                        <div className="number">95</div>
                    </div>
                    <div className="unit">
                        <div className="number">
                            <strong>100</strong>
                        </div>
                    </div>
                </div>
            )}

            {type ==="literacy" && (
                <div className="clearfix unit-wrapper">
                    <div className="unit">
                        <div className="number first">
                            <strong>0</strong>
                        </div>
                    </div>
                    <div className="unit">
                        <div className="number">5</div>
                    </div>
                    <div className="unit">
                        <div className="number">10</div>
                    </div>
                    <div className="unit">
                        <div className="number">15</div>
                    </div>
                    <div className="unit">
                        <div className="number">20</div>
                    </div>
                    <div className="unit">
                        <div className="number">
                            <strong>25</strong>
                        </div>
                    </div>
                    <div className="unit">
                        <div className="number">30</div>
                    </div>
                    <div className="unit">
                        <div className="number">35</div>
                    </div>
                    <div className="unit">
                        <div className="number">40</div>
                    </div>
                    <div className="unit">
                        <div className="number">45</div>
                    </div>
                    <div className="unit">
                        <div className="number">50</div>
                    </div>
                    <div className="unit">
                        <div className="number">55</div>
                    </div>
                    <div className="unit">
                        <div className="number">60</div>
                    </div>
                    <div className="unit">
                        <div className="number">65</div>
                    </div>
                    <div className="unit">
                        <div className="number">70</div>
                    </div>
                    <div className="unit">
                        <div className="number">75</div>
                    </div>
                    <div className="unit">
                        <div className="number">
                            <strong>80</strong>
                        </div>
                    </div>
                    <div className="unit">
                        <div className="number">85</div>
                    </div>
                    <div className="unit">
                        <div className="number">90</div>
                    </div>
                    <div className="unit">
                        <div className="number">95</div>
                    </div>
                    <div className="unit">
                        <div className="number">
                            <strong>100</strong>
                        </div>
                    </div>
                </div>
            )}

            {type === "digitalLiteracy" && (
                <div className="clearfix unit-wrapper">
                    <div className="unit">
                        <div className="number first">
                            <strong>0</strong>
                        </div>
                    </div>
                    <div className="unit">
                        <div className="number">5</div>
                    </div>
                    <div className="unit">
                        <div className="number">10</div>
                    </div>
                    <div className="unit">
                        <div className="number">15</div>
                    </div>
                    <div className="unit">
                        <div className="number">20</div>
                    </div>
                    <div className="unit">
                        <div className="number">25</div>
                    </div>
                    <div className="unit">
                        <div className="number">30</div>
                    </div>
                    <div className="unit">
                        <div className="number">
                            <strong>35</strong>
                        </div>
                    </div>
                    <div className="unit">
                        <div className="number">40</div>
                    </div>
                    <div className="unit">
                        <div className="number">45</div>
                    </div>
                    <div className="unit">
                        <div className="number">50</div>
                    </div>
                    <div className="unit">
                        <div className="number">55</div>
                    </div>
                    <div className="unit">
                        <div className="number">60</div>
                    </div>
                    <div className="unit">
                        <div className="number">
                            <strong>65</strong>
                        </div>
                    </div>
                    <div className="unit">
                        <div className="number">70</div>
                    </div>
                    <div className="unit">
                        <div className="number">75</div>
                    </div>
                    <div className="unit">
                        <div className="number">80</div>
                    </div>
                    <div className="unit">
                        <div className="number">85</div>
                    </div>
                    <div className="unit">
                        <div className="number">90</div>
                    </div>
                    <div className="unit">
                        <div className="number">
                            <strong>95</strong>
                        </div>
                    </div>
                    <div className="unit">
                        <div className="number">
                            <strong>100</strong>
                        </div>
                    </div>
                </div>
            )}
        </>

    );
};

export default Units;
