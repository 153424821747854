import CreateTestBac from "components/createTest/bac/CreateTestBac";
import CreateTestDigitalLiteracy from "components/createTest/digitalLiteracy/CreateTestDigitalLiteracy";
import CreateTestEvaluation from "components/createTest/evaluation/CreateTestEvaluation";
import CreateTestLiteracy from "components/createTest/literacy/CreateTestLiteracy";
import CreateTest from "components/createTest/school/CreateTest";
import CreateTestTraining from "components/createTest/training/CreateTestTraining";
import GenericButton from "components/ui/GenericButton";
import PageTitle from "components/ui/PageTitle";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const TestSchool = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [testType, setTestType] = useState<string>("");

  useEffect(() => {
    let locationArray = location.pathname.split("/");
    if (locationArray[locationArray.length - 1] === "adauga-test-scolar") {
      setTestType("scolare");
    } else if (locationArray[locationArray.length - 1] === "adauga-test-bac") {
      setTestType("bac");
    } else if (locationArray[locationArray.length - 1] === "adauga-test-evaluare") {
      setTestType("evaluate");
    } else if (locationArray[locationArray.length - 1] === "adauga-test-antrenament") {
      setTestType("antrenament");
    } else if (locationArray[locationArray.length - 1] === "adauga-test-literatie") {
      setTestType("literatie");
    } else if (locationArray[locationArray.length - 1] === "adauga-test-literatie-digitala") {
      setTestType("literatieDigitala");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <PageTitle
        back={true}
        actions={
          <GenericButton
            type="close"
            onClick={() => {
              navigate(-1);
            }}
          />
        }
      >
        Generează test {testType === "scolare" && "școlar"}
        {testType === "antrenament" && "antrenament"}
        {testType === "literatie" && "literație"}
        {testType === "literatieDigitala" && "literație digitală"}
        {testType === "bac" && "bacalaureat"}
        {testType === "evaluate" && "evaluare națională"}
      </PageTitle>
      {testType === "scolare" && <CreateTest testType="scolare" />}
      {testType === "antrenament" && <CreateTestTraining testType="scolare" />}
      {testType === "literatie" && <CreateTestLiteracy testType="literatie" />}
      {testType === "literatieDigitala" && <CreateTestDigitalLiteracy testType="literatieDigitala" />}
      {testType === "bac" && <CreateTestBac testType="bac" />}
      {testType === "evaluate" && <CreateTestEvaluation testType="evaluate" />}
    </>
  );
};

export default TestSchool;
