import Box from "@mui/material/Box";
import PageTitle from "../components/ui/PageTitle";
import React from "react";
import BoxContent from "../components/ui/BoxContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import LinkStyled from "../components/ui/LinkStyled";

const Contact = () => {
    return (
        <Box component="main">
            <PageTitle>
                Contact
            </PageTitle>
            <Typography variant="h3" mb={1.6}>Dacă ați întâmpinat dificultăți în folosirea platformei și nu ați găsit răspunsul în secțiunea <LinkStyled to="/intrebari-frecvente">Întrebări frecvente</LinkStyled> sau în instrucțiunile video din <LinkStyled to="/tutoriale">Tutoriale</LinkStyled>, ne puteți contacta folosind:</Typography>
            <BoxContent>
                <Grid container spacing={1.5} alignItems="center">
                    <Grid item xs={12} sm={3} md={2}>
                        <Typography variant="body1"><b>E-mail:</b></Typography>
                    </Grid>
                    <Grid item xs={12} sm={9} md={10}>
                        <Typography variant="body1"><a href="mailto:office@brio.ro">office@brio.ro</a></Typography>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2}>
                        <Typography variant="body1"><b>Telefon:</b></Typography>
                    </Grid>
                    <Grid item xs={12} sm={9} md={10}>
                        <Typography variant="body1"><a href="tel:+40751237170">0751.237.170</a> (L-V, 10:00-17:00)</Typography>
                    </Grid>
                    {/*<Grid item xs={12} sm={3} md={2}>*/}
                    {/*    <Typography variant="body1"><b>Program:</b></Typography>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12} sm={9} md={10}>*/}
                    {/*    <Typography>10:00 - 17:00, L-V</Typography>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12} sm={3} md={2}>
                        <Typography variant="body1"><b>Adresa:</b></Typography>
                    </Grid>
                    <Grid item xs={12} sm={9} md={10}>
                        <Typography>Str. Icoanei nr. 29A, București, Sector 2, Cod poștal 020452, România</Typography>
                    </Grid>
                </Grid>
            </BoxContent>
        </Box>
    )
}

export default Contact;