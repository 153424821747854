import moment from "moment";
import {useAuth} from "../../../hooks/useAuth";

interface CoverData {
    startDate: Date;
    endDate: Date;
    subject: string;
    tag: any[];
}

const Cover = ({startDate, endDate, subject, tag}: CoverData) => {
    const {tenant} = useAuth();
    return (
        <div className="cover-page">
            <img src="/images/reports/cover-romana.png" className="cover-img screen" alt="" />
            <img src="/images/cover-logo-blue.png" className="cover-logo print" alt="" />
            <img src="/images/cover-logo-blue.png" className="cover-logo screen" alt="" />
            <div className="title">
                <h1>Raport consolidat de evaluare educationala</h1>
                <h2><>{subject} | {moment(startDate).locale("ro").format("DD.MM.YYYY")} - {moment(endDate).locale("ro").format("DD.MM.YYYY")}</></h2>
            </div>
            {tag.length > 0 && (
                <>
                    <h4>ID:</h4>
                    <h2>{tag.map((item) => (
                        <span>{item} </span>
                    ))}</h2>
                </>
            )}
            <h4>Numele școlii:</h4>
            <h2>{tenant.name}</h2>
            <img src="/images/reports/cover-romana.png" className="cover-img print" alt=""/>
            <table className="cover-foo">
                <tbody>
                <tr>
                    <td align="left">
                        &copy; Copyright BRIO<sup>&reg;</sup>, toate drepturile rezervate.
                    </td>
                    <td align="right">brio.ro</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Cover;