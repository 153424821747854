import {IAnswer, ITest} from "./_interfaces";
import Box from "@mui/material/Box";
import CustomButton from "../ui/CustomButton";
import Typography from "@mui/material/Typography";
import React from "react";

const QuestionsButtons = ({
                              test,
                              setItemIndex,
                              itemIndex,
                              answeredItems,
                              isLargeScreen,
                            setLoading,
                          }: {
    test: ITest;
    setItemIndex: (index: number) => void;
    itemIndex: number;
    answeredItems: IAnswer[];
    isLargeScreen: boolean;
    setLoading: (index: boolean) => void;
}) => {
    const findItems = (arr1: any, arr2: any) => {
        const arr1Id = arr1.map(({id} : any) => id);
        const arr2Id = arr2.map(({id} : any) => id);

        const multipleExists= arr2Id.every((value: any)=> {
            return arr1Id.includes(value);
        });

    return multipleExists;
    }

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            gap={1}
            sx={{ flexWrap: { xs: "nowrap", lg: "wrap" } }}
        >
            {test.items.map((item, index) => (
                <Box key={index}>
                    {test.test_config === 2 && (
                        <CustomButton
                            buttonType="icon"
                            appearance="circle"
                            bgcolor="white"
                            color="secondary.light"
                            size="small"
                            width={36}
                            height={36}
                            sxExtra={{
                                cursor: "default",
                                "&:hover": {
                                    color: "secondary.light",
                                    backgroundColor: "white",
                                    cursor: "default",
                                },
                                "&[aria-expanded='true']": {
                                    backgroundColor: "white",
                                },
                                ...(index + 1 === itemIndex
                                    ? isLargeScreen
                                        ? {
                                            border: "2px solid",
                                            borderColor: "primary.main",
                                            transform: "scale(1.25)",
                                            color: "primary.main",
                                            fontSize: "1.125rem",
                                        }
                                        : {
                                            transform: "scale(1.25)",
                                            "&:hover": {
                                                border: "0px solid",
                                                color: "#fff",
                                                backgroundColor: "#0064FF",
                                            },
                                        }
                                    : isLargeScreen
                                        ? {}
                                        : {
                                            border: "1px solid",
                                            borderColor: "#fff",
                                            color: "#fff",
                                            backgroundColor: "#00289B",
                                            "&:hover": {
                                                border: "0px solid",
                                                color: "#fff",
                                                backgroundColor: "#0064FF",
                                            },
                                        }),
                                ...(test.mode === 5 ? (findItems(answeredItems, item.questions)
                                    ? {
                                        backgroundColor: { xs: "#fff", lg: "secondary.light" },
                                        color: { xs: "primary.main", lg: "primary.contrastText" },
                                    }
                                    : {}) : (answeredItems.find((answeredItem) => answeredItem.id === item.id) !== undefined
                                    ? {
                                        backgroundColor: { xs: "#fff", lg: "secondary.light" },
                                        color: { xs: "primary.main", lg: "primary.contrastText" },
                                    }
                                    : {})),
                                ...(answeredItems.find((answeredItem) => answeredItem.id === item.id) !== undefined
                                    ? {
                                        backgroundColor: { xs: "#fff", lg: "secondary.light" },
                                        color: { xs: "primary.main", lg: "primary.contrastText" },
                                        "&:hover": {
                                            color: { xs: "primary.main", lg: "primary.contrastText" },
                                            backgroundColor: { xs: "#fff", lg: "secondary.light" },
                                            cursor: "default",
                                        },
                                        "&[aria-expanded='true']": {
                                            backgroundColor: { xs: "#fff", lg: "secondary.light" },
                                        },
                                    }
                                    : {}),
                            }}
                            icon={<Typography lineHeight={1}>{index + 1}</Typography>}
                        />
                    )}
                    {test.test_config === 1 && (
                        <CustomButton
                            buttonType="icon"
                            appearance="circle"
                            bgcolor="white"
                            color="secondary.light"
                            size="small"
                            onClick={() => {
                                setLoading(true);
                                setItemIndex(index + 1);
                                window.setTimeout(() => {
                                    setLoading(false);
                                }, 100);
                            }}
                            width={36}
                            height={36}
                            sxExtra={{
                                cursor: "pointer",
                                ...(index + 1 === itemIndex
                                    ? isLargeScreen
                                        ? {
                                            border: "2px solid",
                                            borderColor: "primary.main",
                                            transform: "scale(1.25)",
                                            color: "primary.main",
                                            fontSize: "1.125rem",
                                        }
                                        : {
                                            transform: "scale(1.25)",
                                            "&:hover": {
                                                border: "0px solid",
                                                color: "#fff",
                                                backgroundColor: "#0064FF",
                                            },
                                        }
                                    : isLargeScreen
                                        ? {}
                                        : {
                                            border: "1px solid",
                                            borderColor: "#fff",
                                            color: "#fff",
                                            backgroundColor: "#00289B",
                                            "&:hover": {
                                                border: "0px solid",
                                                color: "#fff",
                                                backgroundColor: "#0064FF",
                                            },
                                        }),
                            ...(test.mode === 5 ? (findItems(answeredItems, item.questions)
                                ? {
                                    backgroundColor: { xs: "#fff", lg: "secondary.light" },
                                    color: { xs: "primary.main", lg: "primary.contrastText" },
                                }
                                : {}) : (answeredItems.find((answeredItem) => answeredItem.id === item.id) !== undefined
                                ? {
                                    backgroundColor: { xs: "#fff", lg: "secondary.light" },
                                    color: { xs: "primary.main", lg: "primary.contrastText" },
                                }
                                : {})),
                            }}
                            icon={
                                <Typography lineHeight={1} sx={{ cursor: "pointer" }}>
                                    {index + 1}
                                </Typography>
                            }
                        />
                    )}
                </Box>
            ))}
        </Box>
    );
};

export default QuestionsButtons;