import axiosClient from "api/apiClient";

export const APIGetTest = (data: object) => {
    return axiosClient.post("/tests/show", data);
  };

export const APIStartTest = (data: object) => {
  return axiosClient.post(`/tests/start/`, data);
};

export const APISaveTestAnswer = (data: object) => {
  return axiosClient.post(`/tests/answer`, data);
};

export const APICloseTest = (data: object) => {
  return axiosClient.post(`/tests/close/`, data);
};

export const APIPostFinishTest = (data: object) => {
  return axiosClient.post(`/tests/finished/`, data);
};

export const APIPostFinishTestLiteracy = (data: object) => {
  return axiosClient.post(`/literacy-tests/finished`, data);
};