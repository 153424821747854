const AboutSection = () => {
    return (
        <>
            <div className="section-despre">
                <h1>Despre Raportul BRIO</h1>
                <div className="subsection">
                    <h3>Scopul raportului</h3>
                    <div className="subsection-content">
                        <p>
                            Acest raport descrie nivelul de abilități de literație de bază (denumite și „alfabetizare
                            funcțională”) pentru elevul testat. El se bazează pe BRIO®, sistemul de testare standardizată pentru
                            accelerarea performanței în învățământ.
                            <br />
                            Raportul conține detalii privind indicele de literație și scorurile aferente abilităților de literație
                            de bază.
                        </p>
                    </div>
                </div>
                <div className="subsection">
                    <h3>Ce sunt abilitățile de literație de bază?</h3>
                    <div className="subsection-content">
                        <p>
                            Literația se referă la capacitatea unei persoane de a interacționa cu un text scris, în sensul
                            înțelegerii, evaluării, utilizării informațiilor și reflectării asupra acestora.
                        </p>
                        <p>Literația presupune manifestarea a trei abilități, și anume:</p>
                        <ul>
                            <li>
                                <b>Localizarea de informație</b>, anume capacitatea de a găsi în text o informație care este
                                exprimată în mod direct, astfel încât elevul nu trebuie să facă presupuneri sau inferențe pentru a
                                răspunde corect.
                            </li>
                            <li>
                                <b>Înțelegerea</b>, care presupune capacitatea de a da sens unor cuvinte, propoziții sau înșiruiri
                                de evenimente dintr-un material. Astfel, dimensiunea acoperă, pe de o parte, cunoștințe specifice
                                din sfera vocabularului. Pe de altă parte, această dimensiune vizează integrarea cu sens a unor
                                informații disparate din material și capacitatea de a urmări succesiunea temporală a unor evenimente
                                prezentate.
                            </li>
                            <li>
                                <b>Reflecția</b>, adică abilitatea de a reflecta la o anumită parte a unui material, pentru a
                                desprinde fie ideea centrală, fie pentru a evalua critic informația accesată.
                            </li>
                        </ul>
                        <p>Raportul de față conține scorurile aferente fiecărei abilități descrise mai sus.</p>
                    </div>
                </div>
                <div className="subsection">
                    <h3>Utilitatea acestui raport</h3>
                    <div className="subsection-content">
                        <p>
                            Testul pe care se bazează acest raport nu este derivat din programa școlară, astfel nefiind specific
                            unei materii anume. Cu toate acestea, capacitatea de a opera la un nivel de bază cu un text este
                            esențială pentru elevi în situații variate, iar dezvoltarea insuficientă a literației are efecte
                            negative pentru performanța școlară a acestora.
                        </p>
                    </div>
                </div>
                <div className="subsection">
                    <h3>Indicele de literație</h3>
                    <div className="subsection-content">
                        <p>
                            Rezultatele sunt prezentate sub forma unui indice de literație, un scor care poate varia între 0
                            (foarte slab) și 100 (foarte competent). Indicele de literație este scorul total obținut de elevi la
                            evaluare și reflectă nivelul general al competenței de literație, capacitatea generală a elevului de a
                            procesa textul citit în cursul testării. Acest indice se calculează pe baza răspunsurilor corecte.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutSection;
