import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormField from "components/form/FormField";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useAuth } from "hooks/useAuth";
import { IFormLogin } from "components/login/_interfaces";
import { APIPostLogin } from "api/pages/login";
import { APIGetSchoolsLogin } from "../../api/pages/schools";

const schema = yup
  .object({
  })
  .required();

const LoginForm = () => {
  const { login, update } = useAuth();
  const navigate = useNavigate();

  const methods = useForm<IFormLogin>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormLogin> = (data) => {
    const school = data.school ? data.school.slug : null;
    update("loading", { show: true, modal: true });

    APIPostLogin({
      domain: school,
      email: data.email,
      password: data.password,
    })
      .then((response) => {
        if (response.data.success && response.data.data?.token !== null) {
          // console.log("postLOGIN OK", response);
          login({
            firstname: response.data.data.auth.first_name || "",
            lastname: response.data.data.auth.last_name || "",
            email: response.data.data.auth.email || "",
            position: response.data.data.auth.position || "",
            profilePhoto: response.data.data.auth.profile_photo || "",
            token: `Bearer ${response.data.data.auth.token}`,
            menu: response.data.data.auth.menu || [],
            email_verified: response.data.data.auth.email_verified,
            id: response.data.data.auth.id,
            notifications: response.data.data.auth.notifications_unread,
            schoolBoard: response.data.data.auth.has_school_board,
            swap: response.data.data.auth.can_swap_profile,
            date: new Date(),
          });
          let tenantNew = response.data.data.domain;
          delete tenantNew.label;
          delete tenantNew.value;
          update("tenant", tenantNew);
          if (response.data.data?.email_verified === null) {
            navigate("/verifica-email");
          }
          if(response.data.data.auth.can_swap_profile && response.data.data.auth.position === "principal") {
            navigate("/profile");
          }
          update("loading", { show: false });
          methods.clearErrors();
        } else {
          update("loading", { show: false });
          update("error", { show: true, code: "A002" });
        }
      })
      .catch((err) => {
        // console.log("postLOGIN ERR", err);
        if (err.response.status === 400) {
          let errors = err.response.data.data;
          // console.log(errors.general)
          if (errors.email) {
            methods.setError("email", { type: "server", message: errors.email[0] });
          }
          if (errors.domain) {
            methods.setError("school", { type: "server", message: errors.domain[0] });
          }
          if (errors.password) {
            methods.setError("password", { type: "server", message: errors.password[0] });
          }
          if (errors.general) {
            methods.setError("password", { type: "server", message: errors.general[0] });
          }
          update("loading", { show: false });
        } else {
          update("error", { show: true, code: "A001" });
        }
      });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <Box py={1}>
            {/*<FormField*/}
            {/*  name="domain"*/}
            {/*  type="text"*/}
            {/*  defaultValue=""*/}
            {/*  label="Numele școlii"*/}
            {/*  autoFocus={false}*/}
            {/*  errorMessage={methods.formState.errors.domain?.message}*/}
            {/*  inputProps={{*/}
            {/*    autoCapitalize: 'none',*/}
            {/*  }}*/}
            {/*/>*/}
            <FormField
                name="school"
                type="autocomplete_api"
                label="Caută școala..."
                optionsAPI={APIGetSchoolsLogin}
                defaultValue={null}
                multiple={false}
                filterOptions={(x: any) => x}
                errorMessage={methods.formState.errors.school?.message}
                error={!!methods.formState.errors.school?.message}
                autoFocus={true}
            />
          </Box>
          <Box py={1}>
            <FormField
              name="email"
              type="text"
              defaultValue=""
              label="Adresa de e-mail"
              autoComplete="email"
              errorMessage={methods.formState.errors.email?.message}
              inputProps={{
                autoCapitalize: 'none',
              }}
            />
          </Box>
          <Box py={1}>
            <FormField
              name="password"
              type="password"
              defaultValue=""
              label="Parola"
              autoComplete="password"
              errorMessage={methods.formState.errors.password?.message}
            />
          </Box>
          <Box
            sx={{
              mt: 1,
              mb: 3,
            }}
          >
            <Button type="submit" variant="contained">
              Autentificare
            </Button>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default LoginForm;
