import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import { IDashboardBoxDataTable } from "components/dashboard/_interfaces";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
// import Paper from "@mui/material/Paper";

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(-1),
  backgroundColor: "transparent",
  width: "100%",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "transparent",
    color: theme.palette.secondary.light,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
  [`&.${tableCellClasses.footer}`]: {
    fontWeight: 500,
    textTransform: "uppercase",
    color: theme.palette.secondary.light,
  },
  fontWeight: 300,
  border: "none",
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.extra.dashboard_numbers,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CustomTable = ({ data = { columns: [], rows: [], footer: [] } }: IDashboardBoxDataTable) => {
  return (
    <StyledTableContainer>
      <Table sx={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            {data.columns.map((col, index) => (
              <StyledTableCell
                key={index}
                sx={(theme) => ({
                  textAlign: col?.numeric ? "right" : "inherit",
                  pl: index === 0 ? theme.spacing(3) : "auto",
                  pr: index === data.columns.length - 1 ? theme.spacing(3) : "auto",
                })}
              >
                {col.label}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.rows.map((row, index) => (
                <StyledTableRow key={index}>
                  {data.columns.map((col, index) => (
                        <StyledTableCell
                            key={index}
                            sx={(theme) => ({
                              textAlign: col?.numeric ? "right" : "inherit",
                              pl: index === 0 ? theme.spacing(3) : "auto",
                              pr: index === data.columns.length - 1 ? theme.spacing(3) : "auto",
                              border: typeof row[col.id] === 'object' ? `1px solid ${theme.palette.error.main}!important` : "none"
                            })}
                        >
                          {typeof row[col.id] === 'object' ? (
                              <Tooltip
                                  title={
                                <>
                                  {row[col.id].errors.map((item:any) => {
                                    return (
                                        <Typography variant="body2" p={1}>
                                          {item}
                                        </Typography>
                                    )
                                  })}
                                </>
                                  }
                              >
                                <Typography sx={{color: "error.main"}}>
                                  {row[col.id].value}
                                </Typography>
                              </Tooltip>
                          ) : row[col.id]}
                          {/*{col?.numeric ? <Typography variant="caption">{row[col.id]}</Typography> : row[col.id]}*/}
                        </StyledTableCell>
                  ))}
                </StyledTableRow>
          ))}
        </TableBody>
        {data.footer && data.footer.length > 0 && (
          <TableFooter>
            {data.footer.map((row, index) => (
              <StyledTableRow key={index}>
                {data.columns.map((col, index) => (
                  <StyledTableCell
                    key={index}
                    sx={(theme) => ({
                      textAlign: col?.numeric ? "right" : "inherit",
                      pl: index === 0 ? theme.spacing(3) : "auto",
                      pr: index === data.columns.length - 1 ? theme.spacing(3) : "auto",
                    })}
                  >
                    {col?.numeric ? (
                      <Typography variant="caption" sx={{ fontWeight: 500 }}>
                        {row[col.id]}
                      </Typography>
                    ) : (
                      row[col.id]
                    )}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableFooter>
        )}
      </Table>
    </StyledTableContainer>
  );
};

export default CustomTable;
