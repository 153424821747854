import axiosClient from "api/apiClient";

export interface IOptions {
  page: string;
  sort?: string;
  order?: string;
  search?: string;
  startDate?: string;
  endDate?: string;
  type: string;
  export?: boolean;
}

export const APIGetActiveTests = (options: IOptions) => {
  let sort = "";
  let search = "";
  let params = "";
  if (options.hasOwnProperty("sort")) {
    if(options.order === "asc") {
      sort = "&sort=" + options.sort;
    } else if (options.order === "desc") {
    sort = "&sort=-" + options.sort;
    }
  }
  if (options.hasOwnProperty("search")) {
    search = "&filter[search]=" + options.search;
  }

  if(options.type === "school") {
    params = "tests/list/school";
  } else if(options.type === "bac") {
    params = "tests/list/bac";
  } else if (options.type === "evaluate") {
    params = "tests/list/evaluate";
  } else if(options.type === "training") {
    params = "trainings";
  } else if(options.type === "literacy") {
    params = "tests/list/literacy";
  } else if(options.type === "digitalLiteracy") {
    params = "tests/list/digital-literacy";
  }
 
  return axiosClient.post(`/${params}${options.page}${search}${sort}`);
};

export const APIGetTestsHistory = (options: IOptions) => {
  let sort = "";
  let search = "";
  let exportData = "";
  let dateInterval = "";
  let params = "";


  if (options.hasOwnProperty("sort")) {
    if(options.order === "asc") {
      sort = "&sort=" + options.sort;
    } else if (options.order === "desc") {
    sort = "&sort=-" + options.sort;
    }
  }
  if (options.hasOwnProperty("search")) {
    search = "&filter[search]=" + options.search;
  }

  if (options.hasOwnProperty("startDate" ) && options.hasOwnProperty("endDate")) {
    dateInterval = "&start_date=" + options.startDate + "&end_date=" + options.endDate;
  }

  if (options.hasOwnProperty("export" )) {
    if(options.export) {
      exportData = "&export=true";
    }
  }

  if(options.type === "school") {
    params = "tests/history/school";
  } else if(options.type === "bac") {
    params = "tests/history/bac";
  } else if (options.type === "evaluate") {
    params = "tests/history/evaluate";
  } else if(options.type === "training") {
    params = "trainings/history";
  } else if (options.type === "literacy") {
    params = "tests/history/literacy";
  } else if (options.type === "digitalLiteracy") {
    params = "tests/history/digital-literacy";
  }

  return axiosClient.post(`/${params}${options.page}${search}${sort}${dateInterval}${exportData}`);
};