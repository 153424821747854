import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import TenantInfo from "components/sidebar/TenantInfo";
import UserInfo from "components/sidebar/UserInfo";
// import Search from "components/sidebar/Search";
import Menu from "components/sidebar/Menu";
import MenuMobile from "components/sidebar/MenuMobile";
import BrioLogoFooter from "components/sidebar/BrioLogoFooter";
// import { useAuth } from "hooks/useAuth";
import useSticky from "hooks/useStiky";
// import {useAuth} from "../../hooks/useAuth";

const Sidebar = () => {
  // const { user } = useAuth();
  const { stickyRef, sticky } = useSticky();
  // const { tenant } = useAuth();

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box
      position="relative"
      display="flex"
      maxHeight="100dvh"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="content-between"
      sx={(theme) => ({
        paddingTop: { xs: 0, lg: 3 },
        height: { xs: "auto", lg: "100dvh" },
      })}
    >
      {isLgUp ? (
        <>
          <Box width="100%" sx={{ paddingRight: { xs: 0, lg: 2, xl: 3 } }}>
            <TenantInfo />
            <UserInfo />
          </Box>
          <Box width="100%" flexGrow={1} sx={{ overflow: "hidden", paddingRight: { xs: 0, lg: 1, xl: 3 } }}>
            <Menu />
          </Box>
          <Box width="100%" pl={3} pt={3} pb={2}>
            <BrioLogoFooter />
          </Box>
        </>
      ) : (
        <Box width="100%">
            <TenantInfo />
          <Box
            py={1.5}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            ref={stickyRef}
            sx={{
              width: "100%",
              zIndex: 1,
              backgroundColor: "background.paper",
              position: sticky ? "fixed" : "relative",
              top: 0,
            }}
          >
            <UserInfo />
            <Box>
              <MenuMobile />
            </Box>
          </Box>
          <div
            style={{
              height: sticky ? `${stickyRef.current?.clientHeight}px` : "0px",
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default Sidebar;
