import React, {useEffect} from "react";
import { useAuth } from "hooks/useAuth";
// import ThemeToggle from "components/ThemeToggle";
import LoginForm from "components/login/LoginForm";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import LinkStyled from "../components/ui/LinkStyled";

type LocationProps = {
  state: {
    message: string;
  };
};

const LoginPage = () => {
  const { update } = useAuth();
  let location = useLocation() as unknown as LocationProps;

  const message = location.state?.message || "";

  useEffect(() => {
    if (message) {
      update("error", {
        show: true,
        message: message,
        title: "",
        icon: "fat face-smile",
        closable: true,
        button: "ÎNCHIDE"
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <>
        <LoginForm />
        <LinkStyled to="/resetare-parola">Am uitat parola</LinkStyled>
        <Box mb={2} />
        <LinkStyled to="/inregistrare">Creează cont de profesor</LinkStyled>
      </>

  )
};

export default LoginPage;
