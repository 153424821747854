import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface IDBoxList {
  data?: {
    title: string;
    amount: number;
  }[];
  noDataText?: string;
}

const DashboardBoxList = ({ data = [], noDataText = "" }: IDBoxList) => {
  return data.length === 0 ? (
    <Box width="100%" textAlign="left">
      {noDataText}
    </Box>
  ) : (
    <>
      {data.map((item, index) => (
        <Box display="flex" key={index}>
          <Typography variant="body1" mb="0">
            {item.title}
          </Typography>
          <Typography variant="body1" mb="0" sx={{ marginLeft: "auto" }}>
            {item.amount}
          </Typography>
        </Box>
      ))}
    </>
  );
};

export default DashboardBoxList;
