import axiosClient from "api/apiClient";

interface IOptions {
  id?: string;
}

export const APIGetAddress = (options: IOptions) => {
  return axiosClient.get(`/addresses/${options.id}`);
};

export const APIDeleteAddress = (options: IOptions) => {
  return axiosClient.delete(`/addresses/${options.id}`);
};

export const APIUpdateAddress = (id: string, data: object) => {
  return axiosClient.put(`/addresses/${id}`, data);
};

export const APIPostAddress = (data: object) => {
  return axiosClient.post(`/addresses`, data);
};
