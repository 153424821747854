import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormField from "components/form/FormField";
import CustomDatePicker from "./CustomDatePicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import ExportButton from "./ExportButton";
import Icon from "../ui/Icon";
import CustomButton from "../ui/CustomButton";
// import LinkStyled from "../ui/LinkStyled";
// import useCreateTestForm from "../../hooks/useCreateTestForm";

export interface IFormInputs {
    keywords: string;
    searchType: string;
    subject: string;
}

interface ISearchForm {
    onSubmit: (data: any) => void;
    query: string;
    onChange: (e: any) => void;
    onClick: () => void;
    startDate: Date | undefined;
    endDate: Date | undefined;
    onDateRangeChange: (dates: any) => void;
    onClearDateRange: () => void;
    interval: any[];
    type: string;
    order: string;
    page: number;
    sort: string;
}

const schema = yup.object({
    keywords: yup.string(),
});

const AdvancedSearchForm = (
    { onSubmit,
        query,
        onChange,
        onClick,
        endDate,
        startDate,
        onDateRangeChange,
        onClearDateRange,
        interval,
        type,
        order,
        page,
        sort,
    }: ISearchForm) => {

    const methods = useForm<IFormInputs>({
        resolver: yupResolver(schema),
    });
    // eslint-disable-next-line
    const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
    // const {
    //     grades,
    //     subjects,
    //     createTestData,
    //     setSubjectHandler,
    //     setGradeHandler,
    //     setSelectedCategoryHandler,
    // } = useCreateTestForm("scolare", "school");

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                <Grid container spacing={{xs:1, md: 0.5}} direction="row" justifyContent={{xs: "flex-start", md: (isAdvancedSearch ? "flex-start" : "flex-end")}} alignItems="stretch" mt={0} mb={{xs: 1, sm: 1}}>
                    <Grid item xs={12} md={isAdvancedSearch ? 6 : 4} lg={isAdvancedSearch ? 6 : 4}  sx={{ textAlign: { xs: "left", sm: "right" } }}>
                        <Box sx={(theme) => ({borderRadius: theme.spacing(0.6), backgroundColor: "secondary.contrastText", display: "flex", alignItems: "center"})}>
                            <FormField
                                name="keywords"
                                type="text-icon"
                                label="Caută..."
                                autoComplete = "off"
                                errorMessage={methods.formState.errors.keywords?.message}
                                className="dark"
                                defaultValue={query}
                                value={query}
                                onChange={onChange}
                                onClick={() => {
                                    onClick();
                                    methods.reset();
                                }}
                            />
                            {/*<Box width="auto" height="100%" mr={1} sx={(theme) => ({borderRadius: theme.spacing(0.6), backgroundColor: "background.paper"})}>*/}
                            {/*    <FormField*/}
                            {/*        name="searchType"*/}
                            {/*        type="select-small"*/}
                            {/*        defaultValue="clasa"*/}
                            {/*        autoComplete = "off"*/}
                            {/*        options={[{*/}
                            {/*            label: "Elev",*/}
                            {/*            value: "elev",*/}
                            {/*        }, {*/}
                            {/*            label: "Clasa",*/}
                            {/*            value: "clasa",*/}
                            {/*        },*/}
                            {/*            {*/}
                            {/*                label: "Materie",*/}
                            {/*                value: "materie",*/}
                            {/*            }*/}
                            {/*        ]}*/}
                            {/*        errorMessage={methods.formState.errors.searchType?.message}*/}
                            {/*    />*/}
                            {/*</Box>*/}


                        </Box>

                    </Grid>
                    <Grid item xs={12} md={isAdvancedSearch ? 6 : 4} lg={isAdvancedSearch ? 6 : 4}  sx={{ textAlign: { xs: "left", sm: "right" } }}>
                        <Box width="100%">
                            <Box
                                display="flex"
                                alignItems="center"
                                sx={(theme) => ({
                                    paddingRight: theme.spacing(1.5),
                                    // marginRight: -0.6,
                                    borderRadius: theme.spacing(0.6),
                                })}
                                bgcolor="secondary.contrastText"
                            >
                                <CustomDatePicker
                                    name="Interval"
                                    startDate={startDate}
                                    endDate={endDate}
                                    onDateChange={onDateRangeChange}
                                    type="dateRangePicker"
                                />
                                <IconButton
                                    sx={{ visibility: interval.length > 0 ? "visible" : "hidden" }}
                                    onClick={onClearDateRange}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    <FontAwesomeIcon style={{ fontSize: "1rem", color: "#9D0000" }} icon={"xmark"} />
                                </IconButton>
                            </Box>
                        </Box>
                    </Grid>
                    {/*{isAdvancedSearch && (*/}
                    {/*    <>*/}
                    {/*        <Grid item xs={12} md={6} lg={6} >*/}
                    {/*            <Box width="100%" sx={(theme) => ({*/}
                    {/*                // marginRight: -0.6,*/}
                    {/*                borderRadius: theme.spacing(0.6),*/}
                    {/*            })}*/}
                    {/*                 bgcolor="secondary.contrastText">*/}
                    {/*                <FormField*/}
                    {/*                    name="subject"*/}
                    {/*                    type="controlled-select-small"*/}
                    {/*                    label="Alege materia"*/}
                    {/*                    value={createTestData.subject.value}*/}
                    {/*                    onChange={setSubjectHandler}*/}
                    {/*                    options={subjects.map((item: { name: string; id: string }) => {*/}
                    {/*                        return {*/}
                    {/*                            label: item.name,*/}
                    {/*                            value: item.id,*/}
                    {/*                            ...item,*/}
                    {/*                        };*/}
                    {/*                    })}*/}
                    {/*                    errorMessage={methods.formState.errors.subject?.message}*/}
                    {/*                />*/}
                    {/*            </Box>*/}
                    {/*        </Grid>*/}
                    {/*        <Grid item xs={12} md={6} lg={6} >*/}
                    {/*            <Box width="100%" sx={(theme) => ({*/}
                    {/*                // marginRight: -0.6,*/}
                    {/*                borderRadius: theme.spacing(0.6),*/}
                    {/*            })}*/}
                    {/*                 bgcolor="secondary.contrastText">*/}
                    {/*                    <FormField*/}
                    {/*                        disabled={!createTestData.subject.value}*/}
                    {/*                        name="grade"*/}
                    {/*                        type="controlled-select-small"*/}
                    {/*                        label="Alege clasa testului"*/}
                    {/*                        value={createTestData.grade.value}*/}
                    {/*                        onChange={setGradeHandler}*/}
                    {/*                        options={grades.map((item: { name: string; id: string }) => {*/}
                    {/*                                    return {*/}
                    {/*                                        label: item.name,*/}
                    {/*                                        value: item.id.toString(),*/}
                    {/*                                        ...item,*/}
                    {/*                                    };*/}
                    {/*                                })}*/}
                    {/*                        errorMessage={methods.formState.errors.subject?.message}*/}
                    {/*                    />*/}
                    {/*            </Box>*/}
                    {/*        </Grid>*/}
                    {/*        <Grid item xs={12}  >*/}
                    {/*            <Box width="100%" sx={(theme) => ({*/}
                    {/*                // marginRight: -0.6,*/}
                    {/*                borderRadius: theme.spacing(0.6),*/}
                    {/*            })}*/}
                    {/*                 bgcolor="secondary.contrastText">*/}
                    {/*                    <FormField*/}
                    {/*                        disabled={!createTestData.grade.value}*/}
                    {/*                        name="category"*/}
                    {/*                        type="controlled-select-small"*/}
                    {/*                        label="Alege capitolul"*/}
                    {/*                        value={createTestData.category.value}*/}
                    {/*                        onChange={setSelectedCategoryHandler}*/}
                    {/*                        options={createTestData.categories?.map((category: any) => {*/}
                    {/*                            if(Array.isArray(category.subcategories)) {*/}
                    {/*                                return category.subcategories?.map((subcategory: any) => {*/}
                    {/*                                    // console.log(category);*/}
                    {/*                                    return {*/}
                    {/*                                        label: subcategory.name,*/}
                    {/*                                        value: subcategory.id.toString(),*/}
                    {/*                                        ...subcategory,*/}
                    {/*                                    };*/}
                    {/*                                })*/}
                    {/*                            } else {*/}
                    {/*                                return {*/}
                    {/*                                    category,*/}
                    {/*                                };*/}
                    {/*                            }*/}
                    {/*                        })*/}
                    {/*                            .flat()}*/}
                    {/*                        errorMessage={methods.formState.errors.subject?.message}*/}
                    {/*                    />*/}
                    {/*            </Box>*/}
                    {/*        </Grid>*/}
                    {/*    </>*/}

                    {/*)}*/}
                    <Grid item xs="auto" sm="auto" md="auto"  sx={{ textAlign: "left" }}>
                        <CustomButton
                            bgcolor="dark"
                            submit
                            buttonType="textIcon"
                            icon={<Icon icon="far magnifying-glass" fixedWidth={true}/>}
                            // appearance='big'
                        >
                            Caută
                        </CustomButton>
                    </Grid>
                    <Grid item xs="auto" sm="auto" md="auto" sx={{ textAlign: "left"  }}>
                        <ExportButton
                            type={type}
                            endDate={endDate}
                            order={order}
                            page={page}
                            startDate={startDate}
                            search={query}
                            sort={sort}
                        />
                    </Grid>
                </Grid>
                {/*<Box sx={{textAlign: "right"}}>*/}
                {/*    <LinkStyled to="" onClick={(e) => {*/}
                {/*       setIsAdvancedSearch(!isAdvancedSearch);*/}
                {/*    }}>*/}
                {/*        {isAdvancedSearch ? "Căutare simplă" : "Căutare avansată"}*/}
                {/*    </LinkStyled>*/}
                {/*</Box>*/}
            </form>
        </FormProvider>
    );
};

export default AdvancedSearchForm;
