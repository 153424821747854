const Plan = () => {
    return (
        <div className="section-despre">
            <div className="subsection">
                <h3>Plan de acțiune pentru îmbunătățirea nivelului de alfabetizare digitală</h3>
                <div className="subsection-content">
                    <p>
                        Pregătirea unui plan de acțiune este primul pas către îmbunătățirea nivelului de alfabetizare digitală.
                        Pentru a îmbunătăți aceste competențe digitale, trebuie să fiți motivați, dedicați și pregătiți pentru
                        schimbare. Schimbările nu se vor întâmpla peste noapte: ele necesită timp și trebuie practicate noi
                        comportamente. Pentru ca competențele dumneavoastră digitale să se îmbunătățească, acțiunile specifice
                        trebuie repetate, consolidate și evaluate frecvent.
                    </p>
                    <p>
                        Consultați secțiunea profilul competențelor digitale. Evaluarea secțiunii este o modalitate eficientă de a
                        vă evalua punctele forte și de a înțelege zonele care trebuie îmbunătățite. După ce toate competențele
                        digitale testate au fost examinate, continuați cu următoarele etape:
                    </p>
                    <ol>
                        <li>
                            Stabiliți obiective pentru îmbunătățirea competențelor digitale specifice de care aveți nevoie sau în care
                            doriți să fiți mai competent.
                        </li>
                        <li>
                            Identifică orice obstacole in calea obiectivelor tale si planifică-le corespunzător, ținând cont de
                            acestea.
                        </li>
                        <li>
                            Căutați experiență sau resurse care vă vor ajuta să învățați și să exersați abilitățile digitale pe care
                            le-ați ales. Acestea pot include resurse digitale, cum ar fi videoclipuri și cursuri online (specifice
                            subiectului tău de interes), prieteni sau colegi care au deja experiență și competență în acest domeniu
                            sau profesori și părinți care îți pot ghida îmbunătățirea.
                        </li>
                        <li>
                            Continuați să exersați abilitățile identificate și asigurați-vă că aveți un anumit sprijin pentru efortul
                            dvs. Acest sprijin poate include colegi, prieteni, profesori și familie, după cum sa menționat mai sus.
                        </li>
                        <li>
                            În cele din urmă, revizuiți-vă și reevaluați-vă abilitățile digitale pentru a vă ajuta să vă consolidați
                            ceea ce ați învățat.
                        </li>
                    </ol>
                    <p>Câteva sugestii pentru dezvoltarea unui plan de acțiune sunt prezentate în continuare.</p>
                    <p>
                        Stabilirea obiectivelor poate ajuta la concentrarea directă, la creșterea persistenței și la mobilizarea
                        efortului. De asemenea, ajută indivizii să dezvolte noi strategii pentru a îmbunătăți rezolvarea
                        problemelor. Utilizați sugestiile de stabilire a obiectivelor enumerate mai jos pentru a vă ajuta să vă
                        atingeți obiectivele.
                    </p>
                </div>
            </div>
            <div className="subsection">
                <h3>Setarea obiectivelor personale</h3>
                <div className="subsection-content">
                    <p>
                        Pașii pe care îi faci pentru a-ți atinge obiectivele vor determina dacă reușești sau nu să le atingi.
                        Utilizați acest plan de activitate pas cu pas pentru a vă ajuta să vă ghidați mai aproape de obiectivele
                        dvs.
                    </p>
                    <p>
                        <strong>
                            Notați 3 până la 5 abilități digitale pe care doriți să le îmbunătățiți în perioada următoare. Alegerea
                            lor din lista de competențe testate și prezentate în acest raport este importantă.
                        </strong>
                    </p>
                    <ol className="lines-action-plan">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ol>
                    <p>
                        <strong>
                            Gândiți-vă la resurse care v-ar putea ajuta să vă îmbunătățiți aceste abilități și notați-le.
                        </strong>
                    </p>
                    <ol className="lines-action-plan">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ol>
                    <p>
                        <strong>
                            Ordonați resursele menționate anterior în funcție de ușurința cu care pot fi accesate și utilizate.
                        </strong>
                    </p>
                    <ol className="lines-action-plan">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ol>
                    <p>
                        <strong>
                            Acum, faceți o listă cu 3 competențe digitale pe care doriți să le dezvoltați în <u>următoarea lună</u>.
                            Atribuie fiecărui obiectiv un anumit termen limită și notează pași de acțiune detaliați.
                        </strong>
                    </p>

                    <p>
                        <strong>Competență digitală # 1: ________________________________________________________</strong>
                    </p>
                    <p>
                        <strong>Termen limită: ______/______/______</strong>
                    </p>
                    <p className="mb-0">Acțiunea 1</p>
                    <ol className="lines-action-plan without-numbers">
                        <li></li>
                        <li></li>
                        <li></li>
                    </ol>
                    <p className="mb-0">Acțiunea 2</p>
                    <ol className="lines-action-plan without-numbers">
                        <li></li>
                        <li></li>
                        <li></li>
                    </ol>
                    <p className="mb-0">Acțiunea 3</p>
                    <ol className="lines-action-plan without-numbers">
                        <li></li>
                        <li></li>
                        <li></li>
                    </ol>

                    <p className="mt-10">
                        <strong>Competență digitală # 2: ________________________________________________________</strong>
                    </p>
                    <p>
                        <strong>Termen limită: ______/______/______</strong>
                    </p>
                    <p className="mb-0">Acțiunea 1</p>
                    <ol className="lines-action-plan without-numbers">
                        <li></li>
                        <li></li>
                        <li></li>
                    </ol>
                    <p className="mb-0">Acțiunea 2</p>
                    <ol className="lines-action-plan without-numbers">
                        <li></li>
                        <li></li>
                        <li></li>
                    </ol>
                    <p className="mb-0">Acțiunea 3</p>
                    <ol className="lines-action-plan without-numbers">
                        <li></li>
                        <li></li>
                        <li></li>
                    </ol>

                    <p className="mt-10">
                        <strong>Competență digitală # 3: ________________________________________________________</strong>
                    </p>
                    <p>
                        <strong>Termen limită: ______/______/______</strong>
                    </p>
                    <p className="mb-0">Acțiunea 1</p>
                    <ol className="lines-action-plan without-numbers">
                        <li></li>
                        <li></li>
                        <li></li>
                    </ol>
                    <p className="mb-0">Acțiunea 2</p>
                    <ol className="lines-action-plan without-numbers">
                        <li></li>
                        <li></li>
                        <li></li>
                    </ol>
                    <p className="mb-0">Acțiunea 3</p>
                    <ol className="lines-action-plan without-numbers">
                        <li></li>
                        <li></li>
                        <li></li>
                    </ol>
                </div>
            </div>
        </div>
    );
};

export default Plan;
