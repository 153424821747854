import Box from "@mui/material/Box";
import Icon from "../ui/Icon";
import Typography from "@mui/material/Typography";
import ScrollBar from "../ui/ScrollBar";
import QuestionsButtons from "./QuestionsButtons";
import Button from "@mui/material/Button";
import ButtonNext from "./ButtonNext";
import React, {Dispatch, SetStateAction} from "react";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {IAnswer, ITest} from "./_interfaces";

const TestFooter = ({
                        test,
                        itemIndex,
                        answeredItems,
                        setItemIndex,
                        itemsNumber,
                        setLoading,
                        finishTest,
                        nextItem,
                    } : {
    test: ITest;
    itemIndex: number;
    answeredItems: IAnswer[];
    setItemIndex: (index: number) => void;
    itemsNumber: number;
    setLoading: Dispatch<SetStateAction<boolean>>;
    finishTest: () => void;
    nextItem: () => void;
}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

    return (
        <Box
            sx={{
                display: { xs: "block", lg: "none" },
                bgcolor: "#00289B",
                color: "#fff",
                padding: { xs: theme.spacing(1.5, 1.5), sm: theme.spacing(1.5, 2) },
                height: test.test_config === 2 ? "150px" : "130px",
                position: "fixed",
                width: "100%",
                bottom: 0,
            }}
        >
            <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                {test.test_config === 2 && (
                    <Box display="flex" alignItems="center">
                        <Box mr={1}>
                            <Icon
                                icon="fas fa-octagon-exclamation"
                                fixedWidth={true}
                                sx={{ fontSize: "1.5rem", color: "white" }}
                            />
                        </Box>
                        <Typography variant="body2" sx={{ color: "white" }}>
                            Profesorul tău a ales să răspunzi în ordinea afișării întrebărilor, adică fără posibilitatea de a te
                            întoarce la întrebările anterioare sau să le vezi pe următoarele.
                        </Typography>
                    </Box>
                )}

                {test.test_config === 1 && (
                    <Box pb={3} sx={{ margin: { xs: theme.spacing(-1), sm: theme.spacing(-1, -2, -1, -2) } }}>
                        <ScrollBar style={{ maxWidth: "100%" }} forceVisible="y" autoHide={false}>
                            <Box p={1} mb={0.5}>
                                <QuestionsButtons
                                    test={test}
                                    setItemIndex={setItemIndex}
                                    itemIndex={itemIndex}
                                    answeredItems={answeredItems}
                                    isLargeScreen={isLargeScreen}
                                    setLoading={setLoading}
                                />
                            </Box>
                        </ScrollBar>
                    </Box>
                )}

                <Box display="flex" alignItems="center" justifyContent="space-between" gap={1} >
                    <Button
                        variant="contained"
                        size="small"
                        onClick={finishTest}
                        sx={{
                            fontWeight: 500,
                            "&:hover": {
                                backgroundColor: { xs: "#0055d9", lg: "primary.main" },
                            },
                            "&[aria-expanded='true']": {
                                backgroundColor: { xs: "#0055d9", lg: "primary.main" },
                            },
                        }}
                    >
                        TERMINĂ{isSmallScreen && " TESTUL"}
                    </Button>
                    <Box>
                        <Typography color="#D7E6FF" lineHeight={1} m={0}>
                            {answeredItems.length}/{itemsNumber}
                        </Typography>
                    </Box>
                    <ButtonNext itemIndex={itemIndex} test={test} isLargeScreen={isLargeScreen} isMediumScreen={isMediumScreen} nextItem={nextItem} />
                </Box>
            </Box>
        </Box>
    );
}

export default TestFooter;