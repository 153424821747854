import { View, Text, Image } from "@react-pdf/renderer";
import {styles } from "../../StylesPdfReport";

interface ICoverPage {
    coverImage: string;
    type: string;
    children?: JSX.Element[] | JSX.Element | string;
}


const CoverPage = ({ coverImage, children, type }: ICoverPage) => {
    return (
        <>
            <View
                style={
                    type === "school" ? styles.coverPage : styles.coverPagePeach
                }
            >
                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <View>
                        <>
                            <Image src="/images/cover-logo-blue.png" style={styles.coverLogo} />
                            {children}
                        </>
                    </View>
                        <Image style={styles.coverImage} src={coverImage} />
                </View>

                <View
                    style={
                        type === "school"
                            ? styles.coverFooter
                            : styles.coverFooterPeach
                    }
                >
                    <Text>&copy; Copyright BRIO®, toate drepturile rezervate.</Text>
                    <Text>brio.ro</Text>
                </View>
            </View>
        </>
    );
};

export default CoverPage;
