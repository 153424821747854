import { View, Text, Image } from "@react-pdf/renderer";
import {styles} from "../../StylesPDFSchoolReport";
import moment from "moment/moment";

interface CoverData {
    startDate: Date;
    endDate: Date;
    subject: string;
    schoolName: string;
    tag: any[];
}

const CoverPage = ({startDate, endDate, subject, schoolName, tag}: CoverData) => {
    return (
        <>
            <View style={styles.coverPage}>
                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <View>
                        <Image src="/images/cover-logo-blue.png" style={styles.coverLogo} />
                        <View style={styles.titleCover}>
                            <Text style={styles.h1Cover}>Raport consolidat </Text>
                            <Text style={[styles.h1Cover, {marginBottom: "15pt"}]}>de evaluare educationala</Text>
                            <Text style={styles.h2}><>{subject} | {moment(startDate).locale("ro").format("DD.MM.YYYY")} - {moment(endDate).locale("ro").format("DD.MM.YYYY")}</></Text>
                        </View>
                        {tag.length > 0 && (
                            <>
                                <Text style={styles.h4}>ID:</Text>
                                <Text style={styles.h2}>{tag.map((item) => (
                                    <>{item} </>
                                ))}</Text>
                            </>
                        )}
                        <Text style={styles.h4}>Numele școlii:</Text>
                        <Text style={styles.h2}>{schoolName}</Text>
                    </View>
                    <Image style={styles.coverImage} src="/images/reports/cover-romana.png" />
                </View>
                <View style={styles.coverFooter} >
                    <Text>&copy; Copyright BRIO®, toate drepturile rezervate.</Text>
                    <Text>brio.ro</Text>
                </View>
            </View>
        </>
    );
};

export default CoverPage;
