import {Document, Page, View, Font, Text} from "@react-pdf/renderer";

import RubikLight from "../../fonts/Rubik-Light.ttf";
import RubikRegular from "../../fonts/Rubik-Regular.ttf";
import RubikMedium from "../../fonts/Rubik-Medium.ttf";
import RubikBold from "../../fonts/Rubik-Bold.ttf";
import RubikBlack from "../../fonts/Rubik-Black.ttf";

import {styles} from "./StylesPDFSchoolReport";
import CoverPage from "./components/pdf/Cover";
import React from "react";
import Section1 from "./components/pdf/Section1";
import Section2 from "./components/pdf/Section2";
import Section31 from "./components/pdf/Section31";
import Section32 from "./components/pdf/Section32";
import Section33 from "./components/pdf/Section33";

Font.register({
    family: "Rubik",
    format: "truetype",
    fonts: [
        { src: RubikLight, fontWeight: 300 },
        { src: RubikRegular, fontWeight: 400 },
        { src: RubikMedium, fontWeight: 500 },
        { src: RubikBold, fontWeight: 700 },
        { src: RubikBlack, fontWeight: 800 },
    ],
});

Font.registerHyphenationCallback(word => [word]);

const Report = ({data, images, schoolName}: any) => {
    return (
        <Document>
            <Page wrap size="A4" style={styles.body}>
                <View style={styles.coverBg}>
                    <CoverPage
                        startDate={data.start_date}
                        endDate={data.end_date}
                        subject={data.subject}
                        schoolName={schoolName}
                        tag={data.tag}
                    />
                    <View style={styles.mb100}></View>
                    <View style={styles.wrapperCover}>
                        <Section1
                            table2={data.payload.table2}
                            subject={data.subject}
                            totalTests={data.payload.finished_tests_count}
                        />
                        <View style={styles.mb100}></View>
                        <Section2
                            subject={data.subject}
                            imageBoxplot={images[0].image}
                            imageHeatmap={images[1].image}
                            imageRiscHeatmap={images[2].image}
                            table4={data.payload.table4.map((item: any) => {
                                return {
                                    dimensions : `Scor total clasa ${item.grade}`,
                                    ...item
                                }
                            })}
                            table5={data.payload.table5.map((item: any) => {
                                return {
                                    dimensions : `Scor total clasa ${item.grade}`,
                                    ...item
                                }
                            })}
                            table8={data.payload.table8.map((item: any) => {
                                return {
                                    dimensions : `Scor total clasa ${item.grade}`,
                                    ...item
                                }
                            })}
                        />
                        <View style={styles.mb100}></View>
                        <View style={styles.sectionAbout}>
                            <Text style={[styles.h1, styles.mb13]}>SECȚIUNEA 3. Rezultate privind competențele măsurate, pentru fiecare clasă</Text>
                            <Text style={styles.h1subtitle}>3.1. Scoruri medii și distribuții pentru fiecare clasa</Text>
                            <Section31
                                table6={data.payload.table6}
                                subject={data.subject}
                                imagesBoxplot={images.filter((item: any) => item.id.includes("gradeBoxplot"))}
                            />
                            <View style={styles.mb13}></View>
                            <Text style={styles.h1subtitle}>3.2. Distribuția elevilor în categorii decile naționale pentru fecare clasa</Text>
                            <Section32
                                table7={data.payload.table7}
                                subject={data.subject}
                                imagesHeatmap={images.filter((item: any) => item.id.includes("gradeHeatmap"))}
                            />
                            <View style={styles.mb13}></View>
                            <Text style={styles.h1subtitle}>3.3. Distribuția elevilor în categorii de risc pentru fiecare clasa</Text>
                            <Section33
                                table9={data.payload.table9}
                                subject={data.subject}
                                imagesHeatmap={images.filter((item: any) => item.id.includes("gradeRiscHeatmap"))}
                            />
                            <View style={styles.mb13}></View>
                        </View>
                        {/*<View break>*/}
                        {/*    <Text style={styles.h4}>Note:</Text>*/}
                        {/*    <Text style={styles.tableNoteNumber}>1</Text>*/}
                        {/*    <Text style={styles.tableNote}>Scorul mediu reprezinta media obtinuta pentru aceasta materie in aceasta scoala; Min este scorul minim obtinut; Max este scorul maxim obtinut; Q1, Q2 si Q3 sunt scorurile la care, in acesta scoala, se plaseaza 25%, 50% si respectiv 75% din elevi (astfel, jumatate din elevii scolii sunt intre Q1 si Q3; sfertul mai slab este intre Min si Q1, iar sfertul mai bun intre Q3 si Max).</Text>*/}
                        {/*    <Text style={styles.tableNoteNumber}>2</Text>*/}
                        {/*    <Text style={styles.tableNote}>Acest grafic se numeste Boxplot. El reprezinta vizual locatia scorurilor pentru dimensiunile reprezentate. Limitele casutei sunt cvartilele 1 si 3 (adica locul unde se afla 25% si respectiv 75% din elevi). Liniile orizontale atasate merg in stanga pana la scorul minim si in dreapta pana la scorul maxim. Linia care imparte caseta in jumatate este mediana scorurilor.</Text>*/}
                        {/*    <Text style={styles.tableNoteNumber}>3</Text>*/}
                        {/*    <Text style={styles.tableNote}>Tabelul prezinta numarul de elevi din scoala focala care se pleaseaza la nivel national in fiecare din cele 10 decile. Decilele sunt numerotate de la 1 la 10, unde decila 1 contine cei mai slabi 10% elevi la nivel national, iar decila 10 cei mai buni 10% elevi la nivel national.</Text>*/}
                        {/*    <Text style={styles.tableNoteNumber}>4</Text>*/}
                        {/*    <Text style={styles.tableNote}>Acest grafic se numeste Heatmap. El reprezinta vizual numarul de elevi gasiti, pentru fiecare dimensiune, in fiecare decila. Cu cat o casuta este mai inchisa la culoare cu atat este mai populata cu elevi - cu atat mai multi elevi se regasesc in acel interval de scoruri.</Text>*/}
                        {/*    <Text style={styles.tableNoteNumber}>5</Text>*/}
                        {/*    <Text style={styles.tableNote}>Tabelul prezintă numărul de elevi din școală care se plasează în fiecare din cele 5 categorii de risc sau performanță.*/}
                        {/*    "A = absolut fara risc (centilele 90-100), B = fara risc (centilele 70-89), C = risc mediu (centilele 31-69), D = risc ridicat (centilele 11-30), E = risc foarte ridicat  (centilele 1-10)</Text>*/}
                        {/*    <Text style={styles.tableNoteNumber}>6</Text>*/}
                        {/*    <Text style={styles.tableNote}>Acest grafic vizualizează numarul elevilor găsiși, pentru fiecare dimensiune, in fiecare categorie de risc sau performanță. Cu cât o căsuță este mai închisă la culoare cu atât este mai populată cu elevi. Totalul fiecărei linii este 100%.</Text>*/}
                        {/*</View>*/}
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default Report;
