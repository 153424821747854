interface IDMessageTitle {
  position: string;
  type: string;
  name?: string;
  mode: number;
  classroom: string;
  itemType: string;
}

const MessageTitle = (data: IDMessageTitle) => {
  return (
      <>
        {(data.itemType === "Domain\\Notifications\\Notifications\\TestSessionClosedNotification") && (
           <>
             {data.position === "teacher" && (
                 <>
                   Elevul {data.name} din clasa {data.classroom} a finalizat sesiunea de testare {data.mode === 2 && "antrenament"} la {data.type}
                 </>
             )}
           </>
        )}

        {(data.itemType === "Domain\\Notifications\\Notifications\\UserCreatedNotification") && (
            <>
              Bine ai venit pe Brio.ro!
            </>
        )}

        {(data.itemType === "Domain\\Notifications\\Notifications\\TestSessionExpiredNotification") && (
            <>
              {data.position === "student" && (
                  <>
                    Sesiunea de testare a expirat.
                  </>
              )}
              {data.position === "teacher" && (
                  <>
                    Sesiunile de testare pentru clasa {data.classroom}, au expirat.
                  </>
              )}
            </>
        )}

        {(data.itemType === "Domain\\Notifications\\Notifications\\TestSessionStartedNotification") && (
            <>
              {data.position === "teacher" && (
                  <>
                    Elevul {data.name} a accesat sesiunea de testare
                  </>
              )}
            </>
        )}

        {(data.itemType === "Domain\\Notifications\\Notifications\\TestSessionCreatedNotification") && (
            <>
              {data.position === "student" && (
                  <>
                    Profesorul {data.name} ți-a generat un test {data.mode === 2 && "antrenament"} de {data.type}
                  </>
              )}
              {data.position === "teacher" && (
                  <>
                    Testele {data.mode.toString() === "2" && "antrenament"} de {data.type} pentru {data.classroom} au fost generate
                  </>
              )}
            </>
        )}
    </>
  );
};

export default MessageTitle;
