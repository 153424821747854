import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/ro";

import { ITest } from "components/test/_interfaces";
import { useAuth } from "hooks/useAuth";
import { niceUserName } from "utils/functions";
import Icon from "components/ui/Icon";

const TestStartModal = ({ test, startTest = () => {}, testFromClient }: { test: ITest; startTest: () => void, testFromClient: boolean }) => {
  const navigate = useNavigate();
  const { user, update, logout } = useAuth();

  const confirmStartMessage = () => {
    return (
      <>
        <Typography variant="h1" mb={2}>
          {test.title} #{test.test_id}
        </Typography>
        <Typography>
          Apasă pe butonul de mai jos{" "}
          <Typography component="strong">
            doar când ești pregătit să începi testul
          </Typography>
          , deoarece timpul de rezolvare alocat va porni automat odată cu această acțiune.
        </Typography>
        <Typography>
          Test valabil până la <strong>{moment(test.expires_at).locale("ro").format("HH:mm, DD MMMM YYYY")}</strong>
        </Typography>
        {test.test_config === 2 && (
          <Box display="flex" alignItems="center" mt={2} mb={1}>
            <Box mr={2}>
              <Icon
                icon="fas fa-octagon-exclamation"
                fixedWidth={true}
                sx={{ fontSize: "1.5rem", color: "error.main" }}
              />
            </Box>
            <Typography sx={{ color: "secondary.light" }}>
              Profesorul tău a ales să răspunzi în ordinea afișării întrebărilor, adică fără posibilitatea de a te
              întoarce la întrebările anterioare sau să le vezi pe următoarele.
            </Typography>
          </Box>
        )}
      </>
    );
  };

  useEffect(() => {
    // TEST WAS NOT STARTED
    update("confirm", {
      show: true,
      closable: false,
      title: `CONFIRMARE ELEV - ${niceUserName(user.firstname, user.lastname, user.email).toUpperCase()}`,
      message: confirmStartMessage(),
      returnFunction: (callback: Function) => {
        startTest();
        update("confirm", { show: false });
      },
      cancelFunction: () => {
        if(testFromClient) {
          logout();
        } else {
          navigate("/teste");
        }
      },
      buttonYes: (
        <Box display="flex" gap={0.75} alignItems="center">
          <Icon icon="far stopwatch" style={{ fontSize: "1.5rem", top: 2, position: "relative" }} />
          START TEST
        </Box>
      ),
      buttonNo: "Renunță",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default TestStartModal;
