import { View, Text, Image } from "@react-pdf/renderer";
import {styles } from "../../StylesPdfReport";

const ChartNoteWrapperSmall = ({ score, scoreDescription, type }: any) => {
    return (
        <>
            {type === "school" && (
                <View style={styles.chartNoteWrapper}>
                    {score >= 0 && score <= 10 && (
                        <View style={[styles.chartNote, { width: "10%", margin: "7pt 0 0 0" }]}>
                            <View style={styles.chartNoteBorder}></View>
                            <Text style={styles.chartNoteName}>Insuficient</Text>
                            <Text style={styles.chartNoteValues}>0–10</Text>
                            <View style={styles.nextUpgrade}>
                                <Image src="/images/reports/arrow-right.png" style={styles.arrowRight} />
                                <Text style={styles.nextUpgradeChartNoteName}>Satisfăcător</Text>
                                {Array.isArray(scoreDescription) &&
                                    scoreDescription.map((scoreDescription: any, index: any) => (
                                        <View key={index}>
                                            {scoreDescription.study_time !== null && score < 95 && <Text>{scoreDescription.study_time} ore</Text>}
                                        </View>
                                    ))}
                            </View>
                        </View>
                    )}
                    {score > 30 && score <= 60 && (
                        <View style={[styles.chartNote, { width: "30%", margin: "7pt 0 0 0", left: "30%" }]}>
                            <View style={styles.chartNoteBorder}></View>
                            <Text style={styles.chartNoteName}>Intermediar</Text>
                            <Text style={styles.chartNoteValues}>30–60</Text>
                            <View style={styles.nextUpgrade}>
                                <Image src="/images/reports/arrow-right.png" style={styles.arrowRight} />
                                <Text style={styles.chartNoteName}>Competent</Text>
                                {Array.isArray(scoreDescription) &&
                                    scoreDescription.map((scoreDescription: any, index: any) => (
                                        <View key={index}>
                                            {scoreDescription.study_time !== null && score < 95 && (
                                                <Text style={styles.chartNoteValues}>{scoreDescription.study_time} ore</Text>
                                            )}
                                        </View>
                                    ))}
                            </View>
                        </View>
                    )}
                    {score > 80 && score <= 95 && (
                        <View style={[styles.chartNote, { width: "15%", margin: "7pt 0 0 0", left: "80%" }]}>
                            <View style={styles.chartNoteBorder}></View>
                            <Text style={styles.chartNoteName}>Avansat</Text>
                            <Text style={styles.chartNoteValues}>80–95</Text>
                            <View style={styles.nextUpgrade}>
                                <Image src="/images/reports/arrow-right.png" style={styles.arrowRight} />
                                <Text style={styles.chartNoteName}>Expert</Text>
                                {Array.isArray(scoreDescription) &&
                                    scoreDescription.map((scoreDescription: any, index: any) => (
                                        <View key={index}>
                                            {scoreDescription.study_time !== null && score < 95 && (
                                                <Text style={styles.chartNoteValues}>{scoreDescription.study_time} ore</Text>
                                            )}
                                        </View>
                                    ))}
                            </View>
                        </View>
                    )}
                    {score > 10 && score <= 30 && (
                        <View style={[styles.chartNote, { width: "20%", margin: "7pt 0 0 0", left: "10%" }]}>
                            <View style={styles.chartNoteBorder}></View>
                            <Text style={styles.chartNoteName}>Satisfăcător</Text>
                            <Text style={styles.chartNoteValues}>10–30</Text>
                            <View style={styles.nextUpgrade}>
                                <Image src="/images/reports/arrow-right.png" style={styles.arrowRight} />
                                <Text style={styles.chartNoteName}>Intermediar</Text>
                                {Array.isArray(scoreDescription) &&
                                    scoreDescription.map((scoreDescription: any, index: any) => (
                                        <View key={index}>
                                            {scoreDescription.study_time !== null && score < 95 && (
                                                <Text style={styles.chartNoteValues}>{scoreDescription.study_time} ore</Text>
                                            )}
                                        </View>
                                    ))}
                            </View>
                        </View>
                    )}
                    {score > 60 && score <= 80 && (
                        <View style={[styles.chartNote, { width: "20%", margin: "7pt 0 0 0", left: "60%" }]}>
                            <View style={styles.chartNoteBorder}></View>
                            <Text style={styles.chartNoteName}>Competent</Text>
                            <Text style={styles.chartNoteValues}>60–80</Text>
                            <View style={styles.nextUpgrade}>
                                <Image src="/images/reports/arrow-right.png" style={styles.arrowRight} />
                                <Text style={styles.chartNoteName}>Avansat</Text>
                                {Array.isArray(scoreDescription) &&
                                    scoreDescription.map((scoreDescription: any, index: any) => (
                                        <View key={index}>
                                            {scoreDescription.study_time !== null && score < 95 && (
                                                <Text style={styles.chartNoteValues}>{scoreDescription.study_time} ore</Text>
                                            )}
                                        </View>
                                    ))}
                            </View>
                        </View>
                    )}
                    {score > 95 && score <= 100 && (
                        <View style={[styles.chartNote, { width: "5%", margin: "7pt 0 0 0", left: "95%" }]}>
                            <View style={styles.chartNoteBorder}></View>
                            <Text style={styles.chartNoteName}>Expert</Text>
                            <Text style={styles.chartNoteValues}>95–100</Text>
                        </View>
                    )}
                </View>
            )}

            {type === "digitalLiteracy" && (
                <View style={styles.chartNoteWrapper}>
                    {score >= 0 && score < 35 && (
                        <View style={[styles.chartNote, { width: "35%", margin: "7pt 0 0 0" }]}>
                            <View style={styles.chartNoteBorder}></View>
                            <Text style={styles.chartNoteName}>Nivel de bază</Text>
                            <Text style={styles.chartNoteValues}>0–35</Text>
                            <View style={styles.nextUpgrade}>
                                <Image src="/images/reports/arrow-right.png" style={styles.arrowRight} />
                                <Text style={styles.nextUpgradeChartNoteName}>Intermediar</Text>
                                {Array.isArray(scoreDescription) &&
                                    scoreDescription.map((scoreDescription: any, index: any) => (
                                        <View key={index}>
                                            {scoreDescription.study_time !== null && <Text>{scoreDescription.study_time} ore</Text>}
                                        </View>
                                    ))}
                            </View>
                        </View>
                    )}
                    {score >= 35 && score < 65 && (
                        <View style={[styles.chartNote, { width: "30%", margin: "7pt 0 0 0", left: "35%" }]}>
                            <View style={styles.chartNoteBorder}></View>
                            <Text style={styles.chartNoteName}>Intermediar</Text>
                            <Text style={styles.chartNoteValues}>35–65</Text>
                            <View style={styles.nextUpgrade}>
                                <Image src="/images/reports/arrow-right.png" style={styles.arrowRight} />
                                <Text style={styles.chartNoteName}>Avansat</Text>
                                {Array.isArray(scoreDescription) &&
                                    scoreDescription.map((scoreDescription: any, index: any) => (
                                        <View key={index}>
                                            {scoreDescription.study_time !== null && (
                                                <Text style={styles.chartNoteValues}>{scoreDescription.study_time} ore</Text>
                                            )}
                                        </View>
                                    ))}
                            </View>
                        </View>
                    )}
                    {score >= 65 && score < 95 && (
                        <View style={[styles.chartNote, { width: "30%", margin: "7pt 0 0 0", left: "65%" }]}>
                            <View style={styles.chartNoteBorder}></View>
                            <Text style={styles.chartNoteName}>Avansat</Text>
                            <Text style={styles.chartNoteValues}>65–95</Text>
                            <View style={styles.nextUpgrade}>
                                <Image src="/images/reports/arrow-right.png" style={styles.arrowRight} />
                                <Text style={styles.chartNoteName}>Expert</Text>
                                {Array.isArray(scoreDescription) &&
                                    scoreDescription.map((scoreDescription: any, index: any) => (
                                        <View key={index}>
                                            {scoreDescription.study_time !== null && (
                                                <Text style={styles.chartNoteValues}>{scoreDescription.study_time} ore</Text>
                                            )}
                                        </View>
                                    ))}
                            </View>
                        </View>
                    )}
                    {score >= 95 && (
                        <View style={[styles.chartNote, { width: "5%", margin: "7pt 0 0 0", left: "95%" }]}>
                            <View style={styles.chartNoteBorder}></View>
                            <Text style={styles.chartNoteName}>Specializat</Text>
                            <Text style={styles.chartNoteValues}>95–100</Text>
                        </View>
                    )}
                </View>
            )}
        </>

    );
};

export default ChartNoteWrapperSmall;
