import Typography from "@mui/material/Typography";
import RadioField from "components/form/RadioField";
import BoxContent from "components/ui/BoxContent";

const CreateTestIndividual = ({ errors, individualTest, handleSelectChange }: any) => {
  return (
    <>
      <Typography variant="h3" mb={3} mt={3}>
        Teste individuale?
      </Typography>
      <BoxContent>
        <RadioField
          row={true}
          defaultValue={individualTest}
          name="individual_test"
          columns={false}
          onChange={handleSelectChange}
          options={[
            {
              label: "Teste diferite pentru fiecare elev",
              value: 1,
            },
            {
              label: "Același test pentru toți elevii",
              value: 0,
            },
          ]}
          error={errors.individual_test?.message}
        />
          <Typography variant="body2" mt={1.5}>*In cazul testelor cu aceiași itemi, aceștia pot fi vizualizați și înlocuiți în pasul următor.</Typography>
      </BoxContent>
    </>
  );
};

export default CreateTestIndividual;
