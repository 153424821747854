import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Icon from "components/ui/Icon";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";

interface IDMessageContent {
  position: string;
  type: string;
  expires: number;
  createdAt?: Date;
  name?: string;
  id: number;
  grade: string;
  mode: number;
  classroom?: string;
  itemType: string;
}

const MessagesContent = (data: IDMessageContent) => {
  const navigate = useNavigate();

  return (
    <>
      {(data.itemType === "Domain\\Notifications\\Notifications\\TestSessionClosedNotification") && (
          <>
            {data.position === "teacher" && (
                <>
                  Elevul <b>{data.name}</b> din clasa <b>{data.classroom}</b> a finalizat sesiunea de testare <b>{data.mode === 2 && "antrenament"}</b> la <b>{data.type}</b>, generată în data de <b>{moment(data.createdAt).locale("ro").format("DD-MM-YYYY")}</b>, la ora <b>{moment(data.createdAt).locale("ro").format("HH:mm")}</b>. Rezultatele sale pot fi accesate în secțiunea <b>Teste Finalizate</b> a contului tău.
                </>
            )}
          </>
      )}

      {(data.itemType === "Domain\\Notifications\\Notifications\\TestSessionStartedNotification") && (
          <>
            {data.position === "teacher" && (
                <>
                  Elevul <b>{data.name}</b> din clasa <b>{data.classroom}</b> a accesat sesiunea de testare <b>{data.mode === 2 && "antrenament"}</b> la <b>{data.type}</b>, generată în data de {moment(data.createdAt).locale("ro").format("DD-MM-YYYY")}, la ora {moment(data.createdAt).locale("ro").format("HH:mm")}.
                </>
            )}
          </>
      )}

      {(data.itemType === "Domain\\Notifications\\Notifications\\TestSessionExpiredNotification") && (
          <>
            {data.position === "student" && (
                <>
                  Sesiunea de testare <b>{data.mode === 2 && "antrenament"}</b> la <b>{data.type}</b>, generată de <b>{data.name}</b> a expirat. Contactează-ți profesorul coordonator pentru mai multe detalii.
                </>
            )}
            {data.position === "teacher" && (
                <>
                  Sesiunile de testare <b>{data.mode === 2 && "antrenament"}</b> la <b>{data.type}</b>, pentru clasa <b>{data.classroom}</b>, generate în data de <b>{moment(data.createdAt).locale("ro").format("DD-MM-YYYY")}</b>, la ora <b>{moment(data.createdAt).locale("ro").format("HH:mm")}</b> au expirat.
                </>
            )}
          </>
      )}

      {(data.itemType === "Domain\\Notifications\\Notifications\\TestSessionCreatedNotification") && (
          <>
            {data.position === "student" && (
                <>
                  <Typography>
                    Testul  {data.mode.toString() ==="2" && "antrenament"} de{" "}
                    <b> {(data.mode === 1 || data.mode === 2 || data.mode === 5 || data.mode === 6) && data.type}
                      {(data.mode === 3 || data.mode === 4 ) && data.grade}</b>, clasa <b>{data.classroom}</b>, {" "}
                    expiră în <b>{data.expires} ore</b>.
                  </Typography>
                  <Button
                      variant="contained"
                      startIcon={<Icon icon="far pen-to-square" fixedWidth={true} sx={{ fontSize: "1.25rem" }} />}
                      onClick={() => {
                        navigate(`/teste/${data.id}`);
                      }}
                  >
                    Începe testul
                  </Button>
                </>
            )}

            {data.position === "teacher" && (
                <>
                <Typography>
                  Testele {data.mode.toString() === "2" && "antrenament"} de{" "}
                  <b>
                      {(data.mode === 1 || data.mode.toString() === "2" || data.mode === 5 || data.mode === 6) && data.type} {" "}
                      {(data.mode === 3 || data.mode === 4 ) && data.grade}
                  </b>{" "}
                  pentru elevii din <b>{data.classroom}</b>, expiră în <b>{data.expires} {data.expires > 1 ? "ore" : "oră"}</b>. Ele pot fi vizualizate in
                  pagina {data.mode === 1 && <Link to={"/teste"}>Teste școlare</Link>}
                  {data.mode.toString() === "2" && <Link to={"/teste-antrenament"}>Teste antrenament</Link>}
                  {data.mode === 3 && <Link to={"/teste-bac"}>Teste bacalaureat</Link>}
                  {data.mode === 4 && <Link to={"/teste-evaluare"}>Teste evaluare</Link>}
                  {data.mode === 5 && <Link to={"/teste-literatie"}>Teste literație</Link>}
                  {data.mode === 6 && <Link to={"/teste-literatie-digitala"}>Teste literație digitală</Link>}
                </Typography>
                </>
            )}
          </>
      )}
    </>
  );
};

export default MessagesContent;
