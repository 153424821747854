import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChartTreeMap as fasChartTreeMap,
  faListCheck as fasListCheck,
  faGrid2Plus as fasGrid2Plus,
  faChalkboardUser as fasChalkboardUser,
  faScreenUsers as fasScreenUsers,
  faUserGraduate as fasUserGraduate,
  faFileChartPie as fasFileChartPie,
  faFileInvoice as fasFileInvoice,
  faUserPen as fasUserPen,
  faCommentsQuestionCheck as fasCommentsQuestionCheck,
  faCommentDots as fasCommentDots,
  faUnlock as fasUnlock,
  faBars as fasBars,
  faChevronRight as fasChevronRight,
  faSortDown as fasSortDown,
  faPlus as fasPlus,
  faFilePlus as fasFilePlus,
  faEye as fasEye,
  faCaretDown as fasCaretDown,
  faXmark as fasXmark,
  faSchool as fasSchool,
  faFilterList as fasFilterList,
  faTrashCan as fasTrashCan,
  faMoon as fasMoon,
  faSunBright as fasSunBright,
  faChevronLeft as fasChevronLeft,
  faPenToSquare as fasPenToSquare,
  faExclamationCircle as fasExclamationCircle,
  faCaretRight as fasCaretRight,
  faImage as fasImage,
  faBook as fasBook,
  faLaptopMobile as fasLaptopMobile,
  faUser as fasUser,
  faMapLocationDot as fasMapLocationDot,
  faCreditCard as fasCreditCard,
  faCartPlus as fasCartPlus,
  faTrophy as fasTrophy,
  faListRadio as fasListRadio,
  faCircleXmark as fasCircleXmark,
  faCircleCheck as fasCircleCheck,
  faOctagonExclamation as fasOctagonExclamation,
  faBellOn as fasBellOn,
  faBoxingGlove as fasBoxingGlove,
  faListOl as fasListOl,
    faLocationDot as fasLocationDot,
  faUpload as fasUpload,
  faUsers as fasUsers,
  faTags as fasTags,
    faVideo as fasVideo,
}
// @ts-ignore
from "@awesome.me/kit-b8aac00bfe/icons/classic/solid";
import {
  faUserGraduate as falUserGraduate,
  faScreenUsers as falScreenUsers,
  faChalkboardUser as falChalkboardUser,
  faChartLineUp as falChartLineUp,
  faGraduationCap as falGraduationCap,
  faListCheck as falListCheck,
  faXmark as falXmark,
  faStopwatch as falStopwatch,
  faUserPen as falUserPen,
  faBoxTaped as falBoxTaped,
  faBoxesStacked as falBoxesStacked,
  faMapLocationDot as falMapLocationDot,
  faCreditCard as falCreditCard,
  faCircleInfo as falCircleInfo,
}
// @ts-ignore
from "@awesome.me/kit-b8aac00bfe/icons/classic/light";
import {
  faFaceMeh as fatFaceMeh,
  faFaceEyesXmarks as fatFaceEyesXmarks,
  faCircleNotch as fatCircleNotch,
  faPhone as fatPhone,
  faEnvelope as fatEnvelope,
  faFaceSmile as fatFaceSmile,
  faCloudArrowUp as fatCloudArrowUp,
}
// @ts-ignore
from "@awesome.me/kit-b8aac00bfe/icons/classic/thin";

import {
  faCircleNotch as farCircleNotch,
  faCaretDown as farCaretDown,
  faPhone as farPhone,
  faEnvelope as farEnvelope,
  faXmark as farXmark,
  faPenToSquare as farPenToSquare,
  faTrashCan as farTrashCan,
  faStopwatch as farStopwatch,
  faScreenUsers as farScreenUsers,
  faListCheck as farListCheck,
  faChartLineUp as farChartLineUp,
  faChalkboardUser as farChalkboardUser,
  faPlay as farPlay,
  faArrowsRotate as farArrowsRotate,
  faEye as farEye,
  faMagnifyingGlass as farMagnifyingGlass,
  faArrowDownToLine as farArrowDownToLine,
  faBoxArchive as farBoxArchive,
}
// @ts-ignore
from "@awesome.me/kit-b8aac00bfe/icons/classic/regular";
import { faWhatsapp as fabWhatsapp } from "@fortawesome/free-brands-svg-icons";

library.add(
  // solid
  fasChartTreeMap,
  fasListCheck,
  fasGrid2Plus,
  fasChalkboardUser,
  fasScreenUsers,
  fasUserGraduate,
  fasFileChartPie,
  fasFileInvoice,
  fasUserPen,
  fasCommentsQuestionCheck,
  fasCommentDots,
  fasUnlock,
  fasBars,
  fasChevronRight,
  fasSortDown,
  fasPlus,
  fasFilePlus,
  fasEye,
  fasCaretDown,
  fasXmark,
  fasSchool,
  fasFilterList,
  fasTrashCan,
  fasMoon,
  fasSunBright,
  fasChevronLeft,
  fasPenToSquare,
  fasExclamationCircle,
  fasCaretRight,
  fasImage,
  fasBook,
  fasLaptopMobile,
  fasUser,
  fasMapLocationDot,
  fasCreditCard,
  fasCartPlus,
  fasTrophy,
  fasListRadio,
  fasCircleXmark,
  fasCircleCheck,
  fasOctagonExclamation,
  fasBellOn,
  fasBoxingGlove,
  fasListOl,
  fasLocationDot,
  fasUpload,
  fasUsers,
  fasTags,
  fasVideo,
  // light
  falUserGraduate,
  falScreenUsers,
  falChalkboardUser,
  falChartLineUp,
  falGraduationCap,
  falListCheck,
  falXmark,
  falStopwatch,
  falUserPen,
  falBoxTaped,
  falBoxesStacked,
  falMapLocationDot,
  falCreditCard,
  falCircleInfo,
    // regular
  farCircleNotch,
  farCaretDown,
  farPhone,
  farEnvelope,
  farXmark,
  farPenToSquare,
  farTrashCan,
  farStopwatch,
  farScreenUsers,
  farListCheck,
  farChartLineUp,
  farChalkboardUser,
  farPlay,
  farArrowsRotate,
  farEye,
  farMagnifyingGlass,
  farArrowDownToLine,
  farBoxArchive,
  // thin
  fatFaceMeh,
  fatFaceEyesXmarks,
  fatCircleNotch,
  fatPhone,
  fatEnvelope,
  fatFaceSmile,
  fatCloudArrowUp,
  // brands
  fabWhatsapp,
);
