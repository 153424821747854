import {useEffect} from "react";
import {useAuth} from "../hooks/useAuth";

const TestFinishedClient = () => {
    const { update } = useAuth();

    useEffect(() => {
        update("error", {
            show: true,
            title: "Felicitări, ai terminat testul!",
            message: "",
            icon: "fat face-smile",
            closable: false,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;

}

export default TestFinishedClient;