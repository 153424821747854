import React from "react";
import ChartNoteWrapperSmall from "./ChartNoteWrapperSmall";
import Units from "./Units";
import ChartNoteWrapperBig from "./ChartNoteWrapperBig";
import {scoreLevelName} from "../../../utils/reportsFunctions";

const Chart = ({ left, bottom, testScore, segmentsImage, big = false, scoreDescription, type }: any) => {
    const scoreLevel = scoreLevelName(testScore);

    return (
        <>
        {(type === "school" || type === "digitalLiteracy") && (
            <>
                <div className="chart mb-13">
                    <div className="nota-wrapper">
                        <div className="nota" style={{ left: `${left}%`, bottom: `${bottom}mm` }}>
                            <div className="label">Scor BRIO</div>
                            <div className="value" style={{ backgroundImage: "url(/images/reports/nota.png)" }}>
                                <svg
                                    className="print"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 142 138"
                                    xmlSpace="preserve"
                                >
                                    <circle cx="71" cy="126.5" r="11.5" />
                                    <polygon points="0,0 0,84 49,84 71,106 93,84 142,84 142,0 " />
                                </svg>
                                {testScore}
                            </div>
                        </div>
                    </div>
                    <img src={segmentsImage} className="chart-bg chart-insuficient active" alt=""></img>
                    {}
                    <Units type={type}/>
                    {big ? (
                        <ChartNoteWrapperBig type={type}/>
                    ) : (
                        <ChartNoteWrapperSmall score={testScore} scoreDescription={scoreDescription} type={type}/>
                    )}
                </div>
            </>
        )}

            {type === "literacy" && (
                <div className="chart mb-16">
                    <div className="nota-wrapper">
                        <div className={`nota score-horizontal score-horizontal-${scoreLevel}`} style={{ width: `${testScore}%` }}>
                            <svg
                                className="print svg-print"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 10 10"
                                preserveAspectRatio="none"
                            >
                                <rect width="10" height="10" />
                            </svg>
                            <div className="score-horizontal-value">{testScore}</div>
                        </div>
                    </div>
                    <img src="/images/reports/chart-bg-literatie.png" className="chart-bg chart-insuficient active" alt=""></img>
                    <Units type={type}/>
                </div>
            )}
        </>
    );
};

export default Chart;
