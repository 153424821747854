import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

type Props = {
  title?: string;
};

const FormLabelBox = ({ title }: Props) => {
  return (
    <Grid item xs={12} sm={2} md={2} xl={1} sx={{ textAlign: { xs: "left", sm: "right" } }}>
      <Typography variant="body2">{title}</Typography>
    </Grid>
  );
};

export default FormLabelBox;
