import {markedLevel, scoreLevelCssClass} from "../../../utils/reportsFunctions";

const ChartSubsection = ({ gradeLevel, score, index, type }: any) => {
    const scoreLevel = scoreLevelCssClass(gradeLevel.level, "literacy");
    const marked = markedLevel(score, gradeLevel.level, "literacy")
    return (
                <div key={index}>
                    <div
                        key={gradeLevel.level}
                        className={`subsection ${marked} ${scoreLevel}`}
                    >
                        <div>
                            {Array.isArray(gradeLevel.description) ? (
                                <ul>
                                    {gradeLevel.description.map((description: any, index: any) => (
                                        <li key={index}>{description}</li>
                                    ))}
                                </ul>
                            ) : (
                                <div dangerouslySetInnerHTML={{ __html: gradeLevel.description }} />
                            )}
                        </div>
                    </div>
                </div>
    );
};

export default ChartSubsection;
