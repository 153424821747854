import CustomButton from "components/ui/CustomButton";
import { useNavigate } from "react-router-dom";
import Icon from "components/ui/Icon";
import { useAuth } from "hooks/useAuth";
import { useEffect } from "react";
import Pusher from "pusher-js";
import { APIPostNotificationsUnread } from "api/pages/notifications";
import Badge, { BadgeProps } from "@mui/material/Badge";
import { styled } from "@mui/system";

export const StyledBadge = styled((props: BadgeProps) => <Badge {...props} />)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: theme.palette.error.main,
    color: "#FFFFFF",
    fontSize: "0.6875rem",
    minWidth: "17px",
    height: "17px",
    right: "3px",
    borderRadius: "7px",
  },
}));

const NotificationsIcon = () => {
  const navigate = useNavigate();
  const { user, tenant, update } = useAuth();

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || "", {
      cluster: "eu",
    });
    const channel1 = pusher.subscribe(`channel_${tenant.id}`);
    // You can bind more channels here like this
    // const channel2 = pusher.subscribe('channel_name2')
    channel1.bind(`event_${tenant.id}_test_notification`, function (data: any) {
          APIPostNotificationsUnread()
              .then((response) => {
                if (response.data.success) {
                  // console.log(response.data.data.value, "notification unread");
                  update("user", { notifications: response.data.data.value });
                } else {
                  update("error", { show: true, code: "A002" });
                }
              })
              .catch((err) => {
                // console.log("getMessages ERR", err);
                update("error", { show: true, code: "A001" });
              })
      // console.log(data);

    });

    return () => {
      pusher.unsubscribe(`channel_${tenant.id}`);
      // pusher.unsubscribe('channel_name2')
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledBadge badgeContent={user.notifications}>
      <CustomButton
        buttonType="icon"
        appearance="circle"
        bgcolor="white"
        borderColor={user.notifications ? "error.main" : ""}
        size="small"
        onClick={() => navigate("/mesaje")}
        icon={<Icon icon="fas bell-on" style={{ fontSize: "1rem" }} />}
        sxExtra={{fontSize: "1.25rem"}}
      />
    </StyledBadge>
  );
};

export default NotificationsIcon;
