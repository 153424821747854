import Grid from "@mui/material/Grid";

type Props = {
  children: JSX.Element[] | JSX.Element;
};

const AddTestButtonLayout = ({ children }: Props) => {
  return (
    <Grid
      width="100%"
      sx={{ position: "fixed", bottom: 0, left: 0 }}
      container
      direction="row"
      justifyContent="flex-start"
    >
      <Grid
        item
        xs={12}
        lg={4}
        xl={3}
        sx={{
          paddingRight: { xs: 0, md: "10px" },
        }}
      ></Grid>
      <Grid item xs={12} lg={8} xl={9} pt={2} pb={2} pl={{xs: 2, md: 4}} pr={{xs: 2, md: 4}} sx={{ backgroundColor: "background.default" }}>
        {children}
      </Grid>
    </Grid>
  );
};

export default AddTestButtonLayout;
