import axiosClient from "api/apiClient";

interface IOptions {
  id?: string;
  sort?: string;
  search?: string;
}

export const APIGetStudent = (options: IOptions) => {
  let sort = "";
  let search = "";
  if (options.hasOwnProperty("sort")) {
    sort = "&sort=" + options.sort;
  }
  if (options.hasOwnProperty("search")) {
    search = "&filter[search]=" + options.sort;
  }
  return axiosClient.get(`/students/${options.id}?${search}${sort}`);
};

export const APIDeleteStudent = (options: IOptions) => {
  return axiosClient.delete(`/students/${options.id}`);
};

export const APIUpdateStudent = (id: string, data: object) => {
  return axiosClient.put(`/students/${id}`, data);
};

export const APIUpdateStudentClassrooms = (id: string, data: object) => {
  return axiosClient.put(`/students/${id}/classrooms`, data);
};

export const APIPostStudent = (data: object) => {
  return axiosClient.post(`/students`, data);
};
