import {View} from "@react-pdf/renderer";
import {styles } from "../../StylesPdfReport";

const MarkGroup = ({type}: any) => {
    return (
        <>
            {type === "school" &&
                <View style={styles.markGroup}>
                    <View style={[styles.markLine, { left: "1pt" }]}></View>
                    <View style={[styles.markLine, { left: "10%" }]}></View>
                    <View style={[styles.markLine, { left: "30%" }]}></View>
                    <View style={[styles.markLine, { left: "60%" }]}></View>
                    <View style={[styles.markLine, { left: "80%" }]}></View>
                    <View style={[styles.markLine, { left: "95%" }]}></View>
                    <View style={[styles.markLine, { right: 0 }]}></View>
                </View>}
            {type === "literacy" &&
                <View style={styles.markGroup}>
                    <View style={[styles.markLine, { left: "1pt" }]}></View>
                    <View style={[styles.markLine, { left: "25%" }]}></View>
                    <View style={[styles.markLine, { left: "80%" }]}></View>
                    <View style={[styles.markLine, { right: 0 }]}></View>
                </View>}
            {type === "digitalLiteracy" &&
                <View style={styles.markGroup}>
                    <View style={[styles.markLine, { left: "1pt" }]}></View>
                    <View style={[styles.markLine, { left: "35%" }]}></View>
                    <View style={[styles.markLine, { left: "65%" }]}></View>
                    <View style={[styles.markLine, { left: "95%" }]}></View>
                    <View style={[styles.markLine, { right: 0 }]}></View>
                </View>}
        </>
    )
}

export default MarkGroup;