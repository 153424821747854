import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

interface IDBoxNumbers {
  data?: {
    title: string;
    amount: number;
  }[];
  noDataText?: string;
}

const DashboardBoxList = ({ data = [] , noDataText = ""}: IDBoxNumbers) => {
  return data.length === 0 ? (
    <Box width="100%" textAlign="left">
      {noDataText}
    </Box>
  ) : (
    <Grid container spacing={2}>
      {data.map((item, index) => (
        <Grid item xs={3} key={index}>
          <Box p={0.5} sx={{ bgcolor: "extra.dashboard_numbers", borderRadius: "5px", textAlign: "right" }}>
            <Typography mb={0} variant="body1" color={"primary.light"}>
              {item.title}
            </Typography>
            <Typography variant="body1">{item.amount}</Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default DashboardBoxList;
