import { useRef } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {useAuth} from "../../hooks/useAuth";

let today = new Date();
const firstDay = new Date(today.setDate(today.getDate() - today.getDay() + 1));
const startDate = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate() - 7 * 6);
const month = startDate.getUTCMonth();
const year = startDate.getUTCFullYear();
const day = startDate.getUTCDate() + 1;

Highcharts.setOptions({
  lang: {
    months: [
      "Ianuarie",
      "Februarie",
      "Martie",
      "Aprilie",
      "Mai",
      "Iunie",
      "Iulie",
      "August",
      "Septembrie",
      "Octombrie",
      "Noiembrie",
      "Decembrie",
    ],
    weekdays: ["Duminică", "Luni", "Marți", "Miercuri", "Joi", "Vineri", "Sâmbătă"],
    shortMonths: ["Ian.", "Feb.", "Mart.", "Apr.", "Mai", "Iun.", "Iul.", "Aug.", "Sept.", "Oct.", "Nov.", "Dec."],
  },
});

const CustomChart = ({ series, ...rest }: HighchartsReact.Props) => {
  const {user} = useAuth();

  const options: Highcharts.Options = {
    title: {
      text: "",
    },
    chart: {
      backgroundColor: "transparent",
    },
    xAxis: {
      // startOnTick: true,
      // endOnTick: true,
      labels: {
        style: {
          color: user.theme === "light" ? '#0064FF' : "#98C0FF",
        }
      },
      gridLineColor: user.theme === "light" ? '#fff' : "#6481A6",
      tickColor: user.theme === "light" ? '#fff' : "#6481A6",
      gridLineWidth: 1,
      lineColor: user.theme === "light" ? '#fff' : "#6481A6",
      type: "datetime",
      dateTimeLabelFormats: {
        week: "%e %b",
      },
      units: [
        ["week", [1]],
        ["month", [1]],
      ],
    },
    yAxis: {
      max: 100,
      gridLineColor: user.theme === "light" ? '#fff' : "#6481A6",
      labels: {
        style: {
          color: user.theme === "light" ? '#0064FF' : "#98C0FF",
        }
      },
      title: {
        style: {
          display: "none",
        },
      },
    },
    tooltip: {
      dateTimeLabelFormats: {
        week: "Saptamana de %A, %e %b, %Y",
      },
    },
    plotOptions: {
      series: {
        pointStart: Date.UTC(year, month, day),
        pointInterval: 24 * 3600 * 1000 * 7, // one week
      },
    },
    colors: user.theme === "light" ? ["#00289B", "#9D0000", "#008D2C", "#B948DB", "#0064FF", "#DE6525"] : ["#0064FF", "#ED0000", "#008D2C", "#B948DB", "#98C0FF", "#DE6525"] ,
    legend: {
      align: "left",
      useHTML: true,
      labelFormatter: function() {
        //console.log(this.color) check series color
        return (
            user.theme === "light" ?
                `<span class="customLegendLight">${this.name}</span>` :
                `<span class="customLegendDark">${this.name}</span>`
        );
      }
      // itemStyle: {
      //   color: user.theme === "light" ? '#0064FF' : "#98C0FF",
      // }
    },
  };

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={{ ...options, series }} ref={chartComponentRef} {...rest} />
    </>
  );
};

export default CustomChart;
