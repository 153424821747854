import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAuth } from "hooks/useAuth";
// import ThemeToggle from "components/ThemeToggle";
import { replaceDevUrl } from "utils/functions";
// import { useNavigate } from "react-router-dom";
import NotificationsIcon from "components/notifications/NotificationsIcon";
const TenantInfo = () => {
  const { tenant } = useAuth();
  // const navigate = useNavigate();
  // console.log("tenantINFO", tenant);

  return (
      <Box
          display="flex"
          sx={{ alignItems: { xs: "center", lg: "none" }, marginBottom: { xs: 0, lg: 3 }, cursor: "pointer", paddingTop: {xs: 1, lg: 0} }}
          // onClick={() => {
          //   navigate("/statistici");
          // }}
      >
        {tenant.logo !== "" && tenant.logo !== null && (
            <Box sx={{ width: 60, minWidth: 60 }} >
              <img src={replaceDevUrl(tenant.logo)} width="100%" alt={tenant.entity + " " + tenant.name} />
            </Box>
        )}
        <Box ml={2}>
          {/*<Typography variant="h5" sx={{ lineHeight: 1 }}>*/}
          {/*  {tenant.entity}*/}
          {/*</Typography>*/}
          <Typography
              variant="h1"
              sx={{ lineHeight: 1, fontSize: { xs: "1.125rem", lg: "2rem" }, fontWeight: { xs: 500, lg: 300 } }}
          >
            {tenant.name}
          </Typography>
          <Typography variant="h5" color="primary.light" sx={{ lineHeight: 1 }}>
            {tenant.city}
            {tenant.county !== "" && ", " + tenant.county}
          </Typography>
        </Box>
        <Box mr={1.2} sx={{ marginLeft: "auto", display: { xs: "block", lg: "none" } }}>
          {/*<ThemeToggle small={true} />*/}
          <NotificationsIcon />
        </Box>
      </Box>
  );
};

export default TenantInfo;
