import Button from "@mui/material/Button";
import { APIPostReportDigitalLiteracy } from "api/pages/report";
import Loading from "components/ui/Loading";
import { useAuth } from "hooks/useAuth";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import Report from "../components/reports/digitalLiteracy/Report";
import ReportPdf from "../components/reports/digitalLiteracy/pdf/Report";
import {PDFDownloadLink} from "@react-pdf/renderer";
import Icon from "../components/ui/Icon";

const ReportDigitalLiteracy = () => {
  const reportPrintRef = useRef(null);
  const { id = "" } = useParams();
  const { update } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [reportData, setReportData] = useState<any>();
  const [gradeLevels, setGradeLevel] = useState<any>();
  const [graphData, setGraphData] = useState<any>();
  const [skillsData, setSkillsData] = useState<any>();
  const [generatePdf, setGeneratePdf] = useState(false);

  useEffect(() => {
    if (id !== "") {
      setLoading(true);
      APIPostReportDigitalLiteracy({ test_id: id })
        .then((response) => {
          if (response.data.success) {
            // console.log("getReport OK", response);
            setReportData(response.data.data);
            setGradeLevel(response.data.data.grade_levels);
            setGraphData(response.data.data.graph_data);
            setSkillsData(response.data.data.skills_data);
            setLoading(false);
          } else {
            update("error", { show: true, code: "A002" });
          }
        })
        .catch((err) => {
          // console.log("getReport ERR", err);
          update("error", { show: true, code: "A001" });
        });
    } else {
      navigate("/teste");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handlePrint = useReactToPrint({
    content: () => reportPrintRef.current,
  });

  return (
    <>
      {loading ? (
        <Loading show={true} />
      ) : (
        <>
          <div ref={reportPrintRef}>
            <Report
              reportData={reportData}
              gradeLevels={gradeLevels}
              graphData={graphData}
              skillsData={skillsData}
            />
          </div>
          <div className="print-bar screen no-print">
            <Button type="submit" variant="contained" onClick={handlePrint} style={{ marginRight: 20 }}>
              Tipărește
            </Button>
            {!generatePdf ? (
                <Button variant="contained" onClick={() => setGeneratePdf(true)} >
                  Generează pdf
                </Button>
            ) : (
                <PDFDownloadLink document={
                  <ReportPdf
                      reportData={reportData}
                      gradeLevels={gradeLevels}
                      graphData={graphData}
                      skillsData={skillsData}
                  />} fileName="RaportEvaluare.pdf">
                  {({ blob, url, loading, error }) =>
                      loading ? <>Se generează <Icon icon="far circle-notch" spin={true} className="fa-faster" fixedWidth={true} sx={{display: "inline"}}/></> : "Descarcă pdf"
                  }
                </PDFDownloadLink>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ReportDigitalLiteracy;
