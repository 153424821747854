import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip, {tooltipClasses, TooltipProps} from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { visuallyHidden } from "@mui/utils";
import Fade from "@mui/material/Fade";
import Icon from "components/ui/Icon";
import { TableColumns } from "components/dashboard/_interfaces";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import ScrollBar from "./ScrollBar";
import {styled} from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import {alpha} from "@mui/material";

export type Order = "asc" | "desc";
interface IDashboardBoxDataTableData {
  title?: string;
  columns: TableColumns[];
  rows: {
    [key: string]: any;
  }[];
  footer?: {
    [key: string]: any;
  }[];
  page: number;
  rowsPerPage?: number;
  totalRows?: number;
  onPageChange?: (event: unknown, newPage: number) => void;
  handleRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void;
  order?: Order;
  orderBy?: string;
  pagination?: boolean;
  selected?: number[];
  isSelected?: (id: number) => boolean | undefined;
  handleSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClick?: (event: React.MouseEvent<unknown>, id: number) => void;
  hasSelect?: boolean;
  // onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  columns: TableColumns[];
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hasSelect: boolean;
}

const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: "#fff",
    fontSize: "0.875rem",
    "& a": {
      color: theme.palette.primary.contrastText,
    },
    "& a:hover": {
      color: theme.palette.primary.light,
    }
  },
}));

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, columns, onSelectAllClick, numSelected, rowCount, hasSelect = false } = props;
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
      <TableHead>
        <TableRow>
          {hasSelect && (
              <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
              />
          )}

          {columns.map((column) => (
              <TableCell
                  key={column.id}
                  align={column.numeric ? "right" : "left"}
                  sortDirection={orderBy === column.id ? order : false}
                  sx={{ padding: column.disablePadding ? "none" : "10px" }}
              >
                {column.sortable ? (
                    <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={createSortHandler(column.id)}
                        sx={{ lineHeight: 1.1, color: "secondary.light", "&:hover": {
                            color: "primary.main",
                          }, }}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc" ? "sorted descending" : "sorted ascending"}
                          </Box>
                      ) : null}
                    </TableSortLabel>
                ) : (
                    <>{column.label}</>
                )}
              </TableCell>
          ))}
        </TableRow>
      </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  title?: string;
  numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { title = "", numSelected } = props;

  return (
      <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(numSelected > 0 && {
              bgcolor: (theme) =>
                  alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
            }),
          }}
      >
          {numSelected > 0 ? (
              <Typography
                  sx={{ flex: '1 1 100%' }}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
              >
                  {numSelected} selected
              </Typography>
          ) : (
              <Typography
                  sx={{ flex: '1 1 100%' }}
                  variant="h6"
                  id="tableTitle"
                  component="div"
              >
                {title}
              </Typography>
          )}
          {numSelected > 0 ? (
              <Tooltip title="Delete">
                  <IconButton>
                    <Icon icon="trash-can" />
                  </IconButton>
              </Tooltip>
          ) : (
              <Tooltip title="Filtrează">
                  <IconButton>
                    <Icon icon="filter-list" />
                  </IconButton>
              </Tooltip>
          )}

        {/*<Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">*/}
        {/*  {title}*/}
        {/*</Typography>*/}

        {/*<Tooltip title="Filtrează">*/}
        {/*  <IconButton>*/}
        {/*    <Icon icon="filter-list" />*/}
        {/*  </IconButton>*/}
        {/*</Tooltip>*/}
      </Toolbar>
  );
};

const EnhancedTable = (props: IDashboardBoxDataTableData) => {
  const {
    title = "",
    columns,
    rows,
    // footer = [],
    page,
    rowsPerPage =0,
    totalRows = 0,
    onPageChange = () => {},
    handleRequestSort = () => {},
    order = "asc",
    orderBy = "",
    pagination = true,
      // selected = [],
    // isSelected = undefined,
    // handleSelectAllClick = () => {},
    // handleClick = () => {},
    hasSelect = false,
  } = props;
  const [dense, setDense] = React.useState(false);
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const theme = useTheme();

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.test);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalRows) : 0;

  return (
      <Box sx={{ width: "100%" }}>
        <Box sx={{ width: "100%", mb: { xs: 1, sm: 2 } }}>
          {title !== "" && title !== undefined && <EnhancedTableToolbar title={title} numSelected={selected.length}  />}
          <TableContainer>
            <ScrollBar forceVisible="x" autoHide={false}>
              <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"} sx={{marginBottom: "12px" }}>
                <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rowsPerPage}
                    columns={columns}
                    onSelectAllClick={handleSelectAllClick}
                    hasSelect={hasSelect}
                />
                <TableBody>
                  <>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}

                    {rows.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      const isItemSelected: boolean | undefined = isSelected ? isSelected(row.test) : false;

                      return (
                          <TableRow hover onClick={(event: any) => hasSelect ? handleClick(event, row.test) : ""}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={index}
                                    selected={isItemSelected}
                                    sx={{ cursor: 'pointer' }}>

                            {hasSelect && (
                                <TableCell padding="checkbox">
                                  <Checkbox
                                      color="primary"
                                      checked={isItemSelected}
                                      inputProps={{
                                        'aria-labelledby': labelId,
                                      }}
                                  />
                                </TableCell>
                            ) }

                            {Object.keys(row).map((key, index) => (
                                <TableCell
                                    align={columns.find((column) => column.id === key)?.numeric ? "right" : "left"}
                                    padding={columns.find((column) => column.id === key)?.disablePadding ? "none" : "normal"}
                                    {...(key === "id" ? { id: labelId, scope: "row" } : {})}
                                    key={index}
                                    scope="row"
                                    sx={{padding: dense ? "3px" : "10px"}}
                                >
                                  {columns.find((column) => column.id === key)?.tooltip ? (
                                      <TooltipStyled
                                          title={
                                            columns.find((column) => column.id === key)?.noWrap ? (
                                                <Box display="flex" flexDirection="column" gap={1} alignItems="left" p={1}>
                                                  {row[key]}
                                                </Box>
                                            ) : (
                                                ""
                                            )
                                          }
                                          TransitionComponent={Fade}
                                          TransitionProps={{ timeout: 200 }}
                                      >
                                        <Typography
                                            display={columns.find((column) => column.id === key)?.noWrap ? "flex" : "block"}
                                            component="div"
                                            variant="body2"
                                            noWrap={columns.find((column) => column.id === key)?.noWrap}
                                            sx={{
                                              maxWidth: columns.find((column) => column.id === key)?.noWrap ? 200 : "auto",
                                            }}
                                        >
                                          {row[key]}
                                        </Typography>
                                      </TooltipStyled>
                                  ) : (
                                      <Typography
                                          display={columns.find((column) => column.id === key)?.noWrap ? "flex" : "block"}
                                          component="div"
                                          variant="body2"
                                          noWrap={columns.find((column) => column.id === key)?.noWrap}
                                          sx={{
                                            maxWidth: columns.find((column) => column.id === key)?.noWrap ? 200 : "auto",
                                          }}
                                      >
                                        {row[key]}
                                      </Typography>
                                  )}
                                </TableCell>
                            ))}
                          </TableRow>
                      );
                    })}

                    {/* {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
                  </>
                </TableBody>
              </Table>
            </ScrollBar>
          </TableContainer>

          <Box display="flex" justifyContent={{xs: "flex-end", md: "space-between"}}>
            {useMediaQuery(theme.breakpoints.up("md")) &&
                <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense}/>}
                                  label="Tabel restrâns"/>}
            {pagination && (
                <TablePagination
                    rowsPerPageOptions={[15]}
                    component="div"
                    count={totalRows}
                    rowsPerPage={rowsPerPage}
                    // labelRowsPerPage={"Rânduri pe pagină:"}
                    labelDisplayedRows={function defaultLabelDisplayedRows({ from, to, count }) {
                      return `${from}–${to} din ${count !== -1 ? count : `mai multe de ${to}`}`;
                    }}
                    page={page}
                    onPageChange={onPageChange}
                    // onRowsPerPageChange={data.onRowsPerPageChange}
                />
            )}

          </Box>
          {!useMediaQuery(theme.breakpoints.up("md")) &&
              <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense}/>}
                                label="Tabel restrâns"/>}
        </Box>

      </Box>
  );
};

export default EnhancedTable;