import axiosClient from "api/apiClient";

interface IOptions {
  page: string;
  sort?: string;
  order?: string;
  search?: string;
}

export const APIGetClassrooms = (options: IOptions) => {
  let sort = "";
  let search = "";
  if (options.hasOwnProperty("sort")) {
    if(options.order === "asc") {
      sort = "&sort=" + options.sort;
    } else if (options.order === "desc") {
    sort = "&sort=-" + options.sort;
    }
  }
  if (options.hasOwnProperty("search")) {
    search = "&filter[search]=" + options.search;
  }
  return axiosClient.get(`/classrooms${options.page}${search}${sort}`);
};

export const APIGetAllClassrooms = () => {
  return axiosClient.post(`/classrooms/all`);
};
