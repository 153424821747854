import {ITest} from "./_interfaces";
import Icon from "../ui/Icon";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import CustomButton from "../ui/CustomButton";

const ButtonNext = ({
                        itemIndex,
                        test,
                        isLargeScreen,
                        isMediumScreen,
                        nextItem,
                    }: {
    itemIndex: number;
    test: ITest;
    isLargeScreen: boolean;
    isMediumScreen: boolean;
    nextItem: () => void;
}) => {
    return itemIndex < test.items.length ? (
        <>
            {/*<Button*/}
            {/*    variant="contained"*/}
            {/*    size={isLargeScreen ? undefined : "small"}*/}
            {/*    fullWidth={isLargeScreen}*/}
            {/*    onClick={nextItem}*/}
            {/*    sx={{*/}
            {/*        fontWeight: { xs: 500, lg: "inherit" },*/}
            {/*        color: { xs: "#00289B", lg: "#fff" },*/}
            {/*        backgroundColor: { xs: "#fff", lg: "secondary.light" },*/}
            {/*        "&:hover": {*/}
            {/*            color: { xs: "#fff", lg: "primary.contrastText" },*/}
            {/*            backgroundColor: { xs: "#0055d9", lg: "primary.main" },*/}
            {/*        },*/}
            {/*        "&[aria-expanded='true']": {*/}
            {/*            color: { xs: "#fff", lg: "primary.contrastText" },*/}
            {/*            backgroundColor: { xs: "#0055d9", lg: "primary.main" },*/}
            {/*        },*/}
            {/*    }}*/}
            {/*>*/}
            {/*    ÎNAINTE{isLargeScreen && <Icon icon="caret-right" sx={{ marginLeft: 0.5 }} />}*/}
            {/*</Button>*/}
            <CustomButton onClick={nextItem} bgcolor="test" buttonType="textIcon">
                <>
                    ÎNAINTE{isLargeScreen && <Icon icon="caret-right" sx={{ marginLeft: 0.5 }} />}
                </>
            </CustomButton>
        </>
    ) : (
        <>
            {(!isMediumScreen && !isLargeScreen) && <Box textAlign="right" pr={2}>
                <Typography variant="body1" mb={0}>
                    <strong>Ai ajuns la final!</strong>
                </Typography>
            </Box>}
        {isMediumScreen && <Box textAlign="right" pr={2}>
            <Typography variant="body1" mb={0}>
                <strong>Ai ajuns la finalul testului!</strong>
            </Typography>
            {test.test_config === 1 && (
                <Typography variant="body2" sx={{fontSize: {xs: "0.875rem"}}}>
                    Verifică dacă ai răspuns la toate întrebările și apasă butonul TERMINĂ TESTUL.
                </Typography>
            )}
            {test.test_config === 2 && (
                <Typography variant="body2" color="secondary">
                    Apasă butonul TERMINĂ TESTUL.
                </Typography>
            )}
        </Box>}
        </>
    );
};

export default ButtonNext;