import React, {useEffect, useRef, useState} from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { useNavigate } from "react-router-dom";
import { MathJax } from "better-react-mathjax";
import {IAnswer, ITest, ITestItem} from "components/test/_interfaces";
import { useAuth } from "hooks/useAuth";
import Icon from "components/ui/Icon";
import { useCountdown } from "hooks/useCountdown";
import Grid from "@mui/material/Grid";
import ScrollBar from "components/ui/ScrollBar";
import { APICloseTest, APISaveTestAnswer } from "api/pages/test";
import ButtonNext from "components/test/ButtonNext";
import Loading from "../ui/Loading";
import TestFooter from "./TestFooter";
import TestSidebar from "./TestSidebar";

const TestStartedLiteracy = ({ test, testFromClient }: { test: ITest, testFromClient: boolean }) => {
    const navigate = useNavigate();
    const { update, logout } = useAuth();
    const theme = useTheme();
    const id = test.test_id;
    const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
    const [itemIndex, setItemIndex] = useState<number>(1);
    const [item, setItem] = useState<ITestItem>(test.items[0]);
    const [answer, setAnswer] = useState<string[]>([]);
    const [answeredItems, setAnsweredItems] = useState<IAnswer[]>(test.answered_items);
    const scrollBarRef = useRef(null);
    const itemsNumber = test.items.map((item) => {
        return item.questions ? item.questions.length :  0;
    }).reduce((result,number)=> result+number);
    const [loading, setLoading] = useState(false);

    const closeTest = () => {
        APICloseTest({ test: id, mode: test.mode })
            .then((response) => {
                if (response.data.success) {
                    // console.log("closeTest OK", response);
                    update("confirm", { show: false });
                    if(testFromClient) {
                        logout();
                        navigate("/test/test-finalizat");
                    } else {
                        navigate("/teste-literatie/test-finalizat", { state: { mode: 5 } });
                    }
                } else {
                    update("error", { show: true, code: "A002" });
                }
            })
            .catch((err) => {
                update("error", { show: true, code: "A001" });
            });
    }

    const finishTest = () => {
        if (days + hours + minutes + seconds > 0) {
            update("confirm", {
                show: true,
                closable: false,
                title: "Confirmare finalizare test",
                message: (
                    <>
                        <Typography>
                            {answeredItems.length < itemsNumber
                                ? "Nu ai răspuns la toate întrebările și mai ai la dispoziție timp neutilizat!"
                                : "Mai ai la dispoziție timp neutilizat!"}
                        </Typography>
                        <Typography>Dacă dorești să închei acum apasă FINALIZEAZĂ TESTUL.</Typography>
                    </>
                ),
                returnFunction: (callback: Function) => {
                    closeTest();
                },
                cancelFunction: () => {
                    update("confirm", { show: false });
                },
                buttonYes: "Finalizează testul",
                buttonNo: "Continuă",
            });
        } else {
            closeTest();
        }
    };

    const [days, hours, minutes, seconds] = useCountdown({
        targetSeconds: test.remaining_time_seconds,
        onFinish: finishTest,
    });

    const chooseAnswer = (event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log(event.target.name);
        let answerChosen = (event.target as HTMLInputElement).value;
        let answerId = parseInt(event.target.name);
        let newAnswers = answer;
        newAnswers[answerId] = answerChosen;
        setAnswer(newAnswers);

        APISaveTestAnswer({
            test: id,
            item: answerId,
            answer: answerChosen,
        })
            .then((response) => {
                if (response.data.success) {
                } else {
                    update("error", { show: true, code: "A002" });
                }
            })
            .catch((err) => {
                update("error", { show: true, code: "A001" });
            });

        if (answeredItems.find((answeredItem) => answeredItem.id === parseInt(event.target.name)) !== undefined) {
            setAnsweredItems(
                answeredItems.map((answeredItem) => {
                    if (answeredItem.id === parseInt(event.target.name)) {
                        return {
                            id: parseInt(event.target.name),
                            letter: answerChosen,
                        };
                    } else {
                        return answeredItem;
                    }
                }),
            );
        } else {
            setAnsweredItems([...answeredItems, { id: answerId, letter: answerChosen }]);
        }
    };

    const nextItem = () => {
        if (itemIndex < test.items.length) {
            setLoading(true);
            setItemIndex(itemIndex + 1);
            window.setTimeout(() => {
                setLoading(false);
            }, 100);
        }
    };

    useEffect(() => {
        setItem(test.items[itemIndex - 1]);
        setAnswer([...answer, answeredItems.find((answeredItem) => answeredItem.id === test.items[itemIndex - 1].id)?.letter || ""]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemIndex]);

    return (
        <Box
            component="main"
            display="flex"
            height="100%"
            sx={{
                width: "100vw",
                height: "100dvh",
                left: 0,
                top: 0,
                flexDirection: { xs: "column", lg: "row" },
            }}
        >
            <TestSidebar
                test={test}
                itemIndex={itemIndex}
                answeredItems={answeredItems}
                setItemIndex={setItemIndex}
                itemsNumber={itemsNumber}
                setLoading={setLoading}
                finishTest={finishTest}
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
            />
            <Box
                flexGrow={1}
                display="flex"
                flexDirection="row"
                justifyContent="center"
                sx={{
                    position: {xs: "absolute", lg: "relative"},
                    top: {xs: "45px", lg: 0},
                    maxHeight: "100%",
                    width: "100%",
                    bottom: {xs: test.test_config === 2 ? "150px" : "130px", lg: 0},
                }}
            >
                <Box
                    flexGrow={1}
                    sx={{
                        maxWidth: { xs: "100%", lg: 840 },
                        maxHeight: {xs: "auto", lg: "100%"},
                        marginBottom: {xs: 0, lg: 9},
                        overflow: "hidden",
                        "& img, & svg": {
                            height: "auto",
                            maxWidth: "100%",
                        },
                    }}
                >
                    {loading ? (
                        <Loading show={true} />
                    ) : (
                        <ScrollBar
                            style={{
                                maxHeight: "100%",
                                maxWidth: "100%",
                            }}
                            forceVisible="y"
                            autoHide={false}
                            ref={scrollBarRef }
                        >
                            <Box sx={{ p: { xs: 1, sm: 2, lg: 3 } }} width="100%">
                                <MathJax inline dynamic>
                                    {item.scenery && (
                                        <Typography component="div" width="100%" sx={{ fontSize: "1.1125rem", fontWeight: "400", color:"primary.light" }} mb={2}>
                                            <Box dangerouslySetInnerHTML={{ __html: item.scenery }}></Box>
                                        </Typography>
                                    )}
                                    {item.q_data !== "" && (
                                        <Typography component="div" variant="h1" sx={{ fontSize: "1.3125rem", fontWeight: "400" }} mb={2}>
                                            <Box dangerouslySetInnerHTML={{ __html: item.q_data }}></Box>
                                        </Typography>
                                    )}

                                    {item.q_obs !== "" && (
                                        <Typography component="div" sx={{ fontSize: "1.1125rem", fontWeight: "400" }} mb={2}>
                                            <Box dangerouslySetInnerHTML={{ __html: item.q_obs }}></Box>
                                        </Typography>
                                    )}

                                    <Box sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={item.illustration && item.illustration.svg ? 6 : 12} >
                                                <>
                                                    {item.questions && item.questions.map((question: any, index: any) => (
                                                        <Box key={index} sx={{mt: 2}}>
                                                            {question.q_obs !== "" && (
                                                                <Typography component="div" sx={{ fontSize: "1.3125rem", fontWeight: "400" }} mb={2}>
                                                                    <Box dangerouslySetInnerHTML={{ __html: question.q_obs }}></Box>
                                                                </Typography>
                                                            )}
                                                            <FormControl sx={{ width: "100%" }}>
                                                                <RadioGroup
                                                                    aria-labelledby={`${question.id}`}
                                                                    name={`${question.id}`}
                                                                    value={answeredItems.find((answeredItem) => answeredItem.id === question.id)?.letter || ""}
                                                                    onChange={chooseAnswer}
                                                                >
                                                                    {question.q_responses.map((answer: any, index: any) => (
                                                                        <Box
                                                                            key={index}
                                                                            width="100%"
                                                                            mb={2}
                                                                            sx={{
                                                                                backgroundColor: "#D7E6FF",
                                                                                borderRadius: "15px",
                                                                            }}
                                                                        >
                                                                            <FormControlLabel
                                                                                value={answer.letter}
                                                                                control={
                                                                                    <Radio
                                                                                        sx={{
                                                                                            fontSize: "28px",
                                                                                            width: "46px",
                                                                                            height: "46px",
                                                                                            "& .MuiSvgIcon-root": {
                                                                                                fontSize: 28,
                                                                                            },
                                                                                            "& .css-hyxlzm": {
                                                                                                width: "46px",
                                                                                            },
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                sx={{ padding: "12px", width: "100%" }}
                                                                                label={
                                                                                    <Box sx={{ fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: answer.response }} />
                                                                                }
                                                                            />
                                                                        </Box>
                                                                    ))}
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Box>
                                                    ))}
                                                </>
                                            </Grid>
                                            {item.illustration && item.illustration.svg && <Grid item xs={12} md={6}>
                                                <Box sx={{backgroundColor: "rgb(230, 230, 230)", borderRadius: "30px", position: "relative", margin: "0 0 30px 0", maxWidth: "400px"}}>
                                                    <img src={item.illustration.svg} alt="" style={{verticalAlign: "bottom", maxWidth: "400px", borderRadius: "30px", margin: "0 auto"}}/>
                                                    <Box sx={{position: "absolute", top:"-1px", left: "25%", borderRadius: "0 0 8px 8px", backgroundColor: "#fff", padding: "9px 20px 9px", fontSize: "12px", lineHeight: "10px", color: "#ADADAD", whiteSpace: "nowrap", textTransform: "uppercase"}}>Ilustrație ajutătoare</Box>
                                                </Box>
                                            </Grid>
                                            }
                                        </Grid>
                                    </Box>

                                </MathJax>
                            </Box>
                        </ScrollBar>
                    )}
                </Box>
                <Box
                    sx={{
                        display: { xs: "none", lg: "flex" },
                        alignItems: "center",
                        justifyContent: test.test_config === 2 ? "space-between" : "flex-end",
                        position: "absolute",
                        bottom: theme.spacing(useMediaQuery("(min-height:500px)") ? 3 : 1),
                        minHeight: 46,
                        width: "840px",
                    }}
                >
                    {test.test_config === 2 && (
                        <Box display="flex" alignItems="center" mt={2} mb={1}>
                            <Box mr={2}>
                                <Icon
                                    icon="fas fa-octagon-exclamation"
                                    fixedWidth={true}
                                    sx={{ fontSize: "1.5rem", color: "error.main" }}
                                />
                            </Box>
                            <Typography sx={{ color: "secondary.light" }}>
                                Profesorul tău a ales să răspunzi în ordinea afișării întrebărilor, adică fără posibilitatea de a te
                                întoarce la întrebările anterioare sau să le vezi pe următoarele.
                            </Typography>
                        </Box>
                    )}
                    <Box>
                        <ButtonNext itemIndex={itemIndex} test={test} isLargeScreen={isLargeScreen} isMediumScreen={isMediumScreen} nextItem={nextItem} />
                    </Box>
                </Box>
            </Box>
            <TestFooter
                test={test}
                itemIndex={itemIndex}
                answeredItems={answeredItems}
                setItemIndex={setItemIndex}
                itemsNumber={itemsNumber}
                setLoading={setLoading}
                finishTest={finishTest}
                nextItem={nextItem}
            />
        </Box>
    );
};

export default TestStartedLiteracy;
