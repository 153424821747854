import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";

const Timer = ({
  hours,
  minutes,
  seconds,
  secondsTotal,
}: {
  hours: number;
  minutes: number;
  seconds: number;
  secondsLeft?: number;
  secondsTotal: number;
}) => {
  return (
    <Box display="flex" gap={3} alignItems="flex-end" justifyContent="space-between">
      <Box flexGrow={1} pb={0.25} sx={{ display: { xs: "none", lg: "block" } }}>
        <Typography color="secondary.light" lineHeight="1" sx={{ mb: 0.5 }}>
          TIMP RĂMAS
        </Typography>
        <LinearProgress
          variant="determinate"
          color="secondary"
          sx={{ height: 10, bgcolor: "#fff" }}
          value={((hours * 3600 + minutes * 60 + seconds) / secondsTotal) * 100}
        />
      </Box>
      <Box>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Box textAlign="center">
            <Typography
              variant="caption"
              sx={{
                fontSize: "1.5rem",
                fontWeight: 400,
                lineHeight: 1,
                color: { xs: "#fff", lg: "inherit" },
              }}
            >
              {hours < 10 ? `0${hours}` : hours}
            </Typography>
            <Typography
              variant="body2"
              color="secondary.light"
              lineHeight="1"
              sx={{ display: { xs: "none", lg: "block" } }}
            >
              {hours === 1 ? "ORĂ" : "ORE"}
            </Typography>
          </Box>
          <Box textAlign="center" position="relative">
            <Typography
              variant="caption"
              sx={{
                fontSize: "1.5rem",
                fontWeight: 400,
                lineHeight: 1,
                color: { xs: "#fff", lg: "inherit" },
              }}
            >
              :
            </Typography>
          </Box>
          <Box textAlign="center">
            <Typography
              variant="caption"
              sx={{
                fontSize: "1.5rem",
                fontWeight: 400,
                lineHeight: 1,
                color: { xs: "#fff", lg: "inherit" },
              }}
            >
              {minutes < 10 ? `0${minutes}` : minutes}
            </Typography>
            <Typography
              variant="body2"
              color="secondary.light"
              lineHeight="1"
              sx={{ display: { xs: "none", lg: "block" } }}
            >
              MIN
            </Typography>
          </Box>
          <Box textAlign="center" position="relative">
            <Typography
              variant="caption"
              sx={{
                fontSize: "1.5rem",
                fontWeight: 400,
                lineHeight: 1,
                color: { xs: "#fff", lg: "inherit" },
              }}
            >
              :
            </Typography>
          </Box>
          <Box textAlign="center">
            <Typography
              variant="caption"
              sx={{
                fontSize: "1.5rem",
                fontWeight: 400,
                lineHeight: 1,
                color: { xs: "#fff", lg: "inherit" },
              }}
            >
              {seconds < 10 ? `0${seconds}` : seconds}
            </Typography>
            <Typography
              variant="body2"
              color="secondary.light"
              lineHeight="1"
              sx={{ display: { xs: "none", lg: "block" } }}
            >
              SEC
            </Typography>
          </Box>
        </Box>
        <LinearProgress
          variant="determinate"
          sx={{
            height: 2,
            [`& .${linearProgressClasses.bar}`]: {
              backgroundColor: "#fff",
            },
            bgcolor: "secondary.light",
            display: { xs: "block", lg: "none" },
          }}
          value={((hours * 3600 + minutes * 60 + seconds) / secondsTotal) * 100}
        />
      </Box>
    </Box>
  );
};

export default Timer;
