import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { MathJax } from "better-react-mathjax";
import BoxContent from "components/ui/BoxContent";
import GenericButton from "components/ui/GenericButton";
import PageTitle from "components/ui/PageTitle";
import ScrollBar from "../../ui/ScrollBar";

const TestPreview = ({ items, handleItemReplace }: any) => {
  return (
    <Box width="100%">
      <>
        <PageTitle>Previzualizare test</PageTitle>
        {items.length === 0 ? (
          <Typography>Nu sunt itemi</Typography>
        ) : (
          <>
            {items.map((item: any, index: any) => (
              <Box mt={3} mb={3} width="100%" key={index}>
                <BoxContent>
                  <Box width="100%" display="flex" justifyContent="end" mb={3}>
                    <GenericButton
                      type="replace"
                      onClick={() => {
                        handleItemReplace(item.subcategory_id, item.id);
                      }}
                    />
                  </Box>
                  <Box width="100%" display="flex" alignItems="center">
                    <MathJax dynamic style={{ width: "100%" }}>
                      {item.q_data !== "" && (
                        <Box mb={2}>
                          <Typography variant="h4">ENUNȚ</Typography>
                          <ScrollBar forceVisible="x" autoHide={false} style={{maxHeight: "100%", maxWidth: "100%", overflowY: "hidden"}} >
                          <Box component="div" width="100%" mb={1.5} sx={{fontSize: {xs: "0.875rem", md: "1rem"}}}>
                              <Box dangerouslySetInnerHTML={{ __html: item.q_data }}></Box>
                          </Box>
                          </ScrollBar>
                        </Box>
                      )}

                        {item.q_obs !== "" && (
                            <Typography variant="h4">CERINȚĂ</Typography>
                        )}
                        <ScrollBar forceVisible="x" autoHide={false} style={{overflowY: "hidden"}}>
                      {item.q_obs !== "" && (
                        <Typography component="div" variant="body1" width="100%" mb={2} sx={{fontSize: {xs: "0.875rem", md: "1rem"}}}>
                            <Box dangerouslySetInnerHTML={{ __html: item.q_obs }}></Box>
                        </Typography>
                      )}
                      <Box width="fit-content" minWidth="100%">
                        <Box
                          mb={2}
                          sx={{
                            padding: 2,
                            border: "1px solid #fff",
                          }}
                          width="100%"
                        >
                            <Typography component="div" dangerouslySetInnerHTML={{ __html: item.a }} sx={{fontSize: {xs: "0.875rem", md: "1rem"}}}/>
                        </Box>
                        <Box
                          mb={2}
                          sx={{
                            padding: 2,
                            border: "1px solid #fff",
                          }}
                          width="100%"
                        >
                          <Typography component="div" dangerouslySetInnerHTML={{ __html: item.b }} sx={{fontSize: {xs: "0.875rem", md: "1rem"}}}/>
                        </Box>
                        <Box
                          mb={2}
                          sx={{
                            padding: 2,
                            border: "1px solid #fff",
                            // border: item.right_answer === "c" ? "1px solid #6481A6" : "1px solid #fff",
                          }}
                          width="100%"
                        >
                          <Typography component="div" dangerouslySetInnerHTML={{ __html: item.c }} sx={{fontSize: {xs: "0.875rem", md: "1rem"}}}/>
                        </Box>
                        <Box
                          mb={2}
                          sx={{
                            padding: 2,
                            border: "1px solid #fff",
                            // border: item.right_answer === "d" ? "1px solid #6481A6" : "1px solid #fff",
                          }}
                          width="100%"
                        >
                          <Typography component="div" dangerouslySetInnerHTML={{ __html: item.d }} sx={{fontSize: {xs: "0.875rem", md: "1rem"}}}/>
                        </Box>
                      </Box>
                        </ScrollBar>
                    </MathJax>
                  </Box>
                </BoxContent>
              </Box>
            ))}
          </>
        )}
      </>
    </Box>
  );
};

export default TestPreview;
