import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  APIPostNotifications,
  APIPostNotificationsMarkAsRead,
  APIPostNotificationsUnread,
} from "api/pages/notifications";
import MessagesContent from "components/messages/MessageContent";
import MessageTitle from "components/messages/MessageTitle";
import BoxContent from "components/ui/BoxContent";
import CustomAccordion from "components/ui/CustomAccordion";
import Loading from "components/ui/Loading";
import PageTitle from "components/ui/PageTitle";
import { useAuth } from "hooks/useAuth";
import React, { useEffect, useRef, useState } from "react";
import TablePagination from "@mui/material/TablePagination";

// interface IDMessages {
//   created_at: Date;
//   id: string;
//   read_at: string;
//   type: string;
//   expires_at: number;
//   test_owner_name: string;
//   test_id: number;
//   test_item_type: string;
//   test_grade: string;
//   test_mode: number;
//   tester_name?: string;
//   classroom_name: string;
// }

interface IDAccordionData {
  date?: any;
  title: string;
  content: string;
  status?: string;
  id: string;
}

interface IDPagination {
  currentPage: number;
  count: number;
  rowsPerPage: number;
}

const Messages = () => {
  const { update, user } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<IDAccordionData[]>([]);
  const [pagination, setPagination] = useState<IDPagination>({currentPage: 0, count: 0, rowsPerPage: 30});
  const [ready, setReady] = useState(false);
  const prevUserNotifications = useRef<number>();

  useEffect(() => {
    setReady(true);
  }, []);

  useEffect(() => {
    if (!ready) return;
    if (prevUserNotifications.current === undefined) {
      prevUserNotifications.current = user.notifications;
    }
    if (user.notifications < prevUserNotifications.current) return;

    APIPostNotificationsUnread()
        .then((response) => {
          if (response.data.success) {
            update("user", { notifications: response.data.data.value });
          } else {
            update("error", { show: true, code: "A002" });
          }
        })
        .catch((err) => {
          // console.log("getMessages ERR", err);
          update("error", { show: true, code: "A001" });
        });

    notificationsPost();

    prevUserNotifications.current = user.notifications;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.notifications, ready, pagination.currentPage]);

  useEffect(() => {
    setLoading(true);
    notificationsPost();
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.currentPage]);

  const notificationsPost = () => {
    APIPostNotifications(`?page=${pagination.currentPage + 1}`)
        .then((response) => {
          if (response.data.success) {
            // console.log("getMessages OK", response.data.data);
            let receivedMessages: IDAccordionData[] = [];
            setPagination({
              currentPage: response.data.data.current_page-1,
              count: response.data.data.total,
              rowsPerPage: response.data.data.per_page
            });
            if (response.data.data === null) {
              setMessages(receivedMessages);
            } else if (user.position === "student") {
              receivedMessages = response.data.data.data.map((item: any) => {
                return {
                  id: item.id,
                  mode: item.test_mode,
                  bgColor:item.type === "Domain\\Notifications\\Notifications\\TestSessionClosedNotification" ? "success.dark" :
                      item.type === "Domain\\Notifications\\Notifications\\TestSessionExpiredNotification" ? "error.dark" :
                          item.type === "Domain\\Notifications\\Notifications\\TestSessionStartedNotification" ? "warning.dark" :
                              "background.paper",
                  date: item.created_at,
                  title:
                      <MessageTitle
                          position={user.position}
                          type={item.test_item_type}
                          name={item.test_owner_name}
                          classroom={item.test_classroom_name}
                          mode={item.test_mode}
                          itemType={item.type}
                      />,
                  status: item.read_at !== null ? "citit" : "necitit",
                  content:
                      <MessagesContent
                          position={user.position}
                          type={item.test_item_type}
                          expires={item.test_expires_at}
                          name={item.test_owner_name}
                          id={item.test_id}
                          grade={item.test_grade}
                          mode={item.test_mode}
                          classroom={item.test_classroom_name}
                          itemType={item.type}
                      />,
                };
              });
            } else if (user.position === "teacher") {
              receivedMessages = response.data.data.data.map((item: any) => {
                return {
                  id: item.id,
                  mode: item.test_mode,
                  bgColor:item.type === "Domain\\Notifications\\Notifications\\TestSessionClosedNotification" ? "success.dark" :
                      item.type === "Domain\\Notifications\\Notifications\\TestSessionExpiredNotification" ? "error.dark" :
                          item.type === "Domain\\Notifications\\Notifications\\TestSessionStartedNotification" ? "warning.dark" :
                              "background.paper",
                  date: item.created_at,
                  title:
                      <MessageTitle
                          position={user.position}
                          type={item.test_item_type}
                          name={item.test_tester_name}
                          classroom={item.test_classroom_name}
                          mode={item.test_mode}
                          itemType={item.type}
                      />,
                  status: item.read_at !== null ? "citit" : "necitit",
                  content:
                      <MessagesContent
                          createdAt={item.test_created_at}
                          position={user.position}
                          type={item.test_item_type}
                          expires={item.test_expires_at}
                          id={item.test_id}
                          grade={item.test_grade}
                          mode={item.test_mode}
                          classroom={item.test_classroom_name}
                          name={item.test_tester_name}
                          itemType={item.type}
                      />,
                };
              });
            }

            setMessages(receivedMessages);
            setLoading(false);
          } else {
            update("error", { show: true, code: "A002" });
          }
        })
        .catch((err) => {
          // console.log("getMessages ERR", err);
          update("error", { show: true, code: "A001" });
        });
  }

  const markedAsRead = (id: string) => {
    APIPostNotificationsMarkAsRead({ notification_id: id, page: pagination.currentPage + 1 })
      .then((response) => {
        if (response.data.success) {
          const markAsRead = messages.map((item) => {
            if (item.id === id) {
              return { ...item, status: "citit" };
            }
            return item;
          });
          setMessages(markAsRead);
          APIPostNotificationsUnread()
            .then((response) => {
              if (response.data.success) {
                update("user", { notifications: response.data.data.value });
              } else {
                update("error", { show: true, code: "A002" });
              }
            })
            .catch((err) => {
              update("error", { show: true, code: "A001" });
            });
        } else {
          update("error", { show: true, code: "A002" });
        }
      })
      .catch((err) => {
        update("error", { show: true, code: "A001" });
      });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setMessages([]);
    setPagination({...pagination, currentPage: newPage});
  };

  return (
    <Box component="main">
      <>
        <PageTitle>Mesaje</PageTitle>
        {loading ? (
          <Box mt={3} display="flex" alignItems="center" width="100%">
            <Loading show={true} />
          </Box>
        ) : messages.length === 0 ? (
          <BoxContent>
            <Typography variant="body1">Nu sunt mesaje.</Typography>
          </BoxContent>
        ) : (
            <>
              <CustomAccordion data={messages} returnFunction={markedAsRead} />
              <Box sx={{display: "flex", justifyContent: "start", flexWrap: "wrap", marginBottom: 2}}>
                {(user.position === "teacher" || user.position === "student") &&
                  <Box sx={{display: "flex", alignItems: "center", marginRight: 2, marginBottom: 0.5}}>
                    <Box sx={{backgroundColor: "background.paper", width: "22px", height:"22px", marginRight: 1}}></Box>
                    <Typography>Teste generate</Typography>
                </Box>
                }
                {user.position === "teacher" && (
                    <>
                      <Box sx={{display: "flex", alignItems: "center", marginRight: 2, marginBottom: 0.5}}>
                        <Box sx={{backgroundColor: "success.dark", width: "22px", height:"22px", marginRight: 1}}></Box>
                        <Typography>Teste finalizate</Typography>
                      </Box>
                      <Box sx={{display: "flex", alignItems: "center", marginRight: 2, marginBottom: 0.5 }}>
                        <Box sx={{backgroundColor: "warning.dark", width: "22px", height:"22px", marginRight: 1}}></Box>
                        <Typography>Teste accesate</Typography>
                      </Box>
                    </>
                )}
                {(user.position === "teacher" || user.position === "student") &&
                  <Box sx={{display: "flex", alignItems: "center", marginRight: 2 }}>
                    <Box sx={{backgroundColor: "error.dark", width: "22px", height:"22px", marginRight: 1}}></Box>
                    <Typography>Teste expirate</Typography>
                  </Box>
                }
              </Box>
              <TablePagination
                  rowsPerPageOptions={[30]}
                  component="div"
                  count={pagination.count}
                  rowsPerPage={pagination.rowsPerPage}
                  // labelRowsPerPage={"Rânduri pe pagină:"}
                  labelDisplayedRows={function defaultLabelDisplayedRows({ from, to, count }) {
                    return `${from}–${to} din ${count !== -1 ? count : `mai multe de ${to}`}`;
                  }}
                  page={pagination.currentPage}
                  onPageChange={handleChangePage}
                  // onRowsPerPageChange={data.onRowsPerPageChange}
              />

            </>
        )}
      </>
    </Box>
  );
};

export default Messages;
