import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useAuth} from "../hooks/useAuth";
import {useEffect} from "react";

const NotFound = () => {
    const { user, logout } = useAuth();

    useEffect(() => {
        logout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
      <Box component="main" sx={{ minHeight: "100dvh", padding: { xs: "10px", md: "40px" }, backgroundColor: "background.paper",
          backgroundImage: "none", textAlign: "center"}}>
          <Box mb={3} pt={5}>
              <img
                  src={user.theme === "light" ? "/images/logo-brio-blue.svg" : "/images/logo-brio-white.svg"}
                  height="50px"
                  style={{width: "auto"}}
                  alt="Brio Platforma de Testare Fundamentată Științific"
              />
          </Box>
          <Box mt={4}>
              <Typography variant="h1">Pagina pe care o căutați nu există.</Typography>
          </Box>

      </Box>
    // <Box display="flex" gap={1} width="100%" alignItems="flex-start" sx={{ marginBottom: { xs: 2, md: 2, lg: 3 } }}>
    //   <Box flexGrow={1}>
    //     <Typography variant="h1">Pagina pe care o căutați nu există.</Typography>
    //   </Box>
    // </Box>
  );
};

export default NotFound;
