import {Navigate, useOutlet, useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import {defaultUser, useAuth} from "../../hooks/useAuth";

const PublicFullScreen = () => {
    const outlet = useOutlet();
    const [searchParams] = useSearchParams();
    const { update, user } = useAuth();

    useEffect(() => {
        update("user", { theme: user.theme }, true);
        update("cart", defaultUser.cart);
        update("tenant", defaultUser.tenant);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!searchParams.get("token") && !searchParams.get("domain")) {
        return <Navigate to="/" />;
    }

    return outlet;
}

export default PublicFullScreen;