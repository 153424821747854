import Typography from "@mui/material/Typography";
import FormField from "components/form/FormField";
import BoxContent from "components/ui/BoxContent";

const CreateTestDifficulty = ({ errors, difficulty, handleSelectChange }: any) => {
  return (
    <>
      <Typography variant="h3" mb={3} mt={3}>
          Alege gradul de dificultate al testului
      </Typography>
      <BoxContent>
        <FormField
          name="difficulty"
          type="controlled-select"
          label="Alege greutatea testului"
          value={difficulty}
          onChange={handleSelectChange}
          options={[
            {
              label: "Ușor",
              value: 1,
            },
            {
              label: "Mediu",
              value: 2,
            },
            {
              label: "Greu",
              value: 3,
            },
          ]}
          errorMessage={errors.difficulty?.message}
        />
      </BoxContent>
    </>
  );
};

export default CreateTestDifficulty;
