import { View, Text } from "@react-pdf/renderer";
import Html from "react-pdf-html";
import {styles } from "../../StylesPdfReport";
import {markedLevelBorder} from "../../../../utils/reportsFunctions";

const stylesheet = {
    p: {
        fontFamily: "Rubik",
        fontSize: "11pt",
        fontWeight: 400,
        textTransform: undefined,
    },
};

const ChartSubsection = ({ gradeLevel, score, index, type}: any) => {
    const border = markedLevelBorder(score, gradeLevel.level, type)[0];

    return (
        <View
            key={index}
            wrap={false}
            style={{
                padding: "10pt 5pt",
                marginTop: "16pt",
                border:border,
            }}
        >
            <View style={styles.subsectionContent}>
                {Array.isArray(gradeLevel.description) ? (
                    <>
                        {gradeLevel.description.map((description: any, index: any) => (
                            <View key={index} style={{ flexDirection: "row", marginBottom: 4 }}>
                                <Text style={{ marginRight: 8 }}>•</Text>
                                <Text>{description}</Text>
                            </View>
                        ))}
                    </>
                ) : (
                    <Html style={{ fontSize: 9 }} stylesheet={stylesheet}>
                        {gradeLevel.description}
                    </Html>
                )}
            </View>
        </View>
    );
};

export default ChartSubsection;
