import React from "react";
import Box from "@mui/material/Box";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import PageTitle from "components/ui/PageTitle";
import BoxContent from "components/ui/BoxContent";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Icon from "components/ui/Icon";
import { useRouteMatch } from "utils/functions";
import CustomButton from "../components/ui/CustomButton";
import TestsHistory from "../components/tests/TestsHistory";
import TestsActive from "../components/tests/TestsActive";

const TestsEvaluation = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const routeMatch = useRouteMatch(["/teste-evaluare", "/teste-evaluare/teste-finalizate"]);
  const currentTab = routeMatch?.pattern?.path;

  return (
    <Box>
      <PageTitle
        actions={
          <>
            {user.position === "teacher" ? (
                <CustomButton
                    buttonType="textIcon"
                    onClick={() => {
                        navigate("/teste-evaluare/adauga-test-evaluare");
                    }}
                    icon={<Icon icon="fas plus" fixedWidth={true} />}
                >Generează test</CustomButton>
            ) : (
              ""
            )}
          </>
        }
      >
          Teste pregătire Evaluare Națională
      </PageTitle>
      <BoxContent>
        <>
          <Box sx={{ width: "100%" }}>
            <Box mb={2} sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={currentTab}
                sx={{
                  "& .MuiTabs-flexContainer": {
                    flexWrap: "wrap",
                  },
                }}
              >
                <Tab
                    label="Teste active"
                    value="/teste-evaluare"
                    to="/teste-evaluare" component={Link}
                    sx={{color: "secondary.light", "& :hover, & .active": "primary.main"}}
                />
                <Tab
                  label="Teste finalizate"
                  value="/teste-evaluare/teste-finalizate"
                  to="/teste-evaluare/teste-finalizate"
                  component={Link}
                  sx={{color: "secondary.light", "& :hover, & .active": "primary.main"}}
                />
              </Tabs>
            </Box>
            {location.pathname === "/teste-evaluare" && <TestsActive type="evaluate" />}
            {location.pathname === "/teste-evaluare/teste-finalizate" && <TestsHistory type="evaluate" />}
          </Box>
        </>
      </BoxContent>
    </Box>
  );
};

export default TestsEvaluation;
