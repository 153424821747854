import Report from "../components/schoolReports/Report";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useAuth} from "../hooks/useAuth";
import {APIGetReport} from "../api/pages/schoolReports";
import Loading from "../components/ui/Loading";
import html2canvas from "html2canvas";
import Icon from "../components/ui/Icon";
import {PDFDownloadLink} from "@react-pdf/renderer";
import Button from "@mui/material/Button";
import ReportPDF from "../components/schoolReports/ReportPDF";
import {useReactToPrint} from "react-to-print";

const SchoolReport = () => {
    const { id = "" } = useParams();
    const { update, tenant } = useAuth();
    const reportPrintRef = useRef(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [reportData, setReportData] = useState<any>();
    const [generatePdf, setGeneratePdf] = useState(false);
    const [idImages, setIdImages] = useState<string[]>([]);
    const [images, setImages] = useState<any[]>([]);
    const [click, setClick] = useState(false);

    useEffect(() => {
        if (id !== "") {
            setLoading(true);
            APIGetReport({ id: id })
                .then((response) => {
                    if (response.data.success) {
                        let data = response.data.data.item;
                        setReportData(data);
                        let id = ["graph1", "graph2", "graph3"];

                        for (let i = 0; i < data.payload.table6.length; i++) {
                            id.push(`gradeBoxplot${i}`);
                        }
                        for (let i = 0; i < data.payload.table7.length; i++) {
                            id.push(`gradeHeatmap${i}`);
                        }

                        for (let i = 0; i < data.payload.table9.length; i++) {
                            id.push(`gradeRiscHeatmap${i}`);
                        }

                        setIdImages(id);
                        setLoading(false);
                    } else {
                        update("error", { show: true, code: "A002" });
                    }
                })
                .catch((err) => {
                    console.log("getReport ERR", err);
                    update("error", { show: true, code: "A001" });
                });
        } else {
            navigate("/rapoarte");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const createImage = useCallback(async (id: string) => {
        // @ts-ignore
        let data = await html2canvas(document.getElementById(id));
        const img = data.toDataURL("image/png");
        setImages((prevState) => {
            return [...prevState, {image: img, id: id}];
        });
    }, []);

    const PDFGenerateHandler = () => {
        idImages.map((id) => {
            createImage(id);
        })
        setGeneratePdf(true);
    };

    const handlePrint = useReactToPrint({
        content: () => reportPrintRef.current,
    });

    return (
        <>
            {
                loading ? (
                    <Loading show={true}/>
                ) : (
                    <>
                        <div ref={reportPrintRef}>
                            <Report data={reportData} />
                        </div>
                        {reportData.payload.finished_tests_count !== 0 && (
                            <div className="print-bar screen no-print">
                                <Button type="submit" variant="contained" onClick={handlePrint} style={{ marginRight: 20 }}>
                                    Tipărește
                                </Button>
                                {generatePdf && images.length === idImages.length ? (
                                    <>
                                        <PDFDownloadLink document={
                                            <ReportPDF
                                                data={reportData}
                                                images={images}
                                                schoolName={tenant.name}
                                            />} fileName="RaportConsolidatEudcational.pdf">
                                            {({ blob, url, loading, error }) =>
                                                loading ? <>Se generează <Icon icon="far circle-notch" spin={true} className="fa-faster" fixedWidth={true} sx={{display: "inline"}}/></> : "Descarcă pdf"
                                            }
                                        </PDFDownloadLink>
                                    </>
                                ) : (
                                    <Button variant="contained" onClick={() => {
                                        setClick(true);
                                        window.setTimeout(() => {
                                            PDFGenerateHandler();
                                        }, 100);

                                    }} >
                                        {!click ? "Generează pdf" : <>Se generează <Icon icon="far circle-notch" spin={true} className="fa-faster" fixedWidth={true} sx={{display: "inline"}}/></>}
                                    </Button>
                                )}
                            </div>
                        )}
                    </>
                )
            }
        </>
    )
}

export default SchoolReport;