import Typography from "@mui/material/Typography";
import FormField from "components/form/FormField";
import BoxContent from "components/ui/BoxContent";
import Loading from "components/ui/Loading";

const CreateTestType = ({ loading, errors, subject, subjects, setSubjectHandler }: any) => {
  return (
    <>
      <Typography variant="h3" mb={3} mt={3}>
        Alege materia
      </Typography>
      <BoxContent>
        {loading.loading && loading.name === "subjects" ? (
          <Loading show={true} />
        ) : (
          <FormField
            name="subject"
            type="controlled-select"
            label="Alege materia"
            value={subject}
            onChange={setSubjectHandler}
            options={subjects.map((item: { name: string; id: string }) => {
              return {
                label: item.name,
                value: item.id,
                ...item,
              };
            })}
            errorMessage={errors.subject?.message}
          />
        )}
      </BoxContent>
    </>
  );
};

export default CreateTestType;
