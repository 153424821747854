import * as Highcharts from "highcharts";
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from "highcharts-react-official";
import {useAuth} from "../../hooks/useAuth";
import {useRef} from "react";
HighchartsMore(Highcharts);

const CustomBoxplotChart = ({ series, xAxis, ...rest }: HighchartsReact.Props) => {
    const {user} = useAuth();

    const options: Highcharts.Options = {
        chart: {
            type: "boxplot",
            inverted: true,
            backgroundColor: "#FFFFFF",
            height: series[0].data.length * 65,
        },
        title: {
            text: "",
        },
        plotOptions: {
            boxplot: {
                lineWidth: 2,
                medianWidth: 4,
            },
            series: {
                // @ts-ignore
                colorByPoint: true,
                pointWidth: 30,
                groupPadding: 0,
                pointPadding: 0,
            }
        },
        yAxis: {
            min: 0,
            max: 100,
            title: {
                text: 'Centile'
            }
        },
        legend: {
            enabled: false
        },
        colors: user.theme === "light" ? ["#0064FF", "#9D0000", "#008D2C", "#B948DB", "#00289B", "#DE6525"] : ["#0064FF", "#ED0000", "#008D2C", "#B948DB", "#98C0FF", "#DE6525"] ,
        // series: [
        //     {
        //         name: 'Scor clasa 5',
        //         // @ts-ignore
        //         data: [[58, 71.91, 75, 84.75, 98], [58, 71.91, 75, 84.75, 98]],
        //     },
        //     {
        //         name: 'scor clasa 6',
        //         // @ts-ignore
        //         data: [[36, 66.5, 77, 83.5, 97]],
        //     },
        // ]
    }

    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    return (
        <>

            <HighchartsReact highcharts={Highcharts} options={{ ...options, series, xAxis }} ref={chartComponentRef} {...rest}/>
        </>

    )
};

export default CustomBoxplotChart;