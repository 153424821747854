import Box from "@mui/material/Box";
import React from "react";
import Icon from "components/ui/Icon";
import Dialog from "@mui/material/Dialog";

interface IProps {
  show?: boolean;
  modal?: boolean;
}

const Loading = ({ show = false, modal = false }: IProps) => {
  return modal ? (
    <Dialog
      open={show}
      onClose={() => {
        return null;
      }}
      PaperComponent={Box}
      sx={{ backgroundColor: "rgba(0, 40, 155, 0.5)" }}
    >
      <Box p={2} display="flex" justifyContent="center" alignItems="center">
        <Icon
          icon="fat circle-notch"
          spin={true}
          className="fa-faster"
          fixedWidth={true}
          style={{ fontSize: 48, color: "#fff" }}
        />
      </Box>
    </Dialog>
  ) : show ? (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      style={{ fontSize: 18 }}
      color="info.main"
    >
      <Icon icon="far circle-notch" spin={true} className="fa-faster" fixedWidth={true} />
    </Box>
  ) : null;
};

export default Loading;
