import { Link} from "react-router-dom";
import { styled } from "@mui/system";

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.light,
  textDecoration: "none",
  "& :hover, & .active": {
    color: theme.palette.primary.main,
  },
}));

export default StyledLink;
