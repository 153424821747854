import {StyleSheet} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    body: {
        margin: "0",
        padding: "30px 0 30px 0",
        width: "100%",
        color: "#000",
        font: "11pt Rubik",
        fontWeight: 300,
        height: "100%",
        boxSizing: "border-box",
    },
    coverBg: {
        position: "relative",
    },
    wrapperCover: {
        color: "#000000",
        width: "100%",
        maxWidth: "990px",
        margin: "auto",
        padding: "30px",
    },
    coverPage: {
        marginTop: "-30px",
        padding: "30px",
        position: "relative",
        backgroundColor: "#EBF3FF",
        width: "100%",
    },
    coverLogo: {
        width: "80%",
        maxWidth: "288px",
        height: "auto",
    },
    coverImage: {
        float: "right",
        width: "50%",
        maxWidth: "360px",
        height: "auto",
    },
    titleCover: {
        padding: "22mm 0 4mm 0",
        flexDirection: "column",
    },
    h1Cover: {
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: "24pt",
        margin: "0 0 5pt 0",
        lineHeigth: "24pt",
    },
    coverFooter: {
        fontFamily: "Rubik",
        fontWeight: 400,
        fontSize: "7pt",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: "40px",
    },
    sectionAbout: {
        fontFamily: "Rubik",
        fontSize: "11pt",
        // lineHeight: "13pt",
        fontWeight: 400,
        width: "100%",
        position: "relative",
        margin: "20px 0",
    },
    p: {
        margin: "15pt 0 11pt 0",
        fontFamily: "Rubik",
        fontWeight: 400,
        fontSize: "11pt",
        lineHeight: 1.3,
    },
    h1: {
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: "24pt",
        margin: "0 0 20pt 0",
        lineHeigth: "24pt",
    },
    h1subtitle: {
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: "21pt",
        margin: "0 0 30pt 0",
        lineHeigth: "24pt",
    },
    h1About: {
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: "24pt",
        margin: "0 0 13.5mm 0",
        lineHeigth: "24pt",
    },
    h2: {
        fontFamily: "Rubik",
        fontWeight: 400,
        fontSize: "17pt",
        margin: 0,
    },
    h3: {
        fontFamily: "Rubik",
        fontWeight: 700,
        fontSize: "11.2pt",
        margin: "0 0 6mm",
        textTransform: "uppercase",
    },
    h4: {
        fontFamily: "Rubik",
        fontWeight: 600,
        fontSize: "12pt",
        margin: "21px 0",
    },
    tableSubsection: {
        padding: "5mm 0",
    },
    chartTable: {
        width: "100%",
        height: "auto",
        position: "relative",
        left: "1pt",
    },
    gradesRowHeader: {
        flexDirection: "row",
        width: "100%",
        // position: "relative",
        fontFamily: "Rubik",
        alignItems: "center",
        fontSize: "10pt",
        fontWeight: 500,
    },
    gradesRowBody: {
        flexDirection: "row",
        width: "100%",
        // position: "relative",
        fontFamily: "Rubik",
        alignItems: "center",
        fontSize: "10pt",
        fontWeight: 300,
    },
    gradesRowBodyOdd: {
        flexDirection: "row",
        width: "100%",
        // position: "relative",
        fontFamily: "Rubik",
        alignItems: "center",
        fontSize: "10pt",
        fontWeight: 300,
        backgroundColor: "#EFEFEF",
    },
    gradesRowFooter: {
        flexDirection: "row",
        width: "100%",
        // position: "relative",
        fontFamily: "Rubik",
        alignItems: "center",
        fontSize: "10pt",
        fontWeight: 500,
    },
    gradesRowFooterOdd: {
        flexDirection: "row",
        width: "100%",
        // position: "relative",
        fontFamily: "Rubik",
        alignItems: "center",
        fontSize: "10pt",
        fontWeight: 500,
        backgroundColor: "#EFEFEF",
    },
    gradesRow: {
        flexDirection: "row",
        width: "100%",
        // position: "relative",
        fontFamily: "Rubik",
        borderBottom: "0.2pt solid #616060",
        alignItems: "center",
    },
    colValues: {
        width: "100%",
        padding: "5px",
        fontSize: "10pt",
    },
    colValuerNumeric: {
        width: "100%",
        padding: "5px",
        fontSize: "10pt",
        textAlign: "right",
    },
    colValuesStart: {
        width: "100%",
        padding: "5px",
        paddingLeft: "10px",
        fontSize: "10pt",
    },
    colValuesEnd: {
        width: "100%",
        padding: "5px",
        paddingRight: "10px",
        fontSize: "10pt",
    },
    tableTitle: {
        margin: "10pt 0 9pt 0",
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: "14pt",
    },
    tableNoteNumber: {
        fontFamily: "Rubik",
        fontWeight: 400,
        fontSize: "9pt",
        padding: "2px 5px",
        border: "1px solid #0064FF",
        color: "#0064FF",
        borderRadius: "50%",
        width: "15px",
        height: "15px",
        margin: 0,
    },
    tableNote: {
        fontFamily: "Rubik",
        fontWeight: 400,
        fontSize: "10pt",
        marginTop: 0,
        marginBottom: "9pt",
    },
    list: {
        padding: "0 0 0 40px",
    },
    mb13: {
        marginBottom: "11mm"
    },
    mb100: {
        marginBottom: "25px",
    },
})