import { matchPath, useLocation } from "react-router-dom";

export const replaceDevUrl = (url: string, replaceTenancy: boolean = true) => {
  let urlNew = url;
  if (process.env.NODE_ENV === "development") {
    urlNew = urlNew
      .replace("http://brio-saas.test", process.env.REACT_APP_API_DOMAIN || "")
      .replace("http://schools.brio-saas.test", process.env.REACT_APP_API_DOMAIN || "")
      .replace("http://teachers.brio-saas.test", process.env.REACT_APP_API_DOMAIN || "");
    if (replaceTenancy) urlNew = urlNew.replace("/tenancy/assets", "/storage");
  }
  return urlNew;
};

export const nicePosition = (position: string) => {
  switch (position) {
    case "principal":
      return "Director";
    case "teacher":
      return "Profesor";
    case "student":
      return "Elev";
    default:
      return position;
  }
};

export const niceUserName = (firstname: string, lastname: string, email?: string) => {
  return firstname === "" && lastname === ""
    ? email || "Nedefinit"
    : firstname + (firstname !== "" ? " " : "") + lastname;
};

export const gradesRomans = ["0", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII"];

export const getRomanNumber = (number: number) => {
  const data = gradesRomans;
  return data[number];
};

type TError = {
  code: string;
  title?: string;
  message?: string;
  icon?: string;
  button?: string;
  closable?: boolean;
  returnFunction?: () => void;
  onClickFunction?: () => void;
};

export const errors = [
  {
    code: "A001",
    title: "Eroare comunicare cu server",
    message:
      "<p>Din păcate a intervenit o eroare la comunicarea cu serverul. Vă rugăm încercați mai târziu.</p><p>Dacă problema persistă, ne puteți contacta la <a href='mailto:contact@brio.ro'>contact@brio.ro</a>.</p>",
    icon: "fat face-eyes-xmarks",
    closable: true,
    button: "REÎNCARCĂ",
    returnFunction: () => {
      if (process.env.NODE_ENV !== "development") {
      window.location.reload();
      }
    },
  },
  {
    code: "A002",
    title: "Eroare comunicare cu server",
    message:
      "<p>Din păcate, serverul a trimis un mesaj de eroare. Vă rugăm încercați mai târziu.</p><p>Dacă problema persistă, ne puteți contacta la <a href='mailto:contact@brio.ro'>contact@brio.ro</a>.</p>",
    icon: "fat face-eyes-xmarks",
    closable: true,
    button: "REÎNCARCĂ",
    returnFunction: () => {
      if (process.env.NODE_ENV !== "development") {
        window.location.reload();
        }
    },
  },
  {
    code: "A003",
    title: "Verifică adresa de e-mail",
    message:
      "<p>Pentru a continua trebuie sa îți verifici adresa de e-mail</p>",
    icon: "fat envelope",
    closable: true,
    button: "ÎNCHIDE",
  },
  {
    code: "A004",
    title: "Adresa de e-mail a fost verificată cu succes",
    message:
      "<p></p>",
    icon: "fat envelope",
    closable: true,
    button: "ÎNCHIDE",
  },
];

export const errorMessage = (code: string): TError | null => {
  return errors.find((e) => e.code === code) || null;
};

export const arrayEquals = (a: any[], b: any[]) => {
  return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
}

export const dateOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
}

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

export function findInArray(arr: any, num: number) {
  const found = arr.filter((obj: any) => {
  return obj.id === num;
});

  return found;
}

export function heatMapData(arr: any, type = "decile") {
  let graph: any[] = [];

  if(type === "decile") {
    arr.map((item: any, index: number) => {
      for (let i = 0; i < 10; i++) {
        graph.push([i, index , item[`d${i + 1}`]]);
      }
    })
  } else if (type === "risc") {
    arr.map((item: any, index: number) => {
      for (let i = 0; i < 5; i++) {
        switch (i) {
          case 0: {
            graph.push([i, index , item[`a%`]]);
          }
          break;
          case 1: {
            graph.push([i, index , item[`b%`]]);
          }
            break;
          case 2: {
            graph.push([i, index , item[`c%`]]);
          }
            break;
          case 3: {
            graph.push([i, index , item[`d%`]]);
          }
            break;
          case 4: {
            graph.push([i, index , item[`e%`]]);
          }
            break;
        }

      }
    })
  }



  return graph;
}

