import {Image, Text, View} from "@react-pdf/renderer";
import React from "react";
import {styles} from "../../StylesPDFSchoolReport";

const Section32 = ({subject, table7, imagesHeatmap}: any) => {
    const tableRows=[
        {name: "Dimensiune", id: "subcategory"},
        {name: "Decila 1 [0,10]", id: "d1"},
        {name: "Decila 2 (10,20]", id: "d2"},
        {name: "Decila 3 (20,30]", id: "d3"},
        {name: "Decila 4 (30,40]", id: "d4"},
        {name: "Decila 5 (40,50]", id: "d5"},
        {name: "Decila 6 (50,60]", id: "d6"},
        {name: "Decila 7 (60,70]", id: "d7"},
        {name: "Decila 8 (70,80]", id: "d8"},
        {name: "Decila 9 (80,90]", id: "d9"},
        {name: "Decila 10 (90,100]", id: "d10"},
    ];

    return (
        <>
            {table7.map((data: any, index: number) => (
                <View key={index}>
                    <Text style={[styles.h2, styles.mb13]}>3.2.{index + 1}. Distribuția elevilor pentru clasa {table7[index][0].grade}</Text>
                    <View style={styles.tableSubsection} wrap={false}>
                        <Text style={styles.tableTitle}>Tabelul 6.{index + 1}. Scorurile totale obtinute pentru {subject}, clasa {table7[index][0].grade}</Text>
                        <View style={styles.chartTable}>
                            <View style={styles.gradesRowHeader}>
                                {tableRows.map((row, index: number) => (
                                    <Text style={[row.id !== "subcategory" ? styles.colValuerNumeric : styles.colValues, row.id === "subcategory" ? styles.colValuesStart : row.id === "d10" ? styles.colValuesEnd : styles.colValues ]} key={index}>{row.name}</Text>
                                ))}
                            </View>
                            {data.map((item: any, index: any) => (
                                <View style={[index%2 === 0 ? styles.gradesRowBodyOdd : styles.gradesRowBody]} key={index}>
                                    {tableRows.map((row, index: number) => (
                                        <Text style={[row.id !== "subcategory" ? styles.colValuerNumeric : styles.colValues, row.id === "subcategory" ? styles.colValuesStart : row.id === "d10" ? styles.colValuesEnd : styles.colValues ]} key={index}>{typeof item[row.id] === 'string' ? item[row.id].substring(0, 40) : item[row.id]}</Text>
                                    ))}
                                </View>
                            ))}
                        </View>
                    </View>
                    <View style={styles.tableSubsection} wrap={false}>
                        <Text style={styles.tableTitle}>Graficul 5.{index + 1}. Heatmap cu distributia decilelor de performanta pentru {subject}, clasa {table7[index][0].grade}</Text>
                        {imagesHeatmap.map((image : any) => (
                                <>
                                    {image.id === `gradeHeatmap${index}` && <Image src={image.image} key={index}/>}
                                </>
                            )
                        )}
                    </View>
                </View>
            ))}
        </>
    )
}

export default Section32;