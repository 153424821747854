import { SelectChangeEvent } from "@mui/material/Select";
import { APIGetClassroom } from "api/pages/classroom";
import {APIPostCategories, APIPostGrades} from "api/pages/createTest";
import { APIPostItem, APIPostItems } from "api/pages/createTestsTraining";
// import { APIGetSubjects } from "api/pages/subjects";
import { IFormCreateTestTraining } from "components/createTest/_interfaces";
import React, {useEffect, useReducer, useState} from "react";
import { niceUserName } from "utils/functions";
import { useAuth } from "./useAuth";
import {ApiGetAllTags} from "../api/pages/testTags";

const reducer = (state: IFormCreateTestTraining, action: any) => {
  switch (action.type) {
    case 'classroom':
      return {
        ...state,
        classroom: action.payload,
      }
    case 'users':
      return {
        ...state,
        users: action.payload,
      }
    case 'test_config':
      return {
        ...state,
        test_config: action.payload,
      }
    case 'difficulty':
      return {
        ...state,
        difficulty: action.payload,
        grade: { value: "", label: "" },
        categories: [{ value: "", label: "" }],
      }
    case 'items_number':
      return {
        ...state,
        items_number: action.payload,
      }
    case 'individual_test':
      return {
        ...state,
        individual_test: action.payload,
      }
    case 'start_date':
      return {
        ...state,
        start_date: action.payload,
      }
    case 'expire':
      return {
        ...state,
        expire: action.payload,
      }
    case 'subject':
      return {
        ...state,
        subject: action.payload,
        grade: { value: "", label: "" },
        categories: [{ value: "", label: "" }],
        category: { value: "", label: "" },
      }
    case 'grade':
      return {
        ...state,
        grade: action.payload,
        category: { value: "", label: "" },
      }
    case 'categories':
      return {
        ...state,
        categories: action.payload,
        category: { value: "", label: "" },
      }
    case 'category':
      return {
        ...state,
        category: action.payload,
      }
    case 'tag':
      return {
        ...state,
        tag: action.payload,
      }
    default:
          return state;
  }
}

const useCreateTestForm = (testType: string, type: string) => {
  const { update } = useAuth();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState({ loading: true, name: "" });
  const [grades, setGrades] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [subjects, setSubjects] = useState<any[]>([
    { name: "Istorie", id: "22" },
    { name: "Matematică", id: "1" },
    { name: "Română", id: "4" },
    { name: "Fizică", id: "30" },
    { name: "Chimie", id: "34" },
    { name: "Biologie", id: "33" },
    { name: "Geografie", id: "37" },
  ]);
  const [tags, setTags] = useState<any[]>([]);
  const [excludedItems, setExcludedItems] = useState<any[]>([]);
  const [state, dispatch] = useReducer(reducer, {
    classroom: null,
    users: [],
    test_config: {
      value: "1",
      label: "Elevul poate rezolva itemii in orice ordine si se poate reintoarce asupra raspunsurilor la itemi.",
    },
    difficulty: { value: "", label: "" },
    items_number: { value: "", label: "" },
    individual_test: { value: "1", label: "Teste diferite pentru fiecare elev" },
    start_date: new Date(Date.now()),
    expire: "",
    subject: { value: "", label: "" },
    grade: { value: "", label: "" },
    categories: [{ value: "", label: "" }],
    category: {value: "", label: ""},
    tag: {value: "", label: ""},
  })
  const [selectedStudents, setSelectedStudents] = React.useState<any[]>([]);

  useEffect(() => {
    setLoading({ loading: true, name: "tags" });
    if (type === "bac") {
      setSubjects([
        { name: "Bac Mate/Științe", id: "bac-mate" },
        { name: "Bac Mate-Info", id: "bac-mate-info" },
        { name: "Bac Română", id: "bac-romana" },
      ]);
    } else if (type === "evaluate") {
      setSubjects([
        { name: "Evaluare nationala matematica", id: "evaluare-nationala-mate" },
        { name: "Evaluare nationala romana", id: "evaluare-nationala-romana" },
      ]);
    }
    ApiGetAllTags()
      .then((response) => {
        if (response.data.success) {
          setTags(response.data.data.values);
          setLoading({ loading: false, name: "tags" });
        } else {
          update("error", { show: true, code: "A002" });
        }
      })
      .catch((err) => {
        update("error", { show: true, code: "A001" });
      });
    // APIGetSubjects()
    //   .then((response) => {
    //     if (response.data.success) {
    //       // setSubjects(response.data.data.items);
    //       setLoading({ loading: false, name: "subjects" });
    //     } else {
    //       update("error", { show: true, code: "A002" });
    //     }
    //   })
    //   .catch((err) => {
    //     update("error", { show: true, code: "A001" });
    //   });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (type === "literacy" || type === "digitalLiteracy") {
      setLoading({ loading: true, name: "grade" });
      APIPostGrades({ typeId: type === "literacy" ? 19 : 24 })
        .then((response) => {
          if (response.data.success) {
            setGrades(response.data.data.items);
            setLoading({ loading: false, name: "grade" });
          } else {
            update("error", { show: true, code: "A002" });
          }
        })
        .catch((err) => {
          // console.log("getGrades ERR", err);
          update("error", { show: true, code: "A001" });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setClassroomHandler = (event: any, value: any) => {
    let selectedClassroom = value;
    dispatch({
      type: 'classroom',
      payload: selectedClassroom,
    })
    setLoading({ loading: true, name: "students" });
    APIGetClassroom({ id: selectedClassroom.id })
      .then((response) => {
        if (response.data.success) {
          // console.log("getClassroom OK", response);
          let students = response.data.data.item.students.map(
            (item: { first_name: string; last_name: string; id: string; [key: string]: any }) => {
              return {
                label: niceUserName(item.first_name, item.last_name, item.email),
                value: item.id.toString(),
                checked: true,
                ...item,
              };
            },
          );
          dispatch({
            type: 'users',
            payload: students,
          })
          setSelectedStudents(students.map(({label} : any) => label));

          setLoading({ loading: false, name: "students" });
        } else {
          update("error", { show: true, code: "A002" });
        }
      })
      .catch((err) => {
        // console.log("getClassroom ERR", err);
        update("error", { show: true, code: "A001" });
      });
  };

  const setSelectedStudentsHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkedStudents = state.users.map((item: any) => {
      if (item.value.toString() === event.target.name) {
        if(event.target.checked) {
          setSelectedStudents([...selectedStudents, item.label])
        } else setSelectedStudents(selectedStudents.filter((student) => student !== item.label))

        return { ...item, checked: event.target.checked };
      }

      return item;
    });
    dispatch({
      type: 'users',
      payload: checkedStudents,
    });
  };

  const setSelectAllStudentsHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkedStudents = state.users.map((item: any) => {
      if(event.target.checked) {
        setSelectedStudents(state.users.map(({label} : any) => label));
        return { ...item, checked: true };
      } else {
        setSelectedStudents([]);
        return { ...item, checked: false };
      }

    });
    dispatch({
      type: 'users',
      payload: checkedStudents,
    })
  };

  const handleSelectChange = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    // console.log(target);
    const { name, value } = target;
    dispatch({
      type: name,
      payload: { value: value, label: name },
    });
    if (state.grade.value && state.difficulty.value && name === "difficulty") {
      setLoading({ loading: true, name: "categories" });
      APIPostCategories({ grade_id: state.grade.value, difficulty: value })
        .then((response) => {
          if (response.data.success) {
            // console.log("getCategories Ok effect", response.data.data.items);
            dispatch({
              type: 'categories',
              categories: response.data.data.items,
            });
            setLoading({ loading: false, name: "categories" });
          } else {
            update("error", { show: true, code: "A002" });
          }
        })
        .catch((err) => {
          update("error", { show: true, code: "A001" });
        });
    }
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    const { name, value } = target;
    dispatch({
      type: name,
      payload: parseInt(value),
    });
  };

  const handleTestStartDate = (date: Date) => {
    // console.log(date, "start date");
    dispatch({
      type: 'start_date',
      payload: date,
    })
  };

  const setSubjectHandler = (event: SelectChangeEvent) => {
    let selectedSubject = event.target.value;
    dispatch({
      type: 'subject',
      payload: { value: selectedSubject, label: "subject" },
    })
    if (type === "bac" || type === "evaluate") {
      setLoading({ loading: true, name: "categories" });
      APIPostCategories({ test_type: selectedSubject })
        .then((response) => {
          if (response.data.success) {
            // console.log("getCategories Ok", response.data.data.items);
            dispatch({
              type: 'categories',
              payload: response.data.data.items.map((item: { name: string; id: string; [key: string]: any }) => {
                return {
                  disabled: true,
                  label: item.name,
                  value: item.id.toString(),
                  checked: true,
                  ...item,
                };
              }),
            })
            setLoading({ loading: false, name: "categories" });
          } else {
            update("error", { show: true, code: "A002" });
          }
        })
        .catch((err) => {
          // console.log("getCategories ERR", err);
          update("error", { show: true, code: "A001" });
        });
    } else {
      setLoading({ loading: true, name: "grade" });
      APIPostGrades({ typeId: selectedSubject, testType: testType })
        .then((response) => {
          if (response.data.success) {
            setGrades(response.data.data.items);
            setLoading({ loading: false, name: "grade" });
          } else {
            update("error", { show: true, code: "A002" });
          }
        })
        .catch((err) => {
          // console.log("getGrades ERR", err);
          update("error", { show: true, code: "A001" });
        });
    }
  };

  const setGradeHandler = (event: SelectChangeEvent) => {
    let selectedGrade = event.target.value;
    dispatch({
      type: 'grade',
      payload: { value: selectedGrade, label: "grade" },
    });
    setLoading({ loading: true, name: "categories" });
    APIPostCategories({ grade_id: selectedGrade, difficulty: state.difficulty.value })
      .then((response) => {
        if (response.data.success) {
          // console.log("getCategories Ok", type, response.data.data.items);
          if (type === "literacy") {
            dispatch({
              type: 'categories',
              payload: response.data.data.items.map(
                  (item: { name: string; id: string; subcategories: []; [key: string]: any }) => {
                    return {
                      id: item.id,
                      name: item.name,
                      subcategories: item.subcategories.map(
                          (subcategory: { name: string; id: string; [key: string]: any }) => {
                            return {
                              disabled: true,
                              label: subcategory.name,
                              value: subcategory.id.toString(),
                              checked: true,
                              ...subcategory,
                            };
                          },
                      ),
                    };
                  },
              ),
            });
          } else {
            dispatch({
              type: 'categories',
              payload: response.data.data.items,
            });
          }

          setLoading({ loading: false, name: "categories" });
        } else {
          update("error", { show: true, code: "A002" });
        }
      })
      .catch((err) => {
        // console.log("getCategories ERR", err);
        update("error", { show: true, code: "A001" });
      });
  };

  const setSelectedCategoriesHandler = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    let checkedCategories: any[];
    if (type === "digitalLiteracy") {
      checkedCategories = state.categories.map((category: any) => {
        if (category.id?.toString() === event.target.name) {
          return {
            ...category,
            checked: event.target.checked,
            subcategories: category.subcategories?.map((subcategory: any) => {
              return {
                ...subcategory,
                checked: event.target.checked,
              };
            }),
          };
        }
        return category;
      });
    } else {
      const checkedCategory = {
        ...state.categories[index],
        subcategories: state.categories[index].subcategories?.map((subcategory: any) => {
          if (subcategory.id.toString() === event.target.name) {
            return { ...subcategory, checked: event.target.checked };
          }
          return subcategory;
        }),
      };

      checkedCategories = state.categories.map((category: any) => {
        if (category.id === checkedCategory.id) {
          return checkedCategory;
        } else return category;
      });
    }
    dispatch({
      type: "categories",
      payload: checkedCategories,
    })
  };

  const setSelectedCategoryHandler = (event: SelectChangeEvent) => {
    let selectedCategory = event.target.value;
    dispatch({
      type: "category",
      payload: { value: selectedCategory, label: "category" },
    })
  };

  const handleItemReplace = (subcategoryId: string, id: string) => {
    APIPostItem({
      subcategory: subcategoryId,
      excluded_items: excludedItems,
      difficulty: state.difficulty.value,
    })
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.data)
          // console.log("APIPostItem OK", response);
          if(response.data.data.values.length !== 0) {
            setExcludedItems((current) => [...current, id]);
            setItems((prevState) => {
              const newState = prevState.map((obj) => {
                if (obj.id === id) {
                  return response.data.data.values[0];
                }
                return obj;
              });
              return newState;
            });
          }
          // navigate("/clase");
        } else {
          update("error", { show: true, code: "A002" });
        }
      })
      .catch((err) => {
        // console.log("APIPostItem ERR", err);
        update("error", { show: true, code: "A001" });
      });
  };

  const previousStep = (callback: Function) => {
    if (step === 1) return;
    callback();
    setStep((curr) => curr - 1);
    setLoading({ loading: false, name: "step" });
  };

  const confirmPreviousStep = () => {
    update("confirm", {
      show: true,
      closable: true,
      title: "Ești sigur că vrei să te întorci în pagina de generare a testului?",
      message: (
          <>
            Odată cu această acțiune se vor reconfigura toți itemii din cadrul acestei previzualizări a testului.
          </>
      ),
      returnFunction: (callback: Function) => {
        // setLoading({ loading: true, name: "step" });
        setTimeout(() => previousStep(callback), 100);
      },
      buttonYes: "Pasul anterior",
      buttonNo: "Închide",
    });
  };

  const setItemsHandler = () => {
    let selectedCategories: number[];
    selectedCategories = state.categories
      .map((category: any) => {
        return category.subcategories?.filter((item: any) => item.checked === true).map(({ id }: any) => id);
      })
      .flat();
    APIPostItems({
      subcategories: selectedCategories,
      items_number: state.items_number.value,
      difficulty: state.difficulty.value,
    })
      .then((response) => {
        if (response.data.success) {
          // console.log("APIPostItems OK", response);
          // console.log("onSubmit", createTestData);
          setItems(response.data.data.values);
          if (response.data.data.values) {
            setExcludedItems(response.data.data.values.map(({ id }: any) => id));
            setStep(2);
            setLoading({ loading: false, name: "step" });
          } else {
            update("error", { show: true, code: "A002" });
          }

          // update("loading", { show: false });
        } else {
          // console.log("APIPostItems err else", response);
          update("loading", { show: false });
          update("error", { show: true, code: "A002" });
        }
      })
      .catch((err) => {
        // console.log("APIPostItems ERR", err);
        update("loading", { show: false });
        update("error", { show: true, code: "A001" });
      });
  };

  return {
    step,
    setStep,
    loading,
    setLoading,
    grades,
    setGrades,
    items,
    setItems,
    subjects,
    excludedItems,
    setExcludedItems,
    createTestData : state,
    selectedStudents,
    tags,
    setClassroomHandler,
    setSelectedStudentsHandler,
    setSelectAllStudentsHandler,
    handleSelectChange,
    handleInputChange,
    setSubjectHandler,
    setGradeHandler,
    setSelectedCategoriesHandler,
    setSelectedCategoryHandler,
    handleItemReplace,
    previousStep,
    setItemsHandler,
    confirmPreviousStep,
    handleTestStartDate,
  };
};

export default useCreateTestForm;
