import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { niceUserName } from "utils/functions";
import CustomAvatar from "components/ui/CustomAvatar";
import ListItemButton from "@mui/material/ListItemButton";

interface IAvatar {
  small?: boolean;
  firstname: string;
  lastname: string;
  profilePhoto?: string;
  onClick?: () => void;
}

const AvatarItemList = ({ small = true, firstname, lastname, profilePhoto, onClick }: IAvatar) => {
  return (
    <ListItem sx={{ paddingLeft: 0 }}>
      {onClick ? (
          <ListItemButton onClick={() => onClick()} sx={{ paddingLeft: 0 }}>
            <ListItemAvatar sx={{ alignSelf: "flex-start" }}>
              <CustomAvatar small={small} firstname={firstname} lastname={lastname} profilePhoto={profilePhoto} />
            </ListItemAvatar>
            {niceUserName(firstname, lastname)}
          </ListItemButton>
      ) : (
          <>
            <ListItemAvatar sx={{ alignSelf: "flex-start" }}>
              <CustomAvatar small={small} firstname={firstname} lastname={lastname} profilePhoto={profilePhoto} />
            </ListItemAvatar>
            {niceUserName(firstname, lastname)}
          </>
      )}
      
    </ListItem>
  );
};

export default AvatarItemList;
