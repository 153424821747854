import Box from "@mui/material/Box";
import {useAuth} from "../../hooks/useAuth";

interface IDPieChart {
  amount: number;
  size?: string;
}

const PieChart = ({ amount, size = "small" }: IDPieChart) => {
    const {user} = useAuth();

  return (
    <Box
      color="secondary.light"
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        width: size === "big" ? 36 : 30,
        height: size === "big" ? 36 : 30,
        fontSize: size === "big" ? "1.2rem" : "1rem",
        lineHeight: 1,
        borderRadius: "50%",
        border: user.theme === "light" ? "1px solid #fff" : "1px solid #0064FF",
        background: user.theme === "light" ? `conic-gradient(#ffffff ${amount}%, #0000 0)` : `conic-gradient(#0064FF ${amount}%, #0000 0)`,
      }}
    >
      {amount}
    </Box>
  );
};

export default PieChart;
