import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/system";
// import Button from "@mui/material/Button";
// import { useLocation } from "react-router-dom";

import { useAuth } from "hooks/useAuth";
import Icon from "components/ui/Icon";
import ScrollBar from "components/ui/ScrollBar";
import CustomButton from "components/ui/CustomButton";

const NavLinkStyled = styled(NavLink)(({ theme }) => ({
  color: theme.palette.secondary.light,
  textDecoration: "none",
  clear: "both",
  paddingRight: theme.spacing(2),
  "& svg": {
    color: theme.palette.primary.light,
  },
  "&:hover, &.active": {
    color: theme.palette.primary.main,
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
}));

const Menu = () => {
  const { user } = useAuth();
  // const { logout } = useAuth();
  // const location = useLocation();

  // console.log(user.menu);
  // console.log("location: ", location);

  return (
    <ScrollBar style={{ maxHeight: "100%" }} forceVisible="x" autoHide={false}>
      <Box mb={3}>
        {user.menu.map(
          (item, index) =>
            item.enabled === 1 && (
              <Box key={index}>
                {item.type === 0 && (
                  <Box display="flex" alignItems="center" mt={4}>
                    <Box
                      sx={(theme) => ({ width: "60px", borderBottom: `solid 1px ${theme.palette.secondary.light}` })}
                      display="flex"
                      justifyContent="flex-end"
                      mr={1.5}
                    ></Box>
                    <Box flexGrow={1}>
                      <Typography
                        variant="h2"
                        sx={{ fontWeight: 300 }}
                        mb={1}
                        color={user.theme === "light" ? "text.disabled" : "primary.light"}
                        dangerouslySetInnerHTML={{ __html: item.name }}
                      ></Typography>
                    </Box>
                    {item.extra !== null && item.extra !== "" && (
                      <Box display="flex" alignItems="center" gap={1} pb={1} mr={1.5}>
                        {JSON.parse(item?.extra?.toString() || "").map(
                          (extraItem: { icon: string; action: string }, index2: number) => (
                            <Box key={index2}>
                              <CustomButton
                                  buttonType="icon"
                                appearance="circle"
                                bgcolor="light"
                                color="primary.main"
                                size="small"
                                onClick={() => {
                                  window.open(extraItem.action);
                                }}
                                icon={<Icon icon={extraItem?.icon} fixedWidth={true} sx={{ fontSize: "1rem" }} />}
                              />
                            </Box>
                          ),
                        )}
                      </Box>
                    )}
                  </Box>
                )}
                {item.type === 1 && (
                  <Box>
                    <NavLinkStyled to={item.url} target={item.target}>
                      <Box component="span" display="inline-flex" alignItems="center" py={0.5}>
                        <Box component="span" sx={{ width: "60px" }} display="flex" justifyContent="flex-end" mr={1.5}>
                          {item.icon && <Icon icon={item.icon} fixedWidth={true} />}
                        </Box>
                        <Box component="span" flexGrow={1}>
                          <Typography variant="body1">{item.name}</Typography>
                        </Box>
                      </Box>
                    </NavLinkStyled>
                  </Box>
                )}
              </Box>
            ),
        )}
      </Box>
    </ScrollBar>
  );
};

export default Menu;
