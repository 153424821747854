import {styles} from "../../StylesPDFSchoolReport";
import {Image, Text, View} from "@react-pdf/renderer";
import React from "react";

const Section2 = ({subject, table4, table5, table8, imageBoxplot, imageHeatmap, imageRiscHeatmap} : any) => {
    const tableRowsT4 = [
        {name: "Clasa", id: "grade"},
        {name: "Dimensiune", id: "dimensions"},
        {name: "Numar elevi", id: "students"},
        {name: "Scor mediu", id: "avg"},
        {name: "Min", id: "min"},
        {name: "Q1 (25%)", id: "q1"},
        {name: "Q2 (50%)", id: "q2"},
        {name: "Q3 (75%)", id: "q3"},
        {name: "Max", id: "max"},
    ];

    const tableRowsT5 = [
        {name: "Dimensiune", id: "dimensions"},
        {name: "Decila 1 [0,10]", id: "d1"},
        {name: "Decila 2 (10,20]", id: "d2"},
        {name: "Decila 3 (20,30]", id: "d3"},
        {name: "Decila 4 (30,40]", id: "d4"},
        {name: "Decila 5 (40,50]", id: "d5"},
        {name: "Decila 6 (50,60]", id: "d6"},
        {name: "Decila 7 (60,70]", id: "d7"},
        {name: "Decila 8 (70,80]", id: "d8"},
        {name: "Decila 9 (80,90]", id: "d9"},
        {name: "Decila 10 (90,100]", id: "d10"},
    ];

    const tableRowsT8 = [
        {name: "Clasa", id: "grade"},
        {name: "Dimensiune", id: "dimensions"},
        {name: "A  ", id: "a"},
        {name: "B  ", id: "b"},
        {name: "C  ", id: "c"},
        {name: "D  ", id: "d"},
        {name: "E  ", id: "e"},
        {name: "TOT  ", id: "total"},
        {name: "A%  ", id: "a%"},
        {name: "B%  ", id: "b%"},
        {name: "C%  ", id: "c%"},
        {name: "D%  ", id: "d%"},
        {name: "E%  ", id: "e%"},
    ];

    return (
        <View style={styles.sectionAbout}>
            <Text style={styles.h1}>SECȚIUNEA 2: Privire de ansamblu asupra rezultatelor</Text>
            <View style={styles.mb13}>
                <Text style={styles.p}>
                    Această secțiune prezintă două feluri de informații:
                </Text>
                <View style={styles.list}>
                    <Text style={styles.p}>1. Scoruri medii și distribuții în interiorul școlii: pentru fiecare clasă și materie (și pentru fiecare dimensiune a materiei), un scor mediu al școlii, precum și detalii privind scorurile minime și maxime și limitele pentru cvartile - scorurile în care se încadreaza 25% și respectiv 75% dintre copiii acestei școli,</Text>
                    <Text style={styles.p}>2. Distribuții în decile naționale: pentru fiecare materie și clasă, distribuția elevilor în categorii decile la nivel național.</Text>
                    <Text style={styles.p}>3. Distribuții în categorii de risc vs. performanță: pentru fiecare materie și clasă, distribuția elevilor în categorii de risc și performanță la nivel național, (cele 4 mari zone de funcționare).</Text>
                </View>
            </View>
            <Text style={[styles.h1subtitle, {margin: "10mm 0"}]}>2.1. Scoruri medii și distribuții în interiorul școlii</Text>
            <View style={styles.tableSubsection} wrap={false}>
                <Text style={styles.tableTitle}>Tabelul 2. Scorurile totale obtinute pentru {subject}, pentru fiecare clasa</Text>

                <View style={styles.chartTable}>
                    <View style={styles.gradesRowHeader}>
                        {tableRowsT4.map((row, index: number) => (
                            <Text style={[row.id !== "grade" && row.id !== "dimensions" ? styles.colValuerNumeric : styles.colValues, row.id === "grade" ? styles.colValuesStart : row.id === "max" ? styles.colValuesEnd : styles.colValues ]} key={index}>{row.name}</Text>
                        ))}
                    </View>
                    {table4.map((item: any, index: any) => (
                        <View style={[index%2 === 0 ? styles.gradesRowBodyOdd : styles.gradesRowBody]} key={index}>
                            {tableRowsT4.map((row, index: number) => (
                                <Text style={[row.id !== "grade" && row.id !== "dimensions" ? styles.colValuerNumeric : styles.colValues, row.id === "grade" ? styles.colValuesStart : row.id === "max" ? styles.colValuesEnd : styles.colValues ]} key={index}>{item[row.id]}</Text>
                            ))}
                        </View>
                    ))}
                </View>
            </View>
            <View style={[styles.tableSubsection, styles.mb13]} wrap={false}>
                <Text style={styles.tableTitle}>Graficul 1. Boxplot cu distributia cvartilelor de performanta pentru {subject}, scor total</Text>
                <Image src={imageBoxplot} />
            </View>
            <Text style={[styles.h1subtitle, {margin: "10mm 0"}]}>2.2. Distribuția elevilor în categorii decile naționale</Text>
            <View style={styles.tableSubsection} wrap={false}>
                <Text style={styles.tableTitle}>Tabelul 3. Numarul de elevi din scoala plasati in fiecare decila nationala, pentru {subject}, pentru fiecare clasa</Text>
                <View style={styles.chartTable}>
                    <View style={styles.gradesRowHeader}>
                        {tableRowsT5.map((row, index: number) => (
                            <Text style={[row.id !== "dimensions" ? styles.colValuerNumeric : styles.colValues, row.id === "dimensions" ? styles.colValuesStart : row.id === "d10" ? styles.colValuesEnd : styles.colValues ]} key={index}>{row.name}</Text>
                        ))}
                    </View>
                    {table5.map((item: any, index: any) => (
                        <View style={[index%2 === 0 ? styles.gradesRowBodyOdd : styles.gradesRowBody]} key={index}>
                            {tableRowsT5.map((row, index: number) => (
                                <Text style={[row.id !== "dimensions" ? styles.colValuerNumeric : styles.colValues, row.id === "dimensions" ? styles.colValuesStart : row.id === "d10" ? styles.colValuesEnd : styles.colValues ]} key={index}>{item[row.id]}</Text>
                            ))}
                        </View>
                    ))}
                </View>
            </View>
            <View style={[styles.tableSubsection, styles.mb13]} wrap={false}>
                <Text style={styles.tableTitle}>Graficul 2. Heatmap cu distributia decilelor de performanta pentru {subject}</Text>
                <Image src={imageHeatmap} />
            </View>
            <Text style={[styles.h1subtitle, {margin: "10mm 0"}]}>2.3. Distribuția elevilor în categorii de risc</Text>
            <View style={styles.tableSubsection} wrap={false}>
                <Text style={styles.tableTitle}>Tabelul 4. Distribuția în funcție de scorurile generale pentru materia claselor testate</Text>
                <View style={styles.chartTable}>
                    <View style={styles.gradesRowHeader}>
                        {tableRowsT8.map((row, index: number) => (
                            <Text style={[row.id !== "dimensions" && row.id !== "grade" ? styles.colValuerNumeric : styles.colValues, row.id === "dimensions" ? styles.colValuesStart : row.id === "e%" ? styles.colValuesEnd : styles.colValues ]} key={index}>{row.name}</Text>
                        ))}
                    </View>
                    {table8.map((item: any, index: any) => (
                        <View style={[index%2 === 0 ? styles.gradesRowBodyOdd : styles.gradesRowBody]} key={index}>
                            {tableRowsT8.map((row, index: number) => (
                                <Text style={[row.id !== "dimensions" && row.id !== "grade" ? styles.colValuerNumeric : styles.colValues, row.id === "dimensions" ? styles.colValuesStart : row.id === "e%" ? styles.colValuesEnd : styles.colValues ]} key={index}>{item[row.id]}</Text>
                            ))}
                        </View>
                    ))}
                </View>
            </View>
            <View style={styles.tableSubsection} wrap={false}>
                <Text style={styles.tableTitle}>Graficul 3. Heatmap al distribuției în funcție de scorurile generale pentru materia claselor testate</Text>
                <Image src={imageRiscHeatmap} />
            </View>
        </View>
    )
}

export default Section2;