import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormField from "components/form/FormField";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useAuth } from "hooks/useAuth";
import { IFormChangePassword } from "components/login/_interfaces";
import { APIPostResetPassword } from "api/pages/passwordReset";
import React, {useState} from "react";
import ErrorsList from "../ui/ErrorsList";

const schema = yup
  .object({
  })
  .required();

const ChangePasswordForm = () => {
  const { update } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const domain = searchParams.get("domain");

  const methods = useForm<IFormChangePassword>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormChangePassword> = (data) => {
    APIPostResetPassword({
      domain: domain,
      email: email,
      password: data.password,
      password_confirmation: data.confirm_password,
      token: token,
    })
      .then((response) => {
        if (response.data.success) {
          console.log("postResetPassword OK", response);
          update("loading", { show: false });
          methods.clearErrors();
          let message = response.data.data.value;
          setErrorMessage("");
          setSearchParams({});
          navigate("/", { replace: true, state: { message: message } });
        } else {
          update("loading", { show: false });
          update("error", { show: true, code: "A002" });
        }
      })
      .catch((err) => {
        console.log("postResetPassword ERR", err);
        if (err.response.status === 400) {
          // setSearchParams({});
          // update("error", {
          //   show: true,
          //   message: err.response.data.data.value,
          //   icon: "fat face-eyes-xmarks",
          //   closable: true,
          //   button: "ÎNCHIDE"
          // });
          let errors = err.response.data.data;
          if (errors.password) {
            methods.setError("password", { type: "server", message: errors.password[0] });
          }
          if (errors.confirm_password) {
            methods.setError("confirm_password", { type: "server", message: errors.confirm_password[0] });
          }
          if(!errors.password && !errors.confirm_password) {
            setErrorMessage(err.response.data.data.value);
          }
          update("loading", { show: false });
        } else if (err.response.status === 404) {
          setErrorMessage(err.response.data.data.value);
        } else {
          update("error", { show: true, code: "A001" });
        }
      });
    update("loading", { show: true, modal: true });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <Box py={1}>
            <FormField
              name="password"
              type="password"
              defaultValue=""
              label="Parolă nouă"
              autoComplete="password"
              errorMessage={methods.formState.errors.password?.message}
              error={!!methods.formState.errors.password?.message}
            />
          </Box>
          <Box py={1}>
            <FormField
              name="confirm_password"
              type="password"
              label="Confirmă parola"
              errorMessage={methods.formState.errors.confirm_password?.message}
              error={!!methods.formState.errors.confirm_password?.message}
            />
          </Box>
          <Box
            sx={{
              mt: 1,
              mb: 3,
            }}
          >
            <Button type="submit" variant="contained">
              Schimbă parola
            </Button>
            <ErrorsList show={errorMessage !== ""}>{errorMessage}</ErrorsList>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default ChangePasswordForm;
