import Typography from "@mui/material/Typography";
import {APIGetAllClassrooms} from "api/pages/classrooms";
import FormField from "components/form/FormField";
import BoxContent from "components/ui/BoxContent";
import React, {useEffect, useState} from "react";
import {useAuth} from "../../../hooks/useAuth";
import Loading from "../../ui/Loading";
import Box from "@mui/material/Box";
import {getRomanNumber} from "../../../utils/functions";

const CreateTestClassroom = ({ errors, setClassroomHandler, classroom }: any) => {
    const { update } = useAuth();
    const[classrooms, setClassrooms] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true);
        APIGetAllClassrooms()
            .then((response) => {
                if (response.data.success) {
                    // console.log("getClassroom OK", response);
                    setClassrooms(
                        response.data.data.items.map((item: { name: string; id: string, grade: number }) => {
                            return {
                                label: `(${getRomanNumber(item.grade)}) ${item.name}`,
                                value: item.id,
                                ...item,
                            };
                        }),
                    );
                        setLoading(false);
                } else {
                    update("error", { show: true, code: "A002" });
                }
            })
            .catch((err) => {
                // console.log("getClassroom ERR", err);
                update("error", { show: true, code: "A001" });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <>
      <Typography variant="h3" mb={3} mt={3}>
        Alege clasa pentru care dorești generarea testului
      </Typography>
        {loading ? (
            <Loading show={true} />
        ) : classrooms.length === 0 ? (
            <Box>Nicio clasă introdusă în platformă.</Box>
        ) : (<BoxContent>
            <>
                <FormField
                    name="classroom"
                    type="autocomplete-controlled"
                    label="Alege clasa"
                    multiple={false}
                    options={classrooms}
                    errorMessage={errors.classroom?.message}
                    onChange={setClassroomHandler}
                    disableClearable={true}
                />
            </>
        </BoxContent>)}

    </>
  );
};

export default CreateTestClassroom;
