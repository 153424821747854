import axiosClient from "api/apiClient";

export const APIPostItems = (data: object) => {
  return axiosClient.post(`/trainings/items`, data);
};

export const APIPostItem = (data: object) => {
  return axiosClient.post(`/trainings/item`, data);
};

export const APICreateTestTraining = (data: object) => {
  return axiosClient.post("/trainings/create", data);
};
