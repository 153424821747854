import {Text, View} from "@react-pdf/renderer";
import {styles } from "../../StylesPdfReport";

const AboutSection = () => {
    return (
        <>
            <View style={styles.sectionAbout}>
                <View style={styles.subsection}>
                    <Text style={styles.h3}>Despre raport</Text>
                    <View style={styles.subsectionContent}>
                        <Text style={styles.p}>
                            Acest raport prezintă nivelul de alfabetizare digitală pentru elevul testat, pe fiecare componentă
                            evaluată a cadrului de competențe digitale al Comisiei Europene - DigComp 2.1, care definește 5
                            domenii de competență:
                        </Text>
                        <View style={{ flexDirection: "column", marginLeft: "12pt" }}>
                            <View style={{ flexDirection: "row", marginBottom: 5 }}>
                                <Text style={{ marginHorizontal: 8 }}>1.</Text>
                                <Text>Alfabetizarea în materie de informare și date</Text>
                            </View>
                            <View style={{ flexDirection: "row", marginBottom: 5 }}>
                                <Text style={{ marginHorizontal: 8 }}>2.</Text>
                                <Text>Comunicare și colaborare</Text>
                            </View>
                            <View style={{ flexDirection: "row", marginBottom: 5 }}>
                                <Text style={{ marginHorizontal: 8 }}>3.</Text>
                                <Text>Crearea de conținut digital</Text>
                            </View>
                            <View style={{ flexDirection: "row", marginBottom: 5 }}>
                                <Text style={{ marginHorizontal: 8 }}>4.</Text>
                                <Text>Siguranță</Text>
                            </View>
                            <View style={{ flexDirection: "row", marginBottom: 5 }}>
                                <Text style={{ marginHorizontal: 8 }}>5.</Text>
                                <Text>Rezolvarea problemelor</Text>
                            </View>
                        </View>
                        <Text style={styles.p}>
                            Testul completat se bazează pe BRIO®, sistemul standard de testare pentru accelerarea performanței în
                            educație.
                        </Text>
                        <Text style={styles.p}>
                            *Carretero, S.; Vuorikari, R. și Punie, Y. (2017). DigComp 2.1: Cadrul competențelor digitale pentru
                            cetățenii cu opt profi ¬ ciency niveluri și exemple de utilizare, EUR 28558 RO, doi:10.2760/38842.
                        </Text>
                    </View>
                </View>
                <View style={styles.subsection}>
                    <Text style={styles.h3}>Scopul raportului</Text>
                    <View style={styles.subsectionContent}>
                        <Text style={styles.p}>
                            Acest raport prezintă nivelul general de alfabetizare digitală al elevului testat, dar numai atunci
                            când sunt testate toate cele 5 competențe de la DigComp 2.1, care conține un total de 21 de competențe
                            digitale. Dacă se testează doar unele competențe, să spunem 2 din 5, punctajul general de alfabetizare
                            digitală reprezintă nivelul mediu/general de alfabetizare digitală al elevului doar în cazul
                            competențelor respective. De asemenea, în cadrul raportului, se poate găsi nivelul specific de
                            alfabetizare digitală al elevului testat cu privire la fiecare competență și abilitate în parte care
                            au fost testate.
                        </Text>
                        <Text style={styles.p}>
                            Pentru fiecare punctaj raportat, fie că este vorba de cel general sau de cele specifice legate de
                            fiecare competență și abilitate testată, raportul prezintă o descriere narativă a rezultatului.
                            Această descriere își propune să delimiteze ceea ce elevul testat știe și poate face, în materie de
                            alfabetizare digitală, și în cazul în care el / ea încă mai trebuie să învețe / să lucreze pentru
                            îmbunătățire.
                        </Text>
                        <Text style={styles.p}>
                            Pe lângă prezentarea nivelurilor generale și specifice de alfabetizare digitală a studenților, acest
                            raport își propune să sprijine studenții să se implice în activități de auto-reflecție și
                            auto-îmbunătățire, pentru a-și spori nivelul competenței digitale.
                        </Text>
                    </View>
                </View>
                <View style={styles.subsection}>
                    <Text style={styles.h3}>Utilizare</Text>
                    <View style={styles.subsectionContent}>
                        <Text style={styles.p}>
                            În primul rând, acest raport este destinat interpretării de către elevii care au dat testul. Ei își
                            pot vedea propriul nivel de alfabetizare digitală și scorurile, pot citi descrierile narative ale
                            scorurilor pentru a înțelege ce știu și ce pot face, și se pot angaja în acțiune (dacă doresc sau au
                            nevoie să-și îmbunătățească nivelul de alfabetizare digitală). În al doilea rând, acest raport poate
                            fi util pentru părinții/tutorii și profesorii elevului testat. Acesta poate informa lacunele elevului
                            în ceea ce privește competențele digitale și, prin urmare, locurile potrivite pentru intervenția și
                            sprijinul educațional.
                        </Text>
                        <Text style={styles.p}>
                            Trebuie remarcat faptul că nu toți studenții ar trebui să aibă performanțe ridicate atunci când vine
                            vorba de competențe digitale. Desigur, este de dorit ca fiecare elev să aibă un nivel funcțional de
                            alfabetizare digitală. În consecință, intervențiile educaționale și efortul pe care elevul va trebui
                            să îl urmeze pentru a-și spori abilitățile ar trebui armonizate cu obiectivele de învățare ale lor și
                            ale familiei, în ceea ce privește dezvoltarea competențelor digitale.
                        </Text>
                        <Text style={styles.p}>
                            Prin urmare, pe baza raportului, se poate stabili nivelul actual de cunoștințe și abilități digitale
                            ale elevului, se pot determina domeniile în care elevul evaluat ar trebui să-și canalizeze eforturile
                            de învățare și se pot lua decizii cu privire la intervențiile pedagogice necesare.
                        </Text>
                        <Text style={styles.p}>
                            În ciuda acurateței și sofisticării sistemului de testare BRIO, este recomandabil ca, pentru deciziile
                            cu impact ridicat asupra elevului, rezultatele raportului să fie luate în considerare împreună cu
                            informații suplimentare, cum ar fi notele școlare, opinia profesorului clasei sau a altui personal
                            didactic, intențiile și opiniile părinților, discuții cu elevul însuși. Prin coroborarea rezultatelor
                            obținute la testul de alfabetizare digitală cu informațiile primite de la toți actorii din jurul
                            elevului (profesori, părinți), se poate obține cea mai clară imagine posibilă a nivelului de
                            alfabetizare digitală al elevului.
                        </Text>
                    </View>
                </View>
            </View>
            <View style={styles.sectionAbout}>
                <View style={styles.subsection}>
                    <Text style={styles.h3}>Cum să citiți și să interpretați raportul?</Text>
                    <View style={styles.subsectionContent}>
                        <Text style={styles.p}>După această secțiune introductivă, raportul include următoarele secțiuni:</Text>
                        <View style={{ flexDirection: "column", marginLeft: "12pt" }}>
                            <View style={{ flexDirection: "row", marginBottom: 5 }}>
                                <Text style={{ marginHorizontal: 8 }}>1.</Text>
                                <View>
                                    <Text> Scorul general al alfabetizării digitale</Text>
                                    <Text style={styles.p}>
                                        {" "}
                                        În secțiunea generală a scorului de alfabetizare digitală , se poate consulta punctajul general
                                        rezultat din agregarea tuturor scorurilor specifice ale elevilor pe toate abilitățile și
                                        competențele pentru care au fost testați. Acest scor poate varia de la 0 (foarte slab) la 100
                                        (foarte competent). Cu cât scorul este mai mare, cu atât este mai mare nivelul de cunoștințe,
                                        abilități și atitudini digitale.
                                    </Text>
                                    <Text style={styles.p}>
                                        {" "}
                                        Scorul BRIO® este un scor centilă: arată cum elevul testat se compară cu toți ceilalți elevi din
                                        Europa care sunt în același an școlar cu ei. De exemplu, pentru un elev care se află în a 70-a
                                        percentilă (a obținut un scor BRIO® de 70) vom spune că 30% dintre elevii din Europa (din acea
                                        vârstă sau clasă) au performanțe mai bune decât el, în timp ce 70% au performanțe mai slabe sau
                                        egale cu el. În același timp, un elev care se află în percentila 90 (BRIO® scor 90) are doar 10%
                                        din populația școlară comparabilă cu performanțe mai bune decât ei, iar 90% au performanțe mai
                                        slabe - acesta este un scor foarte mare.
                                    </Text>
                                    <Text style={styles.p}>
                                        Pe lângă punctaj, în această secțiune se poate vedea și în ce categorie de performanță se
                                        încadrează elevul testat. Categoriile de spectacole sunt următoarele: Fundație – punctaje intre
                                        0 si 35; Intermediar – scoruri între 36 și 65; Avansat – scoruri între 66 și 95; și Foarte
                                        specializat – scoruri între 95 și 100. Categoria de performanță în care se încadrează punctajul
                                        elevului testat este evidențiată și conține descrieri generale a ceea ce elevul știe și poate
                                        face.
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row", marginBottom: 5 }}>
                                <Text style={{ marginHorizontal: 8 }}>2.</Text>
                                <View>
                                    <Text>Profilul competențelor digitale</Text>
                                    <Text style={styles.p}>
                                        Această secțiune prezintă, sub formă de profil, punctajele și plasarea acestora în categorii de
                                        performanță pe fiecare competență digitală și abilitate testată. Punctajele pentru fiecare
                                        competență variază între 0 și 100 și permit o observare generală a punctelor mai slabe și a
                                        punctelor mai puternice pentru elevul testat.
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row", marginBottom: 5 }}>
                                <Text style={{ marginHorizontal: 8 }}>3.</Text>
                                <View>
                                    <Text> Scorul competențelor digitale specifice</Text>
                                    <Text style={styles.p}>
                                        În această secțiune, rezultatele elevului pentru fiecare competență și abilitate sunt analizate
                                        unul câte unul. Astfel, se explică ce anume s-a măsurat în cadrul fiecărei abilități, care este
                                        punctajul obținut de elevul testat și care este semnificația acestui punctaj. Informațiile pot
                                        fi folosite de elevi, părinți și profesori pentru a înțelege în detaliu ce cunoștințe și
                                        abilități are elevul, dar și ce cunoștințe și abilități îi lipsesc (conform cadrului DigComp
                                        2.1).
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row", marginBottom: 5 }}>
                                <Text style={{ marginHorizontal: 8 }}>4.</Text>
                                <View>
                                    <Text> Plan de acțiune pentru îmbunătățirea competențelor digitale</Text>
                                    <Text style={styles.p}>
                                        {" "}
                                        Această secțiune invită elevii să reflecteze asupra nivelurilor lor de competențe digitale și să
                                        stabilească un plan de acțiune pentru îmbunătățirea competențelor specifice pe care le doresc
                                        sau de care au nevoie. Pentru aceasta, se explică importanța stabilirii obiectivelor, se
                                        recomandă unele etape de acțiune și sunt puse la dispoziția exerciții legate de stabilirea
                                        obiectivelor.
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        </>
    )
}

export default AboutSection;