import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import Icon from "components/ui/Icon";
import CircularProgress from "@mui/material/CircularProgress";
import { BehaviorSubject, of, merge } from "rxjs";
import { debounceTime, map, distinctUntilChanged, filter, switchMap, catchError } from "rxjs/operators";
import { IOptions } from "api/pages/teachers";
import { AxiosResponse } from "axios";
import {AutocompleteTextFieldStyled} from "./AutocompleteAPI";

const PaperStyled = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(0, 0, 1, 1),
  boxShadow: "none",
}));

interface Option {
  value: string | number;
  label: string;
  [key: string]: any;
}
interface Props {
  name: string;
  defaultValue?: string | number | any[] | undefined;
  label?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  error?: string | undefined;
  className?: string;
  options?: Option[];
  multiple?: boolean;
  onInputChange?: (e: any) => void;
  onChange?: (event: any, value: any) => void;
  optionsAPI: (options: IOptions) => Promise<AxiosResponse<any, any>>;
  filterOptions?: any;
  loading?: boolean;
  value?: string | object | undefined;
}

const AutocompleteAPIField = ({
  name,
  defaultValue,
  label,
  optionsAPI,
  multiple = false,
  filterOptions,
  onChange,
  value,
}: Props) => {
  // RXJS autocomplete query
  const [state, setState] = useState({
    data: [],
    loading: false,
    errorMessage: "",
    noResults: false,
  });

  const [subject, setSubject] = useState<BehaviorSubject<string> | null>(null);

  useEffect(() => {
    if (subject === null) {
      const sub = new BehaviorSubject("");
      setSubject(sub);
    } else {
      const observable = subject
        .pipe(
          map((s) => s.trim()),
          distinctUntilChanged(),
          filter((s) => s.length >= 3),
          debounceTime(200),
          switchMap((term) =>
            merge(
              of({ loading: true, errorMessage: "", noResults: false }),
              optionsAPI({ page: "?page=$1", search: term }).then(async (response) => {
                if (response.data.success) {
                  console.log("getTeachers OK", response);
                  const data = await response.data.data.data;
                  return { data, loading: false, noResults: data.length === 0 };
                } else {
                  return {
                    data: [],
                    loading: false,
                    errorMessage:
                      "Din păcate a intervenit o eroare la comunicarea cu serverul. Vă rugăm încercați mai târziu. Dacă problema persistă, ne puteți contacta la contact@brio.ro.",
                  };
                }
              }),
            ),
          ),
          catchError(async (e) => ({
            loading: false,
            errorMessage:
              "Din păcate, serverul a trimis un mesaj de eroare. Vă rugăm încercați mai târziu. Dacă problema persistă, ne puteți contacta la contact@brio.ro.",
          })),
        )
        .subscribe((newState) => {
          setState((s) => Object.assign({}, s, newState));
        });

      return () => {
        // console.log("unsubscribed!!!");
        observable.unsubscribe();
        subject.unsubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject]);

  const onInputChange = (data: string) => {
    // console.log("onInputChange", e.target);
    if (subject) {
      return subject.next(data);
    }
  };

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={
        state.data &&
        state.data.map((item: any) => {
          if (item.first_name && item.last_name) {
            return {
              label: `${item.first_name} ${item.last_name}`,
              value: item.id,
              ...item,
            };
          } else {
            return {
              label: item.name,
              value: item.id,
              ...item,
            };
          }
        })
      }
      blurOnSelect={true}
      getOptionLabel={(option) => option.label}
      // popupIcon={
      //   <Box sx={{ fontSize: "1.25rem" }} color="secondary.light">
      //     <Icon icon="caret-down" fixedWidth={true} />
      //   </Box>
      // }
      clearIcon={
        <Box sx={{ fontSize: "1.25rem" }} color="secondary.light">
          <Icon icon="xmark" fixedWidth={true} />
        </Box>
      }
      PaperComponent={PaperStyled}
      noOptionsText={state.errorMessage ? state.errorMessage : "Nu sunt rezultate"}
      renderOption={(props, option) => {
        return (
            <li {...props} key={option.value}>
              {option.label}
            </li>
        );
      }}
      renderInput={(params) => (
        <AutocompleteTextFieldStyled
          {...params}
          label={label}
          variant="filled"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      onChange={onChange}
      onInputChange={(_, data) => onInputChange(data)}
      filterOptions={filterOptions}
      multiple={multiple}
      loading={state.loading}
      //   value={value}
      defaultValue={defaultValue}
      freeSolo
    />
  );
};

export default AutocompleteAPIField;
