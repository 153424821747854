import Typography from "@mui/material/Typography";
import FormField from "components/form/FormField";
import BoxContent from "components/ui/BoxContent";

const CreateTestItemsNumber = ({ errors, itemsNumber, handleSelectChange }: any) => {
  return (
    <>
      <Typography variant="h3" mb={3} mt={3}>
        Alege numărul de itemi
      </Typography>
      <BoxContent>
        <FormField
          name="items_number"
          type="controlled-select"
          label="Alege numărul de itemi"
          value={itemsNumber}
          onChange={handleSelectChange}
          options={[
            {
              label: "5",
              value: 5,
            },
            {
              label: "10",
              value: 10,
            },
            {
              label: "15",
              value: 15,
            },
            {
              label: "20",
              value: 20,
            },
          ]}
          errorMessage={errors.items_number?.message}
        />
      </BoxContent>
    </>
  );
};

export default CreateTestItemsNumber;
