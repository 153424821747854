import { useOutlet } from "react-router-dom";

import {defaultUser, useAuth} from "hooks/useAuth";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { replaceDevUrl } from "utils/functions";
import Typography from "@mui/material/Typography";
import BrioLogoFooter from "components/sidebar/BrioLogoFooter";
import {useEffect} from "react";

const LayoutUnverified = () => {
  const { update, user } = useAuth();
  const outlet = useOutlet();

    useEffect(() => {
        update("user", { theme: user.theme }, true);
        update("cart", defaultUser.cart);
        update("tenant", defaultUser.tenant);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <Box component="main" display="flex" alignItems="stretch" sx={{ minHeight: "100dvh", padding: { md: "40px" } }}>
      <Grid
        display="grid"
        container
        direction="row"
        spacing={0}
        sx={{
          borderRadius: { md: 3 },
          background: "rgba(0, 40, 155, 0.4) no-repeat center",
          backgroundImage: `url(${replaceDevUrl("/images/background-brio.jpeg")})`,
          backgroundSize: "cover",
          backgroundBlendMode: "soft-light",
          position: "relative",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          xl={3}
          bgcolor="extra.sidebar_login"
          position="relative"
          sx={(theme) => ({
            backdropFilter: "blur(10px)",
            padding: { xs: theme.spacing(5, 2), md: theme.spacing(5, 3) },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            borderRadius: { xs: 2, md: theme.spacing(3, 0, 0, 3) },
            margin: { xs: 2, md: 0 },
          })}
        >
          {/* <Box sx={{ position: "absolute", top: "11px", right: "13px", display: { xs: "block", md: "none" } }}>
            <ThemeToggle />
          </Box> */}

          <Box mb={4} sx={{ textAlign: { xs: "center", md: "left" } }}>
            <Box
              display="flex"
              width="100%"
              alignItems="flex-end"
              mb={2}
              sx={{ position: "relative", paddingLeft: { xs: 0, md: 2 } }}
            >
              <Box flexGrow={1}>
                <Typography variant="h1" sx={{ lineHeight: 1 }}>
                  TESTARE
                </Typography>
                <Typography variant="h5" sx={{ lineHeight: 1 }}>
                  STANDARDIZATĂ
                </Typography>
              </Box>
              {/* <Box sx={{ position: "relative", bottom: -9, right: -8, display: { xs: "none", md: "block" } }}>
                <ThemeToggle />
              </Box> */}
            </Box>
          </Box>
          {outlet}
          <Box sx={{ ml: { md: 2 } }}>{<BrioLogoFooter />}</Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LayoutUnverified;
