import { useEffect } from "react";
import { useAuth } from "hooks/useAuth";

const Logout = () => {
  const { logout } = useAuth();
  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default Logout;
