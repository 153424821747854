import Button, { ButtonProps } from "@mui/material/Button";
import Icon from "./Icon";
import { styled } from "@mui/material/styles";
import CustomButton from "./CustomButton";
import React from "react";

interface Props {
  type: string;
  [x: string]: any;
}

const StyledButton = styled((props: ButtonProps) => <Button {...props} />)(({ theme }) => ({
  padding: "4px 17px",
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.primary.light,
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.contrastText,
  },
}));

const GenericButton = (props: Props) => {
  const { type, ...rest } = props;

  return (
    <>
      {type === "add" && (
          <CustomButton
              {...rest}
              buttonType="textIcon"
              icon={<Icon icon="plus" fixedWidth={true} sx={{ fontSize: "1.25rem" }} />}
          >
            Adaugă
          </CustomButton>
          // <Button
          //     {...rest}
          //     variant="contained"
          //     size="medium"
          //     startIcon={<Icon icon="plus" fixedWidth={true} sx={{ fontSize: "1.25rem" }} />}
          // >
          //   Adaugă
          // </Button>
      )}

      {type === "edit" && (
        <CustomButton
          {...rest}
          buttonType="textIcon"
          size="medium"
          icon={<Icon icon="far pen-to-square" fixedWidth={true} sx={{ fontSize: "1.25rem" }} />}
        >
          Editează
        </CustomButton>
      )}

      {type === "search" && (
          <>
          <Button
              {...rest}
              type="submit"
              variant="contained"
              size="large"
              startIcon={<Icon icon="far magnifying-glass" fixedWidth={true} sx={{ fontSize: "1.25rem" }}  />}
              sx={{display: { xs: "none", md: "inline-flex" }}}
          >
            Caută
          </Button>
        <Button
      {...rest}
        type="submit"
        variant="contained"
        size="medium"
        startIcon={<Icon icon="far magnifying-glass" fixedWidth={true} sx={{ fontSize: "1.25rem" }}  />}
        sx={{display: { xs: "inline-flex", md: "none" }}}
        >
        Caută
        </Button>
          </>
      )}

      {type === "close" && (
          <CustomButton
              {...rest}
              buttonType="textIcon"
              bgcolor="error"
              icon={<Icon icon="far xmark" fixedWidth={true} sx={{ fontSize: "1.25rem" }} />}
          >
            Închide
          </CustomButton>
        // <Button
        //   {...rest}
        //   variant="contained"
        //   size="medium"
        //   color="error"
        //   startIcon={<Icon icon="far xmark" fixedWidth={true} sx={{ fontSize: "1.25rem" }} />}
        // >
        //   Închide
        // </Button>
      )}

      {type === "start" && (
        <CustomButton
          {...rest}
          buttonType="textIconSmall"
          icon={<Icon icon="far play" fixedWidth={true} sx={{ fontSize: "1rem" }} />}
        >
          Start test
        </CustomButton>
      )}

      {type === "report" && (
        <CustomButton
          {...rest}
          buttonType="textIconSmall"
          bgcolor="table"
          icon={<Icon icon="far chart-line-up" fixedWidth={true} sx={{ fontSize: "1rem" }} />}
        >
          Raport
        </CustomButton>
      )}

      {type === "answers" && (
        <CustomButton
          {...rest}
          buttonType="textIconSmall"
          bgcolor="table"
          icon={<Icon icon="far list-check" fixedWidth={true} sx={{ fontSize: "1rem" }} />}
        >
          Răspunsuri
        </CustomButton>
      )}

      {type === "items" && (
        <CustomButton
          {...rest}
          buttonType="textIconSmall"
          bgcolor="table"
          icon={<Icon icon="far eye" fixedWidth={true} sx={{ fontSize: "1rem" }} />}
        >
          Previzualizare
        </CustomButton>
      )}

      {type === "delete" && (
        <CustomButton
          {...rest}
          buttonType="textIconSmall"
          bgcolor="table"
          icon={<Icon icon="far trash-can" fixedWidth={true} sx={{ fontSize: "1rem" }} />}
        >
          Șterge
        </CustomButton>
      )}

      {type === "archive" && (
          <CustomButton
              {...rest}
              buttonType="textIconSmall"
              bgcolor="table"
              icon={<Icon icon="far box-archive" fixedWidth={true} sx={{ fontSize: "1rem" }} />}
          >
            Arhivează
          </CustomButton>
      )}

      {type === "delete-subscription" && (
          <StyledButton
              {...rest}
              variant="contained"
              size="small"
              startIcon={<Icon icon="far trash-can" fixedWidth={true} sx={{ fontSize: "1rem" }} />}
          >
            Renunță la abonament
          </StyledButton>
      )}

      {type === "delete-extra" && (
          <StyledButton
              {...rest}
              variant="contained"
              size="small"
              startIcon={<Icon icon="far trash-can" fixedWidth={true} sx={{ fontSize: "1rem" }} />}
          >
            Renunță la extraopțiune
          </StyledButton>
      )}

      {type === "edit-small" && (
        <CustomButton
          {...rest}
          buttonType="textIconSmall"
          bgcolor="table"
          icon={<Icon icon="far pen-to-square" fixedWidth={true} sx={{ fontSize: "1rem" }} />}
        >
          Editează
        </CustomButton>
      )}

      {type === "switch-user" && (
          <CustomButton
              {...rest}
              buttonType="textIconSmall"
              bgcolor="table"
              icon={<Icon icon="far pen-to-square" fixedWidth={true} sx={{ fontSize: "1rem" }} />}
          >
            Intră în cont
          </CustomButton>
      )}

      {type === "add-teacher" && (
        <StyledButton
          {...rest}
          variant="contained"
          size="small"
          startIcon={<Icon icon="far chalkboard-user" fixedWidth={true} sx={{ fontSize: "1rem" }} />}
        >
          Adaugă profesori
        </StyledButton>
      )}

      {type === "add-classrooms" && (
        <StyledButton
          {...rest}
          variant="contained"
          size="small"
          startIcon={<Icon icon="far screen-users" fixedWidth={true} sx={{ fontSize: "1rem" }} />}
        >
          Adaugă clasă
        </StyledButton>
      )}

      {type === "replace" && (
        <StyledButton
          {...rest}
          variant="contained"
          size="small"
          startIcon={<Icon icon="far arrows-rotate" fixedWidth={true} sx={{ fontSize: "1rem" }} />}
        >
          Înlocuiește item
        </StyledButton>
      )}

      {type === "export" && (
          <CustomButton
              {...rest}
              buttonType="textIcon"
              size="medium"
              icon={<Icon icon="far pen-to-square" fixedWidth={true} sx={{ fontSize: "1.25rem" }} />}
          >
            Generează raport
          </CustomButton>
      )}
    </>
  );
};

export default GenericButton;
