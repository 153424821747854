import {styles} from "../../StylesPDFSchoolReport";
import {Text, View} from "@react-pdf/renderer";
import React from "react";

const Section1 = ({subject, table2, totalTests}: any) => {
    const tableRowsT1 = [
        {name: "Clasa", id: "name"},
        {name: "", id: "count"},
    ];
    const tableRowsT3 = [
        {name: "Clasa", id: "name"},
        {name: "", id: "percent"},
    ];

    return (
        <View style={styles.sectionAbout}>
            <Text style={styles.h1}>SECȚIUNEA 1: Surse ale datelor / participarea la testare</Text>
            <View style={styles.tableSubsection} wrap={false}>
                <Text style={styles.tableTitle}>Tabelul 1. Distribuția elevilor pe clase, obținută în urma testării</Text>
                <View style={styles.chartTable}>
                    <View style={styles.gradesRowHeader}>
                        <Text style={styles.colValuesStart} key={0}>Clasa</Text>
                        <Text style={[styles.colValuesEnd, styles.colValuerNumeric]} key={1}>{subject}</Text>
                    </View>
                    {table2.map((item: any, index: any) => (
                        <View style={[index%2 === 0 ? styles.gradesRowBodyOdd : styles.gradesRowBody]} key={index}>
                            {tableRowsT1.map((row, index: number) => (
                                <Text style={[row.id !== "name" ? styles.colValuerNumeric : styles.colValues, row.id === "name" ? styles.colValuesStart : row.id === "count" ? styles.colValuesEnd : styles.colValues ]} key={index}>{item[row.id]}</Text>
                            ))}
                        </View>
                    ))}
                    <View style={[table2.length%2 === 0 ? styles.gradesRowFooterOdd : styles.gradesRowFooter]}>
                        <Text style={styles.colValuesStart} key={0}>TOTAL</Text>
                        <Text style={[styles.colValuesEnd, styles.colValuerNumeric]} key={1}>{totalTests}</Text>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default Section1;