const ChartNoteWrapperSmall = ({ score, scoreDescription, type}: any) => {
    return (
        <>
            {type === "school" && (
                <div className="chart-note-wrapper">
                    {score >= 0 && score <= 10 && (
                        <div className="chart-note insuficient">
                            <div className="border"></div>
                            <div className="name">Insuficient</div>
                            <div className="values">0–10</div>
                            <div className="next-upgrade">
                                <img src="/images/reports/arrow-right.png" className="arrow-right" alt="" />
                                <div className="upgrade-name">Satisfăcător</div>
                                {Array.isArray(scoreDescription) &&
                                    scoreDescription.map((scoreDescription: any, index: any) => (
                                        <div key={index}>
                                            {scoreDescription.study_time !== null && score < 95 && <>{scoreDescription.study_time} ore</>}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}
                    {score > 30 && score <= 60 && (
                        <div className="chart-note intermediar">
                            <div className="border"></div>
                            <div className="name">Intermediar</div>
                            <div className="values">30–60</div>
                            <div className="next-upgrade">
                                <img src="/images/reports/arrow-right.png" className="arrow-right" alt="" />
                                <div className="upgrade-name">Competent</div>
                                {Array.isArray(scoreDescription) &&
                                    scoreDescription.map((scoreDescription: any, index: any) => (
                                        <div key={index}>
                                            {scoreDescription.study_time !== null && score < 95 && <>{scoreDescription.study_time} ore</>}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}
                    {score > 80 && score <= 95 && (
                        <div className="chart-note avansat">
                            <div className="border"></div>
                            <div className="name">Avansat</div>
                            <div className="values">80–95</div>
                            <div className="next-upgrade">
                                <img src="/images/reports/arrow-right.png" className="arrow-right" alt="" />
                                <div className="upgrade-name">Expert</div>
                                {Array.isArray(scoreDescription) &&
                                    scoreDescription.map((scoreDescription: any, index: any) => (
                                        <div key={index}>
                                            {scoreDescription.study_time !== null && score < 95 && <>{scoreDescription.study_time} ore</>}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}
                    {score > 10 && score <= 30 && (
                        <div className="chart-note satisfacator">
                            <div className="border"></div>
                            <div className="name">Satisfăcător</div>
                            <div className="values">10–30</div>
                            <div className="next-upgrade">
                                <img src="/images/reports/arrow-right.png" className="arrow-right" alt="" />
                                <div className="upgrade-name">Intermediar</div>
                                {Array.isArray(scoreDescription) &&
                                    scoreDescription.map((scoreDescription: any, index: any) => (
                                        <div key={index}>
                                            {scoreDescription.study_time !== null && score < 95 && <>{scoreDescription.study_time} ore</>}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}
                    {score > 60 && score <= 80 && (
                        <div className="chart-note competent">
                            <div className="border"></div>
                            <div className="name">Competent</div>
                            <div className="values">60–80</div>
                            <div className="next-upgrade">
                                <img src="/images/reports/arrow-right.png" className="arrow-right" alt="" />
                                <div className="upgrade-name">Avansat</div>
                                {Array.isArray(scoreDescription) &&
                                    scoreDescription.map((scoreDescription: any, index: any) => (
                                        <div key={index}>
                                            {scoreDescription.study_time !== null && score < 95 && <>{scoreDescription.study_time} ore</>}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}
                    {score > 95 && score <= 100 && (
                        <div className="chart-note expert">
                            <div className="border"></div>
                            <div className="name">Expert</div>
                            <div className="values">95–100</div>
                        </div>
                    )}
                </div>
            )}
            {type === "digitalLiteracy" && (
                <div className="chart-note-wrapper">
                    {score >= 0 && score < 35 && (
                        <div className="chart-note nivel-baza-ld">
                            <div className="border"></div>
                            <div className="name">Nivel de bază</div>
                            <div className="values">0–35</div>
                            <div className="next-upgrade">
                                <img src="/images/reports/arrow-right.png" className="arrow-right" alt="" />
                                <div className="upgrade-name">Intermediar</div>
                                {Array.isArray(scoreDescription) &&
                                    scoreDescription.map((scoreDescription: any, index: any) => (
                                        <div key={index}>{scoreDescription.study_time !== null && <>{scoreDescription.study_time} ore</>}</div>
                                    ))}
                            </div>
                        </div>
                    )}
                    {score >= 35 && score < 65 && (
                        <div className="chart-note intermediar-ld">
                            <div className="border"></div>
                            <div className="name">Intermediar</div>
                            <div className="values">35–65</div>
                            <div className="next-upgrade">
                                <img src="/images/reports/arrow-right.png" className="arrow-right" alt="" />
                                <div className="upgrade-name">Avansat</div>
                                {Array.isArray(scoreDescription) &&
                                    scoreDescription.map((scoreDescription: any, index: any) => (
                                        <div key={index}>{scoreDescription.study_time !== null && <>{scoreDescription.study_time} ore</>}</div>
                                    ))}
                            </div>
                        </div>
                    )}
                    {score >= 65 && score < 95 && (
                        <div className="chart-note avansat-ld">
                            <div className="border"></div>
                            <div className="name">Avansat</div>
                            <div className="values">65–95</div>
                            <div className="next-upgrade">
                                <img src="/images/reports/arrow-right.png" className="arrow-right" alt="" />
                                <div className="upgrade-name">Specializat</div>
                                {Array.isArray(scoreDescription) &&
                                    scoreDescription.map((scoreDescription: any, index: any) => (
                                        <div key={index}>{scoreDescription.study_time !== null && <>{scoreDescription.study_time} ore</>}</div>
                                    ))}
                            </div>
                        </div>
                    )}
                    {score >= 95 && (
                        <div className="chart-note specializat-ld">
                            <div className="border"></div>
                            <div className="name">Specializat</div>
                            <div className="values">95–100</div>
                        </div>
                    )}
                </div>
            )}
        </>

    );
};

export default ChartNoteWrapperSmall;
