export const activeBacTestsPrincipalsTableColumns = [
    {
      id: "test",
      label: "Test",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
    id: "test_grade",
    label: "Tip",
    numeric: false,
    disablePadding: false,
    sortable: true,
    },
    {
      id: "test_tag",
      label: "Tag",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
    id: "owner_name",
    label: "Profesor",
    numeric: false,
    disablePadding: false,
    sortable: true,
    },
    {
    id: "user_name",
    label: "Elev",
    numeric: false,
    disablePadding: false,
    sortable: true,
    noWrap: true,
    },
    {
    id: "classroom_name",
    label: "Clasă",
    numeric: false,
    disablePadding: false,
    noWrap: true,
    sortable: true,
    },
    {
    id: "test_is_open",
    label: "Status",
    numeric: false,
    disablePadding: false,
    sortable: true,
    },
    {
    id: "test_created_at",
    label: "Creat",
    numeric: false,
    disablePadding: false,
    sortable: true,
    },
    {
    id: "test_expires_at",
    label: "Expiră",
    numeric: false,
    disablePadding: false,
    sortable: true,
      noWrap: true,
    },
    {
    id: "actions",
    label: "Acțiuni",
    numeric: true,
    disablePadding: true,
    noWrap: false,
    },
];
  
export const activeBacTestsTeachersTableColumns = [
  {
    id: "test",
    label: "Test",
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
      id: "test_grade",
      label: "Tip",
      numeric: false,
      disablePadding: false,
      sortable: true,
  },
  {
    id: "test_tag",
    label: "Tag",
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
    id: "user_name",
    label: "Elev",
    numeric: false,
    disablePadding: false,
    sortable: true,
    noWrap: true,
  },
  {
    id: "classroom_name",
    label: "Clasă",
    numeric: false,
    disablePadding: false,
    noWrap: true,
    sortable: true,
  },
  {
    id: "status",
    label: "Status",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "test_created_at",
    label: "Creat",
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
    id: "test_expires_at",
    label: "Expiră",
    numeric: false,
    disablePadding: false,
    sortable: true,
    noWrap: true,
  },
  {
    id: "actions",
    label: "Acțiuni",
    numeric: true,
    disablePadding: false,
    noWrap: false,
  },
];
  
export const activeBacTestsStudentsTableColumns = [
  {
    id: "test",
    label: "Test",
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
    id: "test_grade",
    label: "Tip",
    numeric: false,
    disablePadding: false,
    sortable: true,
    },
  {
    id: "owner_name",
    label: "Profesor",
    numeric: false,
    disablePadding: false,
    sortable: true,
    noWrap: true,
  },
  {
    id: "classroom_name",
    label: "Clasă",
    numeric: false,
    disablePadding: false,
    noWrap: true,
    sortable: true,
  },
  {
    id: "status",
    label: "Status",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "test_created_at",
    label: "Creat",
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
    id: "test_expires_at",
    label: "Expiră",
    numeric: false,
    disablePadding: false,
    sortable: true,
    noWrap: true,
  },
  {
    id: "actions",
    label: "Acțiuni",
    numeric: true,
    disablePadding: true,
    noWrap: false,
  },
];
  
export const historyBacTestsPrincipalsTableColumns = [
  {
    id: "test",
    label: "Test",
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
    id: "test_grade",
    label: "Tip",
    numeric: false,
    disablePadding: false,
    sortable: true,
    },
  {
    id: "test_tag",
    label: "Tag",
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
    id: "owner_name",
    label: "Profesor",
    numeric: false,
    disablePadding: false,
    sortable: true,
    noWrap: true,
  },
  {
    id: "user_name",
    label: "Elev",
    numeric: false,
    disablePadding: false,
    sortable: true,
    noWrap: true,
  },
  {
    id: "classroom_name",
    label: "Clasă",
    numeric: false,
    disablePadding: false,
    noWrap: true,
    sortable: true,
  },
  {
    id: "test_score",
    label: "Scor",
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
    id: "test_time",
    label: "Timp",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "responses",
    label: "Răspunsuri",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "test_started_at",
    label: "Dată",
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
    id: "actions",
    label: "Acțiuni",
    numeric: true,
    disablePadding: true,
    noWrap: false,
  },
];
  
export const historyBacTestsTeachersTableColumns = [
  {
    id: "test",
    label: "Test",
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
    id: "test_grade",
    label: "Tip",
    numeric: false,
    disablePadding: false,
    sortable: true,
    },
  {
    id: "test_tag",
    label: "Tag",
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
    id: "user_name",
    label: "Elev",
    numeric: false,
    disablePadding: false,
    sortable: true,
    noWrap: true,
  },
  {
    id: "classroom_name",
    label: "Clasă",
    numeric: false,
    disablePadding: false,
    noWrap: true,
    sortable: true,
  },
  {
    id: "test_score",
    label: "Scor",
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
    id: "test_time",
    label: "Timp",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "responses",
    label: "Răspunsuri",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "test_started_at",
    label: "Dată",
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
    id: "actions",
    label: "Acțiuni",
    numeric: true,
    disablePadding: true,
    noWrap: false,
  },
];
  
export const historyBacTestsStudentsTableColumns = [
  {
    id: "test",
    label: "Test",
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
    id: "test_grade",
    label: "Tip",
    numeric: false,
    disablePadding: false,
    sortable: true,
    },
  {
    id: "owner_name",
    label: "Profesor",
    numeric: false,
    disablePadding: false,
    sortable: true,
    noWrap: true,
  },
  {
    id: "classroom_name",
    label: "Clasă",
    numeric: false,
    disablePadding: false,
    noWrap: true,
    sortable: true,
  },
  {
    id: "test_score",
    label: "Scor",
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
    id: "test_time",
    label: "Timp",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "responses",
    label: "Răspunsuri",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "test_started_at",
    label: "Dată",
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
  {
    id: "actions",
    label: "Acțiuni",
    numeric: true,
    disablePadding: true,
    noWrap: false,
  },
  ];
  