import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DashboardBoxList from "components/dashboard/DashboardBoxList";
import DashboardBoxNumbers from "components/dashboard//DashboardBoxNumbers";
import DashboardBoxPieChart from "components/dashboard//DashboardBoxPieChart";
import Icon from "components/ui/Icon";
import CustomTable from "components/ui/CustomTable";
import Loading from "components/ui/Loading";
import PieChart from "components/ui/PieChart";
import { IDashboardBox } from "components/dashboard/_interfaces";
import DropDown from "components/ui/DropDown";
import CustomChart from "components/ui/CustomChart";
import BoxContent from "components/ui/BoxContent";
import Button from "@mui/material/Button";

const DashboardBox = ({
  type = "numbers",
  title,
  icon,
  number,
  options,
  dataText,
  dataTable,
  dataChart,
  loading = false,
  children,
  borderSuccess = false,
  button = "",
  onClick,
  actions = null,
  noDataText = ""
}: IDashboardBox) => {
  return (
    <BoxContent pt={2} borderSuccess={borderSuccess} dashboardBox={true}>
      <Box width="100%" display="flex" pb={2} alignItems="center" >
        <Box display="flex" alignItems="center" sx={{ minHeight: 28, width: "inherit" }}>
          <Box
            color={"primary.light"}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              position: "absolute",
              left: 0,
              top: 20,
              width: 28,
              height: 28,
              borderRadius: 0.6,
              backgroundColor: "background.default",
              transform: "translateX(-50%)",
            }}
          >
            <Icon icon={icon} fixedWidth={true} />
          </Box>
          <Typography variant="h4">
            {title}
          </Typography>
        </Box>
        {type === "pie-chart" ? (
          <Box sx={{ ml: "auto" }}>
            <Box sx={{ position: "relative", height: 28, top: -5 }}>
              <PieChart amount={number} size="big" />
            </Box>
          </Box>
        ) : (
          <Box sx={{ marginLeft: "auto" }}>
            <Typography variant="subtitle1">{number !== 0 && number}</Typography>
          </Box>
        )}
        {options && (
          <Box ml={1}>
            <DropDown title={title ? title : ""} data={options} />
          </Box>
        )}
        {actions && <Box>{actions}</Box>}
      </Box>
      <Box
        flexGrow={1}
        sx={(theme) => ({
          mx: type === "table" ? -3 : "auto",
          width: type === "table" ? `calc(100% + ${theme.spacing(3 * 2)})` : "100%",
          // display: type === "table" ? "flex" : "block",
        })}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {loading ? (
          <Loading show={true} />
        ) : (
          <>
            {type === "numbers" && <DashboardBoxNumbers data={dataText} noDataText={noDataText} />}
            {type === "list" && (
              <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="space-between" >
                <DashboardBoxList data={dataText} noDataText={noDataText} />
                {button && onClick && (
                  <Box>
                    <Button onClick={onClick} variant="contained" sx={{ marginTop: 3 }}>
                      {button}
                    </Button>
                  </Box>
                )}
              </Box>
            )}
            {type === "chart" && (
              <Box flexGrow={1} sx={{ mx: { xs: -3, sm: 0 }, width: "100%" }}>
                <CustomChart series={dataChart} />
              </Box>
            )}
            {type === "pie-chart" && <DashboardBoxPieChart data={dataText} />}
            {type === "table" && <CustomTable data={dataTable} />}
            {type === "jsx" && (
              <Box
                width="100%"
                height="100%"
                textAlign="left"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                {children}
                {button && onClick && (
                  <Box>
                    <Button onClick={onClick} variant="contained" sx={{ marginTop: 3 }}>
                      {button}
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </>
        )}
      </Box>
    </BoxContent>
  );
};

export default DashboardBox;
