import Box from "@mui/material/Box";
import CustomButton from "components/ui/CustomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// @ts-ignore
import { faMagnifyingGlass } from '@awesome.me/kit-b8aac00bfe/icons/classic/solid';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormField from "components/form/FormField";

export interface IFormInputs {
  keywords: string;
}

interface ISearchForm {
  onSubmit: (data: any) => void;
  query: string;
  onChange: (e: any) => void;
  onClick: () => void;
}

const schema = yup.object({
  keywords: yup.string(),
});

const SearchForm = ({ onSubmit, query, onChange, onClick }: ISearchForm) => {
  const methods = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <Box
          sx={(theme) => ({
            padding: theme.spacing(0, 0.6),
            // marginRight: -0.6,
            borderRadius: theme.spacing(0.6),
          })}
          display="flex"
          alignItems="center"
          bgcolor="secondary.contrastText"
        >
          <Box flexGrow={1}>
            <FormField
              name="keywords"
              type="text-icon"
              label="Caută..."
              autoComplete = "off"
              errorMessage={methods.formState.errors.keywords?.message}
              className="dark"
              defaultValue={query}
              value={query}
              onChange={onChange}
              onClick={() => {
                onClick();
                methods.reset();
              }}
            />
          </Box>
          <Box>
            <CustomButton
                buttonType="icon"
              submit={true}
              icon={<FontAwesomeIcon style={{ fontSize: "1rem" }} icon={faMagnifyingGlass} />}
            />
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

export default SearchForm;
