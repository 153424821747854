import axiosClient from "../apiClient";

interface IOptions {
    id?: string;
    page?: string;
    sort?: string;
    order?: string;
    search?: string;
}

export const APIGetTags = (options: IOptions) => {
    let sort = "";
    let search = "";
    if (options.hasOwnProperty("sort")) {
        if(options.order === "asc") {
            sort = "&sort=" + options.sort;
        } else if (options.order === "desc") {
            sort = "&sort=-" + options.sort;
        }
    }
    if (options.hasOwnProperty("search")) {
        search = "&filter[search]=" + options.search;
    }
    return axiosClient.get(`/tags${options.page}${search}${sort}`);
};

export const APIDeleteTag = (options: IOptions) => {
    return axiosClient.delete(`/tags/${options.id}`);
};

export const APIPostTag = (data: object) => {
    return axiosClient.post(`/tags`, data);
};

export const ApiGetTagsBrio = () => {
    return axiosClient.get(`/tags/brio`);
};

export const ApiGetAllTags = () => {
    return axiosClient.get('/tags/all');
}