import {Text, View} from "@react-pdf/renderer";
import {styles } from "../../StylesPdfReport";

interface IAboutSection {
    type: string;
    subject: string;
}

const AboutSection = ({ type, subject }: IAboutSection ) => {
    console.log(type)
    return (
        <>
            {(type === "matematica" || type === "evaluare-matematica") ? (
                <>
                    <View style={styles.sectionAbout}>
                        <Text style={styles.h1}>Despre Raportul BRIO</Text>
                        <View style={styles.subsection}>
                            <Text style={styles.h3}>Scopul raportului</Text>
                            <View style={styles.subsectionContent}>
                                <Text style={styles.p}>
                                    Acest raport descrie nivelul de cunoștințe și competențe de matematică pentru elevul testat.
                                </Text>
                            </View>
                        </View>
                        <View style={styles.subsection}>
                            <Text style={styles.h3}>Utilizare</Text>
                            <View style={styles.subsectionContent}>
                                <Text style={styles.p}>
                                    Raportul se adresează părinților, elevilor și / sau profesorilor care vor să afle stadiul de pregătire
                                    al elevului evaluat prin sistemul BRIO.
                                </Text>
                                <Text style={styles.p}>
                                    El reprezintă o analiză generală a gradului de pregătire la matematică și cuprinde totodată informații
                                    importante despre ariile cu rezultate bune (punctele tari) și ariile cu rezultate slabe (punctele
                                    vulnerabile) ale școlarului în cadrul acestei discipline.
                                </Text>
                                <Text style={styles.p}>
                                    Prin urmare, pe baza raportului se poate stabili nivelul actual de cunoștințe și competențe al
                                    elevului, se pot stabili ariile pe care ar fi oportun ca elevul evaluat să se concentreze în învățare
                                    și se pot lua decizii privind intervențiile pedagogice necesare. De asemenea, raportul permite
                                    monitorizarea progresului unui elev, atunci când acesta este comparat cu alte testări BRIO.
                                </Text>
                                <Text style={styles.p}>
                                    În ciuda acurateței și sofisticării sistemului de testare BRIO, care îl fac probabil cel mai
                                    performant sistem de testare educațională din România, este recomandabil ca, pentru decizii cu impact
                                    mare asupra elevului, rezultatele din raport să fie luate în considerare doar coroborat cu informații
                                    suplimentare precum notele școlare, opinia profesorului de la clasă sau a altor cadre didactice,
                                    intențiile și opiniile părinților, discuții cu elevul însuși. Prin coroborarea rezultatelor obținute
                                    la testele BRIO cu informațiile primite de la toți actorii cu care elevul relaționează (profesori,
                                    părinți), se poate obține o imagine cât mai clară asupra situației/nivelului școlar.
                                </Text>
                            </View>
                        </View>
                        <View style={styles.subsection}>
                            <Text style={styles.h3}>Fundament</Text>
                            <View style={styles.subsectionContent}>
                                <Text style={styles.p}>
                                    Acest raport este bazat pe BRIO, sistemul de testare standardizată pentru accelerarea performanței în
                                    învățământ. Sistemul BRIO® este dezvoltat de specialiști în testare educațională la nivelul celor mai
                                    actuale standarde internaționale din domeniu. El include o bancă foarte mare de itemi și utilizează o
                                    tehnologie statistică de ultimă oră — Item Response Theory.
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.sectionAbout}>
                        <View style={styles.subsection}>
                            <Text style={styles.h3}>Secțiunile raportului</Text>
                            <View style={styles.subsectionContent}>
                                <Text style={styles.p}>Raportul BRIO® are următoarele componente:</Text>
                                <View style={{ flexDirection: "column", marginLeft: "12pt" }}>
                                    <View style={{ flexDirection: "row", marginBottom: 5 }}>
                                        <Text style={{ marginHorizontal: 8 }}>1.</Text>
                                        <Text>Secțiune introductivă</Text>
                                    </View>
                                    <View style={{ flexDirection: "row", marginBottom: 5 }}>
                                        <Text style={{ marginHorizontal: 8 }}>2.</Text>
                                        <Text>Secțiune de prezentare a rezultatelor generale</Text>
                                    </View>
                                    <View style={{ flexDirection: "row", marginBottom: 5 }}>
                                        <Text style={{ marginHorizontal: 8 }}>3.</Text>
                                        <Text>Secțiune de prezentare a rezultatelor detaliate</Text>
                                    </View>
                                </View>
                                <Text style={styles.p}>Secțiunea introductivă este chiar aceasta pe care o citiți acum.</Text>
                                <Text style={styles.p}>
                                    Secțiunea de prezentare a rezultatelor generale arată rezultatul general al elevului testat pentru
                                    matematică, precum și pentru principalele ramuri ale acestei discipline (de exemplu, geometrie,
                                    algebră sau analiză matematică), atunci când acestea se studiază la clasă în mod explicit separat.
                                </Text>
                            </View>
                        </View>
                        <View style={styles.subsection}>
                            <Text style={styles.h3}>Scorul BRIO®</Text>
                            <View style={styles.subsectionContent}>
                                <Text style={styles.p}>
                                    Rezultatele sunt prezentate sub forma scorului BRIO, un scor care poate varia între 0 (foarte slab) și
                                    100 (foarte competent). Cu cât scorul este mai mare, cu atât el indică un nivel de cunoștințe și
                                    competențe mai ridicat.
                                </Text>
                                <Text style={styles.p}>
                                    Scorul BRIO® este un scor-centilă: el arată cum se compară elevul testat cu toți ceilalți copii din
                                    România care parcurg aceeași clasă ca și el. De exemplu, pentru un elev care se află la centila 70 (a
                                    obținut scorul BRIO® 70) vom spune că 30% dintre elevii din România (de acea vârstă sau clasă) sunt
                                    mai buni decât el în timp ce 70% sunt mai slabi sau egali cu el. Totodată, un copil care are centila
                                    90 (scorul BRIO® 90) are în fața sa doar 10% din populația școlară comparabilă iar 90% dintre elevii
                                    de aceeași clasă sunt mai slabi decât el – acesta este un scor foarte bun.
                                </Text>
                            </View>
                        </View>
                        <View style={styles.subsection}>
                            <Text style={styles.h3}>Profilul competențelor</Text>
                            <View style={styles.subsectionContent}>
                                <Text style={styles.p}>
                                    În secțiunea Profilul competențelor sunt prezentate scorurile generale pentru toate dimensiunile
                                    (capitolele) măsurate, sub formă de profil. Scorurile pentru fiecare dimensiune variază între 0 și 100
                                    și permit observarea per ansamblu a punctelor mai slabe și a punctelor celor mai puternice pentru
                                    elevul testat.
                                </Text>
                            </View>
                        </View>
                        <View style={styles.subsection}>
                            <Text style={styles.h3}>Descrierile rezultatelor</Text>
                            <View style={styles.subsectionContent}>
                                <Text style={styles.p}>
                                    În secțiunea de prezentare a rezultatelor detaliate sunt analizate pe rând rezultatele elevului pentru
                                    fiecare dimensiune. Astfel, se explică ce anume a fost măsurat în cadrul fiecărei dimensiuni, care
                                    este scorul obținut de elevul testat și care este semnificația acestui scor. Informația poate fi
                                    utilizată de elevi, părinți și profesori pentru a înțelege în detaliu ce cunoștințe și competențe are
                                    elevul, dar și ce cunoștințe și competențe îi lipsesc acestuia (conform cerințelor programei școlare
                                    aferente clasei în care se află). Totodată, pe baza rezultatelor obținute de elev, se pot dezvolta
                                    modalități personalizate de intervenție educațională.
                                </Text>
                            </View>
                        </View>
                    </View>
                </>
            ) : (
                <>
                    <View style={styles.sectionAbout}>
                        <Text style={styles.h1}>Despre Raportul BRIO</Text>
                        <View style={styles.subsection}>
                            <Text style={styles.h3}>Scopul raportului</Text>
                            <View style={styles.subsectionContent}>
                                <Text style={styles.p}>
                                    Acest raport descrie nivelul de cunoștințe și de competențe la disciplina {type === "romana" || type === "evaluare-romana" ? "limba și literatura română" : subject}
                                    {" "}pentru elevul testat. El se bazează pe BRIO®, sistemul de testare standardizată pentru accelerarea
                                    performanței în învățământ.
                                </Text>
                            </View>
                        </View>
                        <View style={styles.subsection}>
                            <Text style={styles.h3}>Utilizare</Text>
                            <View style={styles.subsectionContent}>
                                <Text style={styles.p}>
                                    Raportul se adresează părinților, elevilor și/sau profesorilor care vor să afle stadiul de pregătire a
                                    elevului evaluat prin sistemul BRIO.
                                </Text>
                                <Text style={styles.p}>
                                    Raportul realizat în urma testării reprezintă o analiză generală a gradului de pregătire la disciplina{" "}
                                    {type === "romana" || type === "evaluare-romana" ? "limba și literatura română" : subject}, cuprinzând informații relevante despre ariile cu rezultate bune (punctele
                                    tari) și ariile cu rezultate slabe (punctele vulnerabile) ale școlarului la această disciplină.
                                </Text>
                                <Text style={styles.p}>
                                    Itemii propuși în cadrul testelor BRIO sunt concepuți pe baza programei școlare obligatorii aprobate
                                    de Ministerul Educației Naționale.
                                </Text>
                                <Text style={styles.p}>
                                    Testarea poate fi făcută și la clasă, întrucât testele se bazează pe principiul IRT (Item Response
                                    Theory) care ajută la minimizarea efectelor trișării prin oferirea de seturi unice de întrebări pentru
                                    fiecare elev în parte. Față de testele fixe, unde persoanele evaluate ar putea avea posibilitatea de
                                    a-și împărtăși întrebările, testele care utilizează principiul IRT elimină posibilitățile de a obține
                                    avantaje neloiale. Mai mult IRT permite testarea adaptativă, astfel un test devine mai mult sau mai
                                    puțin dificil în funcție de performanța candidatului.
                                </Text>
                                <Text style={styles.p}>
                                    Prin urmare, pe baza raportului se poate stabili nivelul actual de cunoștințe și competențe al
                                    elevului, se pot stabili ariile către care elevul evaluat ar trebui să își canalizeze eforturile de
                                    învățare și se pot lua decizii privind intervențiile pedagogice necesare. De asemenea, raportul
                                    permite monitorizarea progresului unui elev, atunci când acesta este comparat cu alte testări BRIO.
                                </Text>
                            </View>
                        </View>
                        <View style={styles.subsection}>
                            <Text style={styles.h3}>Fundament</Text>
                            <View style={styles.subsectionContent}>
                                <Text style={styles.p}>
                                    În ciuda acurateței și sofisticării sistemului de testare BRIO, care îl fac probabil cel mai
                                    performant sistem de testare educațională din România, este recomandabil ca, pentru decizii cu impact
                                    mare asupra elevului, rezultatele din raport să fie luate în considerare coroborat cu informații
                                    suplimentare precum notele școlare, opinia profesorului de la clasă sau a altor cadre didactice,
                                    intențiile și opiniile părinților, discuții cu elevul însuși. Prin coroborarea rezultatelor obținute
                                    la testele BRIO cu informațiile primite de la toți actorii cu care elevul relaționează (profesori,
                                    părinți), se poate obține o imagine cât mai clară asupra situației/nivelului școlar.
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.sectionAbout}>
                        <View style={styles.subsection}>
                            <Text style={styles.h3}>Secțiunile raportului</Text>
                            <View style={styles.subsectionContent}>
                                <Text style={styles.p}>Raportul BRIO® are următoarele componente:</Text>
                                <View style={{ flexDirection: "column", marginLeft: "12pt" }}>
                                    <View style={{ flexDirection: "row", marginBottom: 5 }}>
                                        <Text style={{ marginHorizontal: 8 }}>1.</Text>
                                        <Text>Secțiune introductivă</Text>
                                    </View>
                                    <View style={{ flexDirection: "row", marginBottom: 5 }}>
                                        <Text style={{ marginHorizontal: 8 }}>2.</Text>
                                        <Text>Secțiune de prezentare a rezultatelor generale</Text>
                                    </View>
                                    <View style={{ flexDirection: "row", marginBottom: 5 }}>
                                        <Text style={{ marginHorizontal: 8 }}>3.</Text>
                                        <Text>Secțiune de prezentare a rezultatelor detaliate</Text>
                                    </View>
                                </View>
                                <Text style={styles.p}>Secțiunea introductivă este chiar aceasta pe care o citiți acum.</Text>
                                <Text style={styles.p}>
                                    Secțiunea de prezentare a rezultatelor generale arată rezultatul general al elevului testat pentru
                                    istorie, precum și pentru principalele ramuri ale acestei discipline, atunci când acestea se studiază
                                    la clasă în mod explicit separat.
                                </Text>
                            </View>
                        </View>
                        <View style={styles.subsection}>
                            <Text style={styles.h3}>Scorul BRIO®</Text>
                            <View style={styles.subsectionContent}>
                                <Text style={styles.p}>
                                    Rezultatele sunt prezentate sub forma scorului BRIO, un scor care poate varia între 0 (foarte slab) și
                                    100 (foarte competent). Cu cât scorul este mai mare, cu atât el indică un nivel de cunoștințe și
                                    competențe mai ridicat.
                                </Text>
                                <Text style={styles.p}>
                                    Scorul BRIO® este un scor-centilă: el arată cum se compară elevul testat cu toți ceilalți copii din
                                    România care parcurg aceeași clasă ca și el. De exemplu, pentru un elev care se află la centila 70 (a
                                    obținut scorul BRIO® 70) vom spune că 30% dintre elevii din România (de acea vârstă sau clasă) sunt
                                    mai buni decât el în timp ce 70% sunt mai slabi sau egali cu el. Totodată, un copil care are centila
                                    90 (scorul BRIO® 90) are în fața sa doar 10% din populația școlară comparabilă iar 90% dintre elevii
                                    de aceeași clasă sunt mai slabi decât el – acesta este un scor foarte bun.
                                </Text>
                            </View>
                        </View>
                        <View style={styles.subsection}>
                            <Text style={styles.h3}>Profilul competențelor</Text>
                            <View style={styles.subsectionContent}>
                                <Text style={styles.p}>
                                    În secțiunea Profilul competențelor sunt prezentate scorurile generale pentru toate dimensiunile
                                    (capitolele) măsurate, sub formă de profil. Scorurile pentru fiecare dimensiune variază între 0 și 100
                                    și permit observarea per ansamblu a punctelor mai slabe și a punctelor celor mai puternice pentru
                                    elevul testat.
                                </Text>
                            </View>
                        </View>
                        <View style={styles.subsection}>
                            <Text style={styles.h3}>Descrierile rezultatelor</Text>
                            <View style={styles.subsectionContent}>
                                <Text style={styles.p}>
                                    În secțiunea de prezentare a rezultatelor detaliate sunt analizate pe rând rezultatele elevului pentru
                                    fiecare dimensiune. Astfel, se explică ce anume a fost măsurat în cadrul fiecărei dimensiuni, care
                                    este scorul obținut de elevul testat și care este semnificația acestui scor. Informația poate fi
                                    utilizată de elevi, părinți și profesori pentru a înțelege în detaliu ce cunoștințe și competențe are
                                    elevul, dar și ce cunoștințe și competențe îi lipsesc acestuia (conform cerințelor programei școlare
                                    aferente clasei în care se află). Totodată, pe baza rezultatelor obținute de elev, se pot dezvolta
                                    modalități personalizate de intervenție educațională.
                                </Text>
                            </View>
                        </View>
                    </View>
                </>
            )}
        </>
    )
}

export default AboutSection;