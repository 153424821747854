import axiosClient from "api/apiClient";

export const APIPostReport = (data: object) => {
    return axiosClient.post(`reports/show`, data);
  };

export const APIPostReportLiteracy = (data: object) => {
  return axiosClient.post(`reports/literacy/show`, data);
};

export const APIPostReportDigitalLiteracy = (data: object) => {
  return axiosClient.post(`reports/digital-literacy/show/`, data);
};