import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAuth } from "hooks/useAuth";

const BrioLogoFooter = () => {
  const { user } = useAuth();

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        flexDirection: "row",
        textAlign: "left",
        // paddingLeft: { md: 2 },
        paddingTop: 0,
        flexWrap : "wrap",
      }}
    >
      <img
        src={user.theme === "light" ? "/images/logo-brio-blue.svg" : "/images/logo-brio-white.svg"}
        height="30px"
        style={{width: "auto"}}
        alt="Brio Platforma de Testare Fundamentată Științific"
      />
      <Box sx={{ marginLeft: 2, marginTop: "2px" }}>
        <Typography component="div" variant="small" color="secondary.light">
          PLATFORMA DE TESTARE
        </Typography>
        <Typography component="div" variant="small" color="secondary.light">
          FUNDAMENTATĂ ȘTIINȚIFIC
        </Typography>
      </Box>
    </Box>
  );
};

export default BrioLogoFooter;
