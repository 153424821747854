interface ICoverPage {
  coverImage: string;
  type: string;
  children?: JSX.Element[] | JSX.Element | string;
}

const CoverPage = ({ coverImage, children, type }: ICoverPage) => {
  return (
    <>
      <div className="cover-page">
        <img src={coverImage} className="cover-img screen" alt="" />
        {type === "school" ? (
            <>
              <img src="/images/cover-logo-blue.png" className="cover-logo print" alt="" />
              <img src="/images/cover-logo-blue.png" className="cover-logo screen" alt="" />
            </>
        ) : (
            <img src="/images/cover-logo-blue.png" className="cover-logo" alt="" />
        )}
        <>{children}</>

        <img src={coverImage} className="cover-img print" alt="" />

        <table className="cover-foo">
          <tbody>
            <tr>
              <td align="left">
                &copy; Copyright BRIO<sup>&reg;</sup>, toate drepturile rezervate.
              </td>
              <td align="right">brio.ro</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CoverPage;
