import React, {useEffect, useState} from "react";
import { ApiGetTagsBrio} from "../../api/pages/testTags";
import {useAuth} from "../../hooks/useAuth";
import Loading from "../ui/Loading";
import Box from "@mui/material/Box";
import CustomDataTable from "../ui/CustomDataTable";

const BrioTags = () => {
    const { update } = useAuth();
    const [loading, setLoading] = useState<boolean>(true);
    const [brioTags, setBrioTags] = useState<any[]>([]);

    useEffect(() => {
        setLoading(true);
        ApiGetTagsBrio()
            .then((response) => {
                if (response.data.success) {
                    console.log("getBrioTags OK", response);
                    setBrioTags(response.data.data.values);
                    setLoading(false);
                } else {
                    update("error", { show: true, code: "A002" });
                }
            })
            .catch((err) => {
                // console.log("getTeachers ERR", err);
                update("error", { show: true, code: "A001" });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {loading ? (
                <Loading show={true} />
            ) : brioTags.length === 0 ? (
                <Box>Niciun tag adăugat.</Box>
            ) : (
                <CustomDataTable
                    columns={[
                        {
                            id: "name",
                            label: "Nume",
                            numeric: false,
                            disablePadding: false,
                            sortable: false,
                            noWrap: true,
                        }
                    ]}
                    rows={brioTags.map((brioTag) => {
                        return {
                            name: brioTag,
                        };
                    })}
                    rowsPerPage={brioTags.length}
                    page={0}
                    totalRows={brioTags.length}
                />
            )}
        </>
    );
}

export default BrioTags;