export function ReportLeftBottom(score : number, type: string) {
    let left = 0;
    let bottom = 0;

        if (score <= 20) {
            left = score - 0.5;
            bottom = 10 + score * 0.3258;
        } else if (score > 20 && score < 30) {
            left = score - 0.5;
            bottom = 9.5 + score * 0.3258;
        } else if (score >= 30 && score <= 40) {
            left = score - 0.5;
            bottom = 9.5 + score * 0.3258;
        } else if (score > 40 && score <= 50) {
            left = score - 0.6;
            bottom = 9.2 + score * 0.3258;
        } else if (score > 50 && score < 60) {
            left = score - 0.6;
            bottom = 9.2 + score * 0.3258;
        } else if (score >= 60 && score <= 70) {
            left = score - 0.7;
            bottom = 9.2 + score * 0.3258;
        } else if (score > 70 && score <= 80) {
            left = score - 0.7;
            bottom = 9 + score * 0.3258;
        } else if (score > 80 && score <= 90) {
            left = score - 0.8;
            bottom = 9 + score * 0.3258;
        } else if (score > 90 && score <= 95) {
            left = score - 0.9;
            bottom = 8.8 + score * 0.3258;
        } else if (score > 95) {
            left = score - 0.1;
            bottom = 8.5 + score * 0.3258;
        }

    return [left, bottom];
}

export function ReportLeftBottomXs(score : number, type: string) {
    let left = 0;
    let bottom = 0;

        if (score <= 20) {
            left = score - 0.5;
            bottom = 25 + score * 0.3258;
        } else if (score > 20 && score < 30) {
            left = score - 0.5;
            bottom = 23 + score * 0.3258;
        } else if (score >= 30 && score <= 40) {
            left = score - 0.7;
            bottom = 21 + score * 0.3258;
        } else if (score > 40 && score <= 50) {
            left = score - 0.9;
            bottom = 19 + score * 0.3258;
        } else if (score > 50 && score < 60) {
            left = score - 0.9;
            bottom = 18 + score * 0.3258;
        } else if (score >= 60 && score <= 70) {
            left = score - 1;
            bottom = 18 + score * 0.3258;
        } else if (score > 70 && score <= 80) {
            left = score - 1.1;
            bottom = 16 + score * 0.3258;
        } else if (score > 80 && score <= 90) {
            left = score - 1.1;
            bottom = 16 + score * 0.3258;
        } else if (score > 90 && score <= 95) {
            left = score - 1;
            bottom = 14 + score * 0.3258;
        } else if (score > 95) {
            left = score - 0.2;
            bottom = 13 + score * 0.3258;
        }

    return [left, bottom];
}

export function ReportSegmentsImage (score: number, type: string) {
    let segmentsImage = "";
    
    if(type === "school") {
        if (score >= 0 && score <= 10) {
            segmentsImage = "/images/reports/chart-bg-insuficient.png";
        } else if (score > 10 && score <= 30) {
            segmentsImage = "/images/reports/chart-bg-satisfacator.png";
        } else if (score > 30 && score <= 60) {
            segmentsImage = "/images/reports/chart-bg-intermediar.png";
        } else if (score > 60 && score <= 80) {
            segmentsImage = "/images/reports/chart-bg-competent.png";
        } else if (score > 80 && score <= 95) {
            segmentsImage = "/images/reports/chart-bg-avansat.png";
        } else if (score > 95 && score <= 100) {
            segmentsImage = "/images/reports/chart-bg-expert.png";
        }
    } else if (type === "digitalLiteracy") {
        if (score >= 0 && score < 35) segmentsImage = "/images/reports/ld/chart-bg-nivel-baza.png";
        else if (score >= 35 && score < 65)
            segmentsImage = "/images/reports/ld/chart-bg-intermediar.png";
        else if (score >= 65 && score < 95) segmentsImage = "/images/reports/ld/chart-bg-avansat.png";
        else if (score >= 95 && score <= 100)
            segmentsImage = "/images/reports/chart-bg-specializat.png";
    }

    return segmentsImage;
}

export function markedLevel (score: number, level: string, type: string) {
    let marked = "";
    if(type === "school") {
        if (score <= 10 && level === "Insuficient") marked = "marked";
        else if (score > 10 && score < 30 && level === "Satisfăcător" ) marked = "marked";
        else if (score >= 30 && score <= 60 && level === "Intermediar") marked = "marked";
        else if (score > 60 && score <= 80 && level === "Competent") marked = "marked";
        else if (score > 80 && score < 95 && level === "Avansat") marked = "marked";
        else if (score > 95 && level === "Expert") marked = "marked";
    } else if(type === "literacy") {
        if (score >= 0 && score <= 25 && level === "Alfabetizare subdezvoltată") marked = "marked";
        else if (score > 25 && score <= 80 && level === "Nivel funcțional al alfabetizării") marked = "marked";
        else if (score > 80 && score <= 100 && level === "Nivel înalt al alfabetizării") marked = "marked";
    } else if(type === "digitalLiteracy") {
        if (score >= 0 && score < 35 && level === "Nivel de bază") marked = "marked";
        if (score >= 35 && score < 65 && level === "Intermediar") marked = "marked";
        else if (score >= 65 && score <=95 && level === "Avansat") marked = "marked";
        else if (score >= 95 && score <= 100 && level === "Specializat") marked = "marked";
    }

    return marked;

}

export function markedLevelBorder (score: number, level: string, type: string) {
    let border = "none";
    let color = "#fff";

    if(type === "school") {
        if (score <= 10 && level === "Insuficient") border = "2pt solid #C80000";
        else if (score > 10 && score < 30 && level === "Satisfăcător" ) border = "2pt solid #FF5A00";
        else if (score >= 30 && score <= 60 && level === "Intermediar") border = "2pt solid #FFA500";
        else if (score > 60 && score <= 80 && level === "Competent") border = "2pt solid #82E164";
        else if (score > 80 && score < 95 && level === "Avansat") border = "2pt solid #41C828";
        else if (score > 95 && level === "Expert") border = "2pt solid #00952C";

        if (level.toLowerCase() === "insuficient") color = "#C80000";
        else if (level.toLowerCase() === "satisfăcător" ) color = "#FF5A00";
        else if (level.toLowerCase() === "intermediar") color = "#FFA500";
        else if (level.toLowerCase() === "competent") color = "#82E164";
        else if (level.toLowerCase() === "avansat") color = "#41C828";
        else if (level.toLowerCase() === "expert") color = "#00952C";

    } else if(type === "literacy") {
        if (score >= 0 && score <= 25 && level === "Alfabetizare subdezvoltată") border = "2pt solid #C80000";
        else if (score > 25 && score <= 80 && level === "Nivel funcțional al alfabetizării") border = "2pt solid #f9db00";
        else if (score > 80 && score <= 100 && level === "Nivel înalt al alfabetizării") border = "2pt solid #00952C";

        if (level === "Alfabetizare subdezvoltată") color = "#C80000";
        else if (level === "Nivel funcțional al alfabetizării") color = "#f9db00";
        else if (level === "Nivel înalt al alfabetizării") color = "#00952C";

    } else if(type === "digitalLiteracy") {
        if (score >= 0 && score < 35 && level === "Nivel de bază") border = "2pt solid #fc8900";
        else if (score >= 35 && score < 65 && level === "Intermediar") border = "2pt solid #f9db00";
        else if (score >= 65 && score <=95 && level === "Avansat") border = "2pt solid #07ff00";
        else if (score >= 95 && score <= 100 && level === "Specializat") border = "2pt solid #0000ff";

        if (level === "Nivel de bază") color = "#fc8900";
        else if (level === "Intermediar") color = "#f9db00";
        else if (level === "Avansat") color = "#07ff00";
        else if (level === "Specializat") color = "#0000ff";
    }

    return [border, color];
}

export function scoreGrades (level: string, type: string) {
    let scoreGrades = "";
    if(type === "school") {
        if (level === "Insuficient") scoreGrades = "SCOR 0–10:";
        else if (level === "Satisfăcător") scoreGrades = "SCOR 10–30:";
        else if (level === "Intermediar") scoreGrades = "SCOR 30–60:";
        else if (level === "Competent") scoreGrades = "SCOR 60–80:";
        else if (level === "Avansat") scoreGrades = "SCOR 80–95:";
        else if (level === "Expert") scoreGrades = "SCOR 95–100:";
    } else if(type === "digitalLiteracy") {
        if (level === "Nivel de bază") scoreGrades = "SCOR 0 – 35:";
        if (level === "Intermediar") scoreGrades = "SCOR 35 – 65:";
        else if (level === "Avansat") scoreGrades = "SCOR 65 – 95:";
        else if (level === "Specializat") scoreGrades = "SCOR 95 – 100:";
    }
    return scoreGrades;
}

export function scoreLevelName (score: number) {
    let scoreLevelName = "";
    if (score >= 0 && score <= 25) scoreLevelName = "insuficient";
    else if (score > 25 && score <= 80) scoreLevelName = "competent-literatie";
    else if (score > 80 && score <= 100) scoreLevelName = "expert";

    return scoreLevelName;
}

export function scoreLevelCssClass (level: string, type: string) {
    let scoreLevelCssClass = "";

    if(type === "literacy") {
        if (level === "Alfabetizare subdezvoltată") scoreLevelCssClass = "insuficient";
        else if (level === "Nivel funcțional al alfabetizării") scoreLevelCssClass = "competent-literatie";
        else if (level === "Nivel înalt al alfabetizării") scoreLevelCssClass = "expert";
    } else if (type === "digitalLiteracy") {
        if (level === "Nivel de bază") scoreLevelCssClass = "ld-nivel-baza";
        if (level === "Intermediar") scoreLevelCssClass = "ld-intermediar";
        else if (level === "Avansat") scoreLevelCssClass = "ld-avansat";
        else if (level === "Specializat") scoreLevelCssClass = "ld-specializat";
    }

    return scoreLevelCssClass;
}