import {Image, Text, View} from "@react-pdf/renderer";
import React from "react";
import {styles} from "../../StylesPDFSchoolReport";

const Section33 = ({subject, table9, imagesHeatmap}: any) => {
    const tableRows=[
        {name: "Dimensiune", id: "subcategory"},
        {name: "A  ", id: "a"},
        {name: "B  ", id: "b"},
        {name: "C  ", id: "c"},
        {name: "D  ", id: "d"},
        {name: "E  ", id: "e"},
        {name: "TOT  ", id: "total"},
        {name: "A%  ", id: "a%"},
        {name: "B%  ", id: "b%"},
        {name: "C%  ", id: "c%"},
        {name: "D%  ", id: "d%"},
        {name: "E%  ", id: "e%"},
    ];

    return (
        <>
            {table9.map((data: any, index: number) => (
                <View key={index}>
                    <Text style={[styles.h2, styles.mb13]}>3.3.{index + 1}. Distribuția elevilor pentru clasa {table9[index][0].grade}</Text>
                    <View style={styles.tableSubsection} wrap={false}>
                        <Text style={styles.tableTitle}>Tabelul 7.{index + 1}. Scorurile totale obtinute pentru {subject}, clasa {table9[index][0].grade}</Text>
                        <View style={styles.chartTable}>
                            <View style={styles.gradesRowHeader}>
                                {tableRows.map((row, index: number) => (
                                    <Text style={[row.id !== "subcategory" ? styles.colValuerNumeric : styles.colValues, row.id === "subcategory" ? styles.colValuesStart : row.id === "e%" ? styles.colValuesEnd : styles.colValues ]} key={index}>{row.name}</Text>
                                ))}
                            </View>
                            {data.map((item: any, index: any) => (
                                <View style={[index%2 === 0 ? styles.gradesRowBodyOdd : styles.gradesRowBody]} key={index}>
                                    {tableRows.map((row, index: number) => (
                                        <Text style={[row.id !== "subcategory" ? styles.colValuerNumeric : styles.colValues, row.id === "subcategory" ? styles.colValuesStart : row.id === "e%" ? styles.colValuesEnd : styles.colValues ]} key={index}>{typeof item[row.id] === 'string' ? item[row.id].substring(0, 40) : item[row.id]}</Text>
                                    ))}
                                </View>
                            ))}
                        </View>
                    </View>
                    <View style={styles.tableSubsection} wrap={false}>
                        <Text style={styles.tableTitle}>Graficul 6.{index + 1}. Heatmap cu distributia decilelor de performanta pentru {subject}, clasa {table9[index][0].grade}</Text>
                        {imagesHeatmap.map((image : any) => (
                                <>
                                    {image.id === `gradeRiscHeatmap${index}` && <Image src={image.image} key={index}/>}
                                </>
                            )
                        )}
                    </View>
                </View>
            ))}
        </>
    )
}

export default Section33;