import { Navigate, useLocation, useOutlet } from "react-router-dom";

import { useAuth } from "hooks/useAuth";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Sidebar from "../sidebar/Sidebar";
import Box from "@mui/material/Box";
import ScrollBar from "components/ui/ScrollBar";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppLogout from "../AppLogout";

interface IProps {
  fullScreen?: boolean;
    allowedRoles: string[];
}

const LayoutProtected = ({ fullScreen = false, allowedRoles}: IProps) => {
  const { user } = useAuth();
  const outlet = useOutlet();
  const location = useLocation();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const localStorageUser = window.localStorage.getItem("user");

    if(user.client) {
        return <Navigate to="/not-found" />;
    }

  if (!user || user.token === undefined || user.token === null || user.token === "" || localStorageUser === "{\"theme\":\"light\"}") {
    return <Navigate to="/" />;
  }

    // if (
    //     user &&
    //     user.firstname !== undefined &&
    //     user.firstname !== null &&
    //     user.firstname !== "" &&
    //     user.email_verified !== null &&
    //     user.position === "principal" &&
    //     user.swap
    // ) {
    //     return <Navigate to="/profile" replace />;
    // }

  // if (
  //   user &&
  //   user.firstname !== undefined &&
  //   user.firstname !== null &&
  //   user.firstname !== "" &&
  //   user.email_verified === null
  // ) {
  //   return <Navigate to="/verifica-email" replace />;
  // }

  return allowedRoles.find((role) => user.position.includes(role)) ? (
      <AppLogout>
          {!fullScreen ? (
              <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  // alignItems="stretch"
                  spacing={0}
                  // minHeight="100dvh"
                  sx={{backgroundColor: {xs: "background.paper", lg: "transparent"}}}
              >
                  <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={3}
                      maxHeight="100dvh"
                      sx={{
                          paddingRight: {xs: 0, lg: "10px"},
                          backgroundColor: {xs: "transparent", lg: "extra.sidebar"},
                          height: {xs: "auto", lg: "100dvh"},
                      }}
                  >
                      <Sidebar/>
                  </Grid>
                  <Grid
                      item
                      xs={12}
                      lg={8}
                      xl={9}
                      sx={{
                          backgroundColor: "background.default",
                          borderRadius: {xs: 2, lg: 0},
                          height: {xs: "auto", lg: "100dvh"},
                          maxHeight: {xs: "auto", lg: "100dvh"},
                      }}
                  >
                      {isLargeScreen ? (
                          <ScrollBar style={{height: "100dvh"}} forceVisible="x" autoHide={false}>
                              <Box sx={(theme) => ({
                                  padding: {
                                      xs: theme.spacing(3, 0, 3, 0),
                                      md: theme.spacing(5, 1, 5, 1)
                                  }
                              })}>
                                  <Container maxWidth="xl">{outlet}</Container>
                              </Box>
                          </ScrollBar>
                      ) : (
                          <>
                              <Box display="flex" flexDirection="column" justifyContent="space-between"
                                   sx={(theme) => ({
                                       padding: {xs: theme.spacing(3, 0, 3, 0), md: theme.spacing(5, 1, 5, 1)},
                                       height: "auto",
                                       minHeight: "calc(100dvh - 149px)"
                                   })}>
                                  <Container maxWidth="xl">{outlet}</Container>
                              </Box>

                          </>
                      )}
                  </Grid>
              </Grid>

          ) : (
              outlet
          )}
      </AppLogout>
  ) : (
      <Navigate to="/" state={{ from: location }} replace />
  )
};

export default LayoutProtected;
