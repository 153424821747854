import IconButton from "@mui/material/IconButton";
import Icon from "../../ui/Icon";
import Tooltip, {tooltipClasses, TooltipProps} from "@mui/material/Tooltip";
import React from "react";
import {styled} from "@mui/material/styles";

interface ITooltip {
    type: string;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.extra.sidebar,
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 450,
        fontWeight: 400,
        lineHeight: "13pt",
        fontSize: "9.2pt",
        fontStyle: "italic",
    },
}));

const TabelTooltip = ({type}: ITooltip) => {
    const data = {
        totalScore : "Nota: Scorul mediu reprezinta media obtinuta pentru aceasta materie in aceasta scoala; Min este scorul minim obtinut; Max este scorul maxim obtinut; Q1, Q2 si Q3 sunt scorurile la care, in acesta scoala, se plaseaza 25%, 50% si respectiv 75% din elevi (astfel, jumatate din elevii scolii sunt intre Q1 si Q3; sfertul mai slab este intre Min si Q1, iar sfertul mai bun intre Q3 si Max).",
        boxplot: "Nota: Acest grafic se numeste Boxplot. El reprezinta vizual locatia scorurilor pentru dimensiunile reprezentate. Limitele casutei sunt cvartilele 1 si 3 (adica locul unde se afla 25% si respectiv 75% din elevi). Liniile orizontale atasate merg in stanga pana la scorul minim si in dreapta pana la scorul maxim. Linia care imparte caseta in jumatate este mediana scorurilor.",
        decile: "Nota: Tabelul prezinta numarul de elevi din scoala focala care se pleaseaza la nivel national in fiecare din cele 10 decile. Decilele sunt numerotate de la 1 la 10, unde decila 1 contine cei mai slabi 10% elevi la nivel national, iar decila 10 cei mai buni 10% elevi la nivel national.",
        heatmap: "Nota: Acest grafic se numeste Heatmap. El reprezinta vizual numarul de elevi gasiti, pentru fiecare dimensiune, in fiecare decila. Cu cat o casuta este mai inchisa la culoare cu atat este mai populata cu elevi - cu atat mai multi elevi se regasesc in acel interval de scoruri.",
        risc: "Notă: Tabelul prezintă numărul de elevi din școală care se plasează în fiecare din cele 5 categorii de risc sau performanță. " +
            "A = absolut fara risc (centilele 90-100), B = fara risc (centilele 70-89), C = risc mediu (centilele 31-69), D = risc ridicat (centilele 11-30), E = risc foarte ridicat  (centilele 1-10)",
        riscHeatmap: "Notă: Acest grafic vizualizează numarul elevilor găsiși, pentru fiecare dimensiune, in fiecare categorie de risc sau performanță. Cu cât o căsuță este mai închisă la culoare cu atât este mai populată cu elevi. Totalul fiecărei linii este 100%.",
    }

    return (
        <StyledTooltip title={type === "totalScore" ? data.totalScore : type === "boxplot" ? data.boxplot : type === "decile" ? data.decile : type === "heatmap" ? data.heatmap : type === "risc" ? data.risc : data.riscHeatmap } >
            <IconButton style={{fontSize: "18px", color: "#0064FF"}}>
                <Icon icon="fal circle-info" fixedWidth={true} />
            </IconButton>
        </StyledTooltip>
    );
}

export default TabelTooltip;