const MarkGroup = ({type}: any) => {
    return (
        <>
        {type === "school" &&
            <div className="mark-group">
                <div className="mark-line mark-0"></div>
                <div className="mark-line mark-10"></div>
                <div className="mark-line mark-30"></div>
                <div className="mark-line mark-60"></div>
                <div className="mark-line mark-80"></div>
                <div className="mark-line mark-95"></div>
                <div className="mark-line mark-100"></div>
            </div>}
        {type === "literacy" &&
            <div className="mark-group">
                <div className="mark-line mark-0"></div>
                <div className="mark-line mark-25"></div>
                <div className="mark-line mark-80"></div>
                <div className="mark-line mark-100"></div>
            </div>}
            {type === "digitalLiteracy" &&
                <div className="mark-group">
                    <div className="mark-line-ld mark-0"></div>
                    <div className="mark-line-ld mark-35"></div>
                    <div className="mark-line-ld mark-65"></div>
                    <div className="mark-line-ld mark-95"></div>
                    <div className="mark-line-ld mark-100"></div>
                </div>}
        </>
    )
}

export default MarkGroup;