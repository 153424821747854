import {markedLevel, scoreGrades, scoreLevelCssClass} from "../../../utils/reportsFunctions";


const ChartSubsection = ({ gradeLevel, score, index, type }: any) => {
  const marked = markedLevel(score, gradeLevel.level, type);
  let scoreTitle = scoreGrades(gradeLevel.level, type);
  let cssClass = scoreLevelCssClass(gradeLevel.level, type);

  return (
        <div key={index}>
          <div
            key={gradeLevel.level}
            className={`subsection ${marked} ${type === "school" ? gradeLevel.level.toLowerCase().replace(/ă/g, "a") : ""} ${type === "digitalLiteracy" ? cssClass : ""}`}
          >
            <div className={`title-nivel ${type === "school" ? gradeLevel.level.toLowerCase().replace(/ă/g, "a") : ""} ${type === "digitalLiteracy" ? cssClass : ""}`}>
              <div className="td">
                <span style={{marginRight: "5pt"}}>{scoreTitle}</span>
                <div className="square">
                  <svg
                      className="print svg-print"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 10 10"
                      preserveAspectRatio="none"
                  >
                    <rect width="10" height="10" />
                  </svg>
                </div>
                {gradeLevel.level}
              </div>
              <div className="subsection-content">
                {Array.isArray(gradeLevel.description) ? (
                    <ul>
                      {gradeLevel.description.map((description: any, index: any) => (
                          <li key={index}>{description}</li>
                      ))}
                    </ul>
                ) : (
                    <div dangerouslySetInnerHTML={{ __html: gradeLevel.description }} />
                )}
              </div>
            </div>
          </div>
        </div>
  );
};

export default ChartSubsection;
