import React, { useEffect, createContext, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "hooks/useLocalStorage";
// import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

type Menu = {
  enabled: number;
  icon: string;
  name: string;
  target: string;
  type: number;
  url: string;
  extra?: string;
};

type Data = {
  title: string;
  amount: string;
};
type Dashboardbox = {
  type: string;
  title: string;
  icon: JSX.Element;
  number?: string;
  options?: {
    icon: string;
    menu: {
      icon: string;
      title: string;
      action: string;
    };
  };
  data: Data[];
};

interface IErrorData {
  show: boolean;
  closable?: boolean;
  code: string;
  icon: string;
  title?: string;
  message?: string;
  button?: string;
  buttonVariant?: "text" | "outlined" | "contained" | undefined;
  returnFunction?: () => {} | null;
  onClickFunction?: () => {} | null;
}

interface IEditModalData {
  show: boolean;
  icon: string;
  title?: string;
  message?: JSX.Element[] | JSX.Element | string;
  button?: string;
  returnFunction?: () => {} | null;
  onClickFunction?: () => {} | null;
}
interface IConfirmData {
  show: boolean;
  closable?: boolean;
  icon?: string;
  title?: string;
  message?: JSX.Element[] | JSX.Element | string;
  buttonYes?: JSX.Element[] | JSX.Element | string;
  buttonNo?: JSX.Element[] | JSX.Element | string;
  returnFunction?: (callback: () => void) => void | null;
  cancelFunction?: () => void | null;
  size: string;
}

interface ISubscription {
  id: string;
  name: string;
  price: number;
  type: string;
  [key: string]: any;
}

export interface ICart {
  cartSubscriptionItem: ISubscription[];
  cartExtraItem: ISubscription[];
  cartTotal: number;
  cartItems: number;
}

export interface IError {
  error: IErrorData;
}

export interface IEditModal {
  edit: IEditModalData;
}
export interface IConfirm {
  confirm: IConfirmData;
  children?: JSX.Element[] | JSX.Element | string;
}
export interface IAuth {
  user: {
    firstname: string;
    lastname: string;
    email: string;
    position: string;
    theme: string;
    menu: Menu[];
    dashboardbox: Dashboardbox[];
    token: string;
    profilePhoto: string;
    email_verified: boolean | null;
    id: string;
    notifications: number;
    schoolBoard: boolean;
    swap: boolean;
    client: boolean;
    date: Date;
  };
  tenant: {
    entity: string;
    name: string;
    city: string;
    county: string;
    logo: string;
    images: string[];
    api_url: string;
    type: string;
    id: string;
  };
  loading: {
    show: boolean;
    modal?: boolean;
  };
  cart: ICart;
  error: IErrorData;
  edit: IEditModalData;
  confirm: IConfirmData;
  update: (what: string, data: object, rewrite?: boolean) => Promise<void>;
  login: (data: object) => Promise<void>;
  loginClient: (data: object) => Promise<void>;
  register: (data: object) => Promise<void>;
  logout: () => void;
}

export const defaultUser = {
  user: {
    firstname: "",
    lastname: "",
    email: "",
    position: "",
    theme: "",
    menu: [],
    dashboardbox: [],
    token: "",
    profilePhoto: "",
    email_verified: null,
    id: "",
    notifications: 0,
    schoolBoard: false,
    swap: false,
    client: false,
    date: new Date(),
  },
  tenant: {
    entity: "",
    name: "",
    city: "",
    county: "",
    logo: "",
    images: [],
    api_url: "",
    type: "",
    id: "",
  },
  loading: {
    show: false,
    modal: false,
  },
  error: {
    show: false,
    closable: false,
    code: "",
    title: "",
    message: "",
    icon: "",
    button: "",
    buttonVariant: undefined,
  },
  edit: {
    show: false,
    title: "",
    message: "",
    icon: "",
    button: "",
  },
  confirm: {
    show: false,
    closable: true,
    title: "",
    message: "",
    icon: "fat face-eyes-xmarks",
    buttonYes: "",
    buttonNo: "",
    size: "small",
  },
  cart: {
    cartSubscriptionItem: [],
    cartExtraItem: [],
    cartTotal: 0,
    cartItems: 0,
  },
  update: async () => {},
  login: async () => {},
  loginClient: async () => {},
  register: async () => {},
  logout: () => {},
};

const AuthContext = createContext<IAuth>(defaultUser);

type Props = {
  children: JSX.Element[] | JSX.Element | string;
};

export const AuthProvider = ({ children }: Props) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [tenant, setTenant] = useLocalStorage("tenant", null);
  const [loading, setLoading] = useState(defaultUser.loading);
  const [error, setError] = useState(defaultUser.error);
  const [edit, setEdit] = useState(defaultUser.edit);
  const [confirm, setConfirm] = useState(defaultUser.confirm);
  const [cart, setCart] = useLocalStorage("cart", null);
  const navigate = useNavigate();
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  // console.log("useAUTH");

  useEffect(() => {
    // check localStorage tenant
    // console.log(typeof tenant);
    if (tenant === null) setTenant(defaultUser.tenant);
    if (cart === null) setCart(defaultUser.cart);
    // if (loading === null) setLoading(defaultUser.loading);
    // setError(defaultUser.error);

    // set default theme
    if (user?.theme === undefined || user?.theme === null || user.theme === "") {
      setUser({ ...user, theme: "light" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log("useAUTH after useEffect");

  const update = async (what: string, data: object, rewrite: boolean = false) => {
    // console.log("update", data);
    if (what === "user") setUser({ ...(!rewrite && user), ...data });
    else if (what === "tenant") setTenant({ ...(!rewrite && tenant), ...data });
    else if (what === "cart") setCart({ ...(!rewrite && cart), ...data });
    else if (what === "loading") {
      if (rewrite) {
        setLoading(defaultUser.loading);
      } else {
        setLoading({ ...loading, ...data });
      }
    } else if (what === "error") {
      if (rewrite) {
        setError(defaultUser.error);
      } else {
        setError({ ...error, ...data });
      }
    } else if (what === "edit") {
      if (rewrite) {
        setEdit(defaultUser.edit);
      } else {
        setEdit({ ...edit, ...data });
      }
    } else if (what === "confirm") {
      if (rewrite) {
        setConfirm(defaultUser.confirm);
      } else {
        setConfirm({ ...confirm, ...data });
      }
    } else throw new Error("Invalid update");
  };

  const login = async (data: object) => {
    update("user", data);
    navigate("/statistici", { replace: true });
  };

  const loginClient = async (data: object) => {
    update("user", data);
  };

  const register = async (data: object) => {
    update("user", data);
    navigate("/login/profesori/verifica-email", { replace: true });
  };

  const logout = () => {
    if(tenant.type === "teacher") {
      update("user", { theme: user.theme }, true);
      update("cart", defaultUser.cart);
      update("tenant", defaultUser.tenant);
    navigate("/login/profesori", { replace: true });
        } else {
      update("user", { theme: user.theme }, true);
      update("cart", defaultUser.cart);
      update("tenant", defaultUser.tenant);
      navigate("/", { replace: true });
    }

  };

  const value = useMemo<IAuth>(
    () => ({
      user,
      tenant,
      loading,
      error,
      edit,
      confirm,
      cart,
      update,
      login,
      loginClient,
      register,
      logout,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, tenant, loading, error, confirm, edit, cart],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
