import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {niceUserName} from "../../utils/functions";
import Timer from "./Timer";
import ScrollBar from "../ui/ScrollBar";
import QuestionsButtons from "./QuestionsButtons";
import Button from "@mui/material/Button";
import React, {Dispatch, SetStateAction} from "react";
import {IAnswer, ITest} from "./_interfaces";
import {useAuth} from "../../hooks/useAuth";
import {useTheme} from "@mui/material/styles";

const TestSidebar = ({
                        test,
                        itemIndex,
                         answeredItems,
                         setItemIndex,
                         itemsNumber,
                         setLoading,
                         finishTest,
                         days,
                         hours,
                         minutes,
                         seconds,
                     } : {
    test: ITest;
    itemIndex: number;
    answeredItems: IAnswer[];
    setItemIndex: (index: number) => void;
    itemsNumber: number;
    setLoading: Dispatch<SetStateAction<boolean>>;
    finishTest: () => void;
    days : number;
    hours: number;
    minutes: number;
    seconds: number;
}) => {
    const theme = useTheme();
    const { user, tenant } = useAuth();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

    return (
        <Box
            sx={{
                width: { xs: "100%", lg: 340 },
                minWidth: { xs: "auto", lg: 340 },
                bgcolor: { xs: "#00289B", lg: "background.paper" },
                position: {xs: "fixed", lg:"relative"},
                top: 0,
                right: 0,
                zIndex: 1,
                padding: {
                    xs: theme.spacing(0.75, 1),
                    sm: theme.spacing(0.75, 2),
                    lg: useMediaQuery("(min-height:500px)") ? 3 : theme.spacing(1, 3),
                },
            }}
        >
            <Box
                display="flex"
                height="100%"
                alignItems="flex-start"
                justifyContent="space-between"
                sx={{
                    flexDirection: { xs: "row", lg: "column" },
                    gap: { xs: 1, sm: 2, lg: useMediaQuery("(min-height:600px)") ? 3 : 1 },
                }}
            >
                <Box sx={{ width: { xs: "auto", lg: "100%" } }}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ gap: { xs: 1, sm: 2 } }}>
                        <img
                            src={
                                theme.palette.mode === "dark" || !isLargeScreen
                                    ? "/images/logo-brio-white.svg"
                                    : "/images/logo-brio-blue.svg"
                            }
                            height="30px"
                            alt="Brio Platforma de Testare Fundamentată Științific"
                        />
                        {/* <ThemeToggle small={true} /> */}
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: { xs: "auto", lg: "100%" },
                        flexGrow: { xs: 1, lg: 0 },
                        textAlign: { xs: "center", lg: "left" },
                    }}
                    position="relative"
                >
                    <Typography
                        variant="h2"
                        sx={{
                            fontWeight: 300,
                            color: { xs: "#D7E6FF", lg: "secondary.light" },
                            display: { xs: "none", sm: "block" },
                        }}
                    >
                        {test.title}
                    </Typography>
                    <Box
                        sx={{
                            display: { xs: "none", lg: "flex" },
                            alignItems: "center",
                            justifyContent: "center",
                            position: "absolute",
                            zIndex: 2,
                            right: -60,
                            top: -18,
                            width: 60,
                            height: 60,
                            borderRadius: "50%",
                            fontSize: "1.75rem",
                            fontWeight: 300,
                            bgcolor: "background.default",
                            border: "3px solid",
                            borderColor: "primary.main",
                        }}
                    >
                        {itemIndex}
                    </Box>
                    <Box
                        sx={{ display: { xs: "none", lg: useMediaQuery("(min-height:500px)") ? "block" : "none" } }}
                        mt={useMediaQuery("(min-height:600px)") ? 3 : 1}
                    >
                        <Typography variant="h2" color="primary" sx={{ lineHeight: 1, fontWeight: 300, mb: 0.25 }}>
                            {niceUserName(user.firstname, user.lastname, user.email)}
                        </Typography>
                        <Typography variant="body2" sx={{ lineHeight: 1, fontWeight: 300 }}>
                            {tenant.entity && tenant.entity + " "}
                            {tenant.name && tenant.name}
                            {tenant.city !== "" && ", " + tenant.city}
                            {tenant.county !== "" && ", " + tenant.county}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ width: { xs: "auto", lg: "100%" } }}>
                    <Timer hours={hours} minutes={minutes} seconds={seconds} secondsTotal={test.total_test_time_seconds} />
                </Box>
                <Box sx={{ width: { xs: "auto", lg: "100%" }, display: { xs: "none", lg: "block" } }}>
                    <Box
                        display="flex"
                        alignItems="flex-end"
                        justifyContent="space-between"
                        mb={useMediaQuery("(min-height:500px)") ? 1.5 : 0}
                    >
                        <Typography color="secondary.light" lineHeight="1" sx={{ mb: 0.5 }}>
                            RĂSPUNSURI
                        </Typography>
                        <Typography variant="caption" lineHeight="1" sx={{ fontSize: "1.625rem", fontWeight: 400, mb: 0.5 }}>
                            {answeredItems.length}/{itemsNumber}
                        </Typography>
                    </Box>
                    <Box sx={{ margin: theme.spacing(0, -2.5) }}>
                        <ScrollBar
                            style={{
                                maxHeight: useMediaQuery("(min-height:500px)") ? "calc(100dvh - 480px)" : "calc(100dvh - 270px)",
                            }}
                            forceVisible="y"
                            autoHide={false}
                        >
                            <Box p={theme.spacing(1.5, 2.5)}>
                                <QuestionsButtons
                                    test={test}
                                    setItemIndex={setItemIndex}
                                    itemIndex={itemIndex}
                                    answeredItems={answeredItems}
                                    isLargeScreen={isLargeScreen}
                                    setLoading={setLoading}
                                />
                            </Box>
                        </ScrollBar>
                    </Box>
                    <Box
                        sx={{
                            marginTop: useMediaQuery("(min-height:600px)") ? 4 : 1,
                        }}
                    >
                        <Button variant="contained" fullWidth={true} onClick={finishTest}>
                            Termină testul
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default TestSidebar;