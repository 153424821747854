

const ChartNoteWrapperBig = ({type } : any) => {
    return (
        <>
            {type === "school" && (
                <>
                <div className="chart-note-wrapper">
                    <div className="chart-note insuficient">
                        <div className="border"></div>
                        <div className="name">Insuficient</div>
                        <div className="values">0–10</div>
                    </div>
                    <div className="chart-note intermediar">
                        <div className="border"></div>
                        <div className="name">Intermediar</div>
                        <div className="values">30–60</div>
                    </div>
                    <div className="chart-note avansat">
                        <div className="border"></div>
                        <div className="name">Avansat</div>
                        <div className="values">80–95</div>
                    </div>
                </div>
                <div className="chart-note-wrapper even">
                    <div className="chart-note satisfacator">
                        <div className="border"></div>
                        <div className="name">Satisfăcător</div>
                        <div className="values">10–30</div>
                    </div>
                    <div className="chart-note competent">
                        <div className="border"></div>
                        <div className="name">Competent</div>
                        <div className="values">60–80</div>
                    </div>
                    <div className="chart-note expert">
                        <div className="border"></div>
                        <div className="name">Expert</div>
                        <div className="values">95–100</div>
                    </div>
                </div>
                </>
            )}
            {type === "literacy" && (
                <div className="chart-note-wrapper">
                    <div className="chart-note insuficient_literatie" style={{ left: 0, width: "25%" }}>
                        <div className="border"></div>
                        <div className="name">Alfabetizare subdezvoltată</div>
                        <div className="values">0–25</div>
                    </div>
                    <div className="chart-note intermediar_literatie" style={{ left: "26%", width: "54%" }}>
                        <div className="border"></div>
                        <div className="name">Nivel funcțional al alfabetizării</div>
                        <div className="values">26–80</div>
                    </div>
                    <div className="chart-note avansat_literatie" style={{ left: "81%", width: "19%" }}>
                        <div className="border"></div>
                        <div className="name">Nivel înalt al alfabetizării</div>
                        <div className="values">81–100</div>
                    </div>
                </div>
            )}
            {type === "digitalLiteracy" && (
                <>
                    <div className="chart-note-wrapper">
                        <div className="chart-note nivel-baza-ld">
                            <div className="border"></div>
                            <div className="name">Nivel de bază</div>
                            <div className="values">0–35</div>
                        </div>
                        <div className="chart-note avansat-ld">
                            <div className="border"></div>
                            <div className="name">Avansat</div>
                            <div className="values">65–95</div>
                        </div>
                    </div>
                    <div className="chart-note-wrapper even">
                        <div className="chart-note intermediar-ld">
                            <div className="border"></div>
                            <div className="name">Intermediar</div>
                            <div className="values">35-65</div>
                        </div>
                        <div className="chart-note specializat-ld">
                            <div className="border"></div>
                            <div className="name">Specializat</div>
                            <div className="values">95–100</div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default ChartNoteWrapperBig;
