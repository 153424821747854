import { createTheme, ThemeOptions } from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { alpha } from "@mui/material";

// import shadows, { Shadows } from "@mui/material/styles/shadows";

const theme = createTheme();

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    small: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  small: React.CSSProperties;
}

const themeOptionsDefault: ThemeOptions = {
  // shadows: shadows.map(() => "none") as Shadows,
  typography: {
    fontFamily: ["Rubik", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
    fontSize: 16,
    h1: {
      // color: "#00289B",
      fontWeight: 300,
      fontSize: "1.5rem",
      // lineHeight: 1,
      "& strong": {
        fontWeight: 500,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '2rem',
      },
      // "& u": {
      //   textDecorationThickness: "from-font",
      //   textDecorationColor: "rgba(255, 255, 255,0.5)",
      // },
    },
    h2: {
      // color: "#0064FF",
      fontSize: "1.3125rem",
      // fontSize: "1.6rem",
      fontWeight: 400,
      lineHeight: 1.2,
    },
    h3: {
      // color: "#777",
      fontSize: "1.3rem",
      // fontSize: "1.3rem",
      fontWeight: 300,
      [theme.breakpoints.up('md')]: {
        fontSize: '1.5rem',
      },
    },
    h4: {
      fontSize: "1.2rem",
    },
    h5: {
      fontSize: "1.125rem",
      // lineHeight: 1,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: "1.3rem",
      lineHeight: 1.3,
    },
    subtitle2: {
      fontSize: "1.1rem",
    },
    body1: {
      fontSize: "1rem",
      lineHeight: 1.4,
      fontWeight: 400,
      marginBottom: 10,
      "&:last-child": {
        marginBottom: 0,
      },
      "& strong": {
        fontWeight: 500,
      },
    },
    body2: {
      fontSize: "0.875rem",
      lineHeight: 1.3,
      fontWeight: 400,
    },
    small: {
      fontSize: "0.6875rem",
      lineHeight: 1.1,
    },
    button: {
      fontSize: "1.125rem",
      // fontSize: "1.1rem",
      fontWeight: 400,
      textTransform: "initial",
      lineHeight: 1.4,
    },
    caption: {
      fontFamily: ['"IBM Plex Mono"', '"Courier New"', "Courier", "monospace"].join(","),
      fontSize: "1rem",
      lineHeight: 1.3,
      fontWeight: 300,
    },
    overline: {
      fontSize: "1.2rem",
      fontWeight: 700,
    },
  } as ExtendedTypographyOptions,
  spacing: 10,
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiListItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiTable: {
      defaultProps: {
        size: "small",
      },
    },
    MuiToolbar: {
      defaultProps: {
        variant: "dense",
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "10px 50px",
          borderRadius: 30,
        },
        sizeSmall: {
          padding: "4px 17px",
        },
        sizeMedium: {
          padding: "6px 16px",
          borderRadius: "10px",
        },
        sizeLarge: {
          borderRadius: "10px",
          padding: "10px 16px",
        },
        contained: ({ theme }) => ({
          color: "#fff",
          backgroundColor: theme.palette.secondary.light,
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
        }),
        containedError: ({ theme }) => ({
          backgroundColor: theme.palette.error.main,
          "&:hover": {
            backgroundColor: theme.palette.error.light,
          },
        }),
        outlined: ({ theme }) => ({
          color:theme.palette.secondary.light,
          backgroundColor: "transparent",
          borderColor: theme.palette.secondary.light,
          "&:hover": {
            backgroundColor: "transparent",
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
          },
        }),
      },
      defaultProps: {
        // size: "small",
        disableElevation: true,
        // sx: {
        // fontWeight: 500,
        // },
      },
    },
    MuiPaper: {
      defaultProps: {
        sx: {
          borderRadius: 3,
          boxShadow: "none",
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        // arrow: true,
        placement: "top-start",
        componentsProps: {
          tooltip: {
            sx: {
              bgcolor: "primary.main",
              opacity: 1,
              lineHeight: 1,
              padding: "6px 10px",
              "& a": {
                color: "primary.contrastText",
                fontWeight: 400,
              },
            },
          },
        },
        PopperProps: {},
      },
    },
    MuiModal: {
      defaultProps: {
        sx: {
          bgcolor: "transparent !important",
        },
        BackdropProps: {
          sx: (theme) => ({
            // bgcolor: alpha(theme.palette.secondary.light, 0.2),
            background: `url("/images/logo-brio-white.svg") left 10% center / 210px 25% repeat-y ${alpha(
              theme.palette.secondary.light,
              0.33,
            )}`,
            backgroundBlendMode: "soft-light",
            // opacity: "0.25 !important",
            // bgcolor: "none",
          }),
        },
      },
    },
    MuiDialog: {
      defaultProps: {},
    },
    // MuiFilledInput: {
    //   defaultProps: {
    //     margin: "dense",
    //   },
    // },
    // MuiFormControl: {
    //   defaultProps: {
    //     margin: "dense",
    //   },
    // },
    // MuiFormHelperText: {
    //   defaultProps: {
    //     margin: "dense",
    //   },
    // },
    // MuiIconButton: {
    //   defaultProps: {
    //     size: "small",
    //   },
    // },
    // MuiInputBase: {
    //   defaultProps: {
    //     margin: "dense",
    //   },
    // },
    // MuiInputLabel: {
    //   defaultProps: {
    //     margin: "dense",
    //   },
    // },
    // MuiOutlinedInput: {
    //   defaultProps: {
    //     margin: "dense",
    //   },
    // },
    // MuiFab: {
    //   defaultProps: {
    //     size: "small",
    //   },
    // },
    // MuiTextField: {
    //   defaultProps: {
    //     margin: "dense",
    //   },
    // },
  },
  /*
  overrides: {
    // you declare you override default material-ui styles
    // MuiDialogContent: {
    //   root: {
    //     willChange: "transform"
    //   }
    // },
    MuiListItemIcon: {
      root: {
        minWidth: "auto",
        marginRight: 8,
      },
    },
    MuiInputBase: {
      root: {
        "& input": {
          "&:-webkit-autofill": {
            transition: "background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s",
          },
          "&:-webkit-autofill:focus": {
            transition: "background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s",
          },
          "&:-webkit-autofill:hover": {
            transition: "background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s",
          },
        },
      },
    },
    MuiButton: {
      root: {
        fontWeight: 500,
        color: "#666",
        minWidth: 44,
      },
      //   textSizeMedium: {
      //     // padding: "8px 12px",
      //     fontSize: "1rem",
      //     // lineHeight: 1,
      //   },
      contained: {
        sizeLarge: {
          fontSize: "1rem",
        },
      },
      sizeLarge: {
        // padding: "12px 16px",
        // fontSize: "1rem",
        fontSize: 24,
        lineHeight: 1,
      },
      startIcon: {
        marginRight: 8,
        marginLeft: 0,
      },
      iconSizeLarge: {
        "&  *:first-child": {
          fontSize: 32,
        },
      },
    },
    MuiPaper: {
      root: {
        // padding: "24px"
      },
      elevation1: {
        boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.06)",
      },
      elevation2: {
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.08)",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: ".875rem",
        fontWeight: 400,
        color: "#fff",
        backgroundColor: "#6481A6",
      },
    },
  },
	*/
};

export default themeOptionsDefault;
