import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckBoxField from "components/form/CheckBoxField";
import BoxContent from "components/ui/BoxContent";
import Loading from "components/ui/Loading";

const CreateTestCategories = ({ type, loading, errors, categories, setSelectedCategoriesHandler }: any) => {
  return (
    <>
      <Typography variant="h3" mb={3} mt={3}>
        Alege capitolele
      </Typography>
      <BoxContent>
        <>
          {/* <Typography variant="body1" mb={3}>
                  O testare completă ar trebui să evalueze toate capitolele de mai jos. Dacă nu vrei să le
                  verifici pe toate, poți bifa doar ce te interesează acum. Durata testului va varia în funcție de
                  lucrurile selectate.
                </Typography> */}
          {loading.loading && loading.name === "categories" ? (
            <Loading show={true} />
          ) : (
            <>
              {(type === "bac" || type === "evaluate" || type === "digitalLiteracy") && (
                <>
                  {categories.length === 0 ? (
                    <Typography>Nu sunt itemi pentru selecția curentă.</Typography>
                  ) : (
                    <CheckBoxField
                      name="chapters"
                      type="checkbox-group"
                      label="Alege capitolele"
                      labelPlacement="end"
                      options={categories.map((item: { name: string; id: string; [key: string]: any }) => {
                        return {
                          disabled: type !== "digitalLiteracy",
                          label: item.name,
                          value: item.id.toString(),
                          checked: type !== "digitalLiteracy",
                          ...item,
                        };
                      })}
                      onChange={(e) => setSelectedCategoriesHandler(e)}
                    />
                  )}
                </>
              )}
              {(type === "school" || type === "training" || type === "literacy") && (
                <>
                  {categories.map((category: any, index: any) => (
                    <Box key={index} mb={2}>
                      <Typography variant="h4">
                        {category.name}
                      </Typography>
                      {category.subcategories?.length === 0 ? (
                        <Typography>Nu sunt itemi pentru selecția curentă. {type === "training" && "Modifică nivelul de dificultate și încearcă din nou."}</Typography>
                      ) : (
                        <CheckBoxField
                          name="chapters"
                          type="checkbox-group"
                          label="Alege capitolele"
                          labelPlacement="end"
                          options={category.subcategories?.map(
                            (item: { name: string; id: string; [key: string]: any }) => {
                              return {
                                disabled: type === "literacy",
                                label: item.name,
                                value: item.id.toString(),
                                checked: type === "literacy",
                                ...item,
                              };
                            },
                          )}
                          onChange={(e) => setSelectedCategoriesHandler(e, index)}
                        />
                      )}
                    </Box>
                  ))}
                </>
              )}
            </>
          )}
          {type === "school" && (
            <Typography variant="body1" mt={2}>
              Durata testului va varia în funcție de capitolele selectate. Timpul de rezolvare alocat acestuia va fi cuprins între <b>15 și 120</b> de minute.
            </Typography>
          )}

            {type === "training" && (
                <Typography variant="body1" mt={2}>
                    Durata testului va varia în funcție de capitolele si numărul de itemi selectați. Timpul de rezolvare alocat acestuia va fi cuprins între <b>15 și 120</b> de minute.
                </Typography>
            )}

          {type === "literacy" && (
            <Typography variant="body1" mt={2}>
              Timpul maxim alocat testului de literație este de 60 de minute. După expirarea timpului, sesiunea se va
              închide automat.
            </Typography>
          )}

          {type === "digitalLiteracy" && (
            <Typography variant="body1" mt={2}>
              Timpul alocat testului de literație digitală este de minimum 40 de minute și maximum 4 ore și 20 minute,
                în funcție de numărul capitolelor alese. După expirarea timpului, sesiunea se va închide automat.
            </Typography>
          )}

          {(type === "bac" || type === "evaluate") && (
            <Typography variant="body1" mt={2}>
                Sesiunea de testare va dura până la maximum 180 de minute.
            </Typography>
          )}
        </>
      </BoxContent>
    </>
  );
};

export default CreateTestCategories;
