import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import BoxContent from "components/ui/BoxContent";
import PageTitle from "components/ui/PageTitle";
import { APIGetClassrooms } from "api/pages/classrooms";
import { APIDeleteClassroom, APIUpdateClassroom } from "api/pages/classroom";
import Loading from "components/ui/Loading";
import CustomDataTable, { Order } from "components/ui/CustomDataTable";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { getRomanNumber } from "utils/functions";
import { niceUserName } from "utils/functions";
import SearchForm, { IFormInputs } from "components/form/SearchForm";
import { SubmitHandler } from "react-hook-form";
import FormField from "components/form/FormField";
import { APIGetTeachers } from "api/pages/teachers";
import GenericButton from "components/ui/GenericButton";

const Classroom = () => {
  const { update, user, tenant } = useAuth();
  const navigate = useNavigate();

  // const params = useParams();
  // const pageNumber = params.pageNumber ? parseInt(params.pageNumber, 10) : 1;

  const [loading, setLoading] = useState<boolean>(true);
  const [classrooms, setClassrooms] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(15);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("name");
  const [query, setQuery] = useState<string>("");
  const [search, setSearch] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    APIGetClassrooms({ page: `?page=${page + 1}`, sort: orderBy, order: order, search: query })
      .then((response) => {
        if (response.data.success) {
          // console.log("getClassroom OK", response);
          setClassrooms(response.data.data.data);
          setTotalRows(response.data.data.total);
          setPerPage(response.data.data.per_page);
          setLoading(false);
        } else {
          update("error", { show: true, code: "A002" });
        }
      })
      .catch((err) => {
        // console.log("getClassroom ERR", err);
        update("error", { show: true, code: "A001" });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, orderBy, order, query, search]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setClassrooms([]);
    setPage(newPage);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);
  };

  const handleSearch: SubmitHandler<IFormInputs> = (data) => {
    setQuery(data.keywords);
    setPage(0);
    setSearch(true);
  };

  const handleClearSearch = () => {
    setQuery("");
    setSearch(false);
    setPage(0);
  };

  const editClassroomtHandler = (id: number) => {
    navigate(`/clase/${id}/editare`);
  };

  const addClassroomHandler = () => {
    navigate(`/clase/adauga`);
  };

  const deleteClassroom = (id: string, callback: Function) => {
    update("loading", { show: true, modal: true });
    APIDeleteClassroom({ id })
      .then((response) => {
        if (response.data.success) {
          // console.log("deleteClassroom OK", response);
          update("loading", { show: false });
          setClassrooms(classrooms.filter((item) => item.id.toString() !== id.toString()));
          // navigate("/clase");
        } else {
          update("loading", { show: false });
          update("error", { show: true, code: "A002" });
        }
        callback();
      })
      .catch((err) => {
        // console.log("deleteClassroom ERR", err);
        update("loading", { show: false });
        update("error", { show: true, code: "A001" });
      });
  };

  const confirmDelete = (classroom: { id: number; name: string; grade: number; [key: string]: any }) => {
    update("confirm", {
      show: true,
      closable: true,
      title: "Ești sigur că vrei să ștergi această clasă?",
      message: (
        <>
          Acțiunea va șterge definitiv clasa
          <strong> {classroom.name}</strong>!
        </>
      ),
      returnFunction: (callback: Function) => {
        deleteClassroom(classroom.id.toString(), callback);
      },
      buttonYes: "Șterge",
      buttonNo: "Închide",
    });
  };

  const addTeachers = (id: string, name: string, grade: number, teachers: [], students: [], callback: Function) => {
    const classroomStudents = students.map(({ id }: any) => id);
    let classroomTeachers: number[] = [];
    teachers && (classroomTeachers = teachers.map(({ id }: any) => id));

    const users = [...classroomTeachers, ...classroomStudents];

    update("loading", { show: true, modal: true });
    APIUpdateClassroom(id, {
      name: name,
      grade: grade,
      users: users })
      .then((response) => {
        if (response.data.success) {
          // console.log("updateClassroom OK", response);
          // logic after update
          const newClassrooms = classrooms.map((classroom) => {
            if (classroom.id.toString() === id) {
              return { ...classroom, teachers: teachers };
            }

            return classroom;
          });

          setClassrooms(newClassrooms);
          // setEditMode(false);
          update("loading", { show: false });
        } else {
          update("loading", { show: false });
          update("error", { show: true, code: "A002" });
        }
        callback();
      })
      .catch((err) => {
        // console.log("updateClassroom ERR", err);
        update("error", { show: true, code: "A001" });
      });
  };

  const confirmAddTeachers = (classroom: {
    id: number;
    name: string;
    grade: number;
    teachers: [];
    students: [];
    [key: string]: any;
  }) => {
    let teachers = classroom.teachers;
    update("confirm", {
      show: true,
      closable: true,
      title: "Adaugă profesori",
      size: "large",
      message: (
        <>
          <Box sx={{ border: "1px solid #ddd", borderRadius: "16px" }}>
            <FormField
              name="teachers"
              type="autocomplete_field"
              label="Caută profesor..."
              optionsAPI={APIGetTeachers}
              multiple={true}
              filterOptions={(x: any) => x}
              onChangeAutocomplete={(event, value) => (teachers = value)}
              AutocompleteFieldDefaultValue={
                classroom.teachers
                  ? classroom.teachers.map((item: { last_name: string; first_name: string; id: string }) => {
                      return {
                        label: `${item.first_name} ${item.last_name}`,
                        value: item.id,
                        ...item,
                      };
                    })
                  : []
              }
              // errorMessage={methods.formState.errors.teachers?.message}
            />
          </Box>
        </>
      ),
      returnFunction: (callback: Function) => {
        addTeachers(classroom.id.toString(), classroom.name, classroom.grade, teachers, classroom.students, callback);
      },
      buttonYes: "Adaugă",
      buttonNo: "Închide",
    });
  };

  return (
    <Box>
      <PageTitle
        actions={
          // <CustomButton
          //   buttonType="icon"
          //   width={36}
          //   height={36}
          //   icon={<Icon icon="plus" fixedWidth={true} sx={{ fontSize: "1.25rem" }} />}
          //   onClick={() => {
          //     addClassroomHandler();
          //   }}
          // />
          <GenericButton
            type="add"
            onClick={() => {
              addClassroomHandler();
            }}
          />
        }
      >
        Clase
      </PageTitle>
      <BoxContent>
        <Box display="flex" justifyContent="end" width="100%" sx={{ marginBottom: { xs: 1, sm: 3 },}}>
          <SearchForm
            onSubmit={handleSearch}
            onChange={(e) => setQuery(e.target.value)}
            onClick={handleClearSearch}
            query={query}
          />
        </Box>
        {loading ? (
          <Loading show={true} />
        ) : classrooms.length === 0 ? (
          <Box>Nicio clasă introdusă în platformă.</Box>
        ) : (
          <>
            <CustomDataTable
              columns={[
                {
                  id: "name",
                  label: "Nume",
                  numeric: false,
                  disablePadding: false,
                  sortable: true,
                  noWrap: true,
                },
                {
                  id: "grade",
                  label: "Clasa",
                  numeric: false,
                  disablePadding: false,
                  sortable: true,
                },
                {
                  id: "students_count",
                  label: "Elevi",
                  numeric: true,
                  disablePadding: false,
                },
                {
                  id: "teachers",
                  label: "Profesori",
                  numeric: false,
                  noWrap: true,
                  tooltip: true,
                  disablePadding: false,
                },
                {
                  id: "actions",
                  label: "Acțiuni",
                  numeric: true,
                  noWrap: false,
                  disablePadding: true,
                },
              ]}
              rows={classrooms.map((classroom) => {
                return {
                  name: <Link to={`/clase/${classroom.id}`}>{classroom.name}</Link>,
                  grade: getRomanNumber(classroom.grade),
                  students_count: <span>{classroom.students_count}</span>,
                  teachers: (
                    <>
                      {classroom.teachers.map((teacher: any, index: any) => (
                        <Box key={index} mr={1}>
                          {user.position === "principal" && (
                            <Link to={`/profesori/${teacher.id}`}>
                              {niceUserName(teacher.first_name, teacher.last_name, teacher.email)}
                            </Link>
                          )}
                          {user.position === "teacher" &&
                            niceUserName(teacher.first_name, teacher.last_name, teacher.email)}
                        </Box>
                      ))}
                    </>
                  ),
                  actions: (
                    <Box display="flex" justifyContent="end">
                      {user.position === "principal" && (
                        <Box mr={1}>
                          <GenericButton type="add-teacher" onClick={() => confirmAddTeachers(classroom)} />
                        </Box>
                      )}
                      <Box mr={1}>
                        <GenericButton
                          type="edit-small"
                          onClick={() => {
                            editClassroomtHandler(classroom.id);
                          }}
                        />
                      </Box>
                      {(user.position === "principal" || tenant.type === "teachers") && (
                      <Box>
                        <GenericButton type="delete" onClick={() => confirmDelete(classroom)} />
                      </Box>
                          )}
                    </Box>
                  ),
                };
              })}
              rowsPerPage={perPage}
              page={page}
              totalRows={totalRows}
              onPageChange={handleChangePage}
              handleRequestSort={handleRequestSort}
              orderBy={orderBy}
              order={order}
            />
          </>
        )}
      </BoxContent>
    </Box>
  );
};

export default Classroom;
