import React, { useEffect, useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import { ITest } from "components/test/_interfaces";
import Test from "components/test/Test";
import { APIGetTest } from "api/pages/test";
import { useAuth } from "hooks/useAuth";

const TestPage = () => {
  const { id = "" } = useParams();
  const [test, setTest] = useState<ITest | null>(null);
  const { update } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    update("loading", { show: true, modal: true });
    APIGetTest({ test: id})
      .then((response) => {
        if (response.data.success) {
          setTest(response.data.data);
          update("loading", { show: false });
        } else {
          if (response.data.code === 400) {
            update("error", {
              show: true,
              title: "Sesiunea de testare a expirat",
              message: "Contactează-ți profesorul coordonator pentru mai multe detalii.",
              button: "ÎNCHIDE",
              icon: "fat face-eyes-xmarks",
              closable: true,
              returnFunction: () => {
                navigate("/");
              }
          })} else {
              update("error", { show: true, code: "A002" });
            }
          update("loading", { show: false });

        }
      })
      .catch((err) => {
        update("error", { show: true, code: "A001" });
        update("loading", { show: false });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // return test !== null ? <Test test={test} /> : null;
  return test !== null ? <Test test={test} testFromClient={false} /> : null;
};

export default TestPage;
