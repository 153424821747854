import SimpleBarReact from "simplebar-react";
import { styled } from "@mui/system";

const SimpleBarReactStyled = styled(SimpleBarReact)(({ theme }) => ({
  "& .simplebar-track .simplebar-scrollbar:before": {
    backgroundColor: theme.palette.info.main,
  },
  // "& .simplebar-content-wrapper": {
  //   minHeight: "900px",
  // }
}));

export default SimpleBarReactStyled;
