import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import BoxContent from "components/ui/BoxContent";
import PageTitle from "components/ui/PageTitle";
import { APIGetTeacher } from "api/pages/teacher";
import Loading from "components/ui/Loading";
import { useAuth } from "hooks/useAuth";
import { APIUpdateTeacher, APIPostTeacher } from "api/pages/teacher";
import FormField from "components/form/FormField";
import ErrorsList from "components/ui/ErrorsList";
import { useLocation } from "react-router-dom";
import { APIGetSubjects } from "api/pages/subjects";
import { APIGetAllClassrooms } from "api/pages/classrooms";
import {arrayEquals, getRomanNumber, niceUserName} from "utils/functions";
import FormLabelBox from "components/ui/FormLabelBox";
import FormFieldBox from "components/ui/FormFieldBox";
import GenericButton from "components/ui/GenericButton";
import CustomButton from "../components/ui/CustomButton";
interface IFormTeacherEdit {
  last_name: string;
  first_name: string;
  email: string;
  subjects: any[];
  classrooms: any[];
  // show_password?: boolean;
  // password?: string;
  // confirm_password?: string;
}

interface ISubjects {
  value: string;
  label: string;
}

const schema = yup
  .object({
  })
  .required();

const Teacher = () => {
  const { id = "", action } = useParams();
  const { update } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const methods = useForm<IFormTeacherEdit>({
    resolver: yupResolver(schema),
  });

  const [loadingClassrooms, setLoadingClassrooms] = useState<boolean>(true);
  const [loadingSubjects, setLoadingSubjects] = useState<boolean>(true);
  const [loadingTeacher, setLoadingTeacher] = useState<boolean>(true);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [teacher, setTeacher] = useState<any>();
  const [subjects, setSubjects] = useState<ISubjects[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [classrooms, setClassrooms] = useState<any[]>([]);

  useEffect(() => {
    setLoadingClassrooms(true);
    setLoadingTeacher(true);
    setLoadingSubjects(true);

    APIGetSubjects()
      .then((response) => {
        if (response.data.success) {
          // console.log("getSubjects Ok", response);
          setSubjects(
            response.data.data.items.map((item: { name: string; id: string }) => {
              return {
                label: item.name,
                value: item.id,
                ...item,
              };
            }),
          );
          setLoadingSubjects(false);
        } else {
          update("error", { show: true, code: "A002" });
        }
      })
      .catch((err) => {
        // console.log("getSubjects ERR", err);
        update("error", { show: true, code: "A001" });
      });

    APIGetAllClassrooms()
        .then((response) => {
          if (response.data.success) {
            console.log("getClassroom OK", response);
            setClassrooms(
                response.data.data.items.map((item: { name: string; id: string, grade: number }) => {
                  return {
                    label: `(${getRomanNumber(item.grade)}) ${item.name}`,
                    value: item.id,
                    ...item,
                  };
                }),
            );
            setLoadingClassrooms(false);
          } else {
            update("error", { show: true, code: "A002" });
          }
        })
        .catch((err) => {
          // console.log("getClassroom ERR", err);
          update("error", { show: true, code: "A001" });
        });

    if (id !== "") {
      APIGetTeacher({ id: id })
        .then((response) => {
          if (response.data.success) {
            // console.log("getTeacher OK", response);
            // api call to get disciplines
            // on ok do following
            if (response.data.data !== null) {
              setTeacher(response.data.data.item);
            } else {
              navigate("/pagina-nu-exista");
            }
            setLoadingTeacher(false);
          } else {
            update("error", { show: true, code: "A002" });
          }
        })
        .catch((err) => {
          // console.log("getTeacher ERR", err);
          update("error", { show: true, code: "A001" });
        });
    } else {
      let locationArray = location.pathname.split("/");
      if (locationArray[locationArray.length - 1] === "adauga") {
        setTeacher({
          last_name: "",
          first_name: "",
          email: "",
        });
        setEditMode(true);
        setLoadingTeacher(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id !== "") {
      methods.clearErrors();
      setErrorMessage("");
      setEditMode(action === "editare");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, id]);

  // console.log(teacher);

  const onSubmit: SubmitHandler<IFormTeacherEdit> = (data) => {
    let teacherSubjects: number[] = [];
    let teacherClassrooms: number[] = [];
    let oldTeacherSubjects: number[] = [];
    let oldTeacherClassrooms: number[] = [];

    data.subjects && (teacherSubjects = data.subjects.map(({ value }) => value));
    data.classrooms && (teacherClassrooms = data.classrooms.map(({ value }) => value));
    teacher.subjects && (oldTeacherSubjects = teacher.subjects.map(({ id }: any) => id));
    teacher.classrooms && (oldTeacherClassrooms = teacher.classrooms.map(({ id }: any) => id));

    // data = {
    //   ...data,
    //   subjects: data.subjects.map((subject: { label: string; value: string }) => ({
    //     name: subject.label,
    //     id: subject.value,
    //   })),
    //   classrooms: data.classrooms.map((classroom: { label: string; value: string }) => ({
    //     name: classroom.label,
    //     id: classroom.value,
    //   })),
    // };

    if (
      data.last_name !== teacher.last_name ||
      data.first_name !== teacher.first_name ||
      data.email !== teacher.email ||
      !arrayEquals(teacherSubjects, oldTeacherSubjects) ||
      !arrayEquals(teacherClassrooms, oldTeacherClassrooms)
    ) {
      methods.clearErrors();
      setErrorMessage("");
      update("loading", { show: true, modal: true });
      if (id !== "") {
        APIUpdateTeacher(id, {
          // ...(data.last_name !== teacher.last_name && { last_name: data.last_name }),
          // ...(data.first_name !== teacher.first_name && { first_name: data.first_name }),
          // ...(data.email !== teacher.email && { email: data.email }),
          // ...(!arrayEquals(teacherSubjects, oldTeacherSubjects) && { subjects: teacherSubjects }),
          // ...(!arrayEquals(teacherClassrooms, oldTeacherClassrooms) && { classrooms: teacherClassrooms }),
          last_name: data.last_name,
          first_name: data.first_name,
          email: data.email,
          subjects: teacherSubjects,
          classrooms: teacherClassrooms,
        })
          .then((response) => {
            if (response.data.success) {
              // console.log("updateTeacher OK", response);
              // logic after update
              setTeacher(data);
              setEditMode(false);
              navigate(-1);
              update("loading", { show: false });
            } else {
              update("loading", { show: false });
              update("error", { show: true, code: "A002" });
            }
          })
          .catch((err) => {
            // console.log("updateTeacher ERR", err);
            if (err.response.status === 400) {
              let errors = err.response.data.data;
              if (errors.last_name) {
                methods.setError("last_name", { type: "server", message: errors.last_name[0] });
              }
              if (errors.first_name) {
                methods.setError("first_name", { type: "server", message: errors.first_name[0] });
              }
              if (errors.email) {
                methods.setError("email", { type: "server", message: errors.email[0] });
              }
              if (errors.classrooms) {
                methods.setError("classrooms", { type: "server", message: errors.classrooms[0] });
              }
              if (errors.subjects) {
                methods.setError("subjects", { type: "server", message: errors.subjects[0] });
              }
              // if (errors.password) {
              //   methods.setError("password", { type: "server", message: errors.password[0] });
              // }
              // if (errors.confirm_password) {
              //   methods.setError("confirm_password", { type: "server", message: errors.confirm_password[0] });
              // }
              update("loading", { show: false });
            } else {
              update("error", { show: true, code: "A001" });
            }
          });
      } else {
        APIPostTeacher({
          last_name: data.last_name,
          first_name: data.first_name,
          email: data.email,
          // password: data.password,
          // confirm_password: data.confirm_password,
          subjects: teacherSubjects,
          classrooms: teacherClassrooms,
        })
          .then((response) => {
            if (response.data.success) {
              // console.log("postTeacher OK", response);
              // logic after update
              setTeacher(data);
              setEditMode(false);
              navigate(`/profesori/${response.data.data.item.id}`, { replace: true });
              update("loading", { show: false });
            } else {
              update("loading", { show: false });
              update("error", { show: true, code: "A002" });
            }
          })
          .catch((err) => {
            // console.log("postTeacher ERR", err);
            if (err.response.status === 400) {
              let errors = err.response.data.data;
              if (errors.last_name) {
                methods.setError("last_name", { type: "server", message: errors.last_name[0] });
              }
              if (errors.first_name) {
                methods.setError("first_name", { type: "server", message: errors.first_name[0] });
              }
              if (errors.email) {
                methods.setError("email", { type: "server", message: errors.email[0] });
              }
              if (errors.classrooms) {
                methods.setError("classrooms", { type: "server", message: errors.classrooms[0] });
              }
              if (errors.subjects) {
                methods.setError("subjects", { type: "server", message: errors.subjects[0] });
              }
              // if (errors.password) {
              //   methods.setError("password", { type: "server", message: errors.password[0] });
              // }
              // if (errors.confirm_password) {
              //   methods.setError("confirm_password", { type: "server", message: errors.confirm_password[0] });
              // }
              update("loading", { show: false });
            } else {
              update("error", { show: true, code: "A001" });
            }
          });
      }
    } else {
      setErrorMessage("Nu ați modificat niciun câmp.");
    }
  };

  return (
    <Box component="main">
      {(loadingTeacher || loadingClassrooms || loadingSubjects) ? (
        <Loading show={true} />
      ) : (
        <>
          <PageTitle
            back={true}
            actions={
              editMode ? (
                <GenericButton
                  type="close"
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              ) : (
                <GenericButton
                  type="edit"
                  onClick={() => {
                    navigate(`/profesori/${id}/editare`);
                  }}
                />
              )
            }
          >
            {id !== "" ? (
              <>
                Profesor{" "}
                <strong style={{ fontWeight: 400 }}>
                  {niceUserName(teacher.first_name, teacher.last_name, teacher.email)}
                </strong>
              </>
            ) : (
              <>Adaugă profesor</>
            )}
          </PageTitle>

          <BoxContent>
            <Box width="100%">
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                  {/* <input
                    type="hidden"
                    name="show_password"
                    ref={methods.register("show_password").ref}
                    defaultValue={id === "" ? "true" : "false"}
                  /> */}
                  <Grid container spacing={1.5} alignItems="center">
                    <FormLabelBox title="Nume:" />

                    <FormFieldBox>
                      {!editMode ? (
                        teacher.last_name
                      ) : (
                        <FormField
                          name="last_name"
                          type="text"
                          defaultValue={teacher.last_name}
                          label="Nume"
                          autoFocus={true}
                          errorMessage={methods.formState.errors.last_name?.message}
                          error={!!methods.formState.errors.last_name?.message}
                        />
                      )}
                    </FormFieldBox>

                    <FormLabelBox title="Prenume:" />

                    <FormFieldBox>
                      {!editMode ? (
                        teacher.first_name
                      ) : (
                        <FormField
                          name="first_name"
                          type="text"
                          defaultValue={teacher.first_name}
                          label="Prenume"
                          errorMessage={methods.formState.errors.first_name?.message}
                          error={!!methods.formState.errors.first_name?.message}
                        />
                      )}
                    </FormFieldBox>

                    <FormLabelBox title="E-mail:" />

                    <FormFieldBox>
                      {!editMode ? (
                        teacher.email
                      ) : (
                        <FormField
                          name="email"
                          type="text"
                          defaultValue={teacher.email}
                          label="E-mail"
                          errorMessage={methods.formState.errors.email?.message}
                          error={!!methods.formState.errors.email?.message}
                          inputProps={{
                            autoCapitalize: 'none',
                          }}
                        />
                      )}
                    </FormFieldBox>

                    <FormLabelBox title="Materii:" />

                    <FormFieldBox>
                      {!editMode ? (
                        teacher.subjects ? (
                          <>
                            {teacher.subjects.map((subject: any, index: number) => (
                              <Box key={index}>{subject.name}</Box>
                            ))}
                          </>
                        ) : (
                          <Box>Nimic selectat</Box>
                        )
                      ) : (
                        <FormField
                          name="subjects"
                          type="autocomplete"
                          defaultValue={
                            teacher.subjects &&
                            teacher.subjects.map((item: { name: string; id: string }) => {
                              return {
                                label: item.name,
                                value: item.id,
                                ...item,
                              };
                            })
                          }
                          label="Alege materiile"
                          multiple={true}
                          options={subjects}
                          errorMessage={methods.formState.errors.subjects?.message}
                          error={!!methods.formState.errors.subjects?.message}
                        />
                      )}
                    </FormFieldBox>

                    <FormLabelBox title="Clasă:" />

                    <FormFieldBox>
                      {!editMode ? (
                        teacher.classrooms ? (
                          <>
                            {teacher.classrooms.map((classroom: any, index: number) => (
                              <Box key={index}>
                                <Link to={`/clase/${classroom.id}`}>({getRomanNumber(classroom.grade)}) {classroom.name}</Link>
                              </Box>
                            ))}
                          </>
                        ) : (
                          <Box>Nimic selectat</Box>
                        )
                      ) : (
                          <FormField
                              name="classrooms"
                              type="autocomplete"
                              defaultValue={
                                teacher.classrooms
                                    ? teacher.classrooms.map((item: { name: string; id: string, grade: number }) => {
                                      return {
                                        label: `(${getRomanNumber(item.grade)}) ${item.name}`,
                                        value: item.id,
                                        ...item,
                                      };
                                    })
                                    : []
                              }
                              label="Alege clasa"
                              multiple={true}
                              options={classrooms}
                              errorMessage={methods.formState.errors.classrooms?.message}
                              error={!!methods.formState.errors.classrooms?.message}
                          />
                      )}
                    </FormFieldBox>
                    {/* {editMode && id === "" && (
                      <>
                        <FormLabelBox title="Parola:" />

                        <FormFieldBox>
                          <FormField
                            name="password"
                            type="password"
                            label="Parola"
                            errorMessage={methods.formState.errors.password?.message}
                          />
                        </FormFieldBox>

                        <FormLabelBox title="Confirmă parola:" />
                        <FormFieldBox>
                          <FormField
                            name="confirm_password"
                            type="password"
                            label="Confirmă parola"
                            errorMessage={methods.formState.errors.confirm_password?.message}
                          />
                        </FormFieldBox>
                      </>
                    )} */}
                    {editMode && (
                      <>
                        <FormLabelBox />
                        <FormFieldBox>
                          <CustomButton submit buttonType="textIcon" bgcolor="dark">
                            {id !== "" ? "SALVEAZĂ DATELE" : "ADAUGĂ PROFESOR"}
                          </CustomButton>
                          <ErrorsList show={errorMessage !== ""}>{errorMessage}</ErrorsList>
                        </FormFieldBox>
                      </>
                    )}
                  </Grid>
                </form>
              </FormProvider>
            </Box>
          </BoxContent>
        </>
      )}
    </Box>
  );
};

export default Teacher;
