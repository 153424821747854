import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuth } from "hooks/useAuth";
import {APIGetProfile, APIUpdateProfile} from "api/pages/profile";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import BoxContent from "components/ui/BoxContent";
import PageTitle from "components/ui/PageTitle";
import UserInfo from "components/sidebar/UserInfo";
import FormField from "components/form/FormField";
import ErrorsList from "components/ui/ErrorsList";
import UploadButton from "components/form/UploadButton";
import CustomAvatar from "components/ui/CustomAvatar";
import FormLabelBox from "components/ui/FormLabelBox";
import FormFieldBox from "components/ui/FormFieldBox";
import DashboardBox from "components/dashboard/DashboardBox";
import GenericButton from "components/ui/GenericButton";
import Typography from "@mui/material/Typography";
// import {APICancelSubscription} from "../api/pages/subscriptions";
import Loading from "../components/ui/Loading";
import moment from "moment";
import CustomButton from "../components/ui/CustomButton";

interface IFormProfileEdit {
  lastname: string;
  firstname: string;
  email: string;
  show_password?: boolean;
  password?: string;
  confirm_password?: string;
  file?: string;
}

const schema = yup
  .object({
    // lastname: yup.string().required("Numele este obligatoriu"),
    // firstname: yup.string().required("Prenumele este obligatoriu"),
    // email: yup.string().required().email("Adresa de e-mail este obligatorie"),
    // show_password: yup.boolean(),
    // password: yup.string().when("show_password", {
    //   is: true,
    //   then: yup.string().required("Parola este obligatorie."),
    // }),
    // confirm_password: yup.string().when("show_password", {
    //   is: true,
    //   then: yup.string().oneOf([yup.ref("password"), null], "Parolele nu sunt identice"),
    // }),
  })
  .required();

const Profile = () => {
  const { user, update } = useAuth();
  const { action } = useParams();
  const navigate = useNavigate();

  const methods = useForm<IFormProfileEdit>({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const [fileUrl, setFileUrl] = useState<string>("");
  const [credits, setCredits] = useState<any[]>([]);
  // const [subscriptions, setSubscriptions] = useState<any[]>([]);
  // const [extra, setExtra] = useState<any[]>([]);

  useEffect(() => {
      setLoading(true);
      APIGetProfile()
          .then((response) => {
            if (response.data.success) {
              // console.log("getProfile OK", response);
              setCredits(response.data.data.credits);
              // if(response.data.data.subscriptions.subscription) {
              //     setSubscriptions(response.data.data.subscriptions.subscription);
              // }
              //   if(response.data.data.subscriptions.extra) {
              //       setSubscriptions(response.data.data.subscriptions.subscription);
              //   }
              setLoading(false);
            } else {
              update("error", { show: true, code: "A002" });
            }
          })
          .catch((err) => {
            // console.log("getClassroom ERR", err);
            update("error", { show: true, code: "A001" });
          });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    methods.clearErrors();
    setErrorMessage("");
    setEditMode(action === "editare");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  // const editModeHandler = () => {
  //   setEditMode((current) => !current);
  // };

  const updateUploadedFile = (file: File | null, selectedFileUrl: string) => {
    setSelectedFile(file);
    setFileUrl(selectedFileUrl);
  };

  const onSubmit: SubmitHandler<IFormProfileEdit> = (data) => {
    if (
      data.lastname !== user.lastname ||
      data.firstname !== user.firstname ||
      data.email !== user.email ||
      selectedFile
    ) {
      methods.clearErrors();
      setErrorMessage("");
      update("loading", { show: true, modal: true });

      const formData = new FormData();
        formData.append("last_name", data.lastname);
        formData.append("first_name", data.firstname);
        formData.append("email", data.email);
        formData.append("profile_photo", selectedFile ? selectedFile : "" );
      // !selectedFile && formData.append("profile_photo", " ");
      selectedFile && (data.file = fileUrl);
      APIUpdateProfile(formData)
        .then((response) => {
          if (response.data.success) {
            if (data.file) {
              const newData = data;
              newData.file = response.data.data.value;
              update("user", {email: newData.email, firstname: newData.firstname, lastname: newData.lastname, profilePhoto: newData.file});
            } else {
              update("user", data);
            }
            setEditMode(false);
            navigate(-1);
            update("loading", { show: false });
          } else {
            update("loading", { show: false });
            update("error", { show: true, code: "A002" });
          }
        })
        .catch((err) => {
          // console.log("updateProfile ERR", err);
          if (err.response.status === 400) {
            let errors = err.response.data.data;
            if (errors.last_name) {
              methods.setError("lastname", { type: "server", message: errors.last_name[0] });
            }
            if (errors.first_name) {
              methods.setError("firstname", { type: "server", message: errors.first_name[0] });
            }
            if (errors.email) {
              methods.setError("email", { type: "server", message: errors.email[0] });
            }
            if (errors.profile_photo) {
              methods.setError("file", { type: "server", message: errors.profile_photo });
            }
            update("loading", { show: false });
          } else {
            update("error", { show: true, code: "A001" });
          }
        });
    } else {
      setErrorMessage("Nu ați modificat niciun câmp.");
    }
  };

    // const cancelSubscription = (type: string, callback: Function) => {
    //     update("loading", { show: true, modal: true });
    //     // const payment_method = id;
    //     APICancelSubscription({ type: type })
    //         .then((response) => {
    //             if (response.data.success) {
    //                 // console.log("deleteCard OK", response);
    //                 update("loading", { show: false });
    //                 // setCards(cards.filter((item) => item.id !== id));
    //                 // navigate("/elevi");
    //             } else {
    //                 update("loading", { show: false });
    //                 update("error", { show: true, code: "A002" });
    //             }
    //             callback();
    //         })
    //         .catch((err) => {
    //             if (err.response.status === 400) {
    //                 let errors = err.response.data.data.values[0];
    //                 update("error", { show: true, message: errors });
    //             } else {
    //                 update("error", { show: true, code: "A001" });
    //             }
    //             update("loading", { show: false });
    //         });
    // };

    // const confirmCancelSubscription = (product: { price_id: string; product_name: string; product_type: string; [key: string]: any }) => {
    //     update("confirm", {
    //         show: true,
    //         closable: true,
    //         title: "Ești sigur că vrei să anulezi subscripția?",
    //         message: (
    //             <>
    //                 Acțiunea va anula subscripția
    //                 <strong style={{ textTransform: "uppercase" }}> {product.product_name}</strong>!
    //             </>
    //         ),
    //         returnFunction: (callback: Function) => {
    //             cancelSubscription(product.product_type, callback);
    //         },
    //         buttonYes: "Anulează subscripția",
    //         buttonNo: "Închide",
    //     });
    // };

  return (
    <Box component="main">
      <PageTitle
        back={editMode}
        actions={
          user.position !== "student" && (editMode ? (
            <GenericButton
              type="close"
              onClick={() => {
                navigate(-1);
              }}
            />
          ) : (
            <GenericButton
              type="edit"
              onClick={() => {
                navigate(`/profil/editare`);
              }}
            />
          ))
        }
      >
        Profilul meu
      </PageTitle>
        {loading ? (
            <Loading show={true} />
        ) : (
            <>
                {!editMode ? (
                    <>
                        <DashboardBox type="jsx" number={0} title="Datele contului" icon="fal user-pen">
                            <UserInfo small={false} />
                        </DashboardBox>
                        {credits.length > 0 && (
                            <>
                                <Typography variant="h3" mt={3} mb={3}>Credite</Typography>
                                <Box mb={3}>
                                    <DashboardBox
                                        type="jsx"
                                        number={0}
                                        title="Credite"
                                        icon="fal box-taped"
                                    >
                                        <>
                                            {credits.map((credit: any, index: any) => (
                                                <Box display="flex" justifyContent="space-between" width={{
                                                    xs: "auto",
                                                    xl: "50%"
                                                }} key={index}>
                                                    <Box mb={2}>
                                                        <Typography variant="body1" >
                                                            {credit.name}
                                                        </Typography>
                                                        {credit.expire_at &&
                                                            <Typography component="span" variant="body2" sx={{color: "error.main"}}>(expiră în data
                                                                de <b>{moment(credit.expire_at.item).locale("ro").format("DD.MM.YYYY HH:mm")}</b>)</Typography>}
                                                    </Box>

                                                    <Typography variant="h4" mb={2}>
                                                        {credit.quantity}
                                                    </Typography>

                                                </Box>
                                            ))}
                                        </>
                                        <Box mt={2}>
                                            <Typography>* Creditele nu se reportează în luna următoare.</Typography>
                                            <Typography>* Testele școlare includ testele de matematică, testele de română și testele de istorie.</Typography>
                                            <Typography>* Testele BAC/Evaluare Națională se calculeaza in functie de numărul de elevi din clasele VIII/XII.</Typography>
                                        </Box>
                                    </DashboardBox>
                                </Box>
                            </>

                        )}
                        {/*{tenant.type === "teacher" && user.position === "teacher" && (*/}
                        {/*    <>*/}
                        {/*        {subscriptions.length === 0 ? (*/}
                        {/*            <>*/}
                        {/*                <Typography variant="h3" mt={3} mb={3}>Abonament</Typography>*/}
                        {/*                <BoxContent>*/}
                        {/*                    <Typography variant="body1">Niciun abonament activ!</Typography>*/}
                        {/*                    <Button*/}
                        {/*                        variant="contained"*/}
                        {/*                        startIcon={<Icon icon="plus" fixedWidth={true} sx={{ fontSize: "1.25rem" }} />}*/}
                        {/*                        onClick={() => {*/}
                        {/*                            navigate(`/abonamente-extraoptiuni`)*/}
                        {/*                        }}*/}
                        {/*                    >*/}
                        {/*                        Cumpără abonament*/}
                        {/*                    </Button>*/}
                        {/*                </BoxContent>*/}
                        {/*            </>*/}
                        {/*        ) : (*/}
                        {/*            <>*/}
                        {/*                <Typography variant="h3" mt={3} mb={3}>Abonament</Typography>*/}
                        {/*                {subscriptions.map((subscription: any, index: any) => (*/}
                        {/*                    subscription.product_type === "subscription" && (*/}
                        {/*                        <Box mb={3} key={index}>*/}
                        {/*                            <DashboardBox*/}
                        {/*                                type="jsx"*/}
                        {/*                                number={0}*/}
                        {/*                                title={`${subscription.product_name} - ${subscription.product_price} lei/luna`}*/}
                        {/*                                icon="fal box-taped"*/}
                        {/*                                actions={ !subscription.subscription_ends_at && <GenericButton type="delete-subscription" onClick={() => {confirmCancelSubscription(subscription);}} />*/}
                        {/*                                }*/}
                        {/*                            >*/}
                        {/*                                <>*/}
                        {/*                                    {subscription.product_credits.length > 0 && subscription.product_credits.map((credit: any, index: any) => (*/}
                        {/*                                        <Box display="flex" justifyContent="space-between" width="50%" key={index}>*/}
                        {/*                                            <Typography variant="body1" mb={2}>*/}
                        {/*                                                {credit.name}*/}
                        {/*                                            </Typography>*/}
                        {/*                                            <Typography variant="h4" mb={2}>*/}
                        {/*                                                {credit.user_credits.quantity}/{credit.quantity}*/}
                        {/*                                            </Typography>*/}
                        {/*                                        </Box>*/}
                        {/*                                    ))}*/}

                        {/*                                    {subscription.subscription_ends_at ? (*/}
                        {/*                                        <Typography variant="body2" sx={{color: "error.main"}}>Abonamentul dvs. se va încheia în data*/}
                        {/*                                            de <b>{moment(subscription.subscription_ends_at.item).locale("ro").format("DD.MM.YYYY HH:mm")}</b>.</Typography>*/}
                        {/*                                    ) : (*/}
                        {/*                                        <>*/}
                        {/*                                            <Typography variant="body2">Abonamentul dvs. este activ și se va reînnoi în*/}
                        {/*                                                data de {moment(subscription.subscription_billing_date.item).locale("ro").format("DD.MM.YYYY HH:mm")}.</Typography>*/}
                        {/*                                            <Typography variant="body2">În cazul în care dorești să anulezi abonamentul te rugăm să alegi opțiunea de anulare a abaoamentului de mai sus.</Typography>*/}
                        {/*                                        </>*/}
                        {/*                                    )}*/}
                        {/*                                </>*/}
                        {/*                            </DashboardBox>*/}
                        {/*                        </Box>*/}
                        {/*                    )*/}
                        {/*                ))}*/}
                        {/*            </>*/}
                        {/*        )}*/}
                        {/*        {extra &&*/}
                        {/*            <>*/}
                        {/*                <Typography variant="h3" mt={3} mb={3}>Extraopțiuni</Typography>*/}
                        {/*                {extra.length === 0 ? (*/}
                        {/*                    <BoxContent>*/}
                        {/*                        <Typography variant="body1">Nu ai nicio extraopțiune activă!</Typography>*/}
                        {/*                        <Button*/}
                        {/*                            variant="contained"*/}
                        {/*                            startIcon={<Icon icon="plus" fixedWidth={true} sx={{ fontSize: "1.25rem" }} />}*/}
                        {/*                            onClick={() => {*/}
                        {/*                                navigate(`/abonamente-extraoptiuni`)*/}
                        {/*                            }}*/}
                        {/*                        >*/}
                        {/*                            Cumpără extraopțiune*/}
                        {/*                        </Button>*/}
                        {/*                    </BoxContent>*/}
                        {/*                ) : (*/}
                        {/*                    <>*/}
                        {/*                        {extra.map((subscription: any, index: any) => (*/}
                        {/*                            <Box mb={3} key={index}>*/}
                        {/*                                <DashboardBox*/}
                        {/*                                    type="jsx"*/}
                        {/*                                    number={0}*/}
                        {/*                                    title={`${subscription.product_name} - 41 lei/luna`}*/}
                        {/*                                    icon="fal boxes-stacked"*/}
                        {/*                                    actions={!subscription.subscription_ends_at && <GenericButton type="delete-extra" onClick={() => {confirmCancelSubscription(subscription);}} />}*/}
                        {/*                                >*/}
                        {/*                                    {subscription.product_credits.length > 0 && subscription.product_credits.map((credit: any, index: any) => (*/}
                        {/*                                        <Box display="flex" justifyContent="space-between" width="50%" key={index}>*/}
                        {/*                                            <Typography variant="body1" mb={2}>*/}
                        {/*                                                {credit.name}*/}
                        {/*                                            </Typography>*/}
                        {/*                                            <Typography variant="h4" mb={2}>*/}
                        {/*                                                12/24*/}
                        {/*                                            </Typography>*/}
                        {/*                                        </Box>*/}
                        {/*                                    ))}*/}
                        {/*                                    {subscription.subscription_ends_at ? (*/}
                        {/*                                        <Typography variant="body2">Extraopțiunea dvs. se va încheia în data*/}
                        {/*                                            de <b>{moment(subscription.subscription_ends_at.item).locale("ro").format("DD.MM.YYYY HH:mm")}</b>.</Typography>*/}
                        {/*                                    ) : (*/}
                        {/*                                        <>*/}
                        {/*                                            <Typography variant="body2">Următoarea plată în data de {moment(subscription.subscription_billing_date.item).locale("ro").format("DD.MM.YYYY HH:mm")}.</Typography>*/}
                        {/*                                            <Typography variant="body2">În cazul în care dorești să anulezi extraopțiunea te rugăm să alegi opțiunea de anulare a extraopțiunii de mai sus.</Typography>*/}
                        {/*                                        </>*/}
                        {/*                                    )}*/}
                        {/*                                </DashboardBox>*/}
                        {/*                            </Box>*/}
                        {/*                        ))}*/}
                        {/*                    </>*/}
                        {/*                )}*/}
                        {/*            </>*/}
                        {/*        }*/}
                        {/*    </>*/}
                        {/*)}*/}
                    </>
                    ) : (
                    <BoxContent>
                        <Box width="100%">
                            <FormProvider {...methods}>
                                <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                                    <input
                                        type="hidden"
                                        name="show_password"
                                        ref={methods.register("show_password").ref}
                                        defaultValue={"false"}
                                    />

                                    <Grid container spacing={1.5} alignItems="center">
                                        <FormLabelBox title="Nume:" />

                                        <FormFieldBox>
                                            <FormField
                                                name="lastname"
                                                type="text"
                                                defaultValue={user.lastname}
                                                label="Nume"
                                                autoFocus={true}
                                                errorMessage={methods.formState.errors.lastname?.message}
                                                error={!!methods.formState.errors.lastname?.message}
                                            />
                                        </FormFieldBox>

                                        <FormLabelBox title="Prenume:" />
                                        <FormFieldBox>
                                            {!editMode ? (
                                                user.firstname
                                            ) : (
                                                <FormField
                                                    name="firstname"
                                                    type="text"
                                                    defaultValue={user.firstname}
                                                    label="Prenume"
                                                    errorMessage={methods.formState.errors.firstname?.message}
                                                    error={!!methods.formState.errors.firstname?.message}
                                                />
                                            )}
                                        </FormFieldBox>

                                        <FormLabelBox title="E-mail:" />
                                        <FormFieldBox>
                                            {!editMode ? (
                                                user.email
                                            ) : (
                                                <FormField
                                                    name="email"
                                                    type="text"
                                                    defaultValue={user.email}
                                                    label="E-mail"
                                                    errorMessage={methods.formState.errors.email?.message}
                                                    error={!!methods.formState.errors.email?.message}
                                                />
                                            )}
                                        </FormFieldBox>

                                        <FormLabelBox title="Imagine profil:" />
                                        <FormFieldBox>
                                            <>
                                                <UploadButton
                                                    name="profilePhoto"
                                                    label="Imagine profil"
                                                    defaultValue=""
                                                    updateFileCb={updateUploadedFile}
                                                    errorMessage={methods.formState.errors.file?.message}
                                                    error={!!methods.formState.errors.file?.message}
                                                />
                                            </>
                                        </FormFieldBox>
                                        {fileUrl && selectedFile && (
                                            <>
                                                <FormLabelBox />

                                                <FormFieldBox>
                                                    {/* <Badge
                          sx={{ backgroundColor: "transparent" }}
                          badgeContent={
                            <IconButton aria-label="delete" onClick={handleFileDelete}>
                              <FontAwesomeIcon style={{ fontSize: "1rem", color: "#9D0000" }} icon={"xmark"} />
                            </IconButton>
                          }
                        > */}
                                                    <CustomAvatar
                                                        small={false}
                                                        firstname={user.firstname}
                                                        lastname={user.lastname}
                                                        profilePhoto={fileUrl}
                                                    />
                                                    {/* </Badge> */}
                                                </FormFieldBox>
                                            </>
                                        )}
                                        {user.profilePhoto && !selectedFile && (
                                            <>
                                                <FormLabelBox />
                                                <FormFieldBox>
                                                    {/* <Badge
                          sx={{ backgroundColor: "transparent" }}
                          badgeContent={
                            <IconButton aria-label="delete" onClick={handleFileDelete}>
                              <FontAwesomeIcon style={{ fontSize: "1rem", color: "#9D0000" }} icon={"xmark"} />
                            </IconButton>
                          }
                        > */}
                                                    <CustomAvatar
                                                        small={false}
                                                        firstname={user.firstname}
                                                        lastname={user.lastname}
                                                        profilePhoto={user.profilePhoto}
                                                    />
                                                    {/* </Badge> */}
                                                </FormFieldBox>
                                            </>
                                        )}

                                        {/* <>
                    <Grid item xs={12} sm={4} md={2} textAlign="right">
                      <Typography variant="body2">Parola:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} md={10}>
                      <FormField
                        name="password"
                        type="password"
                        label="Parola"
                        error={methods.formState.errors.password?.message}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2} textAlign="right">
                      <Typography variant="body2">Confirmă parola:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} md={10}>
                      <FormField
                        name="confirm_password"
                        type="password"
                        label="Confirmă parola"
                        error={methods.formState.errors.confirm_password?.message}
                      />
                    </Grid>
                  </> */}
                                        {editMode && (
                                            <>
                                                <FormLabelBox />
                                                <FormFieldBox>
                                                    <CustomButton submit buttonType="textIcon" bgcolor="dark">
                                                        SALVEAZĂ PROFILUL
                                                    </CustomButton>
                                                    <ErrorsList show={errorMessage !== ""}>{errorMessage}</ErrorsList>
                                                </FormFieldBox>
                                            </>
                                        )}
                                    </Grid>
                                </form>
                            </FormProvider>
                        </Box>
                    </BoxContent>
                    )}
            </>
        )}
    </Box>
  );
};

export default Profile;
