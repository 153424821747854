import axiosClient from "api/apiClient";

export const APIPostGrades = (data: object) => {
  return axiosClient.post("/tests/grades", data);
};

export const APIPostCategories = (data: object) => {
    return axiosClient.post("/tests/categories", data);
  };

  export const APICreateTest = (data: object) => {
    return axiosClient.post("/tests/create", data);
  };