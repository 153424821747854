const TableRow = ({index, section} : any) => {
    return (
       <>
        <tr className="capitol" key={index}>
            <td className="col-capitol">
                {index + 1}. {section.name}
            </td>
            <td>
                <div className="bar-group clearfix">
                    <div className={`bar ${section.level}`} style={{ width: `${section.score}%` }}>
                        <svg
                            className="print svg-print"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 10 10"
                            preserveAspectRatio="none"
                        >
                            <rect width="10" height="10" />
                        </svg>
                    </div>
                    <div className="number">{section.score}</div>
                </div>
            </td>
        </tr>
        {section.subcategories.map((subcategory: any, index2: any) => (
            <tr className="subcapitol" key={index2}>
                <td className="col-capitol">
                    {index + 1}.{index2 + 1}. {subcategory.name}
                </td>
                <td>
                    <div className="bar-group clearfix">
                        <div className={`bar ${subcategory.level}`} style={{ width: `${subcategory.score}%` }}>
                            <svg
                                className="print svg-print"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 10 10"
                                preserveAspectRatio="none"
                            >
                                <rect width="10" height="10" />
                            </svg>
                        </div>
                        <div className="number">{subcategory.score}</div>
                    </div>
                </td>
            </tr>
        ))}
       </>
    )
}

export default TableRow;