import Grid from "@mui/material/Grid";

type Props = {
  children: JSX.Element[] | JSX.Element | string;
};

const FormFieldBox = ({ children }: Props) => {
  return (
    <Grid item xs={12} sm={10} md={10} xl={11}>
      {children}
    </Grid>
  );
};

export default FormFieldBox;
