import Icon from "../ui/Icon";
import {APIGetTestsHistory} from "../../api/pages/tests";
import moment from "moment";
import * as XLSX from "xlsx";
import {useAuth} from "../../hooks/useAuth";
import CustomButton from "../ui/CustomButton";

interface Props {
    page: number;
    sort: string;
    order: string;
    search: string;
    startDate: Date | undefined;
    endDate: Date | undefined;
    type: string;
}

const ExportButton = ({page, sort, order, search, startDate, endDate, type}: Props) => {
    const { update, user, tenant } = useAuth();

    const exportXlsx = (e: any) => {
        e.preventDefault();

        APIGetTestsHistory({
            page: `?page=${page + 1}`,
            sort: sort,
            order: order,
            search: search,
            startDate: startDate ? moment(startDate).locale("ro").format("YYYY-MM-DD") : "",
            endDate: endDate ? moment(endDate).locale("ro").format("YYYY-MM-DD") : "",
            type: type,
            export: true,
        })
            .then((response) => {
                if (response.data.success) {
                    // console.log("Export Ok", response.data.data.values);
                    const data = (response.data.data.values.map((test: any) => {
                        if (type === "training") {
                            if (user.position === "principal") {
                                return {
                                    Data:
                                        test.date != null
                                            ? moment(test.date).locale("ro").format("YYYY.MM.DD HH:mm")
                                            : "Nedeschis",
                                    Clasa: test.classroom,
                                    Elev: test.name,
                                    Profesor: test.teacher,
                                    Materie: test.subject,
                                    "ID sesiune": test.id,
                                };
                            } else if (user.position === "teacher") {
                                return {
                                    Data:
                                        test.date != null
                                            ? moment(test.date).locale("ro").format("YYYY.MM.DD HH:mm")
                                            : "Nedeschis",
                                    Clasa: test.classroom,
                                    Elev: test.student,
                                    Materie: test.subject,
                                    "ID sesiune": test.id,
                                };
                            }
                        } else {
                            if (user.position === "principal") {
                                return {
                                    Data:
                                        test.date != null
                                            ? moment(test.date).locale("ro").format("YYYY.MM.DD HH:mm")
                                            : "Nedeschis",
                                    Clasa: test.classroom,
                                    Elev: test.student,
                                    Profesor: test.teacher,
                                    Materie: test.subject,
                                    "ID sesiune": test.id,
                                    "Scor total": test.score,
                                    "Scor pe competente": test.score_per_subcategory
                                };
                            } else if (user.position === "teacher") {
                                return {
                                    Data:
                                        test.date != null
                                            ? moment(test.date).locale("ro").format("YYYY.MM.DD HH:mm")
                                            : "Nedeschis",
                                    Clasa: test.classroom,
                                    Elev: test.student,
                                    Materie: test.subject,
                                    "ID sesiune": test.id,
                                    "Scor total": test.score,
                                    "Scor pe competente": test.score_per_subcategory
                                };
                            }
                        }
                        return [];
                    }));
                    const ws = XLSX.utils.json_to_sheet(data);
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
                    /* generate XLSX file and send to client */
                    XLSX.writeFile(wb, `${tenant.name}${startDate ? `-${moment(startDate).locale("ro").format("DD-MM-YYYY")}` : ""}${endDate ? `-${moment(endDate).locale("ro").format("DD-MM-YYYY")}` : ""}.xlsx`);

                } else {
                    update("error", { show: true, code: "A002" });
                }
            })
            .catch((err) => {
                // console.log("getHistoryTests ERR", err);
                update("error", { show: true, code: "A001" });
            });

    }

    return (
        <CustomButton
            bgcolor="dark"
            buttonType="textIcon"
            onClick={(e: any) => {
                exportXlsx(e);
            }}
            icon={<Icon icon="far arrow-down-to-line" fixedWidth={true} />}
        >
            Exportă
        </CustomButton>
    )
}

export default ExportButton;