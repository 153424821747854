import "components/reports/SchoolTestReport.css";
import { ReportLeftBottom, ReportSegmentsImage, ReportLeftBottomXs} from "utils/reportsFunctions";
import CoverPage from "../components/CoverPage";
import moment from "moment";
import Chart from "../components/Chart";
import AboutSection from "./AboutSection";
import ChartSubsection from "../components/ChartSubsection";
import ChartNoteWrapperBig from "../components/ChartNoteWrapperBig";
import TableRow from "../components/TableRow";
import MarkGroup from "../components/MarkGroup";
import Units from "../components/Units";
import SubcategorySection from "./SubcategorySection";
import Plan from "./Plan";
import ScrollBar from "../../ui/ScrollBar";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {useEffect, useState} from "react";

const Report = ({ reportData, gradeLevels, graphData, skillsData }: any) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const [left, setLeft] = useState(0);
    const [bottom, setBottom] = useState(0);
    const segmentsImage = ReportSegmentsImage(reportData.score, "digitalLiteracy");

    useEffect(() => {
        if(isSmallScreen) {
            setLeft(ReportLeftBottom(reportData.score, "digitalLiteracy")[0]);
            setBottom(ReportLeftBottom(reportData.score, "digitalLiteracy")[1]);
        } else {
            setLeft(ReportLeftBottomXs(reportData.score, "digitalLiteracy")[0]);
            setBottom(ReportLeftBottomXs(reportData.score, "digitalLiteracy")[1]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="cover-bg cover-test-peach">
                <div id="print_page" className="wrapper-cover">
                    <CoverPage coverImage="/images/reports/cover-literatie-digitala.png" type="digital-literacy">
                        <>
                            <div className="title">
                                <h1>Testul de literație digitală</h1>
                                <h2>
                                    Sesiune #{reportData.test.id}  / {reportData.test.grade.name}
                                </h2>
                                    <h3>Data: {moment(reportData.test.started_at).locale("ro").format("DD MMMM YYYY")}</h3>
                            </div>
                        </>
                    </CoverPage>

                    <div className="pagebreak"></div>

                    <AboutSection />

                    <div className="pagebreak"></div>

                    <div className="section-despre">
                        <h1>Despre Scorul BRIO</h1>
                        <Chart
                            left={left}
                            bottom={bottom}
                            testScore={reportData.score}
                            segmentsImage={segmentsImage}
                            big={true}
                            type="digitalLiteracy"
                        />
                        {gradeLevels.map((gradeLevel: any, index: any) => (
                            <div key={index}>
                            <ChartSubsection gradeLevel={gradeLevel} score={reportData.score} index={index} type="digitalLiteracy" />
                            </div>
                        ))}
                    </div>

                    <div className="pagebreak"></div>
                    {graphData.length > 0 && (
                        <div className="section-despre">
                            <h1>Profilul competențelor evaluate</h1>
                            <ScrollBar forceVisible="x" autoHide={false}  >
                            <table className="tabel-note">
                                <thead>
                                <tr>
                                    <th className="col-capitol">&nbsp;</th>
                                    <th className="col-values">
                                        <Units type="digitalLiteracy"/>
                                        <MarkGroup type="digitalLiteracy" />
                                    </th>
                                </tr>
                                </thead>

                                {graphData.map((section: any, index: any) => (
                                    <tbody key={index}>
                                    <TableRow section={section} index={index} />
                                    </tbody>
                                ))}
                                <tfoot>
                                <tr className="legenda">
                                    <td>&nbsp;</td>
                                    <td className="col-legend">
                                        <img src="/images/reports/ld/chart-tabel.png" className="chart-tabel" alt="" />
                                        <Units type="digitalLiteracy"/>
                                        <ChartNoteWrapperBig big={false} type="digitalLiteracy" />
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                            </ScrollBar>
                        </div>
                    )}

                    <div className="pagebreak"></div>
                    {skillsData.length > 0 && (skillsData.map((skillData: any, index: any) => (
                        <div key={index}>
                            <SubcategorySection skillsData={skillsData[index]}  type="digitalLiteracy"/>
                        </div>
                            ))
                    )}
                    <Plan />
                </div>
            </div>
        </>
    );
};

export default Report;
