import React, {useEffect, useState} from "react";
import { View, Text } from "@react-pdf/renderer";
import Html from "react-pdf-html";
import {styles } from "../../StylesPdfReport";
import {markedLevelBorder, scoreGrades} from "../../../../utils/reportsFunctions";

const stylesheet = {
    p: {
        fontFamily: "Rubik",
        fontSize: "11pt",
        fontWeight: 400,
        textTransform: undefined,
        margin: 0,
    },
};

const ChartSubsection = ({ gradeLevel, score, index, type, small }: any) => {
    const border = markedLevelBorder(score, gradeLevel.level, type)[0];
    const color = markedLevelBorder(score, gradeLevel.level, type)[1];
    let scoreTitle = scoreGrades(gradeLevel.level, type);
    const [closed, setClosed] = useState(true);

    useEffect(() => {
        if (score >= 0 && score < 35 && gradeLevel.level === "Nivel de bază") setClosed(false);
        else if (score >= 35 && score < 65 && gradeLevel.level === "Intermediar") setClosed(false);
        else if (score >= 65 && score <=95 && gradeLevel.level === "Avansat") setClosed(false);
        else if (score >= 95 && score <= 100 && gradeLevel.level === "Specializat") setClosed(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
            <View
                key={index}
                style={{
                    padding: "10pt 5pt",
                    marginTop: "16pt",
                    border: border,
                }}
                wrap={false}
            >
                <View style={styles.title}>
                    <Text style={{ marginRight: "3pt" }}><>{scoreTitle} </> </Text>
                    <View style={{ width: "10px", height: "10px", margin: 0, bottom: "-2px", position: "relative" }}>
                        <View style={{ backgroundColor: color, width: 10, height: 10 }}></View>
                    </View>
                    <Text style={{ marginLeft: "3pt" }}> <>{gradeLevel.level}</> </Text>
                </View>
                {small ? (closed ? ( <View style={styles.subsectionContent}></View>) : (<View style={styles.subsectionContent}>
                    {Array.isArray(gradeLevel.description) ? (
                        <>
                            {gradeLevel.description.map((description: any, index: any) => (
                                <View key={index} style={{ flexDirection: "row", marginBottom: 4 }}>
                                    <Text style={{ marginRight: 8 }}>•</Text>
                                    <Text>{description}</Text>
                                </View>
                            ))}
                        </>
                    ) : (
                        <Html style={{ fontSize: 9 }} stylesheet={stylesheet}>
                            {gradeLevel.description}
                        </Html>
                    )}
                </View>)) : (
                    <View style={styles.subsectionContent}>
                        {Array.isArray(gradeLevel.description) ? (
                            <>
                                {gradeLevel.description.map((description: any, index: any) => (
                                    <View key={index} style={{ flexDirection: "row", marginBottom: 4 }}>
                                        <Text style={{ marginRight: 8 }}>•</Text>
                                        <Text>{description}</Text>
                                    </View>
                                ))}
                            </>
                        ) : (
                            <Html style={{ fontSize: 9 }} stylesheet={stylesheet}>
                                {gradeLevel.description}
                            </Html>
                        )}
                    </View>
                )}
            </View>
    );
};

export default ChartSubsection;
