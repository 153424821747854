import { View, Text } from "@react-pdf/renderer";
import {styles } from "../../StylesPdfReport";

const Plan = () => {
    return (
        <View break style={styles.sectionAbout}>
            <View style={styles.subsection}>
                <Text style={styles.h3}>Plan de acțiune pentru îmbunătățirea nivelului de alfabetizare digitală</Text>
                <View style={styles.subsectionContent}>
                    <Text style={styles.p}>
                        Pregătirea unui plan de acțiune este primul pas către îmbunătățirea nivelului de alfabetizare digitală.
                        Pentru a îmbunătăți aceste competențe digitale, trebuie să fiți motivați, dedicați și pregătiți pentru
                        schimbare. Schimbările nu se vor întâmpla peste noapte: ele necesită timp și trebuie practicate noi
                        comportamente. Pentru ca competențele dumneavoastră digitale să se îmbunătățească, acțiunile specifice
                        trebuie repetate, consolidate și evaluate frecvent.
                    </Text>
                    <Text style={styles.p}>
                        Consultați secțiunea profilul competențelor digitale. Evaluarea secțiunii este o modalitate eficientă de a
                        vă evalua punctele forte și de a înțelege zonele care trebuie îmbunătățite. După ce toate competențele
                        digitale testate au fost examinate, continuați cu următoarele etape:
                    </Text>
                    <View style={{ flexDirection: "column", marginLeft: "12pt" }}>
                        <View style={{ flexDirection: "row", marginBottom: 5 }}>
                            <Text style={{ marginHorizontal: 8 }}>1.</Text>
                            <Text>
                                {" "}
                                Stabiliți obiective pentru îmbunătățirea competențelor digitale specifice de care aveți nevoie sau în
                                care doriți să fiți mai competent.
                            </Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 5 }}>
                            <Text style={{ marginHorizontal: 8 }}>2.</Text>
                            <Text>
                                Identifică orice obstacole in calea obiectivelor tale si planifică-le corespunzător, ținând cont de
                                acestea.
                            </Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 5 }}>
                            <Text style={{ marginHorizontal: 8 }}>3.</Text>
                            <Text>
                                Căutați experiență sau resurse care vă vor ajuta să învățați și să exersați abilitățile digitale pe care
                                le-ați ales. Acestea pot include resurse digitale, cum ar fi videoclipuri și cursuri online (specifice
                                subiectului tău de interes), prieteni sau colegi care au deja experiență și competență în acest domeniu
                                sau profesori și părinți care îți pot ghida îmbunătățirea.
                            </Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 5 }}>
                            <Text style={{ marginHorizontal: 8 }}>4.</Text>
                            <Text>
                                Continuați să exersați abilitățile identificate și asigurați-vă că aveți un anumit sprijin pentru
                                efortul dvs. Acest sprijin poate include colegi, prieteni, profesori și familie, după cum sa menționat
                                mai sus.
                            </Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 5 }}>
                            <Text style={{ marginHorizontal: 8 }}>5.</Text>
                            <Text>
                                În cele din urmă, revizuiți-vă și reevaluați-vă abilitățile digitale pentru a vă ajuta să vă consolidați
                                ceea ce ați învățat.
                            </Text>
                        </View>
                    </View>
                    <Text>Câteva sugestii pentru dezvoltarea unui plan de acțiune sunt prezentate în continuare.</Text>
                    <Text>
                        Stabilirea obiectivelor poate ajuta la concentrarea directă, la creșterea persistenței și la mobilizarea
                        efortului. De asemenea, ajută indivizii să dezvolte noi strategii pentru a îmbunătăți rezolvarea
                        problemelor. Utilizați sugestiile de stabilire a obiectivelor enumerate mai jos pentru a vă ajuta să vă
                        atingeți obiectivele.
                    </Text>
                </View>
            </View>
            <View style={styles.subsection}>
                <Text style={styles.h3}>Setarea obiectivelor personale</Text>
                <View style={styles.subsectionContent}>
                    <Text style={styles.p}>
                        Pașii pe care îi faci pentru a-ți atinge obiectivele vor determina dacă reușești sau nu să le atingi.
                        Utilizați acest plan de activitate pas cu pas pentru a vă ajuta să vă ghidați mai aproape de obiectivele
                        dvs.
                    </Text>
                    <Text style={styles.pBold}>
                        Notați 3 până la 5 abilități digitale pe care doriți să le îmbunătățiți în perioada următoare. Alegerea lor
                        din lista de competențe testate și prezentate în acest raport este importantă.
                    </Text>
                    <View style={[styles.linesActionPlan, { flexDirection: "column" }]}>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>1.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>2.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>3.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>4.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>5.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                    </View>
                    <Text style={styles.pBold}>
                        Gândiți-vă la resurse care v-ar putea ajuta să vă îmbunătățiți aceste abilități și notați-le.
                    </Text>
                    <View style={[styles.linesActionPlan, { flexDirection: "column" }]}>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>1.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>2.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>3.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>4.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>5.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>6.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>7.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>8.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>9.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>10.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                    </View>
                    <Text style={styles.pBold}>
                        Ordonați resursele menționate anterior în funcție de ușurința cu care pot fi accesate și utilizate.
                    </Text>
                    <View style={[styles.linesActionPlan, { flexDirection: "column" }]}>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>1.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>2.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>3.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>4.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>5.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>6.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>7.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>8.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>9.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text>10.</Text>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                    </View>
                    <Text style={styles.pBold}>
                        Acum, faceți o listă cu 3 competențe digitale pe care doriți să le dezvoltați în următoarea lună. Atribuie
                        fiecărui obiectiv un anumit termen limită și notează pași de acțiune detaliați.
                    </Text>

                    <Text style={styles.pBold}>
                        Competență digitală # 1: ________________________________________________________
                    </Text>
                    <Text style={styles.pBold}>Termen limită: ______/______/______</Text>
                    <Text style={[styles.p, { marginBottom: "1mm" }]}>Acțiunea 1</Text>
                    <View style={[styles.linesActionPlan, { flexDirection: "column" }]}>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                    </View>
                    <Text style={[styles.p, { marginBottom: "1mm" }]}>Acțiunea 2</Text>
                    <View style={[styles.linesActionPlan, { flexDirection: "column" }]}>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                    </View>
                    <Text style={[styles.p, { marginBottom: "1mm" }]}>Acțiunea 3</Text>
                    <View style={[styles.linesActionPlan, { flexDirection: "column" }]}>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                    </View>
                    <Text style={[styles.pBold, { marginTop: "10mm" }]}>
                        Competență digitală # 2: ________________________________________________________
                    </Text>
                    <Text style={styles.pBold}>Termen limită: ______/______/______</Text>
                    <Text style={[styles.p, { marginBottom: "1mm" }]}>Acțiunea 1</Text>
                    <View style={[styles.linesActionPlan, { flexDirection: "column" }]}>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                    </View>
                    <Text style={[styles.p, { marginBottom: "1mm" }]}>Acțiunea 2</Text>
                    <View style={[styles.linesActionPlan, { flexDirection: "column" }]}>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                    </View>
                    <Text style={[styles.p, { marginBottom: "1mm" }]}>Acțiunea 3</Text>
                    <View style={[styles.linesActionPlan, { flexDirection: "column" }]}>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                    </View>
                    <Text style={[styles.pBold, { marginTop: "10mm" }]}>
                        Competență digitală # 3: ________________________________________________________
                    </Text>
                    <Text style={styles.pBold}>Termen limită: ______/______/______</Text>
                    <Text style={[styles.p, { marginBottom: "1mm" }]}>Acțiunea 1</Text>
                    <View style={[styles.linesActionPlan, { flexDirection: "column" }]}>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                    </View>
                    <Text style={[styles.p, { marginBottom: "1mm" }]}>Acțiunea 2</Text>
                    <View style={[styles.linesActionPlan, { flexDirection: "column" }]}>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                    </View>
                    <Text style={[styles.p, { marginBottom: "1mm" }]}>Acțiunea 3</Text>
                    <View style={[styles.linesActionPlan, { flexDirection: "column" }]}>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: 10 }}>
                            <Text style={styles.linesActionPlanLi}></Text>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    );
};

export default Plan;
