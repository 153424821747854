import { View, Text } from "@react-pdf/renderer";
import {styles } from "../../StylesPdfReport";

const Units = ({type} : any) => {
    return (
        <>
            {type === "school" && (
                <View style={styles.unitWrapper}>
                    <View style={[styles.unit, { left: 0, transform: "translate(-2%, 0)" }]}>
                        <View style={styles.numberUnits}>
                            <Text style={{ fontWeight: 600 }}>0</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "5%", transform: "translate(-2%, 0)" }]}>
                        <View style={styles.numberUnits}>
                            <Text>5</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "10%" }]}>
                        <View style={styles.numberUnits}>
                            <Text style={{ fontWeight: 600 }}>10</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "15%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>15</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "20%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>20</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "25%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>25</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "30%" }]}>
                        <View style={styles.numberUnits}>
                            <Text style={{ fontWeight: 600 }}>30</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "35%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>35</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "40%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>40</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "45%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>45</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "50%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>50</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "55%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>55</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "60%" }]}>
                        <View style={styles.numberUnits}>
                            <Text style={{ fontWeight: 600 }}>60</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "65%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>65</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "70%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>70</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "75%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>75</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "80%" }]}>
                        <View style={styles.numberUnits}>
                            <Text style={{ fontWeight: 600 }}>80</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "85%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>85</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "90%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>90</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "95%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>95</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { right: 0, transform: "translate(7%, 0)" }]}>
                        <View style={styles.numberUnits}>
                            <Text style={{ fontWeight: 600 }}>100</Text>
                        </View>
                    </View>
                </View>
            )}

            {type === "literacy" && (
                <View style={styles.unitWrapper}>
                    <View style={[styles.unit, { left: 0, transform: "translate(-2%, 0)" }]}>
                        <View style={styles.numberUnits}>
                            <Text style={{ fontWeight: 600 }}>0</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "5%", transform: "translate(-2%, 0)" }]}>
                        <View style={styles.numberUnits}>
                            <Text>5</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "10%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>10</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "15%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>15</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "20%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>20</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "25%" }]}>
                        <View style={styles.numberUnits}>
                            <Text style={{ fontWeight: 600 }}>25</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "30%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>30</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "35%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>35</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "40%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>40</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "45%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>45</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "50%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>50</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "55%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>55</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "60%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>60</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "65%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>65</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "70%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>70</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "75%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>75</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "80%" }]}>
                        <View style={styles.numberUnits}>
                            <Text style={{ fontWeight: 600 }}>80</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "85%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>85</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "90%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>90</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "95%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>95</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { right: 0, transform: "translate(7%, 0)" }]}>
                        <View style={styles.numberUnits}>
                            <Text style={{ fontWeight: 600 }}>100</Text>
                        </View>
                    </View>
                </View>
            )}

            {type === "digitalLiteracy" && (
                <View style={styles.unitWrapper}>
                    <View style={[styles.unit, { left: 0, transform: "translate(-2%, 0)" }]}>
                        <View style={styles.numberUnits}>
                            <Text style={{ fontWeight: 600 }}>0</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "5%", transform: "translate(-2%, 0)" }]}>
                        <View style={styles.numberUnits}>
                            <Text>5</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "10%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>10</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "15%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>15</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "20%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>20</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "25%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>25</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "30%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>30</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "35%" }]}>
                        <View style={styles.numberUnits}>
                            <Text style={{ fontWeight: 600 }}>35</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "40%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>40</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "45%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>45</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "50%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>50</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "55%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>55</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "60%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>60</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "65%" }]}>
                        <View style={styles.numberUnits}>
                            <Text style={{ fontWeight: 600 }}>65</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "70%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>70</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "75%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>75</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "80%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>80</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "85%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>85</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "90%" }]}>
                        <View style={styles.numberUnits}>
                            <Text>90</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { left: "95%" }]}>
                        <View style={styles.numberUnits}>
                            <Text style={{ fontWeight: 600 }}>95</Text>
                        </View>
                    </View>
                    <View style={[styles.unit, { right: 0, transform: "translate(7%, 0)" }]}>
                        <View style={styles.numberUnits}>
                            <Text style={{ fontWeight: 600 }}>100</Text>
                        </View>
                    </View>
                </View>
            )}
        </>

    );
};

export default Units;
