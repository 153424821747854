import {forwardRef, useRef} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'components/form/DateRangePiker.css';
import FormGroup from "@mui/material/FormGroup";
import {Controller, useFormContext} from "react-hook-form";
import * as React from "react";
import {IconTextFieldStyled, IconTextFieldSmallStyled} from "./FormField";
import Box from "@mui/material/Box";
import "moment/locale/ro";
import { registerLocale } from  "react-datepicker";
import ro from 'date-fns/locale/ro';
import ErrorsList from "../ui/ErrorsList";
import {useAuth} from "../../hooks/useAuth";
registerLocale('ro', ro)

interface Props {
    name: string;
    startDate?: Date | undefined;
    endDate?: Date | undefined;
    onDateChange: (date: any) => void;
    type: string;
    errorMessage?: string | undefined;
    defaultValue?: Date;
    error?: boolean;
    showTimeSelect?: boolean;
    showMonthDropdown?: boolean;
    showYearDropdown?: boolean;
    small?: boolean;
}

type IInputProps = {
    error: boolean;
};

export const CustomInputRangeSmall = forwardRef((props: IInputProps, ref) => {

    return <IconTextFieldSmallStyled type="text" label="Selectează perioada" sx={{width: "100%"}} size="small" {...props} inputRef={ref} error={props.error} />;
});

export const CustomInputRange = forwardRef((props: IInputProps, ref) => {

    return <IconTextFieldStyled type="text" label="Selectează perioada" sx={{width: "100%"}} {...props} inputRef={ref} error={props.error} />;
});

export const CustomInputDate = forwardRef((props: IInputProps, ref) => {

    return <IconTextFieldStyled type="text" label="Selectează data" sx={{width: "100%"}} {...props} inputRef={ref} error={props.error} />;
});

const CustomDatePicker = ({name, startDate, endDate, onDateChange, showTimeSelect = false, showMonthDropdown = false, showYearDropdown = false, type = "datePiker", errorMessage = "", defaultValue, error = false, small = true} : Props) => {
    const {user} = useAuth();
    const inputRef = useRef();
    const methods = useFormContext();

    return (
        <Box width="100%">
            {type === "datePicker" && (
                <>
                    <Controller
                        name={name}
                        control={methods.control}
                        defaultValue={defaultValue}
                        render={({ field: { onChange, value } }) => (
                            <FormGroup sx={{ width: "100%", borderRadius: "15px" }}>
                                <DatePicker
                                    // @ts-ignore
                                    locale={ro}
                                    customInput={<CustomInputDate ref={inputRef} error={error} />}
                                    selected={value}
                                    onChange={onChange}
                                    // peekNextMonth
                                    showMonthDropdown={showMonthDropdown}
                                    showYearDropdown={showYearDropdown}
                                    yearDropdownItemNumber={15}
                                    scrollableYearDropdown
                                    dropdownMode="select"
                                    dateFormat={showTimeSelect ? "dd/MM/yyyy p" : "dd/MM/yyyy"}
                                    fixedHeight
                                    popperClassName={user.theme === "light" ? "" : "dark-mode"}
                                    showTimeSelect={showTimeSelect}
                                    timeFormat="p"
                                    timeIntervals={15}
                                    timeCaption="Ora"
                                />
                            </FormGroup>
                        )}
                    />
                    <ErrorsList show={errorMessage !== ""}>{errorMessage}</ErrorsList>
                </>
            )}
            {type === "controlledDatePicker" && (
                <>
                    <Controller
                        name={name}
                        control={methods.control}
                        defaultValue={defaultValue}
                        render={({ field: { onChange, value } }) => (
                            <FormGroup sx={{ width: "100%", borderRadius: "15px" }}>
                                <DatePicker
                                    // @ts-ignore
                                    locale={ro}
                                    customInput={<CustomInputDate ref={inputRef} error={error} />}
                                    selected={startDate}
                                    onChange={onDateChange}
                                    showMonthDropdown={showMonthDropdown}
                                    showYearDropdown={showYearDropdown}
                                    yearDropdownItemNumber={15}
                                    scrollableYearDropdown
                                    dropdownMode="select"
                                    dateFormat={showTimeSelect ? "dd/MM/yyyy p" : "dd/MM/yyyy"}
                                    fixedHeight
                                    popperClassName={user.theme === "light" ? "" : "dark-mode"}
                                    showTimeSelect={showTimeSelect}
                                    timeFormat="p"
                                    timeIntervals={15}
                                    timeCaption="Ora"
                                />
                            </FormGroup>
                        )}
                    />
                    <ErrorsList show={errorMessage !== ""}>{errorMessage}</ErrorsList>
                </>
            )}
            {type === "dateRangePicker" && (
                <>
                    <Controller
                        name={name}
                        control={methods.control}
                        render={() => (
                            <FormGroup sx={{ width: "100%" }}>
                                <DatePicker
                                    // @ts-ignore
                                    locale={ro}
                                    selected={startDate}
                                    onChange={onDateChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    customInput={small ? <CustomInputRangeSmall ref={inputRef} error={error} /> : <CustomInputRange ref={inputRef} error={error} />}
                                    dateFormat="dd/MM/yyyy"
                                    popperPlacement="bottom-start"
                                    popperModifiers={[
                                        {
                                            name: "flip",
                                            options: {
                                                fallbackPlacements: [],
                                            },
                                        },
                                    ]}
                                    popperClassName={user.theme === "light" ? "" : "dark-mode"}
                                />
                            </FormGroup>
                        )}
                    />
                    <ErrorsList show={errorMessage !== ""}>{errorMessage}</ErrorsList>
                </>
            )}
        </Box>

    );

}

export default CustomDatePicker;