import React from "react";
import {PaperStyled} from "./AutocompleteAPI";
import Autocomplete from "@mui/material/Autocomplete";
import {styled} from "@mui/system";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

export const AutocompleteStyled = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    "& .MuiFilledInput-root, & .MuiFilledInput-root:hover": {
        backgroundColor: `${theme.palette.background.paper} !important`,
        border: "none",
        borderRadius: theme.spacing(1),
        paddingTop: `${theme.spacing(1)} !important`,
        paddingBottom: `${theme.spacing(1)} !important`,
        paddingLeft: `${theme.spacing(1)} !important`,
        color: theme.palette.text.secondary,
        // transition: "all 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    },
    "& .MuiChip-root": {
        color: theme.palette.text.secondary,
        backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    "& .MuiChip-deleteIcon": {
        color: "rgba(0, 40, 155, 0.26)!important",
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #9D0000",
    },
    // "& .MuiInputLabel-root": {
    //     paddingLeft: theme.spacing(1),
    // },
    "& .MuiFilledInput-root.Mui-focused": {
        borderRadius: theme.spacing(1.5),
    },
    "& .MuiFilledInput-root:before, & .MuiFilledInput-root:after": {
        border: "none !important",
    },
    "& label, & label.Mui-focused": {
        color: `${theme.palette.text.secondary} !important`,
        // color: "green",
    },
    "& label.Mui-error": {
        color: `${theme.palette.error.main} !important`,
        // color: "green",
    },
    "& .MuiIconButton-root.MuiAutocomplete-clearIndicator": {
        visibility: "visible",
        color: theme.palette.error.main,
    },
    "& .MuiAutocomplete-popper": {},
    "& .MuiFilledInput-input": {
        // backgroundColor: `${theme.palette.background.light} !important`,
        color: `${theme.palette.text.secondary} !important`,
        border: "none",
        borderRadius: theme.spacing(1),
    },
    "& label.MuiInputLabel-shrink": {
        display: "none",
    },
    "& .MuiIconButton-root": {
        color: theme.palette.primary.light,
    },
    "& .MuiAutocomplete-endAdornment": {
        top: "calc(50% - 16px)",
    },
}));

interface ISearchForm {
    classrooms: any[];
    grade: any[];
    grades: any[];
    classroom: { value: number; label: string; } | null;
    handleGradeChange: (e: React.SyntheticEvent<Element, Event>, value: { value: number; label: string; }[]) => void;
    handleClassroomChange: (e: any, value: { value: number; label: string; } | null) => void;
}

const DashboardFilters = ({grade, grades, classroom, classrooms, handleGradeChange, handleClassroomChange}: ISearchForm) => {

    return (
        <Box display={{xs: "block", md: "flex"}} width="100%">
            <Box width="100%" mr={1}>
                <>
                    <Autocomplete
                        limitTags={6}
                        size="small"
                        options={grades.map((grade) => {
                            return {
                                label: grade.name,
                                value: grade.id,
                                ...grade,
                            };
                        })}
                        blurOnSelect={true}
                        getOptionLabel={(option) => option.label}
                        PaperComponent={PaperStyled}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.value}>
                                    {option.label}
                                </li>
                            );
                        }}
                        renderInput={(params) => (
                            <AutocompleteStyled
                                {...params}
                                label="0-XII"
                                variant="filled"
                                value={grade}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        onChange={handleGradeChange}
                        multiple={true}
                    />
                </>
            </Box>
            <Box width="100%">
                <Autocomplete
                    size="small"
                    options={classrooms}
                    value={classroom}
                    blurOnSelect={true}
                    getOptionLabel={(option: { value: number; label: string; }) => option.label}
                    PaperComponent={PaperStyled}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.value}>
                                {option.label}
                            </li>
                        );
                    }}
                    renderInput={(params) => (
                        <AutocompleteStyled
                            {...params}
                            label="Toate clasele"
                            variant="filled"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                    onChange={handleClassroomChange}
                    multiple={false}
                />
            </Box>
        </Box>
    )
}

export default DashboardFilters;