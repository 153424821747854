import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAuth } from "hooks/useAuth";
// import ThemeToggle from "components/ThemeToggle";
import { nicePosition, niceUserName } from "utils/functions";
import NotificationsIcon from "components/notifications/NotificationsIcon";
import CustomAvatar from "../ui/CustomAvatar";

const UserInfo = ({ small = true }) => {
  const { user } = useAuth();

  return (
    <Box display={ small ? "flex" : {xs: "block", sm: "flex"}} alignItems="center" sx={{ marginBottom: { xs: 0, lg: small ? 3 : 0 } }}>
      <Box sx={{ width: small ? "60px" : "auto" }} mr={small ? 1.5 : {xs: 0, sm: 1.5}} display="flex" justifyContent={small ? "flex-end" : {xs: "center", sm: "flex-end"}}>
        <CustomAvatar small={true} firstname={user.firstname} lastname={user.lastname} profilePhoto={user.profilePhoto} />
      </Box>
      <Box flexGrow={1} sx={{textAlign: small ? "left" : {xs: "center", sm: "left"}, marginTop: small ? 0 : {xs: 1, sm: 0}}}>
        <Typography
          variant="body2"
          color={user.theme === "light" ? "text.disabled" : "primary.light"}
          sx={{ lineHeight: 1 }}
        >
          {nicePosition(user.position)}
        </Typography>
        <Typography variant="h2" color="primary" sx={{ lineHeight: 1, fontWeight: 300 }}>
          {niceUserName(user.firstname, user.lastname, user.email)}
        </Typography>
        {!small && <Typography color="primary">{user.email}</Typography>}
      </Box>
        {/*{small && (*/}
        {/*    <Box mr={1} sx={{ display: { xs: "none", md: "block" } }}>*/}
        {/*        <ThemeToggle small={true} />*/}
        {/*    </Box>*/}
        {/*)}*/}
      {small && (
        <Box sx={{ display: { xs: "none", lg: "block" } }}>
          <NotificationsIcon />
        </Box>
      )}

    </Box>
  );
};

export default UserInfo;
