import Typography from "@mui/material/Typography";
import BoxContent from "components/ui/BoxContent";
import CustomDatePicker from "../../form/CustomDatePicker";

const CreateTestStartDate = ({ startDate, errors, handleTestStartDate }: any) => {

    return (
        <>
            <Typography variant="h3" mt={3} mb={3}>
                Completează în câmpul de mai jos data și ora la care elevul/elevii pot accesa sesiunea de testare generată.
            </Typography>
            <BoxContent zIndex={10}>
                <CustomDatePicker
                    name="test_start_date"
                    startDate={startDate}
                    // defaultValue={startDate}
                    onDateChange={(date) => handleTestStartDate(date)}
                    type="controlledDatePicker"
                    errorMessage={errors.startDate?.message}
                    error={!!errors.startDate?.message}
                    showTimeSelect={true}
                />
                <Typography variant="body1" mb={3}></Typography>
            </BoxContent>
        </>
    );
};

export default CreateTestStartDate;
