import {useEffect, useState} from "react";
import {ReportLeftBottom, ReportLeftBottomXs, ReportSegmentsImage} from "../../../utils/reportsFunctions";
import Chart from "../components/Chart";
import SkillDataScale from "./SkillDataScale";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const SubcategorySection = ({ skillsData }: any) => {
    const [expanded, setExpanded] = useState<string | false>(false);
    const segmentsImage = ReportSegmentsImage(skillsData.score, "digitalLiteracy");
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const [left, setLeft] = useState(0);
    const [bottom, setBottom] = useState(0);

    useEffect(() => {
        if(isSmallScreen) {
            setLeft(ReportLeftBottom(skillsData.score, "digitalLiteracy")[0]);
            setBottom(ReportLeftBottom(skillsData.score, "digitalLiteracy")[1]);
        } else {
            setLeft(ReportLeftBottomXs(skillsData.score, "digitalLiteracy")[0]);
            setBottom(ReportLeftBottomXs(skillsData.score, "digitalLiteracy")[1]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <div className="fix-print">
                <h1>
                    {skillsData.numbering1}. {skillsData.subcategory.category.name}
                </h1>
                <h2 className="mb-10">
                    {skillsData.numbering1}. {skillsData.numbering2}. {skillsData.subcategory.name}
                </h2>
                <Chart
                    left={left}
                    bottom={bottom}
                    testScore={skillsData.score}
                    segmentsImage={segmentsImage}
                    type="digitalLiteracy"
                />
                {skillsData.description && Array.isArray(skillsData.description) ? (
                    <>
                        <div className="subsection">
                            <h3>Scor BRIO {skillsData.score}</h3>
                            <div className="subsection-content">
                                <p>{skillsData.description[0]}</p>
                                <ul>
                                    {skillsData.description.map(
                                        (description: any, index: any) => index !== 0 && <li key={index}>{description}</li>,
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="subsection">
                            <table className={`title-nivel ${skillsData.level}`}>
                                <tbody>
                                <tr>
                                    <td>NIVELUL CORESPUNZĂTOR SCORULUI {skillsData.score}:</td>
                                    <td>
                                        <div className="square"></div>
                                    </td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                            <div className="subsection-content">
                                {skillsData.score_description.map(
                                    (scoreDescriptions: any, index: any) =>
                                        scoreDescriptions.level.toLowerCase() === skillsData.level && (
                                            <div key={index} dangerouslySetInnerHTML={{ __html: scoreDescriptions.description }} />
                                        ),
                                )}
                            </div>
                        </div>

                        {skillsData.score_description.map(
                            (scoreDescriptions: any, index: any) =>
                                scoreDescriptions.study_time !== null &&
                                skillsData.score < 95 && (
                                    <div className="subsection" key={index}>
                                        <h3>
                                            Timp de studiu necesar pentru a ajunge la nivelul următor de dezvoltare a competenței:{" "}
                                            {scoreDescriptions.study_time} ore
                                        </h3>
                                        <div className="subsection-content"></div>
                                    </div>
                                ),
                        )}
                    </>
                ) : (
                    <>
                        <div style={{ fontSize: "12px" }} dangerouslySetInnerHTML={{ __html: skillsData.description }} />
                    </>
                )}
                {skillsData.score_description.length > 0 && (
                    <div>
                        <h2 style={{ marginTop: "15px", marginBottom: "10px" }}>
                            Scala de evaluare - {skillsData.subcategory.name}
                        </h2>
                        <div className="section-evaluare">
                            {skillsData.score_description
                                .slice(0)
                                .reverse()
                                .map((description: any, index: any) => (
                                    <div key={index}>
                                    <SkillDataScale
                                        expanded={expanded}
                                        index={index}
                                        handleChange={handleChange}
                                        description={description}
                                        score={skillsData.score}
                                    />
                                    </div>
                                ))}
                        </div>
                    </div>
                )}
            </div>
            <div className="pagebreak"></div>
        </>
    );
};

export default SubcategorySection;
