import axiosClient from "../apiClient";

interface IOptions {
    id?: string;
    page?: string;
    sort?: string;
    order?: string;
    search?: string;
}

// export const APIPostGradesAndClassrooms = (data: object) => {
//     return axiosClient.post(`/reports/gradesAndClassrooms`, data);
// };

export const APIPostReports = (data: object) => {
    return axiosClient.post(`/reports`, data);
};

export const APIGetReports = (options: IOptions) => {
    return axiosClient.get(`/reports${options.page}`);
};

export const APIGetReport = (options: IOptions) => {
    return axiosClient.get(`/reports/${options.id}`);
};

export const APIDeleteReport = (options: IOptions) => {
    return axiosClient.delete(`/reports/${options.id}`);
};

