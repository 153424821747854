import { createTheme } from "@mui/material/styles";
import { deepmerge } from "@mui/utils";
import themeOptionsDefault from "./default";
import { roRO } from "@mui/material/locale";

declare module "@mui/material/styles" {
  // interface Theme {
  //   status: {
  //     danger: string;
  //   };
  // }
  // allow configuration using `createTheme`
  // interface ThemeOptions {
  //   status?: {
  //     danger?: string;
  //   };
  // }
  interface Palette {
    extra: {
      sidebar: React.CSSProperties["color"];
      sidebar_login: React.CSSProperties["color"];
      dashboard_numbers: React.CSSProperties["color"];
    };
    chart: {
      color1: React.CSSProperties["color"];
      color2: React.CSSProperties["color"];
      color3: React.CSSProperties["color"];
      color4: React.CSSProperties["color"];
      color5: React.CSSProperties["color"];
      color6: React.CSSProperties["color"];
    };
  }
  interface PaletteOptions {
    extra: {
      sidebar: React.CSSProperties["color"];
      sidebar_login: React.CSSProperties["color"];
      dashboard_numbers: React.CSSProperties["color"];
    };
    chart: {
      color1: React.CSSProperties["color"];
      color2: React.CSSProperties["color"];
      color3: React.CSSProperties["color"];
      color4: React.CSSProperties["color"];
      color5: React.CSSProperties["color"];
      color6: React.CSSProperties["color"];
    };
  }
}

const theme = createTheme(
  deepmerge(themeOptionsDefault, {
    typography: {
      h1: {
        color: "#fff",
      },
      h2: {
        color: "#0064FF",
      },
      h3: {
        color: "#6481A6",
      },
      h4: {
        color: "#fff",
      },
      h5: {
        color: "#fff",
      },
      h6: {
        color: "#efefef",
      },
      body1: {
        "& a": {
          color: "#98C0FF",
          textDecoration: "none",
        },
        "& a:hover, & a:active": {
          color: "#fff",
          textDecoration: "none",
        },
      },
    },
    palette: {
      mode: "dark",
      primary: { main: "#fff", dark: "#0064FF", light: "#0064FF", contrastText: "#00289B" },
      secondary: { main: "#000F3D", light: "#98C0FF", dark: "#00289B", contrastText: "#fff" },
      error: { main: "#6E2121", light: "#ED0000", dark: "#9D0000" },
      warning: { main: "#DE6525", dark: "#DE6525" },
      info: { main: "rgba(0, 100, 255, 0.5)", light: "#00289B", dark: "#00289B", contrastText: "#fff" },
      success: { main: "#008D2C", light: "#00BA3B", dark: "#008D2C" },
      text: {
        primary: "#efefef",
        secondary: "#00289b",
        info: "#D7E6FF",
        disabled: "#888888",
      },
      background: {
        paper: "#00289B",
        default: "#000F3D",
        light: "#0064FF",
      },
      divider: "#efefef",
      extra: {
        sidebar: "#011A61",
        sidebar_login: "rgba(0, 40, 155, 0.5)",
        dashboard_numbers: "rgba(255, 255, 255, 0.1)",
      },
      chart: {
        color1: "#0064FF",
        color2: "#ED0000",
        color3: "#008D2C",
        color4: "#B948DB",
        color5: "#011A61",
        color6: "#DE6525",
      },
    },
  }),
  roRO,
);

// theme.typography.h1 = {
//   ...theme.typography.h1,
//   fontSize: "2.75rem",
//   marginBottom: 32,
//   [theme.breakpoints.up("sm")]: {
//     fontSize: "3rem",
//     marginBottom: 48,
//   },
//   [theme.breakpoints.up("md")]: {
//     fontSize: "3.5rem",
//     marginBottom: 56,
//   },
//   [theme.breakpoints.up("lg")]: {
//     fontSize: "4rem",
//     marginBottom: 64,
//   },
// };

// theme.typography.h2 = {
//   ...theme.typography.h2,
//   fontSize: "2rem",
//   "& strong": {
//     fontSize: "2.25rem",
//     fontWeight: 100,
//   },
//   [theme.breakpoints.up("sm")]: {
//     fontSize: "2.25rem",
//     "& strong": {
//       fontSize: "2.5rem",
//     },
//   },
//   [theme.breakpoints.up("md")]: {
//     fontSize: "2.5rem",
//     "& strong": {
//       fontSize: "2.75rem",
//     },
//   },
//   [theme.breakpoints.up("lg")]: {
//     fontSize: "2.75rem",
//     "& strong": {
//       fontSize: "3rem",
//     },
//   },
// };

// theme.typography.h5 = {
//   ...theme.typography.h5,
//   marginBottom: 16,
//   [theme.breakpoints.up("sm")]: {
//     marginBottom: 24,
//   },
//   [theme.breakpoints.up("md")]: {
//     marginBottom: 32,
//   },
//   [theme.breakpoints.up("lg")]: {
//     marginBottom: 40,
//   },
// };

// theme.typography.body1 = {
//   ...theme.typography.body1,
//   fontSize: "1.125rem",
//   lineHeight: 1.3,
//   [theme.breakpoints.up("sm")]: {
//     fontSize: "1.125rem",
//   },
//   [theme.breakpoints.up("md")]: {
//     fontSize: "1.125rem",
//   },
//   [theme.breakpoints.up("lg")]: {
//     fontSize: "1.125rem",
//     lineHeight: 1.2,
//   },
// };

export default theme;
