import GenericButton from "../components/ui/GenericButton";
import PageTitle from "../components/ui/PageTitle";
import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import Grid from "@mui/material/Grid";
import FormLabelBox from "../components/ui/FormLabelBox";
import FormFieldBox from "../components/ui/FormFieldBox";
import FormField from "../components/form/FormField";
import CustomButton from "../components/ui/CustomButton";
import ErrorsList from "../components/ui/ErrorsList";
import BoxContent from "../components/ui/BoxContent";
import * as yup from "yup";
import {useAuth} from "../hooks/useAuth";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {APIPostTag} from "../api/pages/testTags";

interface IFormStudentEdit {
    name: string;
}

const schema = yup
    .object({
    })
    .required();

const TestTag = () => {
    const methods = useForm<IFormStudentEdit>({
        resolver: yupResolver(schema),
    });

    const { update } = useAuth();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const onSubmit: SubmitHandler<IFormStudentEdit> = (data) => {
        methods.clearErrors();
        setErrorMessage("");
        update("loading", { show: true, modal: true });
        APIPostTag({
            name: data.name,
        })
            .then((response) => {
                if (response.data.success) {
                    navigate(`/taguri/taguri-adaugate`, { replace: true });
                    update("loading", { show: false });
                } else {
                    update("loading", { show: false });
                    update("error", { show: true, code: "A002" });
                }
            })
            .catch((err) => {
                // console.log("postStudent ERR", err);
                if (err.response.status === 400) {
                    let errors = err.response.data.data;
                    if (errors.name) {
                        methods.setError("name", { type: "server", message: errors.name[0] });
                    }
                    update("loading", { show: false });
                } else {
                    update("error", { show: true, code: "A001" });
                }
            });
    }

    return (
        <>
            <PageTitle
                back={true}
                actions={
                        <GenericButton
                            type="close"
                            onClick={() => {
                                navigate(-1);
                            }}
                        />
                }
            >
             Adaugă tag
            </PageTitle>
            <BoxContent>
                <Box width="100%">
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                            <Grid container spacing={1.5} alignItems="center">
                                <FormLabelBox title="Nume:" />

                                <FormFieldBox>
                                        <FormField
                                            error={!!methods.formState.errors.name?.message}
                                            name="name"
                                            type="text"
                                            label="Nume"
                                            autoFocus={true}
                                            errorMessage={methods.formState.errors.name?.message}
                                        />
                                </FormFieldBox>

                                <FormLabelBox />

                                <FormFieldBox>
                                    <CustomButton submit buttonType="textIcon" bgcolor="dark">
                                        ADAUGĂ TAG
                                    </CustomButton>
                                    <ErrorsList show={errorMessage !== ""}>{errorMessage}</ErrorsList>
                                </FormFieldBox>
                            </Grid>
                        </form>
                    </FormProvider>
                </Box>
            </BoxContent>
        </>
    )
}

export default TestTag;