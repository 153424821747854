import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Icon from "./Icon";

type Props = {
  children: any;
  show: boolean;
};

const ErrorsList = ({ children, show = false }: Props) => {
  return show ? (
    <Box display="flex" width="100%" alignItems="flex-start" gap={0.5} mt={0.5}>
      <Box color="error.main">
        <Icon icon="circle-exclamation" fixedWidth={true} sx={{ fontSize: "1rem" }} />
      </Box>
      <Box flexGrow={1} mt={0.25}>
        <Typography variant="body2">{children}</Typography>
      </Box>
    </Box>
  ) : null;
};

export default ErrorsList;
