import React, { useState } from "react";

import { ITest } from "components/test/_interfaces";
import TestStartModal from "components/test/TestStartModal";
import TestStarted from "components/test/TestStarted";
import TestStartedLiteracy from "components/test/TestStartedLiteracy";
import { APIStartTest } from "api/pages/test";
import { useAuth } from "hooks/useAuth";

const Test = ({ test, testFromClient }: { test: ITest, testFromClient: boolean } ) => {
  const { update } = useAuth();

  const [testOpened, setTestOpened] = useState<boolean>(test.is_open);
  const [testData, setTestData] = useState<ITest>(test);
  const id = test.test_id;

  const startTest = () => {
    // TO-DO API call to start the test
    // ...
    APIStartTest({ test: id, })
      .then((response) => {
        if (response.data.success) {
          // console.log("getTest OK", response);
          setTestOpened(true);
          setTestData(response.data.data.test);
        } else {
          update("error", { show: true, code: "A002" });
        }
      })
      .catch((err) => {
        // console.log("getTest ERR", err);
        update("error", { show: true, code: "A001" });
      });
  };

  return (
      !testOpened ?
          <TestStartModal test={testData} startTest={startTest} testFromClient={testFromClient} /> :
          test.mode === 5 ? <TestStartedLiteracy test={testData} testFromClient={testFromClient} /> :
              <TestStarted test={testData} testFromClient={testFromClient} />
  )
};

export default Test;
