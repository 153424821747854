import Typography from "@mui/material/Typography";
import FormField from "components/form/FormField";
import BoxContent from "components/ui/BoxContent";

const CreateTestExpire = ({ expire, errors, handleInputChange }: any) => {
  return (
    <>
      <Typography variant="h3" mt={3} mb={3}>
        Introdu timpul maxim de accesare al testului
      </Typography>
      <BoxContent>
        <FormField
          name="expire"
          type="controlled-number"
          label="Timpul maxim de accesare al testului în ore (număr întreg între 1 și 72)"
          value={expire}
          inputProps={{ min: 1, max: 72 }}
          onChange={handleInputChange}
          errorMessage={errors.expire?.message}
          error={!!errors.expire?.message}
        />
        <Typography variant="body1" mb={3}></Typography>
      </BoxContent>
    </>
  );
};

export default CreateTestExpire;
