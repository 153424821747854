import Typography from "@mui/material/Typography";
import FormField from "components/form/FormField";
import BoxContent from "components/ui/BoxContent";

const CreateTestConfig = ({ loading, testConfig, handleSelectChange, errors }: any) => {
  return (
    <>
      <Typography variant="h3" mb={3} mt={3}>
        Alege configurația testului
      </Typography>
      <BoxContent>
        <FormField
          name="test_config"
          type="controlled-select"
          label="Alege configurația"
          value={testConfig}
          onChange={handleSelectChange}
          options={[
            {
              label:
                "Elevul poate rezolva itemii în orice ordine și se poate reîntoarce asupra răspunsurilor la itemi.",
              value: 1,
            },
            {
              label:
                "Elevul trebuie sa rezolve itemii în ordinea din test fără posibilitatea de întoarcere la un item deja rezolvat.",
              value: 2,
            },
          ]}
          errorMessage={errors.test_config?.message}
        />
      </BoxContent>
    </>
  );
};

export default CreateTestConfig;
