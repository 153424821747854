import * as yup from "yup";

export const testSchoolSchema = yup
  .object().shape({
    expire: yup
      .number()
      .min(1, "Timpul minim de accesare al testului nu poate fi mai mic de 1 ora")
      .max(72, "Timpul maxim de accesare al testului nu poate fi mai mare de 72 de ore"),
    // .required("Introdu timpul maxim de accesare al testului"),
  })
  .required();

export const testTrainingSchema = yup
  .object({
    expire: yup
      .number()
      .min(1, "Timpul minim de accesare al testului nu poate fi mai mic de 1 ora")
      .max(72, "Timpul maxim de accesare al testului nu poate fi mai mare de 72 de ore"),
    // .required("Introdu timpul maxim de accesare al testului"),
  })
  .required();