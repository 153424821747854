import Avatar from "@mui/material/Avatar";
import { replaceDevUrl } from "utils/functions";

interface IAvatar {
  small?: boolean;
  firstname: string;
  lastname: string;
  profilePhoto?: string;
}

const CustomAvatar = ({ small = true, firstname, lastname, profilePhoto }: IAvatar) => {
  let avatarWidth = 40;
  let avatarHeight = 40;
  if (!small) {
    avatarWidth = 100;
    avatarHeight = 100;
  }
  return (
    <>
      {profilePhoto ? (
        <Avatar
          alt={firstname + " " + lastname}
          src={replaceDevUrl(profilePhoto, false)}
          sx={{ width: avatarWidth, height: avatarHeight, bgcolor: "info.main", fontSize: "1rem", color: "secondary.contrastText" }}
        />
      ) : (
        <Avatar sx={{ width: avatarWidth, height: avatarHeight, bgcolor: "info.main", fontSize: "1rem", color: "secondary.contrastText" }}>
          {firstname === "" && lastname === "" ? "@" : firstname.charAt(0) + lastname.charAt(0)}
        </Avatar>
      )}
    </>
  );
};

export default CustomAvatar;
