import { View, Text } from "@react-pdf/renderer";
import {styles} from "../../StylesPdfReport";

const ChartNoteWrapperBig = ({type} : any) => {

    return (
        <>
            {type === "school" && (
                <>
                    <View style={styles.chartNoteWrapper}>
                        <View style={[styles.chartNote, { width: "10%", margin: "7pt 0 0 0" }]}>
                            <View style={styles.chartNoteBorder}></View>
                            <Text style={styles.chartNoteName}>Insuficient</Text>
                            <Text style={styles.chartNoteValues}>0–10</Text>
                        </View>
                        <View style={[styles.chartNote, { width: "30%", margin: "7pt 0 0 0", left: "30%" }]}>
                            <View style={styles.chartNoteBorder}></View>
                            <Text style={styles.chartNoteName}>Intermediar</Text>
                            <Text style={styles.chartNoteValues}>80–95</Text>
                        </View>
                        <View style={[styles.chartNote, { width: "15%", margin: "7pt 0 0 0", left: "80%" }]}>
                            <View style={styles.chartNoteBorder}></View>
                            <Text style={styles.chartNoteName}>Avansat</Text>
                            <Text style={styles.chartNoteValues}>30–60</Text>
                        </View>
                    </View>
                    <View style={[styles.chartNoteWrapper, { marginTop: "-1mm" }]}>
                        <View style={[styles.chartNote, { width: "20%", margin: "7pt 0 0 0", left: "10%" }]}>
                            <View style={styles.chartNoteBorder}></View>
                            <Text style={styles.chartNoteName}>Satisfăcător</Text>
                            <Text style={styles.chartNoteValues}>10–30</Text>
                        </View>
                        <View style={[styles.chartNote, { width: "20%", margin: "7pt 0 0 0", left: "60%" }]}>
                            <View style={styles.chartNoteBorder}></View>
                            <Text style={styles.chartNoteName}>Competent</Text>
                            <Text style={styles.chartNoteValues}>60–80</Text>
                        </View>
                        <View style={[styles.chartNote, { width: "5%", margin: "7pt 0 0 0", left: "95%" }]}>
                            <View style={styles.chartNoteBorder}></View>
                            <Text style={styles.chartNoteName}>Expert</Text>
                            <Text style={styles.chartNoteValues}>95–100</Text>
                        </View>
                    </View>
                </>
            )}

            {type === "literacy" && (
                <View style={styles.chartNoteWrapper}>
                    <View style={[styles.chartNote, { left: 0, width: "25%" }]}>
                        <View style={styles.chartNoteBorder}></View>
                        <Text style={styles.chartNoteName}>Alfabetizare subdezvoltată</Text>
                        <Text style={styles.chartNoteValues}>0–25</Text>
                    </View>
                    <View style={[styles.chartNote, { left: "26%", width: "54%" }]}>
                        <View style={styles.chartNoteBorder}></View>
                        <Text style={styles.chartNoteName}>Nivel funcțional al alfabetizării</Text>
                        <Text style={styles.chartNoteValues}>26–80</Text>
                    </View>
                    <View style={[styles.chartNote, { left: "81%", width: "19%" }]}>
                        <View style={styles.chartNoteBorder}></View>
                        <Text style={styles.chartNoteName}>Nivel înalt al alfabetizării</Text>
                        <Text style={styles.chartNoteValues}>81–100</Text>
                    </View>
                </View>
            )}

            {type === "digitalLiteracy" && (
                <>
                    <View style={styles.chartNoteWrapper}>
                        <View style={[styles.chartNote, { width: "35%", margin: "7pt 0 0 0" }]}>
                            <View style={styles.chartNoteBorder}></View>
                            <Text style={styles.chartNoteName}>Nivel de bază</Text>
                            <Text style={styles.chartNoteValues}>0–35</Text>
                        </View>
                        <View style={[styles.chartNote, { width: "30%", margin: "7pt 0 0 0", left: "65%" }]}>
                            <View style={styles.chartNoteBorder}></View>
                            <Text style={styles.chartNoteName}>Avansat</Text>
                            <Text style={styles.chartNoteValues}>65–95</Text>
                        </View>
                    </View>
                    <View style={[styles.chartNoteWrapper, { marginTop: "-1mm" }]}>
                        <View style={[styles.chartNote, { width: "30%", margin: "7pt 0 0 0", left: "35%" }]}>
                            <View style={styles.chartNoteBorder}></View>
                            <Text style={styles.chartNoteName}>Intermediar</Text>
                            <Text style={styles.chartNoteValues}>35-65</Text>
                        </View>
                        <View style={[styles.chartNote, { width: "5%", margin: "7pt 0 0 0", left: "95%" }]}>
                            <View style={styles.chartNoteBorder}></View>
                            <Text style={styles.chartNoteName}>Specializat</Text>
                            <Text style={styles.chartNoteValues}>95–100</Text>
                        </View>
                    </View>
                </>
            )}

        </>
    );
};

export default ChartNoteWrapperBig;
