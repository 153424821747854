import Typography from "@mui/material/Typography";
import ForgotPasswordFormTeachers from "components/login/teachers/ForgotPasswordFormTeachers";
import {Link, useSearchParams} from "react-router-dom";
import { styled } from "@mui/system";
import ChangePasswordTeachersForm from "../components/login/teachers/ChangePasswordFormTeacters";

const LinkStyled = styled(Link)(({ theme }) => ({
    color: theme.palette.secondary.light,
    textDecoration: "none",
    "&:hover, &.active": {
        color: theme.palette.primary.main,
    },
}));

const ForgotPasswordTeachers = () => {
    const [searchParams] = useSearchParams();

    const token = searchParams.get("token");

    return (
        <>
            <Typography variant="h2" sx={{ marginBottom: 2 }}>
                Resetează parola
            </Typography>
            {
                token ? <ChangePasswordTeachersForm /> : <ForgotPasswordFormTeachers />
            }
            <LinkStyled to="/login/profesori">Autentificare</LinkStyled>
        </>
    );
};

export default ForgotPasswordTeachers;
