import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import BoxContent from "components/ui/BoxContent";
import PageTitle from "components/ui/PageTitle";
import Loading from "components/ui/Loading";
import { niceUserName } from "utils/functions";
import CustomDataTable from "components/ui/CustomDataTable";
import { useNavigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import GenericButton from "components/ui/GenericButton";
import {APIGetProfiles, APIPostProfileLogin} from "api/pages/profiles";

const Profiles = () => {
    const { update, login, user } = useAuth();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);
    const [profiles, setProfiles] = useState<any[]>([]);
    const [principal, setPrincipal] = useState<any[]>([]);

    useEffect(() => {
        setLoading(true);
        APIGetProfiles()
            .then((response) => {
                if (response.data.success) {
                    // console.log("getTeachers OK", response);
                    setProfiles(response.data.data.teachers);
                    setPrincipal(response.data.data.principal);
                    setLoading(false);
                } else {
                    update("error", { show: true, code: "A002" });
                }
            })
            .catch((err) => {
                // console.log("getTeachers ERR", err);
                update("error", { show: true, code: "A001" });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const switchProfileHandler = (profile: {
        id: number;
        first_name: string;
        last_name: string;
        email: string;
        [key: string]: any;}) => {
        update("loading", { show: true, modal: true });
        if(user.email === profile.email && user.firstname !== undefined &&
            user.firstname !== null &&
            user.firstname !== "" &&
            user.email_verified !== null) {
            navigate("/statistici");
        } else {
            APIPostProfileLogin({user_id: profile.id})
                .then((response) => {
                    if (response.data.success && response.data.data?.token !== null) {
                        login({
                            firstname: response.data.data.first_name || "",
                            lastname: response.data.data.last_name || "",
                            email: response.data.data.email || "",
                            position: response.data.data.position || "",
                            profilePhoto: response.data.data.profile_photo || "",
                            token: `Bearer ${response.data.data.token}`,
                            menu: response.data.data.menu || [],
                            email_verified: response.data.data.email_verified,
                            id: response.data.data.id,
                            notifications: response.data.data.notifications_unread,
                            schoolBoard: response.data.data.has_school_board,
                            swap: response.data.data.can_swap_profile,
                        });
                        if (response.data.data?.email_verified === null) {
                            navigate("/verifica-email");
                        }
                        update("loading", { show: false });
                    } else {
                        update("loading", { show: false });
                        update("error", { show: true, code: "A002" });
                    }
                })
                .catch((err) => {
                    update("error", { show: true, code: "A001" });
                    // console.log("getTeachers ERR", err);

                });
        }
    };

    return (
        <Box>
            <PageTitle>
                Director
            </PageTitle>
            <BoxContent>
                {loading ? (
                    <Loading show={true} />
                ) : (
                    <CustomDataTable
                        columns={[
                            {
                                id: "last_name",
                                label: "Nume și prenume",
                                numeric: false,
                                noWrap: true,
                                disablePadding: false,
                                sortable: true,
                            },
                            {
                                id: "email",
                                label: "E-mail",
                                numeric: false,
                                disablePadding: false,
                                sortable: true,
                            },
                            {
                                id: "actions",
                                label: "Acțiuni",
                                numeric: true,
                                noWrap: false,
                                disablePadding: true,
                            },
                        ]}
                        rows={principal.map((profile) => {
                            return {
                                // id: teacher.id.toString(),
                                last_name: niceUserName(profile.first_name, profile.last_name, profile.email),
                                email: profile.email,
                                actions: (
                                    <Box display="flex" justifyContent="end">
                                        <Box mr={1}>
                                            <GenericButton
                                                type="switch-user"
                                                onClick={() => {
                                                    switchProfileHandler(profile);
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                ),
                            };
                        })}
                        page={1}
                        pagination={false}
                    />
                )}
            </BoxContent>
            <Box mb={3}/>
            <PageTitle>
                Profesori
            </PageTitle>
            <BoxContent>
                {loading ? (
                    <Loading show={true} />
                ) : profiles.length === 0 ? (
                    <Box>Niciun profesor introdus în platformă.</Box>
                ) : (
                    <CustomDataTable
                        columns={[
                            {
                                id: "last_name",
                                label: "Nume și prenume",
                                numeric: false,
                                noWrap: true,
                                disablePadding: false,
                                sortable: true,
                            },
                            {
                                id: "email",
                                label: "E-mail",
                                numeric: false,
                                disablePadding: false,
                                sortable: true,
                            },
                            {
                                id: "actions",
                                label: "Acțiuni",
                                numeric: true,
                                noWrap: false,
                                disablePadding: true,
                            },
                        ]}
                        rows={profiles.map((profile) => {
                            return {
                                // id: teacher.id.toString(),
                                last_name: niceUserName(profile.first_name, profile.last_name, profile.email),
                                email: profile.email,
                                actions: (
                                    <Box display="flex" justifyContent="end">
                                        <Box mr={1}>
                                            <GenericButton
                                                type="switch-user"
                                                onClick={() => {
                                                    switchProfileHandler(profile);
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                ),
                            };
                        })}
                        page={1}
                        pagination={false}
                    />
                )}
            </BoxContent>
        </Box>
    );
};

export default Profiles;
