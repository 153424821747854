import React from "react";
import Icon from "components/ui/Icon";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { useAuth, IError } from "hooks/useAuth";
import { errorMessage } from "utils/functions";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CustomButton from "./CustomButton";

const ErrorModal = ({ error }: IError) => {
  const { update } = useAuth();

  const okHandler = () => {
    error.returnFunction?.();
    errorMessage(error.code)?.returnFunction?.();
    update("error", {}, true);
  };

  const onClickHandler = () => {
    error.onClickFunction?.();
    errorMessage(error.code)?.onClickFunction?.();
  };

  return (
    <Dialog
      open={error.show}
      onClose={() => {
        return (errorMessage(error.code)?.closable !== undefined ? errorMessage(error.code)?.closable : error.closable)
          ? okHandler()
          : null;
      }}
      maxWidth="md"
      // fullWidth={true}
      PaperComponent={Paper}
      PaperProps={{
        sx: (theme) => ({
          backgroundColor: "info.light",
          backgroundImage: "none",
          padding: theme.spacing(0, 1),
        }),
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ backgroundColor: "rgba(0, 40, 155, 0.5)" }}
    >
      <DialogTitle display="flex" alignItems="center" justifyContent="space-between" gap={1} sx={{ m: 0, p: 2 }}>
        <Box flexGrow={1}>
          <Typography
            variant="h3"
            color="info.contrastText"
            dangerouslySetInnerHTML={{ __html: errorMessage(error.code)?.title || error.title || "" }}
          ></Typography>
        </Box>
        {(error.icon || errorMessage(error.code)?.icon) && (
          <Icon
            icon={errorMessage(error.code)?.icon || error.icon}
            fixedWidth={true}
            style={{ fontSize: 48, color: "primary.main" }}
          />
        )}
        {(errorMessage(error.code)?.closable !== undefined ? errorMessage(error.code)?.closable : error.closable) && (
          <IconButton onClick={okHandler} aria-label="close">
            <Icon icon="fal xmark" sx={{ color: "primary.main" }} />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent sx={(theme) => ({ m: 0, p: theme.spacing(2, 2, 3, 2) })}>
        <Typography variant="body1" component="div">
          <Box dangerouslySetInnerHTML={{ __html: errorMessage(error.code)?.message || error.message || "" }}></Box>
        </Typography>
        {/* {!(errorMessage(error.code)?.closable !== undefined ? errorMessage(error.code)?.closable : error.closable) && (
          <Box mt={4}>
            Cod eroare: <strong>{error.code}</strong>
          </Box>
        )} */}
      </DialogContent>
      {(errorMessage(error.code)?.closable !== undefined ? errorMessage(error.code)?.closable : error.closable) && (
        <DialogActions sx={(theme) => ({ m: 0, p: theme.spacing(0, 2, 3, 2), mt: -1 })}>
          <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
            {/* {error.code !== "" && (
              <Box>
                Cod eroare: <strong>{error.code}</strong>
              </Box>
            )} */}
            {/*<Button*/}
            {/*  variant={error.buttonVariant !== undefined ? error.buttonVariant : "contained"}*/}
            {/*  onClick={okHandler}*/}
            {/*  sx={{ marginLeft: "auto" }}*/}
            {/*>*/}
            {/*  {errorMessage(error.code)?.button !== "" ? errorMessage(error.code)?.button : "ÎNCHIDE"}*/}
            {/*  {error.button !== "" && error.button}*/}
            {/*</Button>*/}
            <CustomButton buttonType="textIcon" onClick={okHandler} bgcolor="modal" style={{ fontWeight: 500, marginLeft: "auto" }}>
              <>
                {errorMessage(error.code)?.button !== "" ? errorMessage(error.code)?.button : "ÎNCHIDE"}
                {error.button !== "" && error.button}
              </>
            </CustomButton>
          </Box>
        </DialogActions>
      )}

      {(errorMessage(error.code)?.onClickFunction || error.onClickFunction) && (
        <DialogActions sx={(theme) => ({ m: 0, p: theme.spacing(0, 2, 3, 2), mt: -1 })}>
          <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
            {/*<Button*/}
            {/*  variant={error.buttonVariant !== undefined ? error.buttonVariant : "contained"}*/}
            {/*  onClick={onClickHandler}*/}
            {/*  sx={{ marginLeft: "auto" }}*/}
            {/*>*/}
            {/*  {errorMessage(error.code)?.button !== "" ? errorMessage(error.code)?.button : "ÎNCHIDE"}*/}
            {/*  {error.button !== "" && error.button}*/}
            {/*</Button>*/}
            <CustomButton buttonType="textIcon" onClick={onClickHandler} bgcolor="modal" style={{ fontWeight: 500, marginLeft: "auto" }}>
              <>
                {errorMessage(error.code)?.button !== "" ? errorMessage(error.code)?.button : "ÎNCHIDE"}
                {error.button !== "" && error.button}
              </>
            </CustomButton>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ErrorModal;
