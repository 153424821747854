import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import {useAuth} from "../../hooks/useAuth";
import {useRef} from "react";
HighchartsHeatmap(Highcharts);
const CustomHeatmapChart = ({ series, yAxis, xAxis, colorAxis, ...rest }: HighchartsReact.Props) => {
    const {user} = useAuth();

    const options: Highcharts.Options = {
        chart: {
            type: "heatmap",
            backgroundColor: "transparent",
            plotBorderWidth: 1,
            height: xAxis.categories.length > 5 ? (yAxis.categories.length * 50) + 150 : (yAxis.categories.length * 55) + 180,
            width: xAxis.categories.length > 5 ?(xAxis.categories.length * 50) + 250 : (xAxis.categories.length * 55) + 250,
        },
        title: {
            text: "",
        },
        plotOptions: {
            series: {
                // @ts-ignore
                pointWidth: 40,
            }
        },
        colorAxis: {
            min: 0,
            minColor: '#eef1f8',
            maxColor: '#002D96',
        },
    }

    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    return (
        <HighchartsReact highcharts={Highcharts} options={{ ...options, series, yAxis, xAxis, colorAxis }} ref={chartComponentRef} {...rest} />
    )
}

export default CustomHeatmapChart;