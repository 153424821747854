import axiosClient from "api/apiClient";

export interface IOptions {
    search?: string;
}

export const APIGetSchools = (options: IOptions) => {
    let search = "";

    if (options.hasOwnProperty("search")) {
        search = "?search=" + options.search;
    }
    return axiosClient.get(`/tenants/schools/login${search}`);
};

export const APIGetSchoolsLogin = (options: IOptions) => {
    let search = "";

    if (options.hasOwnProperty("search")) {
        search = "?search=" + options.search;
    }
    return axiosClient.get(`/tenants/schools/login${search}`);
};
