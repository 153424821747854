import { View, Text, Image } from "@react-pdf/renderer";
import ChartNoteWrapperBig from "components/reports/components/pdf/ChartNoteWrapperBig";
import Units from "components/reports/components/pdf/Units";
import ChartNoteWrapperSmall from "components/reports/components/pdf/ChartNoteWrapperSmall";
import {styles } from "../../StylesPdfReport";
import React from "react";

const Chart = ({ left, bottom, testScore, segmentsImage, big = false, scoreDescription, type }: any) => {
    return (
        <>
            {(type === "school" || type === "digitalLiteracy") && (
                <View wrap={false} style={styles.chart}>
                    <View style={styles.gradeWrapper}>
                        <View style={[styles.chartGrade, { left: `${left}%`, bottom: `${bottom}%` }]}>
                            <Text style={styles.gradeLabel}>Scor BRIO</Text>
                            <View style={styles.gradeValue}>
                                <Text>{testScore}</Text>
                            </View>
                        </View>
                    </View>
                    <Image src={segmentsImage} style={styles.chartBackground} />
                    <Units type={type}/>
                    {big ? (
                        <ChartNoteWrapperBig type={type}/>
                    ) : (
                        <ChartNoteWrapperSmall score={testScore} scoreDescription={scoreDescription} type={type}/>
                    )}
                </View>
            )}

            {type === "literacy" && (
                <View wrap={false} style={styles.chart}>
                    <View style={styles.gradeWrapper}>
                        <View
                            style={[
                                styles.chartGradeLiteracy,
                                {
                                    width: `${testScore}%`,
                                    backgroundColor:
                                        testScore >= 0 && testScore <= 25 ? "#C80000" : testScore > 25 && testScore <= 80 ? "#f9db00" : "#00952C",
                                },
                            ]}
                        >
                            <View style={styles.scoreHorizontalValue}>
                                <Text>{testScore}</Text>
                            </View>
                        </View>
                    </View>
                    <Image src="/images/reports/chart-bg-literatie.png" style={styles.chartBackground} />
                    <Units type="literacy" />
                </View>
            )}

        </>
    );
};

export default Chart;