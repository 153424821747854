import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import Box from "@mui/material/Box";
// import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
// import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface IconProps {
  icon: string;
  fixedWidth?: boolean;
  spin?: boolean;
  className?: string;
  style?: React.CSSProperties;
  sx?: React.CSSProperties;
}

const Icon = ({ icon, ...rest }: IconProps) => {
  //const { iconStyle, iconName } = props;
  // const { size, rotation, spin, border, pull, flip } = rest;
  const { fixedWidth, spin, className, style, sx } = rest;
  const iconName = icon.split(" ")[1] || icon.split(" ")[0];

  // fas far fat fal
  const iconStyle = icon.split(" ")[1] ? icon.split(" ")[0] : "fas";

  return (
    <Box sx={sx}>
      <FontAwesomeIcon
        icon={[iconStyle as IconPrefix, iconName as IconName]}
        fixedWidth={fixedWidth}
        className={className}
        spin={spin}
        style={style}
      />
    </Box>
  );
};

export default Icon;
