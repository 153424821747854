import axiosClient from "api/apiClient";

export const APIGetTenants = () => {
  return axiosClient.get("/tenants");
};

export const APIGetTenantBySlug = (data: object) => {
  return axiosClient.post("/tenants", data);
};

export const APIPostLogin = (data: object) => {
  return axiosClient.post("/login", data);
};

export const APIPostLoginSpecial = (data: object) => {
  return axiosClient.post("/login/special", data);
};

export const APIPostRegister = (data: object) => {
  return axiosClient.post("/register", data);
};

export const APIPostRegisterTeacherSchools = (data: object) => {
  return axiosClient.post("/register/code", data);
};

export const APIGetEmailVerify = (url: string) => {
  return axiosClient.get(url);
};

export const APIVerificationEmail = (data: object) => {
  return axiosClient.post("/email/verification-notification", data);
};