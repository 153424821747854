import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import BoxContent from "components/ui/BoxContent";
import PageTitle from "components/ui/PageTitle";
import { useAuth } from "hooks/useAuth";
import UploadButton from "components/form/UploadButton";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomButton from "../components/ui/CustomButton";
import {APIPostExampleUrl, APIPostImportUsers} from "../api/pages/import";
import ErrorsList from "../components/ui/ErrorsList";
import Loading from "../components/ui/Loading";
import CustomTable from "../components/ui/CustomTable";

interface IFormImport {
    file?: string;
}

const schema = yup
    .object({
    })
    .required();

const UsersImport = () => {
    const { update } = useAuth();

    const methods = useForm<IFormImport>({
        resolver: yupResolver(schema),
    });

    const [loading, setLoading] = useState<boolean>(true);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [fileUrl, setFileUrl] = useState<string>("");
    const [exampleUrl, setExampleUrl] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [errorTable, setErrorTable] = useState<any>(undefined);

    useEffect(() => {
        setLoading(true);
        APIPostExampleUrl()
                .then((response) => {
                    if (response.data.success) {
                        // console.log("getClassroom OK", response);
                        setExampleUrl(response.data.data.value);
                        setLoading(false);
                    } else {
                        update("error", { show: true, code: "A002" });
                    }
                })
                .catch((err) => {
                    update("error", { show: true, code: "A001" });
                    setLoading(false);
                    // console.log("getClassroom ERR", err);
                });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateUploadedFile = (file: File | null, selectedFileUrl: string) => {
        setSelectedFile(file);
        setFileUrl(selectedFileUrl);
        setErrorTable(undefined);
        setErrorMessage("");
    };

    const handleFileDelete = (e: React.MouseEvent) => {
        setSelectedFile(null);
    };

    const onSubmit: SubmitHandler<IFormImport> = (data) => {
        if (selectedFile) {
            update("loading", { show: true, modal: true });
            setErrorTable(undefined);
            const formData = new FormData();
            selectedFile && formData.append("file", selectedFile);

            APIPostImportUsers(formData)
                .then((response) => {
                    if (response.data.success) {
                        // console.log("postFeedback OK", response);
                        update("error", {
                            show: true,
                            message: "Importul este în curs de desfășurare și poate dura până la câteva minute.",
                            title: "Import școală",
                            icon: "fat face-smile",
                            closable: true,
                            button: "ÎNCHIDE",
                        });
                        setSelectedFile(null);
                        setErrorMessage("");
                        update("loading", { show: false});
                    } else {
                        update("error", { show: true, code: "A002" });
                        update("loading", { show: false });
                    }
                })
                .catch((err) => {
                    // console.log("postFeedback ERR", err);
                    if (err.response.status === 400) {
                        let errors = err.response.data.data;
                        setSelectedFile(null);

                        if (errors.value) {
                            methods.setError("file", { type: "server", message: errors.value });
                            setErrorMessage("");
                        } else {
                            setErrorTable({
                                columns: errors.head.map((item:any) => {
                                    if(item === "row") {
                                        return {
                                            id: item,
                                            label: "*",
                                        }
                                    } else {
                                        return {
                                            id: item,
                                            label: item,
                                        }
                                    }
                                }),
                                rows: errors.body,
                            });
                            setErrorMessage("Au apărut erori de validare la import. Următoarele rânduri au date invalide.");
                        }
                        update("loading", { show: false, modal: true });
                    } else {
                        update("error", { show: true, code: "A001" });
                        update("loading", { show: false, modal: true });
                    }
                });
        } else {
            setErrorMessage("Nu ați modificat niciun câmp.");
        }
    };

    return (
        <Box component="main">
            <PageTitle>Importă</PageTitle>
            {loading ? (
                <Loading show={true} />
            ) : (
              <>
                  <Typography variant="h3">Pentru a importa elevii, profesorii și clasele urmează pașii de mai jos:</Typography>
                  <ol>
                      <li>
                          Descarcă fișierul <a href={exampleUrl}>exemplu</a>
                      </li>
                      <li>
                          Completează fișierul cu datele elevilor și ale profesorilor
                      </li>
                      <li>
                          Încarcă fișierul și apasă pe butonul IMPORTĂ
                      </li>
                  </ol>
                  <BoxContent>
                      <Box width="100%">
                          <FormProvider {...methods}>
                              <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                                  <Box mb={1.5}>
                                      <UploadButton
                                          name="file"
                                          label="Imagine"
                                          defaultValue=""
                                          updateFileCb={updateUploadedFile}
                                          errorMessage={methods.formState.errors.file?.message}
                                          error={!!methods.formState.errors.file?.message}
                                          fileType="xls"
                                      />
                                  </Box>

                                  {fileUrl !== "" && selectedFile && (
                                          <Typography>
                                              {selectedFile.name}
                                              <IconButton aria-label="delete" onClick={handleFileDelete}>
                                                <FontAwesomeIcon style={{ fontSize: "1rem", color: "#9D0000" }} icon={"xmark"} />
                                                </IconButton>
                                          </Typography>
                                  )}
                                  <CustomButton buttonType="textIcon" bgcolor="dark" submit={true}>
                                      IMPORTĂ
                                  </CustomButton>
                                  <Box mt={2}><ErrorsList show={errorMessage !== ""}>{errorMessage}</ErrorsList></Box>
                                  {
                                      errorTable &&  <Box mt={3}><CustomTable data={errorTable} /></Box>
                                  }
                              </form>
                          </FormProvider>
                      </Box>
                  </BoxContent>
              </>
            )}
        </Box>
    );
};

export default UsersImport;
