import React from "react";
import { To, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CustomButton from "./CustomButton";
import Icon from "./Icon";

type Props = {
  children: JSX.Element[] | JSX.Element | string | React.ReactNode;
  back?: boolean;
  url?: To;
  actions?: JSX.Element[] | JSX.Element | string | React.ReactNode;
};

const PageTitle = ({ children, back = false, actions = null, url = "" }: Props) => {
  const navigate = useNavigate();

  return (
    <Box display="flex" flexWrap="wrap" gap={1} width="100%" alignItems="center" sx={{ marginBottom: { xs: 2, md: 2, lg: 3 } }}>
      {back && (
        <Box>
          <CustomButton
              buttonType="icon"
            // appearance="circle"
            bgcolor="paper"
            color="primary.main"
            size="small"
            onClick={() => {
              if (url !== "") {
                navigate(url);
              } else {
                navigate(-1);
              }
            }}
            width={36}
            height={36}
            icon={<Icon icon="chevron-left" fixedWidth={true} sx={{ fontSize: "1.25rem" }} />}
          />
        </Box>
      )}
      <Box flexGrow={1}>
        <Typography variant="h1" sx={{ fontSize: { xs: "1.5rem", md: "2rem" } }}>
          {children}
        </Typography>
      </Box>
      {actions && <Box>{actions}</Box>}
    </Box>
  );
};

export default PageTitle;
