import Chart from "./Chart";
import ChartSubsection from "./ChartSubsection";
import {ReportLeftBottom, ReportLeftBottomXs, ReportSegmentsImage} from "../../../utils/reportsFunctions";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useEffect, useState} from "react";

const SubcategorySection = ({ skillsData, type }: any) => {
    const segmentsImage = ReportSegmentsImage(skillsData.score, type);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const [left, setLeft] = useState(0);
    const [bottom, setBottom] = useState(0);

    useEffect(() => {
        if(isSmallScreen) {
            setLeft(ReportLeftBottom(skillsData.score, "school")[0]);
            setBottom(ReportLeftBottom(skillsData.score, "school")[1]);
        } else {
            setLeft(ReportLeftBottomXs(skillsData.score, "school")[0]);
            setBottom(ReportLeftBottomXs(skillsData.score, "school")[1]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="fix-print">
                <h1>
                    {skillsData.numbering1}. {skillsData.subcategory.category.name}
                </h1>
                <h2>
                    {skillsData.numbering1}. {skillsData.numbering2}. {skillsData.subcategory.name}
                </h2>
                <Chart
                    left={left}
                    scoreDescription={skillsData.score_description}
                    bottom={bottom}
                    testScore={skillsData.score}
                    segmentsImage={segmentsImage}
                    type={type}
                />
                {skillsData.description && Array.isArray(skillsData.description) ? (
                    <>
                        <div className="subsection">
                            <h3>Scor BRIO {skillsData.score}</h3>
                            <div className="subsection-content">
                                <p>{skillsData.description[0]}</p>
                                <ul>
                                    {skillsData.description.map(
                                        (description: any, index: any) => index !== 0 && <li key={index}>{description}</li>,
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="subsection">
                            <div className={`title-nivel ${skillsData.level}`}>
                                <div className="td">
                                    <span style={{marginRight: "5pt"}}>NIVELUL CORESPUNZĂTOR SCORULUI {skillsData.score}:</span>
                                    <div className="square" ></div>
                                </div>
                            </div>
                            <div className="subsection-content">
                                {skillsData.score_description.map(
                                    (scoreDescriptions: any, index: any) =>
                                        scoreDescriptions.level.toLowerCase() === skillsData.level && (
                                            <div key={index} dangerouslySetInnerHTML={{ __html: scoreDescriptions.description }} />
                                        ),
                                )}
                            </div>
                        </div>

                        {skillsData.score_description.map(
                            (scoreDescriptions: any, index: any) =>
                                scoreDescriptions.study_time !== null &&
                                skillsData.score < 95 && (
                                    <div className="subsection" key={index}>
                                        <h3>
                                            Timp de studiu necesar pentru a ajunge la nivelul următor de dezvoltare a competenței:{" "}
                                            {scoreDescriptions.study_time} ore
                                        </h3>
                                        <div className="subsection-content"></div>
                                    </div>
                                ),
                        )}
                    </>
                ) : (
                    <>
                        <div dangerouslySetInnerHTML={{ __html: skillsData.description }} />
                    </>
                )}
                {skillsData.score_description.length > 0 && (
                    <>
                        <h2>Scala de evaluare - {skillsData.subcategory.name}</h2>
                        {skillsData.score_description.map((gradeLevel: any, index: any) => (
                            <div key={index}>
                            <ChartSubsection gradeLevel={gradeLevel} score={skillsData.score} index={index} type={type} />
                            </div>
                        ))}
                    </>
                )}
            </div>
            <div className="pagebreak"></div>
        </>
    );
};

export default SubcategorySection;
