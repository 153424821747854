import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PieChart from "components/ui/PieChart";

interface IDBoxPieChart {
  data?: {
    title: string;
    amount: number;
  }[];
}

const DashboardBoxPieChart = ({ data = [] }: IDBoxPieChart) => {
  return data.length === 0 ? (
    <Box width="100%" textAlign="left">
      Nu sunt date.
    </Box>
  ) : (
    <Grid container spacing={2}>
      {data.map((item, index) => (
        <Grid item xs={6} key={index} display="flex" flexDirection="column" alignItems="center">
          <PieChart amount={item.amount} />
          <Typography mb={0} variant="body2" color="secondary.light" textAlign="center" sx={{ mt: 0.5 }}>
            {item.title}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default DashboardBoxPieChart;
