import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { APIGetStudentsArchived } from "api/pages/students";
import {APIUpdateStudentClassrooms} from "api/pages/student";
import Loading from "components/ui/Loading";
import { niceUserName } from "utils/functions";
import CustomDataTable, { Order } from "components/ui/CustomDataTable";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import Icon from "components/ui/Icon";
import SearchForm, { IFormInputs } from "components/form/SearchForm";
import { SubmitHandler } from "react-hook-form";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import Typography from "@mui/material/Typography";
import GenericButton from "components/ui/GenericButton";
import {APIGetAllClassrooms } from "api/pages/classrooms";
import {AutocompleteTextFieldStyled, PaperStyled} from "components/form/AutocompleteAPI";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";

const StudentsArchived = () => {
    const { update, user, tenant } = useAuth();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);
    const [students, setStudents] = useState<any[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [perPage, setPerPage] = useState<number>(15);
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState<string>("last_name");
    const [query, setQuery] = useState<string>("");
    const [search, setSearch] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        APIGetStudentsArchived({ page: `?page=${page + 1}`, sort: orderBy, order: order, search: query })
            .then((response) => {
                if (response.data.success) {
                    // console.log("getStudents OK", response);
                    setStudents(response.data.data.data);
                    setTotalRows(response.data.data.total);
                    setPerPage(response.data.data.per_page);
                    setLoading(false);
                } else {
                    update("error", { show: true, code: "A002" });
                }
            })
            .catch((err) => {
                // console.log("getStudents ERR", err);
                update("error", { show: true, code: "A001" });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, orderBy, order, query, search]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setStudents([]);
        setPage(newPage);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        setPage(0);
    };

    const handleSearch: SubmitHandler<IFormInputs> = (data) => {
        setQuery(data.keywords);
        setPage(0);
        setSearch(true);
    };

    const handleClearSearch = () => {
        setQuery("");
        setSearch(false);
        setPage(0);
    };

    const editStudentHandler = (id: number) => {
        navigate(`/elevi/${id}/editare`);
    };

    const addClassrooms = (id: string, last_name: string, first_name: string, birth_date: string, gender: string, email: string, classrooms: any[], callback: Function) => {
        const studentClassrooms = classrooms.map(({ id }: any) => id);
        if(studentClassrooms.length > 0) {
            update("loading", { show: true, modal: true });
            APIUpdateStudentClassrooms(id, {classrooms: studentClassrooms })
                .then((response) => {
                    if (response.data.success) {
                        // console.log("updateClassroom OK", response);
                        // logic after update

                        APIGetStudentsArchived({ page: `?page=${page + 1}`, sort: orderBy, order: order, search: query })
                            .then((response) => {
                                if (response.data.success) {
                                    // console.log("getStudents OK", response);
                                    setStudents(response.data.data.data);
                                    setTotalRows(response.data.data.total);
                                    setPerPage(response.data.data.per_page);
                                    setLoading(false);
                                } else {
                                    update("error", { show: true, code: "A002" });
                                }
                            })
                            .catch((err) => {
                                // console.log("getStudents ERR", err);
                                update("error", { show: true, code: "A001" });
                            });

                        // setEditMode(false);
                        update("loading", { show: false });
                    } else {
                        update("loading", { show: false });
                        update("error", { show: true, code: "A002" });
                    }

                    callback();
                })
                .catch((err) => {
                    // console.log("updateClassroom ERR", err);
                    update("error", { show: true, code: "A001" });
                    callback();
            });
        }
    };

    const confirmAddClassrooms = (student: {
        id: number;
        first_name: string;
        last_name: string;
        email: string;
        birth_date: string;
        gender: string;
        classrooms: [];
        [key: string]: any;
    }) => {
        let studentClassrooms = student.classrooms
            ? student.classrooms.map((item: { name: string; id: string }) => {
                return {
                    label: item.name,
                    value: item.id,
                    ...item,
                };
            })
            : [];

        const handleOptionSelect = (event: any, newValue: any) => {
            studentClassrooms = newValue;
        };

        APIGetAllClassrooms()
            .then((response) => {
                if (response.data.success) {
                    // console.log("getClassroom OK", response);
                    const classrooms = response.data.data.items.map((item: { name: string; id: string }) => {
                        return {
                            label: item.name,
                            value: item.id,
                            ...item,
                        };
                    });

                    update("confirm", {
                        show: true,
                        closable: true,
                        title: "Adaugă clasă",
                        size: "large",
                        message: (
                            <>
                                <Box sx={{ border: "1px solid #ddd", borderRadius: "10px" }}>
                                    <Autocomplete
                                        // @ts-ignore
                                        defaultValue={studentClassrooms}
                                        isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                                        options={classrooms}
                                        blurOnSelect={true}
                                        getOptionLabel={(option:any) => option.label}
                                        popupIcon={
                                            <Box sx={{ fontSize: "1.25rem" }} color="secondary.light">
                                                <Icon icon="caret-down" fixedWidth={true} />
                                            </Box>
                                        }
                                        clearIcon={
                                            <Box sx={{ fontSize: "1.25rem" }} color="secondary.light">
                                                <Icon icon="xmark" fixedWidth={true} />
                                            </Box>
                                        }
                                        PaperComponent={PaperStyled}
                                        noOptionsText="Nu sunt rezultate"
                                        renderInput={(params) => (
                                            <AutocompleteTextFieldStyled
                                                {...params}
                                                label="Alege clasa"
                                                variant="filled"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                        multiple
                                        loading={loading}
                                        onChange={handleOptionSelect}
                                        freeSolo
                                    />
                                </Box>
                            </>
                        ),
                        returnFunction: (callback: Function) => {
                            addClassrooms(student.id.toString(), student.last_name, student.first_name, student.birth_date, student.gender, student.email, studentClassrooms, callback);
                        },
                        buttonYes: "Adaugă",
                        buttonNo: "Închide",
                    });

                } else {
                    update("error", { show: true, code: "A002" });
                }
            })
            .catch((err) => {
                // console.log("getClassroom ERR", err);
                update("error", { show: true, code: "A001" });
            });
    };

    return (
        <>
            <Box display="flex" justifyContent="end" width="100%" sx={{ marginBottom: { xs: 1, sm: 3 },}}>
                <SearchForm
                    onSubmit={handleSearch}
                    onChange={(e) => setQuery(e.target.value)}
                    onClick={handleClearSearch}
                    query={query}
                />
            </Box>
            {loading ? (
                <Loading show={true} />
            ) : students.length === 0 ? (
                <Box>Niciun elev arhivat.</Box>
            ) : (
                <CustomDataTable
                    columns={[
                        {
                            id: "last_name",
                            label: "Nume și prenume",
                            numeric: false,
                            disablePadding: false,
                            noWrap: true,
                            sortable: true,
                        },
                        {
                            id: "email",
                            label: "E-mail",
                            numeric: false,
                            disablePadding: false,
                            sortable: true,
                        },
                        // {
                        //     id: "classrooms",
                        //     label: "Clasă",
                        //     numeric: false,
                        //     noWrap: true,
                        //     tooltip: true,
                        //     disablePadding: false,
                        // },
                        {
                            id: "email_verified_at",
                            label: "Status",
                            numeric: false,
                            noWrap: true,
                            disablePadding: true,
                            sortable: true,
                        },
                        {
                            id: "actions",
                            label: "Acțiuni",
                            numeric: true,
                            noWrap: false,
                            disablePadding: true,
                        },
                    ]}
                    rows={students.map((student) => {
                        return {
                            last_name: (
                                <Link to={`/elevi/${student.id}`}>
                                    {niceUserName(student.first_name, student.last_name, student.email)}
                                </Link>
                            ),
                            email: student.email,
                            // classrooms: (
                            //     <>
                            //         {student.classrooms.map((classroom: any, index: any) => (
                            //             <Box key={index} mr={1}>
                            //                 <Link to={`/clase/${classroom.id}`}>{classroom.name}</Link>
                            //             </Box>
                            //         ))}
                            //     </>
                            // ),
                            email_verified_at:
                                student.email_verified_at !== null ? (
                                    <Tooltip
                                        title={
                                            <Typography variant="body2" p={1}>
                                                Cont verificat la
                                                <br /> {moment(student.email_verified_at).locale("ro").format("HH:mm, DD MMMM YYYY")}
                                            </Typography>
                                        }
                                    >
                                        <Box sx={{ width: "100%", paddingLeft: "16px" }}>
                                            <Icon
                                                icon="fas circle-check"
                                                fixedWidth={true}
                                                sx={{ fontSize: "1.5rem", color: "success.main" }}
                                            />
                                        </Box>
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        title={
                                            <Typography variant="body2" p={1}>
                                                Cont inactiv
                                            </Typography>
                                        }
                                    >
                                        <Box sx={{ width: "100%", paddingLeft: "16px" }}>
                                            <Icon
                                                icon="fas circle-xmark"
                                                fixedWidth={true}
                                                sx={{ fontSize: "1.5rem", color: "error.main" }}
                                            />
                                        </Box>
                                    </Tooltip>
                                ),
                            actions: (
                                <Box display="flex" justifyContent="end">
                                    {(user.position === "principal" || (tenant.type === "teacher" && user.position === "teacher"))  && (
                                        <Box mr={1}>
                                            <GenericButton type="add-classrooms" onClick={() => confirmAddClassrooms(student)} />
                                        </Box>
                                    )}
                                    <Box mr={1}>
                                        <GenericButton
                                            type="edit-small"
                                            onClick={() => {
                                                editStudentHandler(student.id);
                                            }}
                                        />
                                    </Box>
                                </Box>
                            ),
                        };
                    })}
                    rowsPerPage={perPage}
                    page={page}
                    totalRows={totalRows}
                    onPageChange={handleChangePage}
                    handleRequestSort={handleRequestSort}
                    orderBy={orderBy}
                    order={order}
                />
            )}
        </>
    );
};

export default StudentsArchived;
