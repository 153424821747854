import Typography from "@mui/material/Typography";
import CheckBoxField from "components/form/CheckBoxField";
import BoxContent from "components/ui/BoxContent";
import Loading from "components/ui/Loading";

const CreateTestStudents = ({ loading, users, selectedStudents, setSelectedStudentsHandler, setSelectAllStudentsHandler, errors }: any) => {
  return (
    <>
      <Typography variant="h3" mb={3} mt={3}>
        Alege elevii pentru care dorești generarea testului
      </Typography>
      <BoxContent>
        {loading.loading && loading.name === "students" ? (
          <Loading show={true} />
        ) : (
          <>
            {users.length === 0 ? (
              <Typography>Nu sunt elevi adăugați</Typography>
            ) : (
                <CheckBoxField
                    name="users"
                    type="checkbox-group"
                    label="Alege elevii"
                    labelPlacement="end"
                    selected={selectedStudents}
                    selectAll={true}
                    options={users}
                    onChange={setSelectedStudentsHandler}
                    onSelectAll={setSelectAllStudentsHandler}
                    columns={true}
                    error={errors.users?.message}
                />
            )}
          </>
        )}
        <Typography variant="body1" mt={2}>
          Testele se vor genera doar pentru elevii selectați din clasa curentă.
        </Typography>
      </BoxContent>
    </>
  );
};

export default CreateTestStudents;
