import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import moment from "moment";
import Icon from "components/ui/Icon";
import Box from "@mui/material/Box";

interface IDAccordionData {
  date?: string;
  title: string;
  content: string | JSX.Element;
  status?: string;
  id: string;
  bgColor?: string;
}

interface IDAccordion {
  data: IDAccordionData[];
  returnFunction?: (id: string) => void;
}

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: "none",
    width: "100%",
    borderRadius: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }),
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<Icon icon="caret-down" />} {...props} />
))(({ theme }) => ({
  // backgroundColor: "background.paper",
  width: "100%",

  // flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "none",
}));

const CustomAccordion = ({ data, returnFunction }: IDAccordion) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string, id: string, status: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      if (status === "necitit" && typeof returnFunction !== "undefined") {
        returnFunction(id);
      }
    };

  return (
    <>
      {data.map((item, index) => (
              <Accordion
                  sx={{backgroundColor: item.bgColor ? item.bgColor : "background.paper"}}
                  key={index}
                  expanded={expanded === `panel${index}`}
                  onChange={handleChange(`panel${index}`, item.id, item.status ? item.status : "")}
              >
                  <AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`} >
                      {item.date && (
                          <Box
                              mr={{xs: 1.5, sm:3}}
                              sx={{
                                  width: "auto",
                                  flexShrink: 0,
                                  textAlign: "center",
                              }}
                          >
                              <Typography variant="h4" sx={{ fontWeight: item.status === "necitit" ? 500 : 400, color: "primary.main" }}>
                                  {moment(item.date).locale("ro").format("HH:mm")}
                              </Typography>
                              <Typography variant="body2" sx={{ fontWeight: item.status === "necitit" ? 500 : 400 }}>
                                  {moment(item.date).locale("ro").format("DD-MM-YYYY")}
                              </Typography>
                          </Box>
                      )}
                      <Typography variant="h4" sx={{ fontWeight: item?.status === "necitit" ? 500 : 400, fontSize: {xs: "1.125rem", sm: "1.2rem"}, color: "primary.main" }}>
                          {item.title}
                      </Typography>
                  </AccordionSummary>
                  {typeof item.content === "string" ? (
                      <AccordionDetails dangerouslySetInnerHTML={{ __html: item.content }}></AccordionDetails>
                  ) : (
                      <AccordionDetails>{item.content}</AccordionDetails>
                  )}
              </Accordion>
      ))}
    </>
  );
};

export default CustomAccordion;
