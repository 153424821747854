import Typography from "@mui/material/Typography";
import FormField from "components/form/FormField";
import BoxContent from "components/ui/BoxContent";
import Loading from "components/ui/Loading";

const CreateTestTags = ({ loading, errors, tag, tags, handleSelectChange }: any) => {
    return (
        <>
            <Typography variant="h3" mb={3} mt={3}>
                Alege tagul
            </Typography>
            <BoxContent>
                {loading.loading && loading.name === "tag" ? (
                    <Loading show={true} />
                ) : (
                    <FormField
                        name="tag"
                        type="controlled-select"
                        label="Alege tagul"
                        value={tag}
                        onChange={handleSelectChange}
                        options={tags.map((item: string) => {
                            return {
                                label: item,
                                value: item,
                            };
                        })}
                        errorMessage={errors.tag?.message}
                        error={!!errors.tag?.message}
                    />
                )}
            </BoxContent>
        </>
    );
};

export default CreateTestTags;
