import {Text, View} from "@react-pdf/renderer";
import {styles } from "../../StylesPdfReport";

const TableRow = ({index, section} : any) => {
    return (
        <View key={index}>
            <View style={styles.gradesRow} key={index}>
                <View style={[styles.colChapter, { padding: "5pt 0" }]}>
                    <Text>
                        {index + 1}. {section.name}
                    </Text>
                </View>
                <View style={styles.colValues}>
                    <View style={styles.barGroup}>
                        <View
                            style={[styles.bar, { width: `${section.score}%`, backgroundColor: `${section.color}` }]}
                        ></View>
                        <View style={styles.number}>
                            <Text>{section.score}</Text>
                        </View>
                    </View>
                </View>
            </View>

            {section.subcategories.map((subcategory: any, index2: any) => (
                <View style={styles.gradesRow} key={index2}>
                    <View style={[styles.colSubchapter, { padding: "5pt 0" }]}>
                        <Text>
                            {index + 1}.{index2 + 1}. {subcategory.name}
                        </Text>
                    </View>
                    <View style={styles.colValues}>
                        <View style={styles.barGroup}>
                            <View
                                style={[
                                    styles.bar,
                                    { width: `${subcategory.score}%`, backgroundColor: `${subcategory.color}` },
                                ]}
                            ></View>
                            <View style={styles.numberSubchapter}>
                                <Text>{subcategory.score}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            ))}
        </View>
    )
}

export default TableRow;